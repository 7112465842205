<template>
  <div class="pop-up unit-price-form settings-form">
    <PopUpHead :details="details" />
    <div class="form-holder">
      <div class="currency frame vertical-center">
        <div class="lbl">{{ stringTable.word('currency') }}</div>
        <div class="input-holder">
          <input type="text" :placeholder="stringTable.word('currency')" v-model="price_info.currency">
        </div>
      </div>
      <div class="vertical-center">
        <SelectDropDown class="select-day-of-week" mainLabel="day_of_the_week" :items="dayOfWeeks"
          checkedString="dayOfWeekChecked" :subtitle="dayOfWeekSubtitle" :normalFontLabel="true" :hideIcon="true" />
        <div class="select-holiday frame vertical-center" @click="clickHoliday()" role="button">
          {{ stringTable.word('holiday') }}
        </div>
      </div>
      <!-- {{ chart_datas }} -->
      <!-- {{ chartData }} -->
      <div v-if="showChart" class="zone-bar-holder">
        <!-- {{ index }} -->
        <Chart v-for="(chartData, index) in chart_datas" :key="index" :name="index" :data="chartData" :options="options"
          :plugins="plugins" :isMountedRefresh="true" />
        <div class="zone-bar-label vertical-center">
          <div v-for="lbl in this.zoneBarLabels" :key="lbl" class="zone-bar-lbl">
            {{ lbl }}
          </div>
        </div>
      </div>
    </div>
    <div class="navigation btm-line expand">
      <div class="btn-holder">
        <BtnNavIconText changeScreenVarName="zone" text="weekday" :compoForChangeScreen="this" />
        <BtnNavIconText changeScreenVarName="zone" text="holiday" :compoForChangeScreen="this" />
      </div>
    </div>
    <div class="zone-price-holder">
      <LoadingSpinner v-if="isLoading" />
      <div v-if="showBottom">
        <BarZonePrice v-for="n in [1, 4, 2, 5, 3, -1]" :key="n" :priceInfo="price_info" :zone="zone" :num="n"
          :zoneColors="zoneColors" />
      </div>
    </div>
  </div>
</template>

<style>
.pop-up.unit-price-form .loading-holder {
  margin-top: 60px;
}

.pop-up.unit-price-form .form-holder {
  margin-top: 0;
  margin-bottom: -8px;
}

.pop-up.unit-price-form .currency {
  padding: 0 6px;
  margin-bottom: 6px;
}

.pop-up.unit-price-form .btn-group {
  width: 100%;
}

.pop-up.unit-price-form .select-day-of-week {
  margin-right: auto;
}

.pop-up.unit-price-form .select-day-of-week .btn {
  text-align: left;
}

.pop-up.unit-price-form .select-day-of-week,
.pop-up.unit-price-form .select-holiday {
  width: 49.6%;
  height: 70px;
}

.pop-up.unit-price-form .select-holiday {
  padding: .75rem;
}

.pop-up.unit-price-form .zone-bar-holder {
  /* margin-top: -16px; */
  position: relative;
  width: 100%;
  height: 65px;
}

.pop-up.unit-price-form .chart-holder {
  width: 100%;
  height: 50px;
  padding: 0 23px;
  position: absolute;
}

.pop-up.unit-price-form .zone-bar-lbl {
  margin: 40px auto 0 auto;
}

.pop-up.unit-price-form .zone-bar-lbl:first-child {
  margin-left: 0;
  margin-right: auto;
}

.pop-up.unit-price-form .zone-bar-lbl:last-child {
  margin-left: auto;
  margin-right: 0;
}

.pop-up.unit-price-form .zone-price-holder {
  margin: 6px auto 0 auto;
  text-align: center;
}
</style>

<script>
import PopUpHead from "./PopUpHead.vue"
import SelectDropDown from "./SelectDropDown.vue"
import Chart from './Chart.vue'
import BtnNavIconText from "./BtnNavIconText.vue"
import BarZonePrice from "./BarZonePrice.vue"
import LoadingSpinner from './LoadingSpinner.vue'

export default {
  name: "PopUpUnitPriceSet",
  props: {
    commModule: Object,
    details: Object,
  },
  data() {
    return {
      isLoading: false,
      showBottom: false,
      timer: null,
      dayOfWeeks: [],
      dayOfWeekSubtitle: '',
      showChart: false,
      zoneColors: ['#F44336', '#FFEB3B', '#9C27B0', '#4CAF50', '#FFADC8'],
      data: {
        labels: [23],
        datasets: []
      },
      zoneBarLabels: [],
      plugins: {
        tooltip: { enabled: false },
        legend: { display: false }
      },
      options: {
        indexAxis: 'y',
        scales: {
          x: {
            max: 24,
            min: 0,
            display: false,
            grid: { display: false },
            stacked: true,
          },
          y: {
            display: false,
            type: 'linear',
            stacked: true,
          },
        }
      },
      zone: 'weekday',
    }
  },
  watch: {
    // 'isLoading'() {
    //   if (this.isLoading == true)
    //     this.showBottom = true
    // }
  },
  created() {
    this.isLoading = true
    this.startTimer()
    var weeksFull = this.ConvertHelper.getWeeksFull();
    weeksFull.forEach((w, i) => {
      this.dayOfWeeks.push({ key_id: i, name: w });
    });
    this.clickedCheckBox(null, true);

    this.zoneBarLabels = [];
    var tempTime;
    for (let hour = 0; hour < 24; hour += 8) {
      tempTime = new Date();
      tempTime.setHours(hour);
      tempTime.setMinutes(0);
      this.zoneBarLabels.push(this.ConvertHelper.dtObjToTimeStr(tempTime));
    }
    this.zoneBarLabels.push('24:00');
    this.showChart = true;
  },
  methods: {
    startTimer() {
      if (this.timer)
        clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.isLoading = false
        this.showBottom = true
      }, 1);
    },
    clickedCheckBox(item, isFirstLoad) {
      var dayOfWeeksSubtitle = [];
      var weeks = this.ConvertHelper.getWeeks();

      if (!isFirstLoad) this.holiday_cal.dow = [];
      this.dayOfWeeks.forEach((dw, i) => {
        if (isFirstLoad) dw.dayOfWeekChecked = this.holiday_cal.dow.includes(i);
        else if (dw.dayOfWeekChecked) this.holiday_cal.dow.push(i);

        if (dw.dayOfWeekChecked)
          dayOfWeeksSubtitle.push(weeks[i]);
      });
      if (dayOfWeeksSubtitle.length > 0)
        this.dayOfWeekSubtitle = dayOfWeeksSubtitle.join(",");
      else this.dayOfWeekSubtitle = ' ';
    },
    getChartDataset(startTime, endTime, chartIndex, addedLength) {
      var chartDataset = { labels: [0], datasets: [] };
      var startVal = this.ConvertHelper.zoneTimeToDecimalTime(startTime);
      var endVal = this.ConvertHelper.zoneTimeToDecimalTime(endTime) - startVal;
      [[startVal, '#00000000'], [endVal, this.zoneColors[chartIndex]]].forEach(val => {
        chartDataset.datasets.push({
          type: "bar",
          data: [val[0]],
          backgroundColor: val[1],
          barThickness: 15 - (addedLength * 0.6),
        })
      });
      return chartDataset;
    },
    clickHoliday() {
      this.NavAssistant.showPopUp({
        name: 'PopUpCalendar',
        details: {
          name: 'holiday',
          calendar: this.holiday_cal.calendar
        },
      }, true);
    }
  },
  computed: {
    // showBottom() {
    //   return this.isLoading ? false : true
    // },
    holiday_cal() { return this.commModule.load_holiday_calendar; },
    price_info() { return this.commModule.load_price_info; },
    chart_datas() {
      var result = [];
      var currentZone = this.price_info.zone[this.zone];
      var chartIndex = 0;
      for (const zoneKey in currentZone) {
        if (currentZone[zoneKey].enable) {
          var zonePeriod = currentZone[zoneKey].period;
          result.push(this.getChartDataset(zonePeriod[0], zonePeriod[1], chartIndex, result.length));
        }
        chartIndex++;
      }
      return result.reverse();
    }
  },
  components: {
    PopUpHead,
    SelectDropDown,
    Chart,
    BtnNavIconText,
    BarZonePrice,
    LoadingSpinner
  },
}

</script>