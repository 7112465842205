<template>
	<div class="iaq-moni-page ">
		<LoadingSpinner v-if="isLoading" />
		<div v-else class="bef-tile-section">
			<NavPagination direction="left" />
			<div class="iaq-tile-section">
				<TileIaq v-for="room in slicedList" :key="room" :commModule="commModule" :roomProp="room" />
			</div>
			<NavPagination direction="right" />
		</div>
	</div>
</template>

<style>
.iaq-moni-page {
	width: inherit;
	height: 480px;
}

.iaq-moni-page .bef-tile-section {
	height: inherit;
}

.iaq-moni-page .loading-container {
	width: inherit;
}

.iaq-moni-page .iaq-tile-section {
	text-align: center;
	padding-top: 10px;
}

.iaq-moni-page .iaq-tile-section .top-content .name {
	text-align: left;
	width: 190px;
}

.iaq-moni-page .tile-iaq {
	width: calc(100% / 4 - 6px);
	display: inline-block;
	margin: 0 3px;
	height: 110px;
	overflow: hidden;
}

.tile-iaq .inner {
	width: 100%;
	height: 100%;
}

.tile-iaq .top-content {
	padding: 2px 0;
	font-size: .85em;
}

.tile-iaq .top-content img {
	margin-left: auto;
	width: 18px;
}

.tile-iaq .btm-content {
	text-align: left;
	height: 80px;
	padding: 0 4px;
}

.tile-iaq .btm-content .box {
	display: inline-block;
	width: 33%;
	height: 33px;
	text-align: left;
	padding: 0 3px;
	margin: 2px 0 4px 0;
	border-left-style: solid;
	border-left-width: 4px;
}

.tile-iaq .btm-content .box.gray {
	border-color: var(--fontGray);
}

.tile-iaq .btm-content .box.red {
	border-color: var(--orange);
}

.tile-iaq .btm-content .box.yellow {
	border-color: var(--yellow);
}

.tile-iaq .btm-content .box.green {
	border-color: var(--green);
}

.tile-iaq .btm-content .label {
	font-size: .6em;
}

.tile-iaq .btm-bar {
	height: 6px;
}

.btm-bar.bkg-red {
	background-image: linear-gradient(to bottom, var(--background), var(--orange));
}

.btm-bar.bkg-yellow {
	background-image: linear-gradient(to bottom, var(--background), var(--yellow));
}

.btm-bar.bkg-green {
	background-image: linear-gradient(to bottom, var(--background), var(--green));
}
</style>

<script>
import LoadingSpinner from './LoadingSpinner.vue'
import NavPagination from './NavPagination.vue'
import TileIaq from './TileIaq.vue'

export default {
	name: "PageIaqMoni",
	props: {
		commModule: Object,
		cache: Object,
	},
	data() {
		return {
			page: 1,
			itemPerPage: 16,
			mainList: [],
			slicedList: [],
			isLoading: true
		}
	},
	created() {
		this.NavAssistant.push(this);
		this.commModule.getIaq();
	},
	watch: {
		'commModule.iaq_monitoring_settings'(iaq) {
			this.mainList = [];
			for (const roomName in iaq) {
//				console.log(this.commModule.point_list)
				iaq[roomName] = iaq[roomName].filter(detail => detail.id in this.commModule.point_list)
//				console.log(iaq[roomName])
				if (iaq[roomName].length > 0)
					this.mainList.push({ name: roomName, details: iaq[roomName] });
			}
			this.isLoading = false;
		},
	},
	methods: {
	},
	computed: {
	},
	components: {
		LoadingSpinner,
		NavPagination,
		TileIaq
	},
}
</script>