<template>
  <div class="custom-table">
    <LoadingSpinner v-if="isLoading" />
    <NoList v-else-if="datas.length == 0" />
    <div v-else>
      <div class="label-head table-row font-bold">
        <div v-for="h in headings_loaded" :key="h" class="txt-jump-down">{{ h }}</div>
      </div>
      <div class="data can-scroll">
        <div v-for="data in datas" :key="data" class="table-row">
          <div v-for="d in data" :key="d">
            <span v-if="d.constructor.name == 'Array'">
              <div v-for="sub_d in d" :key="sub_d">{{ sub_d }}</div>
            </span>
            <span v-else>{{ d }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.custom-table {
  width: 650px;
  height: 400px;
  margin: 10px auto;
}

.custom-table .table-row {
  display: flex;
}

.custom-table .table-row>div {
  border-top: 2px solid var(--disable);
}

.custom-table .table-row:first-child>div {
  border-top: none;
}

.custom-table .data {
  height: inherit;
  overflow-y: scroll;
}
</style>

<script>
import LoadingSpinner from './LoadingSpinner.vue'
import NoList from './NoList.vue'

export default {
  name: "CustomTable",
  props: {
    isLoading: Boolean,
    headings: Array,
    datas: Array
  },
  data() {
    return {
      headingsLoaded: []
    }
  },
  created() {
    this.loadHeadings();
  },
  watch: {
    headings() {
      this.loadHeadings();
    }
  },
  computed: {
    headings_loaded() {
      return this.headingsLoaded;
    }
  },
  methods: {
    loadHeadings() {
      this.headingsLoaded = [];
      this.headings.forEach(heading => {
        var headingName = this.stringTable.word(heading);
        if (heading.includes('price'))
          headingName += ` [${this.$parent.commModule.load_price_info.currency}]`;
        if (heading.includes('power'))
          headingName += " [kWh]";
        this.headingsLoaded.push(headingName);
      });
    }
  },
  components: {
    LoadingSpinner,
    NoList
  }
}
</script>
