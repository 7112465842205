<template>
  <div class="nav-pagin vertical-center" :class="{ 'expand-hori': expandHori, [direction]: true }">
    <div class="button-holder vertical-center">
      <div class="frame button vertical-center" :class="class_btn" @click="clicked()" role="button">
        <img class="left-right-btn" v-if="direction == 'left' || direction == 'right'" :src="ReiriIcons.up"
          :class="`rotate-${direction}-90`" />
        <img class="up-down-btn" v-else-if="direction == 'up' || direction == 'down'" :src="ReiriIcons.up"
          :class="`${direction}`" />
        <img class="double-updown-btn" v-else-if="direction == 'double-up' || direction == 'double-down'"
          :src="ReiriIcons.page_up" :class="`${direction}`" />
      </div>
    </div>
  </div>
</template>

<style>
.rotate-left-90 {
  transform: rotate(-90deg);
}

.rotate-right-90 {
  transform: rotate(90deg);
}

.nav-pagin .frame .up,
.nav-pagin .frame .double-up {
  transform: rotate(0deg);
}

.nav-pagin .frame .down,
.nav-pagin .frame .double-down {
  transform: rotate(180deg);
}

.nav-pagin .button-holder {
  margin: 3px auto;
}

.nav-pagin .button-holder .button {
  border-radius: 20px;
  overflow: hidden;
}

.nav-pagin .left-right-btn {
  text-align: center;
  border-radius: 30px;
  width: 30px;
  height: 300px;
  padding: 2px;
}

.nav-pagin.expand-hori {
  flex: 1;
  margin: 1px 0;
}

.nav-pagin.expand-hori.left,
.nav-pagin.expand-hori.up {
  margin: 1px 3px 0 6px;
}

.nav-pagin.expand-hori.right,
.nav-pagin.expand-hori.down {
  margin: 1px 6px 0 3px;
}

.nav-pagin.expand-hori .button-holder,
.nav-pagin.expand-hori .frame {
  width: 100%;
  padding: 0;
  margin-bottom: 0;
}

.nav-pagin.expand-hori .left-right-btn,
.nav-pagin.expand-hori .up-down-btn {
  height: auto;
  margin: 0 auto;
}

.nav-pagin .up-down-btn,
.nav-pagin .double-updown-btn {
  text-align: center;
  border-radius: 30px;
  height: 105px;
  margin: 3px;
  width: 30px;
  padding: 0;
}

.nav-pagin .up-down-btn img,
.nav-pagin .double-updown-btn img {
  width: 100%;
}

.nav-pagin .left-right-btn img {
  height: 30px;
  margin: 0 auto;
}
</style>

<script>
export default {
  name: "NavPagination",
  props: {
    direction: String,
    noNull: Boolean,
    hideBoth: Boolean,
    expandHori: Boolean,
  },
  data() {
    return {
      doublePage: 10,
    }
  },
  watch: {
    'sliced_list'(new_sliced_list) {
      if (this.not_action_nav) return;
      this.$parent.slicedList = new_sliced_list;
      if (this.$parent.page > 1 &&
        this.$parent.slicedList.filter(r => r != null).length == 0) {
        this.$parent.page--;
      }
    }
  },
  created() {
    if (this.not_action_nav) return;
    this.$parent.slicedList = this.sliced_list;
  },
  computed: {
    class_btn() {
      var classes = '';
      if ((this.page_max == 0) ||
        (((this.direction == 'left' || this.direction == 'up') || this.direction == 'double-up') && this.$parent.page == 1) ||
        (((this.direction == 'right' || this.direction == 'down') || this.direction == 'double-down') && this.$parent.page == this.page_max)
      )
        classes = 'invisible';
      return classes;
    },
    page_max() {
      if (this.$parent.mainList) {
        return this.ConvertHelper.getMaxPage(this.$parent.mainList.length, this.$parent.itemPerPage);
      }
      else return 1;
    },
    not_action_nav() {
      return !['up', 'left'].includes(this.direction);
    },
    sliced_list() {
      if (this.not_action_nav) return [];
      var startIndex = (this.$parent.page - 1) * this.$parent.itemPerPage;
      var endIndex = startIndex + this.$parent.itemPerPage;
      var mainList = this.$parent.mainList;
      if (mainList) {
        var sliced = mainList.slice(startIndex, endIndex);
        while (!this.noNull && sliced.length < this.$parent.itemPerPage) {
          sliced.push(null);
        }
        return sliced;
      }
      return [];
    },
  },
  methods: {
    clicked() {
      if (this.direction == 'left' || this.direction == 'up')
        this.pagePrev();
      else if (this.direction == 'right' || this.direction == 'down')
        this.pageNext();
      else if (this.direction == 'double-up')
        this.pageDoublePrev();
      else if (this.direction == 'double-down')
        this.pageDoubleNext();
      if ('pageClicked' in this.$parent.$options.methods)
        this.$parent.pageClicked();
    },
    pagePrev() {
      var pageNew = this.$parent.page - 1;
      if (pageNew > 0) this.$parent.page = pageNew;
    },
    pageNext() {
      var pageNew = this.$parent.page + 1;
      if (pageNew <= this.page_max) this.$parent.page = pageNew;
    },
    pageDoublePrev() {
      var pageNew = this.$parent.page - this.doublePage;
      pageNew > 0 ? this.$parent.page = pageNew : this.$parent.page = 1;
    },
    pageDoubleNext() {
      var pageNew = this.$parent.page + this.doublePage;
      pageNew <= this.page_max ? this.$parent.page = pageNew : this.$parent.page = this.page_max;
    },
  }
}
</script>