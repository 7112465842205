<template>
  <div class="pop-up device-detail history-filter edit-action edit-iaq user device-setting">
    <PopUpToast />
    <PopUpHead :details="head_detail" rightBtnTxt="done" :directText="true" :hideBorderBtm="true" :isSamePageHide="true"
      :leftArr="true" :hideCross="true" :wholeScreen="true" />
    <div class="action-title">
    </div>
    <div class="main-content can-scroll">
      <!-- {{ currentPoint }} -->
      <!-- {{ isTempHumi }} -->
      <SelectDropDown v-if="isNewIAQValue" class="new-title" mainLabel="select_device" :items="allowAddPointList"
        :hideCheckBox="true" :isCloseOutside="true" :currentPoint="currentPoint" />
      <div v-else class="point-title frame">
        <img :src="IconsHelper.iconMain(commModule.point_list[pointDetail.details.id])" class="point-icon" />
        {{ stringTable.word(commModule.point_list[pointDetail.details.id].name) }}
      </div>
      <div class="info-box frame ">
        <div class="upper-content ">
          <div class="left-text vertical-center">
            <div>{{ stringTable.word("type") }}</div>
          </div>
          <!-- {{ pointDetail }} -->
          <div class="right-content type">
            <div v-if="!isNewIAQValue">{{ displayPointType(pointDetail.details.type) }}</div>
            <div v-else>{{ Object.keys(currentPoint).length == 0 ? '-' :
              displayPointType(currentPoint.usage) }}</div>
          </div>
        </div>
      </div>
      <!-- {{ pointDetail.details }} -->
      <div class="title font-bold">
        {{ stringTable.word("threshold") }}
      </div>
      <div class="frame setting">
        <div class="upper-content">
          <div class="left-text vertical-center">
            <div v-if="isTempHumi" class="vertical-center">
              <div class="yellow-circle" />
              <div>{{ stringTable.word("upper") }}</div>
            </div>
            <div v-else class="vertical-center">
              <div class="red-circle" />
              <div>{{ stringTable.word("warning") }}</div>
            </div>
          </div>
          <div class="right-content">
            <input v-if="isTemp" class="input-unit" type="number" v-model="thresholdTemp.red" />
            <input v-else class="input-unit" type="number" v-model="threshold.red" />
          </div>
        </div>
        <!-- {{ pointDetails }} -->
        <div class="upper-content">
          <div class="left-text vertical-center">
            <div v-if="isTempHumi" class="vertical-center">
              <div class="yellow-circle" />
              <div>{{ stringTable.word("lower") }}</div>
            </div>
            <div v-else class="vertical-center">
              <div class="yellow-circle" />
              <div>{{ stringTable.word("caution") }}</div>
            </div>
          </div>
          <div class="right-content">
            <input v-if="isTemp" class="input-unit" type="number" v-model="thresholdTemp.yellow" />
            <input v-else class="input-unit" type="number" v-model="threshold.yellow" />
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style>
.edit-iaq .right-content.type {
  width: 300px;
}

.yellow-circle {
  width: 20px;
  height: 20px;
  background-color: #e8c618;
  border-radius: 50%;
  margin-right: 10px;
}

.red-circle {
  width: 20px;
  height: 20px;
  background-color: #f14444;
  border-radius: 50%;
  margin-right: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.edit-iaq .btn-group {
  width: 100%;
  /* padding: 0px 14px; */
}

.edit-iaq .input-unit {
  height: 30px !important;
}

.edit-iaq .title {
  margin: 20px 0px 10px 0px;
  font-size: 18px;
}

.edit-iaq .new-title {
  margin-bottom: 15px;
}

.edit-iaq .info-box {
  padding: 5px 10px;
  margin-top: 0px;
}
</style>

<script>
import SelectDropDown from './SelectDropDown.vue';
import PopUpToast from './PopUpToast.vue';
import PopUpHead from './PopUpHead.vue';

export default {
  name: "EditIAQValue",
  data() {
    return {
      nameDetails: { name: "edit_iaq_val" },
      allowAddPointList: [],
      currentPoint: {},
      pointDetail: {},
      clonedCommModule: {},
      toast: {},
      threshold: { 'red': '', 'yellow': '' },
      thresholdTemp: { 'red': '', 'yellow': '' },
    }
  },
  props: {
    commModule: Object,
    isRegister: Boolean,
    pointDetails: Object,
    isNewIAQValue: Boolean,
    pointList: Array,
  },
  created() {
    this.pointDetail = JSON.parse(JSON.stringify(this.pointDetails))
    this.allowAddPointList = JSON.parse(JSON.stringify(this.commModule.point_list))
    this.generateAllowAddPointList()

    if (!this.isNewIAQValue) {
      this.threshold.red = this.pointDetail.details.threshold_red.toFixed(1)
      this.threshold.yellow = this.pointDetail.details.threshold_yellow.toFixed(1)

      this.thresholdTemp.red = this.tempConvert.tempConvertRound(this.pointDetail.details.threshold_red)
      this.thresholdTemp.yellow = this.tempConvert.tempConvertRound(this.pointDetail.details.threshold_yellow)
    }
  },
  watch: {
    currentPoint() {
      if (this.isNewIAQValue) {
        this.pointDetail['details'] = {}
      }
      this.pointDetail['details'].id = this.currentPoint.key_id
      if (this.currentPoint.usage == 'ac') {
        this.pointDetail['details'].type = 'temp'
      } else {
        this.pointDetail['details'].type = this.currentPoint.usage
      }
      this.pointDetail['details'].threshold_red = 0
      this.pointDetail['details'].threshold_yellow = 0
    }
  },
  computed: {
    isTempHumi() {
      return ['temp', 'humi', 'dehumi'].includes(this.pointDetail.details.type)
    },
    isTemp() {
      return ['temp'].includes(this.pointDetail.details.type)
    },
    head_detail() {
      var name = '';
      // if (this.showEdit)
      name = this.isNewIAQValue ? 'add_iaq_val' : 'edit_iaq_val';
      return name
    },
  },
  methods: {
    displayPointType(usage) {
      if (usage == 'ac') {
        return this.stringTable.word('temp')
      } else if (usage == 'dehumi') {
        return this.stringTable.word('Humidity')
      } else {
        return this.stringTable.word(usage)
      }
    },
    generateAllowAddPointList() {
      this.pointList.forEach(key => {
        delete this.allowAddPointList[key];
      });
      this.allowAddPointList = Object.values(this.allowAddPointList).filter(item => item.type === "Ac" ||
        item.type === "Dehumi" || item.type === "Value");
    },
    changeOption(newPoint) {
      if (newPoint) {
        this.currentPoint = newPoint
      }
    },
    clickRightBtn() {
      if (Object.keys(this.pointDetail.details).length === 0) {
        this.toast = { text: 'fields_empty', isError: true, };
        return;
      }

      if (this.isTemp) {
        this.pointDetail.details.threshold_red = parseFloat(this.tempConvert.tempRevConvertRound(this.thresholdTemp.red))
        this.pointDetail.details.threshold_yellow = parseFloat(this.tempConvert.tempRevConvertRound(this.thresholdTemp.yellow))
      } else {
        this.pointDetail.details.threshold_red = parseFloat(this.threshold.red)
        this.pointDetail.details.threshold_yellow = parseFloat(this.threshold.yellow)
      }
      if ([null, '', NaN].includes(this.pointDetail['details'].threshold_red)) {
        this.pointDetail['details'].threshold_red = 0
      }
      if ([null, '', NaN].includes(this.pointDetail['details'].threshold_yellow)) {
        this.pointDetail['details'].threshold_yellow = 0
      }
      this.$emit('updatedActionDetails', this.pointDetail);
      this.$parent.isRegister = this.isRegister
      this.back()
    },
    back() {
      this.$parent.showEditIAQValue = false
    },
  },
  components: {
    SelectDropDown,
    PopUpToast,
    PopUpHead
  }
}
</script>