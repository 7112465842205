<template>
	<PopUpToast />
	<LoadingSpinner v-if="isLoading" />
	<div class="bef-tile-section page-scene">
		<NavPagination direction="left" />
		<NoList v-if="mainList.length === 0" name="no_scene" />
		<div class="tile-section">
			<TileScene v-for="(key, index) in slicedList" :key="index" :detailsKey="key" :commModule="commModule" />
			<!-- :details="commModule.scene_list[key]" -->
		</div>
		<NavPagination direction="right" />
	</div>
</template>

<style>
.page-scene .empty-info,
.page-group .empty-info {
	height: 100%;
}
</style>

<script>
import PopUpToast from './PopUpToast.vue'
import TileScene from './TileScene.vue'
import NavPagination from './NavPagination.vue'
import NoList from './NoList.vue'
import LoadingSpinner from './LoadingSpinner.vue'

export default {
	name: "PageScene",
	props: {
		commModule: Object,
	},
	data() {
		return {
			isLoading: false,
			loadingTimer: null,
			toast: {},
			page: 1,
			itemPerPage: 35,
			mainList: [],
			slicedList: [],
		}
	},
	watch: {
	},
	methods: {
		startLoadingTimer() {
			if (this.loadingTimer)
				clearTimeout(this.loadingTimer);
			this.loadingTimer = setTimeout(() => {
				this.isLoading = false
			}, 1);
		},
	},
	computed: {
	},
	created() {
		this.isLoading = true
		this.startLoadingTimer()
		this.mainList = Object.keys(this.commModule.scene_list);
		this.NavAssistant.push(this);
	},
	components: {
		PopUpToast,
		TileScene,
		NavPagination,
		NoList,
		LoadingSpinner,
	},
}
</script>

<!-- 
<img v-for="(img, i) in images" :key="i" :src="img.pathLong" width="40" />

methods: {
	importAll(r) {
		r.keys().forEach(key => (this.images.push({ pathLong: r(key), pathShort: key })));
	},
},

mounted() {
	this.NavAssistant.push(this);
	this.importAll(require.context('../../public/icon/', true, /\.svg$/));
},
-->