<template>
  <div class="guest-room settings">
    <PopUpToast />
    <PopUpHead :details="details" rightBtnTxt="save" :hideBorderBtm="true" />
    <div v-if="Object.keys(settings).length > 0" class="frame-holder justify-between vertical-top">
      <div class="left-holder">
        <div class="unrent-holder">
          <div class="title-heading font-bold">{{ stringTable.word('unrent') }}</div>
          <div class="reverse-frame">
            <GuestControl :settings="settings" label="ac_stat" :keys="['unrent', 'stat']" optStatus="-" />
            <GuestControl :settings="settings" label="csp" :keys="['unrent', 'sp', 0]" optSetpoint="-" />
            <GuestControl :settings="settings" label="hsp" :keys="['unrent', 'sp', 1]" optSetpoint="-" />
            <GuestControl :settings="settings" label="fanspeed" :keys="['unrent', 'fanstep']" valFanspeed="-" />
            <GuestControl :settings="settings" label="rc_proh" :keys="['unrent', 'rcp']" valSwitch="-" />
          </div>
        </div>
        <div class="pre_cool_heat-holder">
          <div class="title-heading font-bold">{{ stringTable.word('pre_cool_heat') }}</div>
          <div class="reverse-frame">
            <GuestControl :settings="settings" label="ac_stat" :keys="['prep', 'stat']" optStatus="-" />
            <GuestControl :settings="settings" label="csp" :keys="['prep', 'sp', 0]" optSetpoint="-" />
            <GuestControl :settings="settings" label="hsp" :keys="['prep', 'sp', 1]" optSetpoint="-" />
            <GuestControl :settings="settings" label="fanspeed" :keys="['prep', 'fanstep']" valFanspeed="-" />
          </div>
        </div>
        <div class="first_occ-holder">
          <div class="title-heading font-bold">{{ stringTable.word('first_occ') }}</div>
          <div class="reverse-frame">
            <GuestControl :settings="settings" label="csp" :keys="['def_sp', 0]" optSetpoint="-" />
            <GuestControl :settings="settings" label="hsp" :keys="['def_sp', 1]" optSetpoint="-" />
          </div>
        </div>
      </div>
      <div class="right-holder">
        <div class="unocc-holder">
          <div class="title-heading font-bold">{{ stringTable.word('unocc') }}</div>
          <div class="reverse-frame">
            <GuestControl :settings="settings" label="ac_stat" :keys="['unocc', 'stat']" optStatus="-" />
            <GuestControl :settings="settings" label="csp" :keys="['unocc', 'sp', 0]" optSetpoint="-" />
            <GuestControl :settings="settings" label="hsp" :keys="['unocc', 'sp', 1]" optSetpoint="-" />
            <GuestControl :settings="settings" label="fanspeed" :keys="['unocc', 'fanstep']" valFanspeed="-" />
            <GuestControl :settings="settings" label="rc_proh" :keys="['unocc', 'rcp']" valSwitch="-" />
          </div>
        </div>
        <div class="delay_settings-holder">
          <div class="title-heading font-bold">{{ stringTable.word('delay_settings') }}</div>
          <div class="reverse-frame">
            <GuestControl :settings="settings" label="window_delay" :keys="['win_delay']" optDelayWin="-" />
            <GuestControl :settings="settings" label="unocc_delay" :keys="['unocc_delay']" optDelayUnoc="-" />
          </div>
        </div>
        <div class="rent_signal-holder">
          <div class="title-heading font-bold">{{ stringTable.word('rent_signal') }}</div>
          <div class="reverse-frame">
            <GuestControl :settings="settings" label="rent_from_office" :keys="['rem_rent_op']" valSwitch="-" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.guest-room.settings .reverse-frame * {
  font-size: .98em;
}

.guest-room.settings .frame-holder>div {
  margin-top: 4px;
  width: 50%;
}

.guest-room.settings .left-holder {
  margin-right: 3px;
}

.guest-room.settings .right-holder {
  margin-left: 3px;
}

.guest-room.settings select,
.guest-room.settings .fanspeed,
.guest-room.settings .switch-holder {
  height: 30px !important;
  margin-left: auto;
}

.guest-room.settings select {
  width: 130px;
}

.guest-room.settings .fanspeed {
  width: 30px;
  padding: 3px;
}

.guest-room.settings .fanspeed img {
  width: 100%;
}

.guest-room.settings .reverse-frame {
  margin-bottom: 4px;
}

.guest-room.settings .reverse-frame>.vertical-center {
  margin: 6px 0;
}
</style>

<script>
import PopUpToast from './PopUpToast.vue'
import PopUpHead from './PopUpHead.vue'
import GuestControl from './GuestControl.vue'

export default {
  name: "GuestSettings",
  props: {
    commModule: Object,
    details: Object,
  },
  data() {
    return {
      toast: {},
      settings: {},
      optsStatus: ['on', 'off', 'setback'],
    }
  },
  created() {
    this.mainData = {
      unrent: {
        ac_stat: { options: ['on', 'off', 'setback'] },
        csp: { options: this.sel_setpoint },
        hsp: { options: this.sel_setpoint },
        fanspeed: {},
        rc_proh: { switch: 'on' },
      },
    }
    this.commModule.getGuestSettings();
  },
  watch: {
    'commModule.get_control_param'(guestSettings) {
      this.settings = guestSettings;
    }
  },
  methods: {
    prev() {
      this.$parent.closeRightScreen();
    },
    clickRightBtn() {
      this.toast = {
        text: 'conf_save',
        pendingYesMethod: () => {
          this.commModule.saveGuestSettings(this.settings);
          this.$parent.currentScreen = '';
        }
      }
    }
  },
  computed: {
    sel_setpoint() {
      var list = [this.stringTable.word('none')];
      for (let index = 16; index <= 32; index += 0.5) {
        list.push(`${index.toFixed(1)}${this.stringTable.word('DEG')}`);
      }
      return list;
    }
  },
  components: {
    PopUpToast,
    PopUpHead,
    GuestControl,
  },
}
</script>