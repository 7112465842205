<template>
	<PopUpToast />
	<div v-if="!roomData" class="tile tile-guest-room frame hidden">
	</div>
	<div v-else class="tile tile-guest-room frame">
		<div class="content vertical-center">
			<div v-if="roomData.connected">
				<div v-if="roomData.occ_stat == 'on'" class="occ-icon-main-holder">
					<img class="to-active" :src="IconsHelper.iconMain(roomData)">
				</div>
				<div v-else-if="roomData.occ_stat == 'off'" class="unocc-icon-main-holder">
					<img class="to-active" :src="IconsHelper.iconMain(roomData)">
				</div>
			</div>
			<div v-else>
				<div class="unocc-icon-main-holder">
					<img class="to-inactive" :src="ReiriIcons.single_bed">
				</div>
			</div>

			<div class="middle-content">
				<div class="name-text">{{ stringTable.word(roomKey) }}</div>
			</div>
			<div v-if="!hideBtn" class="right-content">
				<div v-if="roomData.connected">
					<div v-if="roomData.rent_stat == 'on'" class="active-button-main-holder" @click="checkInOut('off')"
						role="button">
						<img class="button-holder" :src="ReiriIcons.lock_1">
					</div>
					<div v-else-if="roomData.rent_stat == 'off'" class="inactive-button-main-holder" @click="checkInOut('on')"
						role="button">
						<img class="button-holder" :src="ReiriIcons.lock_2">
					</div>
				</div>
				<div v-else>
					<div class="disconnected-button-main-holder">
						<img class="button-holder" :src="ReiriIcons.no_connection">
					</div>
				</div>
			</div>



		</div>

	</div>
</template>

<style>
.tile-guest-room .name-text {
	height: 100%;
	width: 65px;
	margin: 0 4px;
	text-overflow: clip;
	white-space: normal;
}

.tile-guest-room .content {
	padding: 2px;
	height: 100%;
}

.tile-guest-room .content>div {
	display: inline-block;
}

.tile-guest-room .middle-content {
	width: 70px;
}

.tile-guest-room .occ-icon-main-holder {
	padding: 8px;
	margin-right: 3px;
	border-radius: 50%;
	background-color: rgba(255, 211, 167, 0.455);
	box-shadow: inset 3px 4px 5px rgba(246, 188, 73, 0.626);
	border-bottom: 2.5px solid var(--frameBorder);
	border-right: 2.5px solid var(--frameBorder);
	width: fit-content;
}

.tile-guest-room .occ-icon-main-holder img {
	width: 30px;
	height: 30px;
}

.tile-guest-room .unocc-icon-main-holder {
	padding: 8px;
	margin-right: 3px;
	border-radius: 50%;
	box-shadow: inset 1px 1px 3px rgb(159, 159, 159);
	border-bottom: 2.5px solid var(--frameBorder);
	border-right: 2.5px solid var(--frameBorder);
	width: fit-content;
}

.tile-guest-room .unocc-icon-main-holder img {
	width: 30px;
	height: 30px;
}

.tile-guest-room .disconnected-button-main-holder {
	padding: 8px;
	margin-right: 3px;
	border-radius: 50%;
	background-color: rgb(241, 68, 68);
	box-shadow: inset 1px 1px 3px rgb(159, 159, 159);
	border-bottom: 2.5px solid var(--frameBorder);
	border-right: 2.5px solid var(--frameBorder);
	width: fit-content;
}

.tile-guest-room .active-button-main-holder {
	padding: 8px;
	border-radius: 50%;
	background-color: var(--active);
	box-shadow: inset 1px 1px 3px rgb(159, 159, 159);
	border-bottom: 2.5px solid var(--frameBorder);
	border-right: 2.5px solid var(--frameBorder);
	width: fit-content;
}

.tile-guest-room .right-content {
	margin-left: auto;
}

.tile-guest-room .inactive-button-main-holder {
	padding: 8px;
	border-radius: 50%;
	background-color: var(--inactive);
	box-shadow: inset 1px 1px 3px rgb(159, 159, 159);
	border-bottom: 2.5px solid var(--frameBorder);
	border-right: 2.5px solid var(--frameBorder);
	width: fit-content;
}

.tile-guest-room .button-holder {
	width: 30px;
	height: 30px;
	filter: invert()
}
</style>

<script>
import PopUpToast from './PopUpToast.vue'

export default {
	name: "TileGuestRoom",
	props: {
		roomData: new Map(),
		roomKey: String,
		commModule: Object
	},
	data() {
		return {
			toast: {},
			hideBtn: false,
		}
	},
	created() {
		if (!this.roomData) return;
		if (this.roomData.connected) {
			if (!this.roomData.rem_rent_op) {
				this.hideBtn = true
			}
			if (this.roomData.rent_stat == null) {
				this.hideBtn = true
			}
		}
	},
	watch: {
	},
	methods: {
		checkInOut(data) {
			var roomOp = {}
			var msg = ''

			if (this.roomData.rent_stat == 'off') {
				msg = 'check_in'
			} else if (this.roomData.rent_stat == 'on') {
				msg = 'check_out'
			}

			this.toast = {
				text: msg,
				pendingYesMethod: () => {
					roomOp[this.roomKey] = data
					this.commModule.guestRoomRentOp(roomOp);
				}
			}
		}
	},
	computed: {
	},
	components: {

		PopUpToast,
	},
}
</script>