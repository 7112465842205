<template>
  <div class="pop-up passwd">
    <PopUpToast />
    <PopUpHead :details="details" :showIcon="false" ref="myChild" :hideBorderBtm="true" :leftArr="true"
      :hideCross="true" />
    <div class="pw-description"> {{ stringTable.word("pass_req") }}
    </div>
    <div class="input-holder">
      <FrameInputPwd class="password-input" varStr="newPasswd" placeholder="new_passwd" />
      <FrameInputPwd varStr="confirmPasswd" placeholder="confirm_passwd" />
      <div v-if="isInvalid" class=" pw-not-match">
        {{ pwInvalidMesssage }}
      </div>
      <div class="submit-holder">
        <input type="submit" :class="(newPasswd && confirmPasswd) ? 'button-enable' : 'button-disable'"
          :value="stringTable.word('save')" @click="submitPassword" maxlength=30 minlength=10 @input="validatePassword">
      </div>
    </div>
  </div>
</template>

<style>
.pop-up.passwd select,
.pop-up.passwd input[type=text],
.pop-up.passwd input[type=date],
.pop-up.passwd input[type=password] {
  font-size: 1.2em;
  /* height: 40px !important; */
}

.pop-up.passwd .password-input {
  margin-bottom: 20px;
}

.pop-up.passwd .pw-not-match {
  color: red;
}

.pop-up.passwd .pop-up-head {
  font-size: 25px;
  height: 60px;
  padding: auto;
}

.pop-up.passwd .pw-description {
  text-align: center;
  margin: 10px 70px 20px 70px;
  font-size: 20px;
}

.pop-up.passwd .input-holder {
  width: 350px;
  margin: 0 auto;
}

.pop-up.passwd .submit-holder {
  width: 300px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
</style>

<script>
import PopUpHead from "./PopUpHead.vue";
import FrameInputPwd from "./FrameInputPwd.vue";
import PopUpToast from './PopUpToast.vue'

export default {
  name: "PopUpPassword",
  data() {
    return {
      details: { name: "passwd" },
      newPasswordVisible: false,
      confirmPasswordVisible: false,
      newPasswd: '',
      confirmPasswd: '',
      isInvalid: null,
      pwInvalidMesssage: null,
      userName: '',
      toast: {},
    }
  },
  props: {
    commModule: Object,
  },
  created() {
    this.userName = localStorage.getItem('User')
  },
  methods: {
    // updateNewPassword(pw) {
    //   this.newPasswd = pw
    // },
    // updateConfirmPassword(pw) {
    //   this.confirmPasswd = pw
    // },
    submitPassword() {
      if (this.newPasswd == '' || this.confirmPasswd == '') {
        this.isInvalid = true;
        this.pwInvalidMesssage = this.stringTable.word("no_password")
        return
      } else if (this.newPasswd !== this.confirmPasswd) {
        this.isInvalid = true;
        this.pwInvalidMesssage = this.stringTable.word("pass_err_mismatch")
        return
      } else {
        // const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{10,}$/;
        // if (!(regex.test(this.newPasswd) && regex.test(this.confirmPasswd))) {
        //     this.isInvalid = true
        //     this.pwInvalidMesssage = "Password doesn't meet requirements"
        // } else {
        //     this.isInvalid = false
        // }
        this.toast = {
          text: 'conf_save',
          pendingYesMethod: () => {
            this.commModule.changePwd({
              name: this.userName,
              passwd: this.confirmPasswd
            });
          }
        }
      }
    },
  },
  watch: {
    'commModule.change_passwd'() {
      if (this.commModule.change_passwd.result == "OK") {
        this.NavAssistant.hidePopUp()
      } else {
        this.isInvalid = true;
        this.pwInvalidMesssage = this.stringTable.word("pass_err")
      }
    },
  },
  components: {
    PopUpHead,
    FrameInputPwd,
    PopUpToast,
  },
}
</script>