<template>
  <div v-if="!details" class="tile frame hidden">
  </div>
  <div v-else class="tile tile-group frame" @click="clickedTile()" role="button">
    <div class="content vertical-center">
      <div class="icon-main-holder" @click.stop="clickedIcon">
        <img :src="IconsHelper.iconMain(details)" class="icon-main" :class="{ 'to-active': canShow('stat_on') }" />
      </div>
      <div class="right-content">
        <div class="name-text">{{ stringTable.word(details.name) }}</div>
        <div class="btm-content">
          <div v-if="canShow('error_red') || canShow('error_alert') || canShow('error_blue') || canShow('filter') ||
            get_temp_target() || get_value_target()" class="value-info vertical-center can-scroll">
            <div v-if="canShow('error_red')" class="detail-frame only-icon error-holder">
              <img :src="ReiriIcons.warning" class="to-error" />
            </div>
            <div v-if="canShow('error_alert')" class="detail-frame only-icon alert-holder">
              <img :src="ReiriIcons.warning" class="to-alert" />
            </div>
            <div v-if="canShow('error_blue')" class="detail-frame only-icon error-blue-holder">
              <img :src="ReiriIcons.warning" class="to-blue" />
            </div>
            <div v-if="canShow('filter')" class="detail-frame-plain only-icon ">
              <img :src="ReiriIcons.clean_filter" />
            </div>
            <div v-if="get_temp_target()" class="detail-frame icon-text">
              <img :src="ReiriIcons.thermometer" />{{ get_temp_target() }}
            </div>
            <div v-if="get_value_target()" class="detail-frame only-text">
              {{ get_value_target() }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.layout .error-blue-holder,
.tile .error-blue-holder {
  background-color: rgb(183, 215, 253) !important;
}

.tile-group .name-text {
  width: 120px;
}

.tile-group .content,
.tile-scene .content {
  padding: 2px;
  height: 100%;
}

.tile-group .content>div,
.tile-scene .content>div {
  display: inline-block;
}

.tile-group .right-content {
  width: 100%;
}

.tile-group .icon-main-holder,
.tile-scene .icon-main-holder {
  padding: 8px;
  margin-right: 3px;
  border-radius: 50%;
  box-shadow: inset 1px 1px 3px rgb(159, 159, 159);
  border-bottom: 2.5px solid var(--frameBorder);
  border-right: 2.5px solid var(--frameBorder);
  width: fit-content;
}

.tile-group .icon-main-holder img,
.tile-scene .icon-main-holder img {
  width: 30px;
  height: 30px;
}

.tile-group .name-text,
.tile-scene .name-text {
  margin: 0 4px;
}

.tile-group .btm-content {
  display: flex;
  width: 125px;
}

.tile-group .btm-content .value-info {
  height: 22px;
  width: fit-content;
  overflow-x: scroll;
}
</style>

<script>

export default {
  name: "TileGroup",
  props: {
    details: Object,
    detailsKey: String
  },
  data() {
    return {
    };
  },
  created() {
  },
  computed: {
  },
  methods: {
    gotTrue(obj) {
      return obj ? Object.values(obj).includes(true) : false;
    },
    canShow(type) {
      if (!this.details) return;
      var canShow = false;
      this.details.mp.forEach(mp => {
        var point = this.$parent.commModule.point_list[mp];
        if (point &&
          ((type == 'error_red' && point.comm_stat && this.gotTrue(point.error)) ||
            (type == 'error_alert' && point.comm_stat && this.gotTrue(point.alert)) ||
            (type == 'error_blue' && (!point.comm_stat)) ||
            (type == 'filter' && (point.filter == 'on' && point.comm_stat)) ||
            (type == 'stat_on' && (point.stat == 'on' && point.comm_stat)))
        ) {
          canShow = true;
        }
      });
      this.$parent.commModule.group_list[this.detailsKey][type] = canShow;
      return canShow;
    },
    get_temp_target() {
      var point = this.$parent.commModule.point_list[this.details.temp_targ];
      if (!point || !point.comm_stat) return '';
      var val = '';
      if (point.temp) val = point.temp;
      else if (point.av) val = point.av;
      return val ? `${this.tempConvert.tempConvert(val).toFixed(1)
        } ${this.stringTable.word('DEG')}` : '';
    },
    get_value_target() {
      var point = this.$parent.commModule.point_list[this.details.value_targ];
      if (!point || !point.comm_stat) return '';
      return point.av ? `${this.ConvertHelper.formatNumber(point.av)} ${point.unit}` : '';
    },
    clickedTile() {
      this.NavAssistant.showPopUp({
        name: 'PopUpDevices',
        details: this.details,
        // page: this.$parent.page,
      });
    },
    clickedIcon() {
      var someOnCommStat = false;
      var usages = [];
      this.details.mp.forEach(point_key => {
        var point = this.$parent.commModule.point_list[point_key];
        if (point) {
          if (!usages.includes(point.usage)) usages.push(point.usage);
          if (point.comm_stat) someOnCommStat = true;
        }
      });
      var grpKey = this.detailsKey;
      if (someOnCommStat && ['ac', 'hrv', 'light', 'switch', 'dehumi', 'light'].some(u => usages.includes(u)))
        this.NavAssistant.showPopUp({
          name: 'PopUpOperGrp',
          details: { ...this.details, usages, grpKey },
          page: this.$parent.page,
        });
    }
  },
  components: {
  }
}
</script>