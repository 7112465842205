<template>
  <div class="pop-up schedule-setting system-setting">
    <div class="content-holder">
      <div class="left-content nav-hori-holder">
        <PopUpToast />
        <PopUpHead :details="{ name: 'schedule' }" :hideBorderBtm="true" :leftArr="true" :small="true"
          :hideCross="true" />
        <LoadingSpinner v-if="isLoading" :topSpacing="true" />
        <div v-else>
          <div class="nav-list">
            <div v-for="title, screen  in screenTitleMap" :key="title" class="frame">
              <NavHorizontal :name="title" @click="showScreen(screen)" />
            </div>
          </div>
          <div class="program-title vertical-center">
            {{ stringTable.word('program') }}
            <img class="add-rotate" :src="ReiriIcons.cross" @click="showScreen('ScheduleProgram')" role="button" />
          </div>
          <div class="program-list nav-list">
            <div v-for=" id in slicedList" :key="id">
              <div v-if="schedules[id]" class="frame">
                <NavHorizontal :id="id" :value="schedules[id]" :name="schedules[id].name" :showSwitch="true"
                  :switchValue="schedules[id].exec" />
              </div>
            </div>
          </div>
          <div class="vertical-center">
            <NavPagination direction="up" :expandHori="true" />
            <NavPagination direction="down" :expandHori="true" />
          </div>
        </div>
      </div>
      <div class="right-frame">
        <component :is="currentScreen" :commModule="commModule" :cache="cacheCloned" :page="page" />
      </div>
    </div>
  </div>
</template>

<style>
.system-setting .left-content {
  width: 300px;
  padding: 0;
  border-right: 2px solid var(--disable);
}

.system-setting .right-frame {
  width: 724px;
  height: 100%;
  padding: 0 6px;
}

.system-setting .head-title {
  padding: 4px 10px 8px 10px;
  justify-content: center;
}

.system-setting {
  padding: 5px 0;
}

.system-setting .content-holder {
  display: flex;
  height: 100%;
}

.system-setting .nav-list,
.group-setting .nav-list {
  height: 513px;
  margin: 0 8px;
}

.system-setting .nav-list .frame,
.group-setting .nav-list .frame {
  max-height: 513px;
  padding: 4px;
}

.schedule-setting .nav-horizontal .item-text,
.interlock .nav-horizontal .item-text,
.auto-changeover .nav-horizontal .item-text {
  line-height: 1.4;
}

.schedule-setting .nav-list {
  height: fit-content;
}

.schedule-setting .program-title {
  position: relative;
  margin: 0 8px;
  padding: 0 10px 6px 10px;
  border-bottom: 2px solid var(--disable);
}

.schedule-setting .program-title .add-rotate {
  position: absolute;
  right: 4px;
}

.schedule-setting .nav-horizontal .item-text {
  padding-left: 8px;
}

.schedule-setting .program-list {
  height: 398px;
  padding: 6px 4px 4px 4px !important;
  margin-bottom: 3px;
}
</style>

<script>
import PopUpToast from './PopUpToast.vue'
import PopUpHead from './PopUpHead.vue'
import LoadingSpinner from './LoadingSpinner.vue'
import NavHorizontal from './NavHorizontal.vue'
import SelectOption from './SelectOption.vue'
import ScheduleAction from './ScheduleAction.vue'
import ScheduleCalendar from './ScheduleCalendar.vue'
import ScheduleProgram from './ScheduleProgram.vue'
import DroppableAc from './DroppableAc.vue'
import NavPagination from './NavPagination.vue'

export default {
  name: "PopUpSchedule",
  props: {
    commModule: Object,
    cache: Object,
  },
  data() {
    return {
      toast: {},
      isLoading: true,
      cacheCloned: this.cache,
      currentScreen: '', //ScheduleAction
      screenTitleMap: {
        ScheduleAction: 'act_set',
        ScheduleCalendar: 'cal_set',
      },
      page: 1,
      itemPerPage: 10,
      slicedList: [],
    }
  },
  created() {
    if (this.cacheCloned) {
      this.isLoading = false;
      this.currentScreen = this.cacheCloned.screen;
      this.page = this.cacheCloned.page;
    } else this.commModule.getSchedule();
  },
  watch: {
    'commModule.get_schedule'() {
      this.commModule.getInterlock();
    },
    'commModule.interlock_get_program'() {
      this.isLoading = false;
    },
    'commModule.enable'() {
      this.getProgram();
    },
    mainList(newList, oldList) {
      if(oldList.length == 0) { this.page = 1; return; }
      if(newList.toString() != oldList.toString())
        this.page = this.ConvertHelper.getMaxPage(newList.length, this.itemPerPage);
    }
  },
  methods: {
    showScreen(screenName) {
      this.currentScreen = screenName;
      this.cacheCloned = '';
    },
    closeRightScreen(isCompleted) {
      this.currentScreen = '';
      if (isCompleted) {
        this.toast = { text: 'succeed' }
      }
    },
    getProgram() {
      this.commModule.getSchedule();
    },
    completedOper() {
      this.getProgram();
      this.closeRightScreen(true);
    },
    frameSelected(item) {
      this.commModule.getSchedule()
      this.currentScreen = 'ScheduleProgram';
      this.cacheCloned = item.value;
      this.cacheCloned.patnId = item.key;
      this.cacheCloned.isEdit = true;
    },
    switchClicked(id) {
      var schedule = this.commModule.get_schedule.program[id];
      schedule.exec = !schedule.exec;
      this.commModule.saveScheduleEnable({
        [id]: { enable: schedule.exec }
      });
    },
  },
  computed: {
    schedules() {
      var schedules = this.commModule.get_schedule;
      return schedules && schedules.program ? schedules.program : {};
    },
    mainList() {
      return this.schedules ? Object.keys(this.schedules) : [];
    }
  },
  components: {
    PopUpToast,
    PopUpHead,
    LoadingSpinner,
    NavHorizontal,
    SelectOption,
    ScheduleAction,
    ScheduleCalendar,
    ScheduleProgram,
    DroppableAc,
    NavPagination
  }
}
</script>
