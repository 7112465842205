<template>
  <select class="select-option" v-model="selectModel"
    :class="{ 'faded': selectModel == undefined, 'dropdown-top': upsideDropdown, 'got-arrow': !hideArrow, 'hide-arrow': hideArrow, 'frame': frame }">
    <option v-if="placeholder" value="" disabled selected hidden>
      <span class="dis-hid">{{ stringTable.word(placeholder) }}</span>
    </option>
    <option v-for="opt in selectList" :key="getVal(opt)" :value="getVal(opt)" class="other">
      <div v-if="showPointName">{{ getPointName(opt) }}</div>
      <div v-else-if="showStringTable">{{ stringTable.word(opt) }}</div>
      <div v-else>{{ getName(opt) }}</div>
    </option>
  </select>
</template>

<style>
.select-option.got-arrow {
  padding-right: 28px !important;
}

.select-option.faded {
  color: var(--fontGray) !important;
}

.select-option.dropdown-top {
  margin-top: -200px !important;
}

.select-option.hide-arrow {
  background-image: none;
}

.select-option .other {
  color: black;
}
</style>

<script>

export default {
  detailsName: "SelectOption",
  props: {
    name: String,
    selectList: Array,
    defaultSelectIndex: Number,
    selectIndex: Number,
    placeholder: String,
    upsideDropdown: Boolean,
    useObject: Boolean,
    commModule: Object,
    isNewAction: Boolean,
    hideArrow: Boolean,
    showPointName: Boolean,
    showStringTable: Boolean,
    frame: Boolean
  },
  data() {
    return {
      selectModel: '',
    }
  },
  created() {
    this.updateSelModelFromSelIndex();
  },
  watch: {
    selectModel(newVal) {
      var index = this.selectList.findIndex(object => {
        return object.id === newVal;
      });
      this.$parent.changeOption(newVal, this.name, index);
    },
    defaultSelectIndex() {
      this.updateSelModelFromSelIndex();
    },
    selectIndex(newIndex) {
      this.assignModelByIndex(newIndex);
    },
    selectList() {
      this.updateSelModelFromSelIndex();
    },
  },
  computed: {
    default_select_index() {
      if (this.selectIndex) return this.selectIndex;
      else if (this.placeholder) return -1;
      else if (!this.defaultSelectIndex) return 0;
      return this.defaultSelectIndex;
    }
  },
  methods: {
    updateSelModelFromSelIndex() {
      var defIndex = this.default_select_index;
      if (!defIndex || defIndex >= 0) {
        this.assignModelByIndex(defIndex);
      }
    },
    assignModelByIndex(index) {
      var selectIndex = index >= 0 ? index : 0;
      this.selectModel = index < 0 ? '' : this.getVal(this.selectList[selectIndex]);
    },
    getVal(opt) {
      return this.useObject && opt ? opt.id : opt;
    },
    getName(opt) {
      return this.useObject && opt ? opt.name : opt;
    },
    getPointName(opt) {
      if (opt == "no_device") {
        return this.stringTable.word(opt)
      } else {
        var point = this.commModule.point_list[opt];
        return point ? point.name : opt;
      }
    },
  },
  components: {
  }
}
</script>