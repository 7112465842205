<template>
  <div class="schedule-action schedule-sub">
    <PopUpToast />
    <PopUpHead :details="{ name: 'act_set' }" :leftBtnTxt="leftBtnTxt" :rightBtnTxt="rightBtnTxt" :hideBorderBtm="true" />
    <div class="option-holder vertical-center justify-center">
      <SelectOption :selectList="ConvertHelper.listToIdNameList(target)" placeholder="sel_action" :useObject="true"
        :selectIndex="selIndex" />
      <img class="add-rotate" :src="ReiriIcons.cross" @click="addSettings()" role="button" />
    </div>
    <!-- {{ actionsCloned }} -->
    <div v-if="showAction || got_action" class="input-content">
      <FrameInput varStr="actionName" placeholder="name" />
      <div class="of-day vertical-center">
        {{ stringTable.word('act_of_the_day') }}
        <img class="add-rotate" :src="ReiriIcons.cross" @click="openActionOfDay()" role="button" />
      </div>
      <div class="detail-frame-holder can-scroll">
        <FrameAction v-for="action in actions" :key="action" :label="action.name" :actionIcons="action.icons"
          :actionDesc="action.desc" :pointKey="action" @click="openActionOfDay(action)" role="button" />
      </div>
    </div>
  </div>
</template>

<style>
.schedule-sub .select-option {
  width: 250px;
  margin: 0 auto;
}

.schedule-sub .option-holder {
  position: relative;
  padding-bottom: 6px;
  border-bottom: 2px solid var(--disable);
}

.schedule-sub .input-content {
  padding: 8px 4px;
}

.schedule-sub .of-day {
  position: relative;
  padding: 8px 8px 3px 8px;
  border-bottom: 2px solid var(--disable);
  margin-bottom: 4px;
}

.schedule-sub .of-day .add-rotate,
.schedule-sub .of-day .edit {
  margin-left: auto;
}

.schedule-sub .detail-frame-holder {
  height: 400px;
  overflow-y: scroll;
  padding: 8px;
}
</style>

<script>
import PopUpToast from './PopUpToast.vue'
import PopUpHead from './PopUpHead.vue'
import SelectOption from './SelectOption.vue'
import FrameInput from './FrameInput.vue'
import FrameAction from './FrameAction.vue'

export default {
  name: "ScheduleAction",
  props: {
    commModule: Object,
    cache: Object,
    page: Number,
  },
  data() {
    return {
      cacheC: this.cache,
      toast: {},
      leftBtnTxt: '',
      rightBtnTxt: '',
      actionsCloned: {},
      selIndex: null,
      iconKeys: {
        stat: { key: 'power' },
        off_timer: { key: 'timer' },
        sp: { unit: 'DEG' },
        fanstep: { keyList: 'fanstep3' },
        mode: { keyList: 'mode' },
        hum_sp: { unit: '%' },
        vmode: { keyList: 'vmode' },
        vamount: { keyList: 'vamount' },
      },
      actionName: '',
      actionId: '',
      showAction: false,
      addMode: false,
      target: {},
      pendingDel: ''
    }
  },
  created() {
    this.initTarget();
  },
  mounted() {
    if (this.cacheC) {
      const time = this.cacheC.time;
      const action = this.cacheC.action;
      const actionPrev = this.cacheC.actionPrev;
      const edit = this.cacheC.edit;
      var actionId = this.cacheC.actionId;
      this.selIndex = this.cacheC.selIndex;
      if (actionId == '') {
        this.addSettings(true);
        const allActionKeys = Object.keys(this.getTarget());
        if (allActionKeys.length > 0) {
          var lastIdNum = parseInt(allActionKeys[allActionKeys.length - 1].replace('patn', ''));
          actionId = `patn${lastIdNum + 1}`;
        } else actionId = 'patn1';

        this.actionName = this.cacheC.actionName;
      }
      this.actionsCloned = actionPrev;
      if (!action) return;

      this.actionsCloned[time] = action;
      if (edit && time && time != edit.name) delete this.actionsCloned[edit.name];
      this.actionsCloned = this.ConvertHelper.orderObjByKey(this.actionsCloned);
    }
  },
  watch: {
    'commModule.add_pattern'() {
      this.$parent.completedOper();
    },
    'commModule.modify_pattern'() {
      this.$parent.completedOper();
    },
    'commModule.delete_pattern'() {
      this.$parent.completedOper();
    },
    'commModule.get_schedule'() {
      this.initTarget();
    }
  },
  methods: {
    getTarget() {
      var tar = this.commModule.get_schedule.pattern;
      return tar ? JSON.parse(JSON.stringify(tar)) : {};
    },
    initTarget() {
      this.target = this.getTarget();
    },
    removeSel() {
      this.showAction = false;
      this.actionsCloned = {};
      this.selIndex = -1;
      this.leftBtnTxt = '';
      this.rightBtnTxt = '';
      this.toast = { text: 'succeed' }
    },
    changeOption(newActionId, name, index) {
      var gotSelection = index >= 0;
      this.actionId = newActionId;
      const canAssignPrev = this.cacheC && this.target[this.cacheC.actionId];
      if (canAssignPrev)
        this.target[this.cacheC.actionId].action = this.cacheC.actionPrev;
      else this.initTarget();

      var actionSettings = this.target[newActionId];
      this.actionsCloned = actionSettings ? JSON.parse(JSON.stringify(actionSettings.action)) : {};
      this.selIndex = index;
      if (!gotSelection) this.actionName = '';
      else if (canAssignPrev) this.actionName = this.cacheC.actionName;
      else if (actionSettings && actionSettings.name)
        this.actionName = actionSettings.name;

      this.leftBtnTxt = gotSelection ? 'delete' : '';
      if (!this.showAction)
        this.rightBtnTxt = gotSelection ? 'save' : '';
      if (gotSelection)
        this.showAction = true;
      this.cacheC = null;
      this.addMode = false;
    },
    addSettings(ignoreSelInd) {
      if (!ignoreSelInd)
        this.selIndex = -1;
      this.showAction = true;
      this.addMode = true;
      this.rightBtnTxt = 'save';
      this.actionName = '';
    },
    openActionOfDay(action) {
      var details = {
        screen: this.$options.name,
        name: action ? 'edit_act_of_the_day' : 'add_act_of_the_day',
        actionId: this.actionId,
        actionName: this.actionName,
        selIndex: this.selIndex,
        actionPrev: this.actionsCloned,
        page: this.page
      }
      if (action)
        details.edit = {
          name: action.name,
          action: this.actionsCloned[action.name],
        };

      this.NavAssistant.showPopUp({
        name: 'PopUpActionOfDay',
        details
      }, true);
    },
    clickLeftBtn() {
      this.pendingDel = this.actionId;
      this.toast = {
        text: 'conf_del',
        pendingYesMethod: () => {
          this.commModule.delSchAction(this.pendingDel);
        }
      }
    },
    clickRightBtn() {
      var text;
      if (this.actionName.length == 0 || !this.got_action) {
        if (this.actionName.length == 0) text = 'no_name';
        else if (!this.got_action) text = 'no_action';
        this.toast = { text, isError: true, }
      } else {
        text = 'conf_save';
        this.toast = {
          text,
          pendingYesMethod: () => {
            var data = { name: this.actionName, action: this.actionsCloned }
            if (this.addMode) {
              this.commModule.addSchAction(data);
            } else {
              this.commModule.editSchAction({
                [this.actionId]: data
              });
            }
            // this.$parent.closeRightScreen(true);
          }
        }
      }
    },
    prev() {
      this.$parent.closeRightScreen();
    },
    deleteAction(timeKey) {
      delete this.actionsCloned[timeKey.name]
    }
  },
  computed: {
    selections() {
      var res = [];
      for (const id in this.target) res.push({
        id, name: this.target[id].name
      });
      return res;
    },
    got_action() {
      return this.actionsCloned && Object.keys(this.actionsCloned).length != 0;
    },
    actions() {
      var res = [];
      var timeObjList = [];
      for (const time in this.actionsCloned) {
        var hour = this.ConvertHelper.timeStrToHour(time, true);
        var mins = this.ConvertHelper.timeStrToMin(time, true);
        timeObjList.push({ time, hour, mins });
      }
      timeObjList.sort((a, b) => (a.mins < b.mins) ? -1 : 1);
      timeObjList.sort((a, b) => (a.hour < b.hour) ? -1 : 1);
      timeObjList.forEach(timeObj => {
        var time = timeObj.time;
        var desc = JSON.parse(JSON.stringify(this.actionsCloned[time]));
        var actionKeys = Object.keys(desc);
        var icons = [];
        for (const iconKey in this.iconKeys) {
          if (actionKeys.includes(iconKey)) {
            var iconKeySub = this.iconKeys[iconKey];
            var icon = {};
            if (iconKeySub.key) {
              icon.name = this.ReiriIcons[iconKeySub.key];
              icon.isOn = desc[iconKey] == 'on';
            } else if (iconKeySub.unit) {
              var valNum = desc[iconKey]
              if (iconKeySub.unit == 'DEG') valNum = this.tempConvert.spConvert(valNum);
              icon.name = `${valNum}${this.stringTable.word(iconKeySub.unit)}`;
              icon.isIconText = true;
            } else if (iconKeySub.keyList) {
              icon.name = this.ReiriIcons.iconList[iconKeySub.keyList][desc[iconKey]];
            }
            icons.push(icon);
            delete desc[iconKey];
          }
        }
        var newDesc = {};
        for (const descKey in desc) {
          if (descKey == 'exec')
            newDesc.execute = 'scene';
          else if (descKey == 'enable')
            newDesc.program = desc[descKey] ? 'enable' : 'disable';
          else newDesc[descKey] = desc[descKey];
        }
        res.push({ name: time, icons, desc: newDesc });
      });
      return res;
    },
  },
  components: {
    PopUpToast,
    PopUpHead,
    SelectOption,
    FrameInput,
    FrameAction
  }
}
</script>

<!-- // deleteAction(action) {
  //   alert();
  //   this.showToast = true;
  //   this.toast_type = 'confirm';
  //   this.toastText = this.stringTable.word("conf_del");
  //   this.pendingDel = action.name;
  //   this.pendingYesMethod = () => {
  //     delete this.actionsCloned[this.pendingDel];
  //   }
  // }, -->