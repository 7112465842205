export class ColorHelper {
  constructor() {
    // colors: ['#826f8b', '#dcbb56', '#37bcb6', '#f2631b', '#ff6f61', '#4c1130', '#43c787', '#1e510b', '#a99116', '#9b4848', '#0f8880', '#98a148', '#2b6a1f', '#a5435c', '#0e0449', '#f2631b',],

    this.mainColors = [
      "#179be0", "#fd4f4d", "#7acd26", "#8ab3f0",
      "#fd951c", "#00d4a0", "#e8c618", "#744ccb",
      "#adb268", "#f14444",
      // "#131314", "#e84b4b"
    ];
  }

  get(index) {
    index = index % this.mainColors.length;
    return this.mainColors[index];
  }

  len() {
    return this.mainColors.length;
  }

  arrayRange(start, stop, step) {
    return Array.from({ length: (stop - start) / step + 1 },
      (value, index) => start + index * step
    );
  }

  rand(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  generate() {
    var hueList = this.arrayRange(0, 360, 10);
    var h = hueList[this.rand(0, hueList.length)];
    var s = this.rand(60, 100);
    var l = this.rand(35, 45);
    return 'hsl(' + h + ',' + s + '%,' + l + '%)';
  }
}
