<template>
	<NoList v-if="!mainList || mainList.length == 0" name="no_fav_dev" />
	<div v-else class="fav-page bef-tile-section">
		<NavPagination direction="left" />
		<!-- {{ slicedList }} -->
		<div class="tile-section">
			<TileDevice v-for="(key, index) in slicedList" :key="index" :detailsKey="key" :details="commModule.point_list[key]"
				:commModule="commModule" />
		</div>
		<NavPagination direction="right" />
	</div>
</template>

<style>
.bef-tile-section {
	display: grid;
	grid-template-columns: 3.5% 93% 3.5%;
}

.tile-section {
	padding: 5px 3px;
	display: grid;
	grid-template-columns: 19.6% 19.6% 19.6% 19.6% 19.6%;
	gap: 5.3px 0.5%;
}
</style>

<script>
import TileDevice from './TileDevice.vue'
import NavPagination from './NavPagination.vue'
import NoList from './NoList.vue'

export default {
	name: "PageFavDevice",
	props: {
		commModule: Object,
	},
	data() {
		return {
			page: 1,
			itemPerPage: 30,
			slicedList: [],
		}
	},
	created() {
		this.NavAssistant.push(this);
	},
	watch: {
	},
	computed: {
		mainList() {
			var fav = this.commModule.get_favorite;
			if (fav) return fav.device;
			return [];
		}
	},
	methods: {
	},
	components: {
		TileDevice,
		NavPagination,
		NoList
	},
}
</script>