<template>
  <div v-if="!details" class="tile frame hidden">
  </div>
  <div v-else class="tile tile-scene frame">
    <div class="content vertical-center">
      <div class="icon-main-holder">
        <img :src="IconsHelper.iconMain(details)" class="icon-main to-active" />
      </div>
      <div class=" ">
        <div class="name-text">{{ details.name }}</div>
      </div>
      <div class="execute-holder">
        <img :src="ReiriIcons.execute" class="execute to-active" role="button" @click="execScene" />
      </div>
    </div>
  </div>
</template>

<style>
.tile .name-text,
.txt-jump-down {
  height: 100%;
  word-wrap: break-word;
  line-height: 1.1;
}

.tile-scene .name-text {
  width: 80px;
}

.tile-scene .execute-holder {
  float: right;
  margin-left: auto;
  text-align: center;
}

.tile-scene .execute-holder img {
  width: 36px;
  height: 36px;
}
</style>

<script>
export default {
  name: "TileScene",
  props: {
    commModule: Object,
    detailsKey: String
  },
  data() {
    return {
      isExec: false,
      details: this.commModule.scene_list[this.detailsKey]
    }
  },
  created() {
  },
  watch: {
    'commModule.scene_exec'(exec) {
      if (this.isExec && exec.result == 'OK') {
        this.$parent.toast = { text: 'scene_success' }
        this.isExec = false;
      }
    }
  },
  methods: {
    execScene() {
      this.isExec = true;
      this.commModule.execScene(this.details.key_id);
    }
  }
}
</script>