<template>
  <div class="bar-img-text" :class="{ 'sel-li': selectList }">
    <div v-for="item in items" :key="item">
      <div v-if="!checkedString || item[checkedString]" class="img-text-item vertical-center">
        <img v-if="item.icon_map" class="icon" :src="IconsHelper.iconMain(item)" />
        <div class="name can-scroll">
          <div :class="{ 'one-line': item.icon_map && selectList }">
            {{ item.name }}
          </div>
        </div>
        <SelectOption v-if="selectList" :name="item.key_id" :selectList="selectList" :useObject="true"
          :selectIndex="item.actionIndex" />
      </div>
    </div>
  </div>
</template>

<style>
.bar-img-text .icon {
  margin: 4px;
}

.bar-img-text.sel-li .icon {
  margin: 0 4px 0 0;
}

.bar-img-text.sel-li .img-text-item {
  margin: 6px 6px 8px 6px;
}

.bar-img-text.sel-li .name.one-line {
  width: 68px;
}
</style>

<script>
import SelectOption from './SelectOption.vue'

export default {
  name: "BarSchedule",
  props: {
    items: Array,
    checkedString: String,
    selectList: Array
  },
  methods: {
    changeOption(value, name) {
      this.$parent.changeOption(value, name);
    }
  },
  components: {
    SelectOption
  }
}

</script>
