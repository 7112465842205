<template>
  <div v-if="!showEditIAQValue" class="pop-up user scene-setting system-setting device-setting iaq iaq-page">
    <div class="content-holder">
      <div class="left-content  ">
        <PopUpHead :details="details" :hideBorderBtm="true" :leftArr="true" :small="true" :plus="true" />
        <LoadingSpinner v-if="isLoading" :topSpacing="true" />
        <div v-else-if="Object.keys(IAQRoomData).length !== 0" class="nav-list ">
          <div class="frame">
            <div v-for=" key in slicedList" :key="key">
              <NavHorizontal v-if="iaqObj[key]" :name="key" hideKeyValue="true" showDescription="false"
                @click="roomSelected({ key, value: iaqObj[key] })" />
            </div>
          </div>
        </div>
        <div class="vertical-center">
          <NavPagination direction="up" :expandHori="true" />
          <NavPagination direction="down" :expandHori="true" />
        </div>
      </div>
      <div class="right-frame">
        <PopUpToast />
        <PopUpHead v-if="showEdit" :details="head_detail" :leftBtnTxt="!isRegister ? 'delete' : ''" rightBtnTxt="save"
          :hideBorderBtm="true" />
        <div class="edit-section can-scroll" v-show="IAQDetails != '' && showEdit">
          <!-- name -->
          <FrameInput v-if="roomDetails.hasOwnProperty('key')" varStr="roomDetails" varStr2="key"
            placeholder="room_name" />
          <!-- select device title -->
          <div class="mail-addr">
            <div class="nomargin-content vertical-center ">
              <div class="left-text">
                <div>{{ stringTable.word("select_devices") }}</div>
              </div>
              <div class="right-content">
                <img class="add-rotate add-device-btn" :src="ReiriIcons.cross" @click="addNewIAQValue" />
              </div>
            </div>
          </div>
          <!-- iaq room details -->
          <div v-if="actionFrameList" class="scene-action can-scroll">
            <div v-for="(value, key) in actionFrameList" :key="key">
              <FrameAction :label="clonedCommModule.point_list[value.id].name" :actionDesc="value"
                :alignDescValRight="true" :commModule="commModule" :isIAQ="true" :pointKey="key"
                @click="roomDeviceClicked({ 'roomId': roomId, 'details': value, 'index': key })" />
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <EditIAQValue v-if="showEditIAQValue" :isNewIAQValue="isNewIAQValue" :pointDetails="pointAction"
    :commModule="clonedCommModule" @updated-action-details="updateAction" :isRegister="isRegister"
    :pointList="roomPointList">
  </EditIAQValue>
</template>

<style>
.iaq-page .nav-list .nav-horizontal {
  margin-bottom: 2.9px;
}

.iaq .nav-horizontal .item-text {
  font-size: 16px;
}

.iaq-page .item-text {
  width: 245px;
  margin: 4.7px 0;
}

.iaq .edit-section {
  margin-top: 10px;
}

.iaq .scene-action {
  max-height: 440px;
}
</style>

<script>
import FrameInput from "./FrameInput.vue";
import EditIAQValue from "./EditIAQValue.vue";
import FrameAction from "./FrameAction.vue";
import NavHorizontal from './NavHorizontal.vue';
import LoadingSpinner from './LoadingSpinner.vue';
import PopUpToast from './PopUpToast.vue';
import PopUpHead from './PopUpHead.vue';
import NavPagination from './NavPagination.vue'

export default {
  name: "PopUpIAQRoomSetting",
  props: {
    commModule: Object,
    cache: Object,
  },
  data() {
    return {
      details: { name: "iaq_room_set" },
      clonedCommModule: {},
      isNewIAQValue: false,
      IAQDetails: {
        "key": '', "value": {}
      },
      toast: {},
      clonedRoomIAQ_list: {},
      showEditIAQValue: false,
      IAQRoomData: {},
      isRegister: false,
      showEdit: false,
      roomDetails: { "key": '', "value": [] },
      roomId: '',
      pointAction: { 'roomId': '', 'details': {} },
      detailsOrder: {
        'id': null,
        'type': null,
        'threshold_red': null,
        'threshold_yellow': null,
      },
      isLoading: false,
      roomKeyList: [],
      actionFrameList: [],
      page: 1,
      itemPerPage: 14,
      slicedList: [],
    }
  },
  created() {
    this.clonedCommModule = Object.assign({}, this.commModule);
    this.commModule.getIaq();
  },
  mounted() {
    if (this.cache) {
      this.isRegister = this.cache.isRegister
    }
    this.isLoading = true;
  },
  computed: {
    roomPointList() {
      return Object.values(this.roomDetails.value).map(item => item.id)
    },
    head_detail() {
      var name = '';
      if (this.showEdit)
        name = this.isRegister ? 'add_iaq_room' : 'edit_iaq_room';
      return { name }
    },
    iaqObj() {
      return this.IAQRoomData;
    },
    mainList() {
      return Object.keys(this.iaqObj)
    },
  },
  watch: {
    actionFrameList() {
      this.roomDetails.value = this.actionFrameList
    },
    'commModule.iaq_monitoring_settings'(iaq) {
      if (iaq) {
        this.IAQRoomData = JSON.parse(JSON.stringify(iaq))
        this.cleanRoomIAQData()
        this.roomKeyList = Object.keys(this.IAQRoomData)
      }
      this.isLoading = false;
    },
    'commModule.iaq_monitoring_settings_save'() {
      this.toast = { text: 'succeed' }
      this.showEdit = false
      this.commModule.getIaq();
    }
  },
  methods: {
    prev() {
      this.showEdit = false
    },
    clickLeftBtn() {
      this.toast = {
        text: 'conf_del',
        pendingYesMethod: () => {
          delete this.IAQRoomData[this.roomId];
          this.commModule.saveIaq(this.IAQRoomData);
        }
      }
    },
    plus() {
      // alert('fdasf')
      this.isRegister = true;
      this.roomId = ''
      this.actionFrameList = []
      this.roomDetails = { "key": '', "value": [] }
      this.showEdit = true;
    },
    // isNumberWithSpace(input) {
    //   return /^\d+\s$/.test(input);
    // },
    removeSpaceFromKeys(obj) {
      const result = {};
      for (const key in obj) {
        if (/^\d+\s$/.test(key)) {
          const newKey = key.trim(); // Remove the space
          result[newKey] = obj[key];
        } else {
          result[key] = obj[key];
        }
      }
      return result;
    },
    updateAction(newAction) {
      if (newAction.index == -1) {
        this.actionFrameList.push(newAction.details)
      } else {
        if (this.actionFrameList[newAction.index]) {
          this.actionFrameList[newAction.index] = newAction.details
        } else {
          this.actionFrameList.push(newAction.details)
        }
      }
    },
    deleteAction(index) {
      this.toast = {
        text: 'conf_del',
        pendingYesMethod: () => {
          if (this.isNewIAQValue) {
            this.actionFrameList.splice(index, 1)
          }
          else {
            this.actionFrameList.splice(index, 1)
          }
        }
      }
    },
    removeSpaceAfterNumber(input) {
      // Match the pattern: digits followed by a space at the end of the string
      const regex = /^(\d+)\s$/;
      const match = input.match(regex);
      if (match) {
        return match[1]; // Return the matched number
      } else {
        return input; // Return the original string if no match
      }
    },
    cleanRoomIAQData() {
      for (const room in this.IAQRoomData) {
        if (Object.prototype.hasOwnProperty.call(this.IAQRoomData, room)) {
          const roomItems = JSON.parse(JSON.stringify(this.IAQRoomData[room]));
          for (let i = 0; i < roomItems.length; i++) {
            const newItem = Object.assign(this.detailsOrder, roomItems[i]);
            if (newItem.type == 'ac') {
              newItem.type = 'temp'
            }
            this.IAQRoomData[room][i] = { ...newItem }
          }
        }
      }
    },
    addNewIAQValue() {
      this.pointAction['roomId'] = this.roomId
      this.pointAction['details'] = {}
      this.pointAction['index'] = -1
      this.isNewIAQValue = true
      this.NavAssistant.openPopUp(this);
      this.showEditIAQValue = true
    },
    roomSelected(item) {
      this.isRegister = false
      this.showEdit = true
      this.roomDetails = JSON.parse(JSON.stringify(Object.assign({}, item)));
      this.roomId = item.key
      this.actionFrameList = JSON.parse(JSON.stringify(item.value))
    },
    roomDeviceClicked(item) {
      this.pointAction = item
      this.isNewIAQValue = false
      this.NavAssistant.openPopUp(this);
      this.showEditIAQValue = true
    },
    clickRightBtn() {
      if (this.ConvertHelper.isEmptyString(this.roomDetails.key) || Object.keys(this.roomDetails.value).length === 0) {
        this.toast = { text: 'fields_empty', isError: true, }
        return
      }
      if (this.isRegister) {
        if (this.roomKeyList.includes(this.roomDetails.key)) {
          this.toast = { text: 'same_name', isError: true, }
          return
        }
      } else {
        if (this.roomDetails.key == '' || Object.keys(this.roomDetails.value).length === 0) {
          this.toast = { text: 'fields_empty', isError: true, }
          return
        }
      }
      if (this.roomId !== this.roomDetails.key) {
        this.IAQRoomData = this.ConvertHelper.renameObjKey(this.IAQRoomData, this.roomId, this.roomDetails.key)
      }
      this.IAQRoomData[this.roomDetails.key] = this.roomDetails.value
      this.commModule.saveIaq(this.IAQRoomData);
    },
    iconSelectionClicked() {
    },
    back() {
      this.NavAssistant.hidePopUp();
      if (this.showLast) {
        this.NavAssistant.showLast();
      }
    },
  },
  components: {
    FrameInput,
    EditIAQValue,
    FrameAction,
    NavHorizontal,
    LoadingSpinner,
    PopUpToast,
    PopUpHead,
    NavPagination
  }
}
</script>