<template>
	<LoadingSpinner v-if="isLoading" />
	<div class="bef-tile-section page-group">
		<NavPagination direction="left" />
		<NoList v-if="mainList.length === 0" name="no_group" />
		<div class="tile-section">
			<TileGroup v-for="(key, index) in slicedList" :key="index" :detailsKey="key"
				:details="commModule.group_list[key]" />
		</div>
		<NavPagination direction="right" />
	</div>
</template>

<style>
.page-group.bef-tile-section,
.page-scene.bef-tile-section {
	height: 518px;
	padding-top: 6px;
}
</style>

<script>
import TileGroup from './TileGroup.vue'
import NavPagination from './NavPagination.vue'
import NoList from './NoList.vue'
import LoadingSpinner from './LoadingSpinner.vue'

export default {
	name: "PageGroup",
	props: {
		commModule: Object,
	},
	data() {
		return {
			isLoading: false,
			loadingTimer: null,
			page: 1,
			itemPerPage: 35,
			mainList: [],
			slicedList: [],
			isOnState: false,
		}
	},
	created() {
		this.isLoading = true
		this.startLoadingTimer()
		this.NavAssistant.push(this);
		this.mainList = this.commModule.getGroupsClean();
	},
	watch: {
	},
	methods: {
		startLoadingTimer() {
			if (this.loadingTimer)
				clearTimeout(this.loadingTimer);
			this.loadingTimer = setTimeout(() => {
				this.isLoading = false
			}, 1);
		},
	},
	computed: {
	},
	components: {
		TileGroup,
		NavPagination,
		NoList,
		LoadingSpinner,
	},
}
</script>