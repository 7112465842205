<template>
  <div class="pop-up device-detail qr">
    <PopUpHead :details="details" :showIcon="false" :hideBorderBtm="true" :leftArr="true" :hideCross="true" />
    <div class="center-content">
      <qrcode-vue :value=controllerInfoBase64 :size=200 background="white" margin=10 class="qr-code"
        level='L'></qrcode-vue>
      <div class="register-controller font-bold"> {{ stringTable.word("create_controller") }}</div>
      <div> {{ stringTable.word("qr_instr") }}</div>
    </div>
  </div>
</template>

<style>
.qr .qr-code {
  margin: 20px 0px 10px 0px;
  border-radius: 20px;
}

.qr .register-controller {
  margin-bottom: 15px;
  font-size: 20px;
}

.qr .center-content {
  text-align: center;
}
</style>

<script>
import PopUpHead from "./PopUpHead.vue";
import { Buffer } from 'buffer';
import QrcodeVue from 'qrcode.vue'

export default {
  name: "PopUpQR",
  data() {
    return {
      details: { name: "qr_code_gen" },
      // ipAddr: localStorage.getItem('IpAddr'),
      controllerInfo: {
        'mac_addr': this.commModule.controller["macaddr"],
        'ip_addr': localStorage.getItem('IpAddr'),
        'name': this.commModule.controller["name"],
        'model': this.commModule.controller["model"],
        // 'appVer': this.commModule.app_version,
        'appVer': "0.0.2 pro max",
        'version': this.commModule.controller["version"],
        'ssc_connect': this.commModule.controller["ssc"],
        'ssc_url': this.commModule.controller["url"]
      },
      controllerInfoString: '',
      controllerInfoBase64: ''
    }
  },
  props: {
    commModule: Object,
  },
  created() {
    this.getControllerList();
    this.encodeToUTF8();
  },
  watch: {
  },
  methods: {
    getControllerList() {
      this.commModule.getControllerList();
    },
    encodeToUTF8() {
      // Serialize the JSON object to a UTF-8 string
      this.controllerInfoString = JSON.stringify(this.controllerInfo);
      this.controllerInfoString.split(" ").join("");

      // Encode the UTF-8 string to Base64
      const controllerInfoStringUTF8 = Buffer.from(this.controllerInfoString, 'utf-8');
      this.controllerInfoBase64 = controllerInfoStringUTF8.toString('base64');
    },
  },
  components: {
    PopUpHead,
    QrcodeVue
  }
}
</script>