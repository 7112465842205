<template>
  <div class="btn-toggle" role="button">
    <div class="equal-switch vertical-center">
      <div class="equal-left-switch" @click="leftSwitchClicked" :class="{ 'active-switch': leftSwitchOn }">
        {{ stringTable.word(leftSwitchString) }}
      </div>
      <div class="equal-right-switch" @click="rightSwitchClicked" :class="{ 'active-switch': !leftSwitchOn }">
        {{ stringTable.word(rightSwitchString) }}
      </div>
    </div>
  </div>
</template>

<style>
.btn-toggle {
  height: 47px;
  align-items: center;
  display: flex;
}

.btn-toggle .equal-switch {
  height: 30px;
  width: 150px;
  border-radius: 15px;
  background-color: var(--light1Gray);
  color: var(--active);
}

.btn-toggle .equal-left-switch,
.btn-toggle .equal-right-switch {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.btn-toggle .active-switch {
  background-color: var(--active);
  border-radius: 15px;
  color: white;
}
</style>

<script>
export default {
  name: "BtnToggle",
  props: {
    leftSwitchString: String,
    rightSwitchString: String,
    leftSwitchSelected: Boolean,
    itemToChange: String,
  },
  data() {
    return {
      leftSwitchOn: false,
    }
  },
  created() {
    this.leftSwitchOn = this.leftSwitchSelected
  },
  watch: {
    leftSwitchSelected() {
      this.leftSwitchOn = this.leftSwitchSelected
    }
  },
  methods: {
    leftSwitchClicked() {
      this.leftSwitchOn = true
      this.$parent.changeBtnToggle(this.itemToChange, this.leftSwitchOn)
    },
    rightSwitchClicked() {
      this.leftSwitchOn = false
      this.$parent.changeBtnToggle(this.itemToChange, this.leftSwitchOn)
    },
  }
}
</script>

