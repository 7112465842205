<template>
  <div class="pop-up device">
    <PopUpHead :details="details" :isIconActive="details.stat_on" :showIcon="true" />
    <LoadingSpinner v-if="isLoading" />
    <div class="bottom-content bef-tile-section">
      <NavPagination direction="left" />
      <div class="tile-section">
        <TileDevice v-for="(key, index) in slicedList" :key="index" :detailsKey="key"
          :details="commModule.point_list[key]" :popUpWithCache="true" />
      </div>
      <NavPagination direction="right" />
    </div>
  </div>
</template>

<style>
.pop-up.device .loading-holder {
  margin-top: -80px !important;
}
</style>

<script>
import PopUpHead from './PopUpHead.vue'
import TileDevice from './TileDevice.vue'
import NavPagination from './NavPagination.vue'
import LoadingSpinner from './LoadingSpinner.vue'

export default {
  name: "PopUpDevices",
  props: {
    commModule: Object,
    details: Object,
    page_prop: Number,
  },
  data() {
    return {
      isLoading: false,
      page: 1,
      itemPerPage: 35,
      slicedList: [],
      mainList: [],
    }
  },
  created() {
    this.isLoading = true
    this.startLoadingTimer()
    this.mainList = this.details.mp.filter(id =>
      this.commModule.point_list[id]);
    if (this.page_prop) this.page = this.page_prop;
  },
  watch: {
  },
  methods: {
    startLoadingTimer() {
      if (this.loadingTimer)
        clearTimeout(this.loadingTimer);
      this.loadingTimer = setTimeout(() => {
        this.isLoading = false
        this.showBottom = true
      }, 1);
    },
  },
  computed: {
  },
  components: {
    PopUpHead,
    TileDevice,
    NavPagination,
    LoadingSpinner
  },
}
</script>