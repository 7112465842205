<template>
  <div class="pop-up favorite">
    <PopUpToast />
    <PopUpHead :details="details" :showIcon="false" :showLast="true" rightBtnTxt="save" :hideBorderBtm="true"
      :leftArr="true" :hideCross="true" :wholeScreen="true" />
    <div class="main-holder">
      <LoadingSpinner v-if="isLoading" />
      <div v-else>
        <div class="main-content">
          <div class="fav-section">
            <div class="fav-section-title justify-between">
              <div>{{ stringTable.word("dev") }}</div>
              <img v-if="commModule.isAdmin" :src="ReiriIcons.point_setup" @click="deviceSettingClicked" role="button" />
            </div>
            <div class="reverse-frame">
              <div class="reverse-frame-inner fav-list can-scroll">
                <div v-for="(value, key, index) in commModule.point_list " :key="key">
                  <ItemList :name="value.name" :icon="IconsHelper.iconMain(value)"
                    :class="{ 'grey-bgcolor': index % 2 == 0 }"
                    :isFav="mainFav.device ? mainFav.device.includes(key) : false" @toggle-favorite="receiveEmit"
                    favType="device" :favKey="key" />
                </div>
              </div>
            </div>
          </div>

          <div class="fav-section">
            <div class="fav-section-title justify-between">
              <div>{{ stringTable.word("group") }}</div>
              <img v-if="commModule.isAdmin" :src="ReiriIcons.point_setup" @click="groupSettingClicked" role="button" />
            </div>
            <div class="reverse-frame">
              <div class="reverse-frame-inner fav-list can-scroll">
                <div v-for="( groupId, index ) in commModule.getGroupsClean()" :key="index">
                  <ItemList :name="commModule.group_list[groupId].name"
                    :icon="IconsHelper.iconMain(commModule.group_list[groupId])"
                    :class="{ 'grey-bgcolor': index % 2 == 0 }"
                    :isFav="mainFav.group ? mainFav.group.includes(groupId) : false" @toggle-favorite="receiveEmit"
                    favType="group" :favKey="groupId" />
                </div>
              </div>
            </div>
          </div>

          <div class="fav-section">
            <div class="fav-section-title justify-between">
              <div>{{ stringTable.word("scene") }}</div>
              <img v-if="commModule.isAdmin" :src="ReiriIcons.point_setup" @click="sceneSettingClicked" role="button" />
            </div>
            <div class="reverse-frame">
              <div class="reverse-frame-inner fav-list can-scroll">
                <div v-for="( value, key, index ) in  commModule.scene_list " :key="key">
                  <ItemList :name="value.name" :icon="IconsHelper.iconMain(value)"
                    :class="{ 'grey-bgcolor': index % 2 == 0 }"
                    :isFav="mainFav.scene ? mainFav.scene.includes(key) : false" @toggle-favorite="receiveEmit"
                    favType="scene" :favKey="key" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.favorite.pop-up.device-detail .content>div {
  padding: 8px 8px;
}

.favorite.pop-up.device-detail .content {
  grid-template-columns: unset;
}

.favorite .fav-list {
  margin-top: 6px;
  height: 495px;
}

.favorite .main-holder {
  height: 540px;
}

.favorite .main-content {
  width: 99%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}

.favorite .fav-section {
  width: 32.5%;
  height: 100%;
}

.favorite .fav-section-title {
  padding: 0 8px;
  font-size: 20px;
  display: flex;
}

.favorite .item-text {
  width: 240px;
}
</style>

<script>
import PopUpHead from "./PopUpHead.vue";
import ItemList from "./ItemList.vue";
import PopUpToast from './PopUpToast.vue'
import LoadingSpinner from './LoadingSpinner.vue'

export default {
  name: "PopUpFavorite",
  data() {
    return {
      details: { name: "fav_settings" },
      toast: {},
      mainFav: {},
      isLoading: true
    }
  },
  props: {
    commModule: Object,
  },
  created() {
    this.commModule.getFavorite();
  },
  watch: {
    'commModule.get_favorite'() {
      this.mainFav = JSON.parse(JSON.stringify(this.commModule.get_favorite));
      this.isLoading = false;
    },
    'commModule.set_favorite'() {
      this.toast = {
        text: this.commModule.set_favorite.result == "OK" ?
          'succeed' : this.commModule.set_favorite.result
      }
    }
  },
  computed: {
  },
  methods: {
    deviceSettingClicked() {
      this.NavAssistant.showPopUp({
        name: 'PopUpDeviceSetting',
        page: this.$parent.page
      }, true);
    },
    groupSettingClicked() {
      this.NavAssistant.showPopUp({
        name: 'PopUpGroupSetting',
        page: this.$parent.page
      }, true);
    },
    sceneSettingClicked() {
      this.NavAssistant.showPopUp({
        name: 'PopUpSceneSetting',
        page: this.$parent.page
      }, true);
    },
    receiveEmit(toggledItem) {
      if (toggledItem.isFav == true) {
        this.mainFav[toggledItem.type].push(toggledItem.favKey)
      } else { // remove item
        this.mainFav[toggledItem.type] =
          this.mainFav[toggledItem.type].filter(item => item !== toggledItem.favKey)
      }
    },
    clickRightBtn() {
      const favData = {
        name: this.commModule.user,
        favorite: {
          device: this.mainFav.device,
          group: this.mainFav.group,
          scene: this.mainFav.scene
        }
      }
      this.commModule.saveFavorite(favData);
    }
  },
  components: {
    PopUpHead,
    ItemList,
    PopUpToast,
    LoadingSpinner
  },
}
</script>