<template>
	<PopUpToast />
	<NoList v-if="!mainList || mainList.length == 0" name="no_fav_scene" />
	<div v-else class="fav-page bef-tile-section">
		<NavPagination direction="left" />
		<div class="tile-section">
			<TileScene v-for="(key, index) in slicedList" :key="index" :detailsKey="key" :commModule="commModule" />
			<!-- :details="commModule.scene_list[key]" -->
		</div>
		<NavPagination direction="right" />
	</div>
</template>

<script>
import PopUpToast from './PopUpToast.vue'
import TileScene from './TileScene.vue'
import NavPagination from './NavPagination.vue'
import NoList from './NoList.vue'

export default {
	name: "PageFavScene",
	props: {
		commModule: Object,
	},
	data() {
		return {
			toast: {},
			page: 1,
			itemPerPage: 30,
			slicedList: [],
			showNoList: false
		}
	},
	mounted() {
		this.NavAssistant.push(this);
	},
	watch: {
	},
	methods: {
	},
	computed: {
		mainList() {
			var fav = this.commModule.get_favorite;
			if (fav) return fav.scene;
			return [];
		}
	},
	components: {
		PopUpToast,
		TileScene,
		NavPagination,
		NoList
	},
}
</script>