<template>
  <div class="pop-up report-settings-points-form">
    <PopUpHead :details="details" />
    <div class="form-holder frame">
      <div class="inp-holder vertical-center">
        <div class="lbl">{{ stringTable.word('name') }}</div>
        <div class="inp">
          <input type="text" :placeholder="stringTable.word('name')" maxlength="30" v-model="detailsCloned.data.name">
        </div>
      </div>
      <div class="list-lbl">{{ stringTable.word('select_devices') }}</div>
      <div class="bars-check-boxes-holder can-scroll">
        <BarCheckBoxes :items="detailsCloned.displayDevices" :checkedString="checkedString" />
      </div>
    </div>
  </div>
</template>

<style>
.pop-up.report-settings-points-form input[type=text],
.pop-up.settings-form input[type=text] {
  background-color: var(--inputArea);
}

.pop-up.report-settings-points-form .form-holder {
  width: 700px;
  margin: 6px auto;
}

.pop-up.report-settings-points-form .form-holder .lbl,
.pop-up.report-settings-points-form .form-holder .inp {
  width: 50%;
}

.pop-up.report-settings-points-form .form-holder .inp-holder {
  border-bottom: 1px solid var(--disable);
  padding: 8px;
}

.pop-up.report-settings-points-form .form-holder .list-lbl {
  text-align: center;
  padding: 8px;
}

.pop-up.report-settings-points-form .bars-check-boxes-holder {
  padding: 0 8px;
  max-height: 420px;
  overflow: scroll;
}
</style>

<script>
import PopUpHead from "./PopUpHead.vue";
import BarCheckBoxes from './BarCheckBoxes.vue'

export default {
  name: "PopUpRepSetPoints",
  props: {
    details: Object,
  },
  data() {
    return {
      detailsCloned: this.details,
      checkedString: this.details.checkedString,
    }
  },
  created() {
    if (this.detailsCloned.assignCheckString) {
      for (const point_id in this.detailsCloned.displayDevices) {
        var point = this.detailsCloned.displayDevices[point_id];
        point[this.checkedString] = this.details.data.points.includes(point_id)
      }
    }
  },
  methods: {
    clickedCheckBox(dev) {
      if (dev[this.checkedString])
        this.detailsCloned.data.points.push(dev.key_id);
      else this.ConvertHelper.removeArrayItems(
        this.detailsCloned.data.points, dev.key_id);
    }
  },
  components: {
    PopUpHead,
    BarCheckBoxes
  },
}
</script>

<!-- // var newPoints = [];
// this.detailsCloned.data.points.forEach(p_id => {
//   if (dev.key_id != p_id) newPoints.push(p_id);
// });
// this.detailsCloned.data.points = newPoints; -->