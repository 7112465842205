<template>
  <div class="pop-up device-detail version-info">
    <PopUpToast />
    <PopUpHead :details="details" :showIcon="false" :hideBorderBtm="true" :leftArr="true" :hideCross="true" />
    <div style="height: 440px">
      <div>
        <div class="info-box frame">
          <div class="left-content">
            <div class="controller-value">{{ stringTable.word("controller_name") }}</div>
            <div class="controller-value">{{ stringTable.word("model") }}</div>
            <div class="controller-value">{{ stringTable.word("app_version") }}</div>
            <div class="controller-value">{{ stringTable.word("system_version") }}</div>
          </div>
          <div class="right-content">
            <div class="controller-value">{{ controllerInfo.name }}</div>
            <div class="controller-value">DCP{{ controllerInfo.model }}</div>
            <div class="controller-value">{{ controllerInfo.appVer }}</div>
            <div class="controller-value">{{ controllerInfo.version }}</div>
          </div>
        </div>
        <div v-if="commModule.isAdmin">
          <LoadingSpinner v-if="isLoading" />
          <div v-else>
            <div class="checkversion-btn frame" @click="checkUpdate" role="button">
              {{ stringTable.word("check_update") }}
            </div>
            <div class="update-result font-bold" v-show="versionResult != ''">
              {{ versionResult }}
            </div>
          </div>
          <div class="btn-holder" @click="rebootSystem()" role="button">
            {{ stringTable.word('reboot_ctrl') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.version-info .btn-holder {
  color: grey;
  margin: auto;
  bottom: 50px;
  position: absolute;
  text-align: center;
  left: 50%;
  transform: translate(-50%, -50%);
}

.version-info .update-result {
  margin-top: 20px;
  text-align: center;
}

.version-info .controller-value {
  margin: 5px;
}

.version-info .info-box {
  margin: 20px 30px 10px 30px;
  display: flex;
  padding: 15px 10px;
}

.version-info .checkversion-btn {
  margin: 20px auto;
  /* margin: 20px 30px 10px 30px; */
  width: 300px;
  text-align: center;
  justify-content: center;
  display: flex;
  padding: 15px 10px;
}

.version-info .right-content {
  margin-left: auto;
  text-align: right;
  color: grey;
}
</style>

<script>
import PopUpHead from "./PopUpHead.vue";
import LoadingSpinner from './LoadingSpinner.vue'
import PopUpToast from './PopUpToast.vue'

export default {
  name: "PopUpVersonInfo",
  data() {
    return {
      details: { name: "version_information" },
      versionResult: '',
      isLoading: false,
      controllerInfo: {
        'name': this.commModule.controller["name"],
        'model': this.commModule.controller["model"],
        'appVer': this.commModule.app_version,
        'version': this.commModule.controller["version"],
      },
      toast: {},
    }
  },
  props: {
    commModule: Object,
  },
  created() {
  },
  watch: {
    // 'commModule.pdd'() {
    //   // this.toast = { text: 'succeed' }
    // },
    'commModule.check_update'() {
      this.isLoading = false;
//      console.log(this.commModule.check_update)
      if (this.commModule.check_update.result == "OK") {
        this.toast = {
          text: 'confirm_update',
          pendingYesMethod: () => {
            this.commModule.systemUpdate()
            this.isLoading = true;
          }
        }
      } else if (this.commModule.check_update.result == "NG") {
        this.versionResult = this.stringTable.word("no_new_update")
      }
    },
  },
  methods: {
    rebootSystem() {
      this.toast = {
        text: 'conf_reboot',
        toShowReboot: true,
        pendingYesMethod: () => {
          this.commModule.rebootSystem()
        }
      }
    },
    showReboot() {
      this.toast = {
        text: 'rebooting',
        type: 'showReboot',
      }
    },
    checkUpdate() {
      this.commModule.checkUpdate();
      this.isLoading = true;
    }
  },
  components: {
    PopUpHead,
    LoadingSpinner,
    PopUpToast
  }
}
</script>