<template>
  <div class="select-month">
    <SelectOption :selectList="monthList" />
  </div>
</template>

<style>
.select-month {
  width: 130px;
}
</style>

<script>
import SelectOption from './SelectOption.vue'

export default {
  name: "SelectMonth",
  props: {
  },
  data() {
    return {
      monthList: [],
      monthObjList: [],
      monthIndex: 0,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.monthList = [];
      var tempDate = new Date();
      var before3years = new Date();
      before3years.setFullYear(before3years.getFullYear() - 2);
      while (before3years <= tempDate) {
        var month = new Date(tempDate);
        this.monthList.push(this.ConvertHelper.dtObjToMthNameYear(month));
        this.monthObjList.push(new Date(month));
        tempDate.setDate(1);
        tempDate.setMonth(tempDate.getMonth() - 1);
      }
    },
    changeOption(newMonth) {
      this.monthIndex = this.monthList.indexOf(newMonth);
      this.$parent.changeMonth(this.monthObjList[this.monthIndex], this.monthIndex);
    }
  },
  watch: {
  },
  components: {
    SelectOption
  }
}
</script>
