<template>
  <div v-if="['month', 'year'].includes(lyType)" class="bar-date-range-year frame vertical-center justify-center">
    <SelectDateRange v-if="dateRangeData" :startDay="dateRangeData.start_day" :startMonth="dateRangeData.start_month"
      :lyType="lyType" :year="startYear" />
    <div class="start-year">
      <SelectOption v-if="lyType != 'year'" :selectList="yearList" />
    </div>
  </div>
</template>

<style>
.bar-date-range-year {
  padding: 3px;
}

.bar-date-range-year .start-year select {
  width: 100px;
  margin-left: 8px;
}
</style>

<script>
import SelectDateRange from './SelectDateRange.vue'
import SelectOption from './SelectOption.vue'

export default {
  name: "BarDateRangeYear",
  props: {
    firstLoad: Boolean,
    dateRangeData: Object,
    lyType: String
  },
  data() {
    return {
      yearList: [],
      dateRange: {},
      startYear: '',
    }
  },
  created() {
    var currYear = new Date();
    currYear = currYear.getFullYear();
    for (let i = 0; i < 3; i++) {
      this.yearList.push(currYear - i);
    }
  },
  watch: {
    startYear() {
      if (!this.firstLoad)
        this.changeDateRange();
    },
  },
  methods: {
    changeOption(newStartYear) {
      this.startYear = newStartYear;
      this.$parent.startYear = newStartYear;
    },
    changeDateRange(newDateRange) {
      this.dateRange = newDateRange ? newDateRange : this.dateRange;
      this.startYear = this.startYear ? this.startYear : this.yearList[0];
      if (this.lyType == 'month') {
        if (!this.dateRange.from) return;
        this.dateRange.from.setYear(this.startYear);
        if (this.dateRange.to.getMonth() == 0)
          this.dateRange.to.setYear(this.startYear + 1);
        else this.dateRange.to.setYear(this.startYear);
        this.dateRange.fy = this.dateRange.from.getFullYear();
        this.dateRange.ty = this.dateRange.to.getFullYear();
      }
      this.$parent.changeDateRange(this.dateRange);
    }
  },
  components: {
    SelectDateRange,
    SelectOption,
  }
}
</script>

<!-- <select v-if="lyType != 'year'" v-model="startYear" class="start-year">
  <option v-for="opt in yearList" :key="opt" :value="opt">
    {{ opt }}
  </option>
</select> -->