<template>
  <div class="report operation-report">
    <div class="navigation btm-line expand">
      <div class="btn-holder">
        <BtnNavIconText text="day" :compoForChangeScreen="this" />
        <BtnNavIconText text="month" :compoForChangeScreen="this" />
        <BtnNavIconText text="year" :compoForChangeScreen="this" />
        <BtnNavIconText text="lifetime" :compoForChangeScreen="this" />
      </div>
    </div>
    <BarDateTime v-if="currentScreen == 'day'" />
    <BarDateRangeYear v-if="['month', 'year'].includes(currentScreen)"
      :dateRangeData="commModule.load_report_setting.data" :lyType="currentScreen" :firstLoad="firstLoad" />
    <CustomTable :class="{ 'lifetime': currentScreen == 'lifetime' }" :isLoading="isLoading"
      :headings="['dev_name', 'op_info']" :datas="tableData" />
  </div>
</template>

<style>
.operation-report .custom-table .data {
  height: 400px;

}

.operation-report .custom-table.lifetime .data {
  height: 450px;
}

.error-report .bar-date-range-year,
.operation-report .bar-date-range-year {
  padding: 2px 0;
}

.operation-report .table-row>div:nth-child(1) {
  width: 100%;
}

.operation-report .table-row>div:nth-child(2) {
  width: 200px;
}
</style>

<script>
import BtnNavIconText from './BtnNavIconText.vue'
import BarDateTime from './BarDateTime.vue'
import BarDateRangeYear from './BarDateRangeYear.vue'
import CustomTable from './CustomTable.vue'

export default {
  name: "ReportOperation",
  props: {
    commModule: Object,
  },
  data() {
    return {
      currentScreen: 'day',
      startDtInput: new Date(),
      dateRange: {},
      tableData: [],
      isLoading: true,
      firstLoad: true,
      queryOpTime: {
        day: 'hourly_op_time',
        month: 'daily_op_time',
        year: 'monthly_op_time',
        lifetime: 'total_op_time'
      }
    }
  },
  created() {
    this.commModule.getOpTimePoints();
  },
  watch: {
    currentScreen() {
      this.firstLoad = true;
      this.startDtInput = new Date();
      if (['day', 'lifetime'].includes(this.currentScreen))
        this.loadData();
    },
    startDtInput() {
      if (!this.firstLoad)
        this.loadData();
    },
    'commModule.optime_point_list'() {
      this.loadData();
    },
    'commModule.hourly_op_time'() {
      this.reloadData();
    },
    'commModule.daily_op_time'() {
      this.reloadData();
    },
    'commModule.monthly_op_time'() {
      this.reloadData();
    },
    'commModule.total_op_time'() {
      this.reloadData();
    },
  },
  methods: {
    loadData() {
      this.isLoading = true;
      var fromDate = this.ConvertHelper.dtInputToDtObj(this.startDtInput);
      var dataPrep = {};
      switch (this.currentScreen) {
        case 'day':
          dataPrep = {
            year: fromDate.getFullYear(),
            month: fromDate.getMonth() + 1,
            day: fromDate.getDate(),
          }
          break;
        case 'month':
        case 'year':
          dataPrep = this.dateRange;
          break;
      }
      if (!dataPrep) return;
      dataPrep.points = this.commModule.optime_point_list;
      console.log(dataPrep)
      this.commModule.getData(this.queryOpTime[this.currentScreen], dataPrep);
    },
    reloadData() {
      var response = this.commModule[this.queryOpTime[this.currentScreen]];
      var data = response.data;
      this.tableData = [];
      if (data && Object.values(data).length === 0) {
        this.isLoading = false;
        return;
      }
      for (const point_id in this.commModule.point_list) {
        var pointName = this.commModule.point_list[point_id].name;
        var eachHours = data[point_id];
        if (!eachHours) {
          if (this.commModule.optime_point_list.includes(point_id))
            this.tableData.push([pointName, '00:00']);
          continue;
        }
        if (['month', 'year'].includes(this.currentScreen))
          eachHours = Object.values(eachHours);
        var totalSeconds;
        if (this.currentScreen == 'lifetime')
          totalSeconds = eachHours;
        else totalSeconds = eachHours.reduce((a, b) => a + b);
        this.tableData.push([pointName,
          this.ConvertHelper.secondsToHHMM(totalSeconds)
        ]);
      }
      this.isLoading = false;
      this.firstLoad = false;
    },
    changeMonth(newMonth) {
      this.startMthInput = newMonth;
    },
    changeDateRange(newDateRange) {
      this.dateRange = newDateRange;
      this.loadData();
    }
  },
  computed: {
  },
  components: {
    BtnNavIconText,
    BarDateTime,
    BarDateRangeYear,
    CustomTable,
  },
}
</script>

<!-- <div class="content" :class="{ 'lifetime': currentScreen == 'lifetime' }">
  <div class="label-head">
    <div>{{ stringTable.word('dev_name') }}</div>
    <div>{{ stringTable.word('op_info') }}</div>
  </div>
  <LoadingSpinner v-if="isLoading" />
  <div v-if="!isLoading" class="data can-scroll">
    <div v-for="data in tableData" :key="data" class="table-row">
      <div>{{ data.name }}</div>
      <div>{{ data.time }}</div>
    </div>
  </div>
</div> -->