<template>
	<div class="report-page">
		<div class="content-holder">
			<PopUpToast />
			<LoadingSpinner v-if="isLoading" :background="true" />
			<div class="left-content nav-hori-holder">
				<!-- <LoadingSpinner v-if="isNavLoading" :topSpacing="true" /> -->
				<div class="frame" v-if="avlFunc.trend_graph || avlFunc.energy_graph">
					<NavHorizontal v-if="avlFunc.trend_graph" :isIconActiveColor="true" icon="terms_conditions" name="trend_graph"
						@click="changeScreen('ReportTrend')" />
					<NavHorizontal v-if="avlFunc.energy_graph" :isIconActiveColor="true" icon="terms_conditions" name="energy_man"
						@click="changeScreen('ReportEnergy')" />
				</div>
				<div class="frame" v-if="avlFunc.op_report || avlFunc.err_report">
					<NavHorizontal v-if="avlFunc.op_report" :isIconActiveColor="true" icon="terms_conditions" name="op_report"
						@click="changeScreen('ReportOperation')" />
					<NavHorizontal v-if="avlFunc.err_report" :isIconActiveColor="true" icon="terms_conditions" name="err_report"
						@click="changeScreen('ReportError')" />
				</div>
				<div class="frame" v-if="avlFunc.ppd_billing || avlFunc.ppd">
					<NavHorizontal v-if="avlFunc.ppd_billing" :isIconActiveColor="true" icon="terms_conditions" name="issue_bill"
						@click="changeScreen('ReportBill')" />
					<NavHorizontal v-if="avlFunc.ppd" :isIconActiveColor="true" icon="terms_conditions" name="ppd_data_analysis"
						@click="changeScreen('ReportPpdData')" />
				</div>
				<div class="frame">
					<NavHorizontal :isIconActiveColor="true" icon="terms_conditions" name="report_settings"
						@click="changeScreen('ReportSettings')" />
				</div>
			</div>
			<div class="right-content bef-loading">
				<component :is="currentScreen" :commModule="commModule" />
			</div>
		</div>
	</div>
</template>

<style>
.nav-hori-holder .frame {
	padding: 6px;
	margin-bottom: 6px;
}

.report-page .item-text {
	width: 200px;
}

.report-page .content-holder {
	display: flex;
	width: 100%;
	height: 525px;
}

.report-page .left-content {
	width: 300px;
	margin: 6px 0;
	padding: 8px 12px;
	border-right: 2px solid var(--disable);
}

.report-page .right-content {
	width: 724px;
	/* padding: 0 10px; */
}
</style>

<script>
import LoadingSpinner from './LoadingSpinner.vue'
import NavHorizontal from './NavHorizontal.vue'
import ReportBill from './ReportBill.vue'
import ReportEnergy from './ReportEnergy.vue'
import ReportError from './ReportError.vue'
import ReportOperation from './ReportOperation.vue'
import ReportPpdData from './ReportPpdData.vue'
import ReportSettings from './ReportSettings.vue'
import ReportTrend from './ReportTrend.vue'
import PopUpToast from './PopUpToast.vue'

export default {
	name: "PageReport",
	props: {
		commModule: Object,
	},
	data() {
		return {
			currentScreen: '',
			currentScreenPending: '',
			isNavLoading: true,
			isLoading: false,
			savedResponse: [],
			toast: {},
			avlFunc: this.commModule.account.available_functions
		}
	},
	mounted() {
	},
	created() {
		this.NavAssistant.push(this);
		this.initSettings();
	},
	watch: {
		'commModule.load_report_setting'(res) {
			console.log(res)
			if (!res || this.noRes(res.data)) {
				res.data = {
					start_month: 0,
					start_day: 1,
					categories: {
						is_show_energy_categories: false,
						is_energy_generator_enabled: false,
						is_water_consumption_enabled: false,
						is_recycled_water_enabled: false,
						energy: {},
						energy_generator: { name: "energy_generator", points: [] },
						water_consumption: { name: "water_consumption", points: [] },
						recycled_water: { name: "recycled_water", points: [] }
					}
				}
			}

			// // DUMMY
			// res.data.categories.water_consumption = {
			// 	name: "water_consumption",
			// 	points: ['wmeter-dummy-3', 'wmeter-dummy-5']
			// 	// points: []
			// }
			// res.data.categories.recycled_water = {
			// 	name: "recycled_water",
			// 	points: ['wmeter-dummy-4', 'wmeter-dummy-1']
			// 	// points: []
			// }

			this.saveResponse(res)
		},
		'commModule.load_holiday_calendar'(res) {
			if (this.noRes(res)) {
				res.dow = [];
				res.calendar = {
					date: {}, dow: {}
				}
			}
			this.saveResponse(res)
		},
		'commModule.load_owner_info'(res) { this.saveResponse(res) },
		'commModule.load_price_info'(res) {
			if (this.noRes(res)) {
				res.currency = '';
				res.zone = {
					weekday: {
						zone1: { name: '', period: [0, 2400], price: [0, 0], enable: false },
						zone2: { name: '', period: [0, 2400], price: [0, 0], enable: false },
						zone3: { name: '', period: [0, 2400], price: [0, 0], enable: false },
						zone4: { name: '', period: [0, 2400], price: [0, 0], enable: false },
						zone5: { name: '', period: [0, 2400], price: [0, 0], enable: false },
					},
					holiday: {
						zone1: { name: '', period: [0, 2400], price: [0, 0], enable: false },
						zone2: { name: '', period: [0, 2400], price: [0, 0], enable: false },
						zone3: { name: '', period: [0, 2400], price: [0, 0], enable: false },
						zone4: { name: '', period: [0, 2400], price: [0, 0], enable: false },
						zone5: { name: '', period: [0, 2400], price: [0, 0], enable: false },
					}
				}
			}
			this.saveResponse(res)
		},
		'commModule.ppd_point_list'(res) { this.saveResponse(res) },
		'commModule.load_tenant'(res) { this.saveResponse(res) },
	},
	methods: {
		initSettings() {
			this.savedResponse = [];
			this.commModule.getReportSetting();
			this.commModule.getHolidayCal();
			this.commModule.getOwnerInfo();
			this.commModule.getPriceInfo();
			this.commModule.getPpdPoints();
			this.commModule.getTenant();
		},
		noRes(res) {
			return !res || Object.keys(res).length == 0;
		},
		saveResponse(res) {
			this.savedResponse.push(res);
			//			console.log(this.savedResponse)
			this.isNavLoading = this.savedResponse.length != (this.avlFunc.ppd_billing || this.avlFunc.ppd ? 6 : 1);
			if (this.currentScreenPending && !this.isNavLoading) {
				this.currentScreen = this.currentScreenPending;
				this.currentScreenPending = '';
			}
		},
		changeScreen(screenName) {
			if (this.currentScreen == 'ReportSettings') {
				this.currentScreenPending = screenName;
				this.initSettings();
			} else this.currentScreen = screenName;
		}
	},
	computed: {
	},
	components: {
		LoadingSpinner,
		NavHorizontal,
		ReportBill,
		ReportEnergy,
		ReportError,
		ReportOperation,
		ReportPpdData,
		ReportSettings,
		ReportTrend,
		PopUpToast
	},
}
</script>