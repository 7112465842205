<template>
  <component :is="popUp.name" :details="popUp.details" :page_prop="popUp.page" :commModule="commModule"
    :cache="popUp.cache" />
  <div class="screen">
    <LoadingSpinner v-if="isLoading" :background="true" />
    <component :is="currentScreen" :commModule="commModule" :err="reason" />
  </div>
</template>


<style>
/* * {
  cursor: default;
} */
body {
  margin: 0;
  font-family: "Poppins";
}

.font-bold {
  font-weight: bold;
  font-family: 'Poppins-bold';
}

.screen {
  overflow: hidden;
  background-color: var(--background);
}

.vertical-center {
  display: flex !important;
  align-items: center;
}

.vertical-top {
  display: flex !important;
  align-items: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.screen,
.pop-up {
  width: 1024px;
  height: 600px;
}

.pop-up {
  position: absolute;
  top: 0;
  z-index: 50;
  background-color: var(--background);
  overflow: hidden;
}

.pop-up .scroll {
  overflow-y: scroll;
}

.frame {
  border-radius: 5px;
  background-color: var(--background);
  box-shadow: 2px 2px 4px #b8b8b8,
    -3px -3px 0 #ffffff;
}

.frame.shadow-only {
  box-shadow: 2px 2px 4px #b8b8b8;
}

select {
  background: var(--inputArea);
  appearance: none;
  background-image: url(../public/icon/chevron_down.svg);
  background-repeat: no-repeat;
  background-position: bottom 50% right 8px;
  background-size: 18px;

  /* box-shadow: 3px 3px 6px #9d9d9d,
    -3px -3px 6px #ffffff; */
}

select:focus,
input:focus {
  outline: none;
}

input[type=text],
input[type=number],
input[type=date],
input[type=password] {
  width: 100%;
  margin: 2px 0px;
  background-color: var(--background);
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
}

select,
input[type=text],
input[type=number],
input[type=date],
input[type=password] {
  font-size: 1.1em;
  height: 40px !important;
  padding: 0 10px;
}

.reverse-frame {
  border-radius: 6px;
  background: var(--background);
  box-shadow: inset 2px 2px 4px #b8b8b8,
    inset -3px -3px 0 #ffffff;
  ;
}

.reverse-frame-inner {
  border-top: 5px;
  border-style: solid;
  border-color: transparent;
}

select {
  width: 100%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.rotate-frame {
  border-radius: 10px;
  background: var(--background);
  box-shadow: 2px 2px 4px #ffffff,
    -2px -2px 4px #b8b8b8;
}

/* Change color from black to... */
.to-white {
  filter: invert(100%);
}

.to-black {
  filter: invert(0%) sepia(0%) saturate(13%) hue-rotate(61deg) brightness(97%) contrast(101%);
}

.to-active {
  filter: invert(54%) sepia(100%) saturate(606%) hue-rotate(165deg) brightness(102%) contrast(102%);
}

.to-error {
  filter: invert(12%) sepia(91%) saturate(5515%) hue-rotate(358deg) brightness(100%) contrast(110%);
}

.to-alert {
  filter: invert(59%) sepia(80%) saturate(5851%) hue-rotate(360deg) brightness(103%) contrast(106%);
}

.to-blue {
  filter: invert(10%) sepia(97%) saturate(3951%) hue-rotate(230deg) brightness(109%) contrast(121%);
}

.to-fav {
  filter: invert(81%) sepia(30%) saturate(5201%) hue-rotate(43deg) brightness(106%) contrast(71%);
}

.to-green {
  filter: invert(58%) sepia(97%) saturate(548%) hue-rotate(114deg) brightness(91%) contrast(101%);
}

.to-faded {
  filter: invert(72%) sepia(15%) saturate(138%) hue-rotate(158deg) brightness(101%) contrast(84%);
}

.hidden {
  visibility: hidden;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}

.can-scroll {
  overflow-y: scroll;
}

.can-scroll-x {
  overflow-x: scroll;
}

.can-scroll::-webkit-scrollbar,
.can-scroll-x::-webkit-scrollbar {
  display: none;
}

.one-line {
  /* IE and Edge */
  /* Firefox */
  /* text-overflow: ellipsis; */
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.dropdown-menu {
  z-index: 2;
}

.form-check-input {
  margin: 0;
}

.plus-icon {
  font-size: 22px !important;
  /* color: var(--active); */
}

.add-rotate {
  transform: rotate(45deg);
  padding: 5px;
  /* margin-left: 15px; */
  /* margin-bottom: 5px; */
}

.circle {
  border-radius: 50%;
}

.circle.small {
  width: 12px;
  height: 12px;
  margin: 0 6px;
}

.circle.medium {
  width: 18px;
  height: 18px;
  margin: 0 10px;
}

.bkg-gray {
  background-color: var(--fontGray);
}

.bkg-red {
  background-color: var(--orange);
}

.bkg-yellow {
  background-color: var(--yellow);
}

.bkg-green {
  background-color: var(--green);
}
</style>

<script>
import { CommModule } from './components/CommModule.js'
import PageLogin from './components/PageLogin.vue'
import PageMain from './components/PageMain.vue'

import PopUpDevices from './components/PopUpDevices.vue'
import PopUpOperDev from './components/PopUpOperDev.vue'
import PopUpOperGrp from './components/PopUpOperGrp.vue'

import PopUpLanguage from './components/PopUpLanguage.vue'
import PopUpPassword from './components/PopUpPassword.vue'
import PopUpHistory from './components/PopUpHistory.vue'
import PopUpQR from './components/PopUpQR.vue'
import PopUpVersionInfo from './components/PopUpVersionInfo.vue'
import PopUpHistoryFilterDropDown from './components/PopUpHistoryFilterDropDown.vue'
import PopUpUserAdmin from './components/PopUpUserAdmin.vue'
import PopUpRepSetPoints from './components/PopUpRepSetPoints.vue'
import PopUpOwnerSet from './components/PopUpOwnerSet.vue'
import PopUpUnitPriceSet from './components/PopUpUnitPriceSet.vue'
import PopUpCalendar from './components/PopUpCalendar.vue'
import PopUpSchedule from './components/PopUpSchedule.vue'
import PopUpActionOfDay from './components/PopUpActionOfDay.vue'
import PopUpFavorite from './components/PopUpFavorite.vue'
import PopUpIaqChart from './components/PopUpIaqChart.vue'
import PopUpDeviceSetting from './components/PopUpDeviceSetting.vue'
import PopUpGroupSetting from './components/PopUpGroupSetting.vue'
import PopUpSceneSetting from './components/PopUpSceneSetting.vue'
import PopUpIcons from './components/PopUpIcons.vue'
import PopUpIAQRoomSetting from './components/PopUpIAQRoomSetting.vue'
import PopUpGuestRoom from './components/PopUpGuestRoom.vue'
import PopUpGuestLocation from './components/PopUpGuestLocation.vue'
import PopUpAutomaticChangeover from './components/PopUpAutomaticChangeover.vue'
import PopUpInterlock from './components/PopUpInterlock.vue'
import PopUpTermAndCond from './components/PopUpTermAndCond.vue'

import LoadingSpinner from './components/LoadingSpinner.vue'


export default {
  name: "App",
  data() {
    return {
      commModule: new CommModule(),
      currentScreen: 'PageLogin',
      isLoading: false,
      reason: '',
      popUp: {}
    }
  },
  created() {
    this.NavAssistant.push(this);
    this.NavAssistant.setApp(this);
    this.MixList.setCommModule(this.commModule);
  },
  watch: {
    'commModule.ready'(newState) {
      //      console.log('Login State ' + newState);
      //      console.log(this.commModule);
      if (newState == true) {
        this.isLoading = false
        this.currentScreen = 'PageMain'
        var unit = this.commModule.account.temp_unit;
        // unit = 'F'; // this is for test
        // unit = 'C'; // this is for test
        this.tempConvert.setTempUnit(unit);
        this.stringTable.setTempUnit(unit);

        this.commModule.point_list = Object.keys(this.commModule.point_list).sort().reduce(
          (obj, key) => { obj[key] = this.commModule.point_list[key]; return obj; }, {}
        );
        this.commModule.getSpList();


        // // DUMMY
        // var wmeters = [];
        // for (const key in this.commModule.point_list) {
        //   var point = this.commModule.point_list[key];
        //   if (point.usage == 'emeter')
        //     wmeters.push({ ...point });
        // }
        // for (const i in wmeters) {
        //   var wmeterKey = `wmeter-dummy-${3 + parseInt(i)}`;
        //   wmeters[i].key_id = wmeterKey;
        //   wmeters[i].usage = 'wmeter';
        //   wmeters[i].name = `*wmeter*-${wmeters[i].name}`;//
        //   this.commModule.point_list[wmeterKey] = wmeters[i];
        // }
        // for (const point_key in this.commModule.point_list) {
        //   if (['emeter', 'wmeter'].includes(this.commModule.point_list[point_key].usage))
        //     console.log(this.commModule.point_list[point_key])
        // }
        // // DUMMY

      } else if (newState == false) {
        this.currentScreen = 'PageLogin'
      } else {
        console.log('Login Failed');
        this.isLoading = false;
      }
    },
    'commModule.reason'(newState) {
      console.log('Logout reason ' + newState);
      this.reason = newState;
      if (newState.length > 0) this.isLoading = false;
    }
  },
  methods: {
  },
  computed: {
  },
  components: {
    LoadingSpinner,
    PageLogin,
    PageMain,
    PopUpDevices,
    PopUpOperDev,
    PopUpOperGrp,
    PopUpLanguage,
    PopUpPassword,
    PopUpHistory,
    PopUpQR,
    PopUpVersionInfo,
    PopUpHistoryFilterDropDown,
    PopUpUserAdmin,
    PopUpRepSetPoints,
    PopUpOwnerSet,
    PopUpUnitPriceSet,
    PopUpCalendar,
    PopUpSchedule,
    PopUpActionOfDay,
    PopUpFavorite,
    PopUpIaqChart,
    PopUpDeviceSetting,
    PopUpIcons,
    PopUpGroupSetting,
    PopUpSceneSetting,
    PopUpIAQRoomSetting,
    PopUpGuestRoom,
    PopUpGuestLocation,
    PopUpAutomaticChangeover,
    PopUpInterlock,
    PopUpTermAndCond
  },
}
</script>
