<template>
  <div class="frame-action frame" role="button">
    <div class="upper-content vertical-center" @click="toggleSlider">
      <div class="label font-bold">{{ label }}</div>
      <img class="switch to-error" :src="ReiriIcons.delete" @click.stop="deleteTile" />
    </div>
    <div class="bottom-content">
      <div v-if="actionIcons" class="icons-holder vertical-center"
        :class="{ 'btm-spacing': Object.keys(displayDesc).length > 0 || actionIcons.length > 0 }">
        <span v-for="actionIcon in actionIcons" :key="actionIcon">
          <img v-if="actionIcon.name == ReiriIcons.power" :src="actionIcon.name" :class="{
            'to-active': actionIcon.isOn, 'to-faded': !actionIcon.isOn
          }" />
          <span v-else-if="actionIcon.isIconText" class="detail-frame icon-text text-white aircon-temp aircon-temp-cool">
            <img :src="ReiriIcons.air_conditioning" class="to-white" />
            {{ actionIcon.name }}
          </span>
          <img v-else :src="actionIcon.name" :class="{
            'to-active': actionIcon.isOn,
            'to-faded': !actionIcon.isOn && actionIcon.name.includes('timer')
          }" />
        </span>
      </div>
      <!-- {{ displayDesc }} -->
      <div class="vertical-center" v-for="(value, key) in displayDesc" :key="key">
        <div class="left-text">
          <div v-show="key == 'threshold_red'" class="threshold-title font-bold">{{ stringTable.word("threshold") }}</div>
          <div v-if="key === 'threshold_red'">
            <div v-if="isTempHumi" class="vertical-center">
              <div class="yellow-circle" />
              <div>{{ stringTable.word("upper") }}</div>
            </div>
            <div v-else class="vertical-center">
              <div class="red-circle" />
              <div>{{ stringTable.word("warning") }}</div>
            </div>
          </div>
          <div v-else-if="key === 'threshold_yellow'">
            <div v-if="isTempHumi" class="vertical-center">
              <div class="yellow-circle" />
              <div>{{ stringTable.word("lower") }}</div>
            </div>
            <div v-else class="vertical-center">
              <div class="yellow-circle" />
              <div>{{ stringTable.word("caution") }}</div>
            </div>
          </div>
          <div v-else>{{ this.stringTable.word(key) }}</div>
        </div>
        <span v-if="key === 'execute'">&nbsp;</span>
        <span v-else-if="!alignDescValRight && key != 'execute'">:&nbsp;</span>
        <div class="value-content" :class="{ 'right': alignDescValRight, 'right-bottom': isIAQ }">
          <img v-if="key === 'fanstep' || key === 'vmode' || key === 'mode' || key === 'vamount'"
            :src="displayValue(key, value)" />
          <div v-else>{{ displayValue(key, value) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.frame-action .yellow-circle {
  width: 16px;
  height: 16px;
}

.frame-action .red-circle {
  width: 16px;
  height: 16px;
}


.frame-action .right-bottom {
  margin-top: auto;
}

.frame-action.frame {
  padding: 0px 6px;
  margin-bottom: 6px;
}

.frame-action .upper-content {
  padding: 6px;
  border-bottom: 1px solid var(--disable);
}

.frame-action .upper-content img {
  margin-left: auto;
  margin-right: 0;
}

.frame-action .label {
  padding-left: 10px;
}

.frame-action .bottom-content {
  padding: 0 20px 5px 20px;
  font-size: 14px;
}

.frame-action .icons-holder.btm-spacing {
  margin: 8px 0 4px 0;
}

.frame-action .icons-holder span {
  margin-right: 3px;
}

.frame-action .value-content.right {
  margin-left: auto;
  color: var(--fontGray);
}

.frame-action .icon-text img {
  width: 18px;
}
</style>

<script>

export default {
  setup() {
  },
  name: "FrameAction",
  props: {
    label: String,
    actionIcons: Array,
    pointKey: String,
    actionDesc: Object,
    alignDescValRight: Boolean,
    commModule: Object,
    isIAQ: Boolean,
  },
  data() {
    return {
      sceneActionDisplayList: {
        stat: { on: 'on', off: 'off' }, off_timer: { on: 'enable', off: 'disable' },
        notify: { true: 'enable', false: 'disable' }, av: {},
        rop_proh: { true: 'enable', false: 'disable' }, 'rmode_proh': { true: 'enable', false: 'disable' },
        rsp_proh: { true: 'enable', false: 'disable' }, 'rfan_proh': { true: 'enable', false: 'disable' },
        fanstep: {
          2: { L: this.ReiriIcons.fan2_1, H: this.ReiriIcons.fan2_2, A: this.ReiriIcons.fan2_auto },
          3: { L: this.ReiriIcons.fan3_1, M: this.ReiriIcons.fan3_2, H: this.ReiriIcons.fan3_3, A: this.ReiriIcons.fan3_auto },
          5: {
            L: this.ReiriIcons.fan5_1, LM: this.ReiriIcons.fan5_2, M: this.ReiriIcons.fan5_3, MH: this.ReiriIcons.fan5_4, H: this.ReiriIcons.fan5_4, A: this.ReiriIcons.fan5_auto
          }
        },
        vmode: {
          A: this.ReiriIcons.HRV_auto, HX: this.ReiriIcons.HRV_bypass, S: this.ReiriIcons.HRV_bypass
        },
        mode: {
          C: this.ReiriIcons.cooling, H: this.ReiriIcons.sun, F: this.ReiriIcons.fan, D: this.ReiriIcons.dry, A: this.ReiriIcons.auto_default
        },
        vamount: {
          A: this.ReiriIcons.fan2_auto, L: this.ReiriIcons.fan2_1, H: this.ReiriIcons.fan2_2
        },
        backupActionDesc: {},
      }
    }
  },
  watch: {
  },
  created() {
    // this.displayDesc = JSON.parse(JSON.stringify(this.actionDesc))
    if (this.isIAQ) {
      // this.backupActionDesc = JSON.parse(JSON.stringify(this.actionDesc))
      delete this.displayDesc['id']
    }
  },
  computed: {
    isTempHumi() {
      return ['temp', 'humi', 'dehumi'].includes(this.displayDesc.type)
    },
    displayDesc() {
      const prop = JSON.parse(JSON.stringify(this.actionDesc))
      delete prop['id']
      return prop;
    },
  },
  methods: {
    displayValue(key, value) {
      if (key === 'sp' || key === 'sb_csp' || key === 'sb_hsp') {
        return value == '--' ? value : `${this.tempConvert.spConvert(value).toFixed(1)}${this.stringTable.word('DEG')}`;
      } else if (['off_timer', 'notify', 'rop_proh', 'rmode_proh', 'rsp_proh', 'rfan_proh'].includes(key)) {
        return this.stringTable.word(this.sceneActionDisplayList[key][value]);
      } else if (key === 'fanstep') {
        return this.sceneActionDisplayList[key][this.commModule.point_list[this.pointKey].fanstep_cap['S']][value]
      } else if (key === 'vmode' || key === 'mode' || key === 'vamount') {
        return this.sceneActionDisplayList[key][value]
      } else if (key === 'hum_sp') {
        return `${value.toFixed(1)}%`;
      } else if (key === 'av') {
        return `${value}`;
      } else if (key === 'rgbw') {
        return '0x' + value.toString(16).slice(0, -2).toUpperCase()
      } else if ((key === 'threshold_red' || key === 'threshold_yellow') && this.isIAQ) {
        if (this.displayDesc.type == 'temp' || this.displayDesc.type == 'Ac') {
          return `${this.tempConvert.tempConvertRound(value)}${this.stringTable.word('DEG')}`;
        } else if (this.displayDesc.type == 'humi' || this.displayDesc.type == 'dehumi') {
          return `${value.toFixed(1)}%`;
        } else if (this.displayDesc.type == 'pm25') {
          return `${value.toFixed(1)}µg/m3`;
        } else if (this.displayDesc.type == 'co2') {
          return `${value.toFixed(1)}ppm`;
        } else if (this.displayDesc.type == 'tvoc') {
          return `${value.toFixed(1)}mg/m3`;
        }
      } else if (value == 'dehumi')
        return this.stringTable.word('humi')
      else
        return this.stringTable.word(value)
    },
    convertToDecimal(value) {
      return [null, ''].includes(value) ? 0.0 : value.toFixed(1)
    },
    deleteTile() {
      this.$parent.deleteAction(this.pointKey)
    }
  },
  components: {
  }
}
</script>
