<template>
  <div :class="`guest-control ${label}-holder vertical-center`">
    <div class="lbl">{{ stringTable.word(label) }}</div>
    <SelectOption v-if="showOption" :selectList="getSelections()" :showStringTable="true"
      :defaultSelectIndex="optionIndex()" :placeholder="optPlaceholder" :frame="true" />
    <div v-if="valFanspeed !== undefined" class="frame fanspeed" @click="changeFanspeed()">
      <img :src="ReiriIcons.iconList.fanstep3[fanspeeds[fanspeedIndex()]]" />
    </div>
    <BtnSwitch v-if="valSwitch !== undefined" class="switch-holder vertical-center" name="main" :default="settings_val" />
  </div>
</template>

<style></style>

<script>
import SelectOption from './SelectOption.vue'
import BtnSwitch from './BtnSwitch.vue'

export default {
  name: "GuestControl",
  props: {
    settings: Object,
    label: String,
    keys: Array,
    optStatus: String,
    optSetpoint: String,
    optDelayWin: String,
    optDelayUnoc: String,
    valFanspeed: String,
    valSwitch: String,
  },
  data() {
    return {
      settingsCloned: this.settings,
      mainData: {},
      optStatusDef: this.optStatus !== undefined,
      optSetpointDef: this.optSetpoint !== undefined,
      optDelayWinDef: this.optDelayWin !== undefined,
      optDelayUnocDef: this.optDelayUnoc !== undefined,
      fanspeeds: ['L', 'M', 'H'],
      fanspeed: this.valFanspeed,
      optPlaceholder: '',
    }
  },
  created() {
    this.showOption = this.optStatusDef || this.optSetpointDef || this.optDelayWinDef || this.optDelayUnocDef;
  },
  watch: {
  },
  methods: {
    toTemperature(num) {
      if (typeof num === 'number')
        return `${this.tempConvert.spConvert(num).toFixed(1)}${this.stringTable.word('DEG')}`;
      else return num;
    },
    toMinute(num) {
      if (typeof num === 'number')
        return `${num} ${this.stringTable.word('minutes')}`
      else return num;
    },
    getSelectionsObj() {
      var res = {};
      if (this.optStatusDef) {
        ['on', 'off', 'setback'].forEach(k => res[k] = k);
      }
      else if (this.optSetpointDef) {
        res['none'] = null;
        for (let index = this.tempConvert.spConvert(16);
          index <= this.tempConvert.spConvert(32);
          index += this.tempConvert.spGap()) {
          var convertedTemp = this.tempConvert.spRevConvert(index);
          res[this.toTemperature(convertedTemp)] = convertedTemp;
        }
      }
      else if (this.optDelayWinDef) {
        res = { 'ignore': -1, 'no_delay': 0 };
        for (let index = 1; index <= 5; index++) {
          res[this.toMinute(index)] = index;
        }
      }
      else if (this.optDelayUnocDef) {
        res = { 'no_delay': 0 };
        for (let index = 1; index <= 10; index++) {
          res[this.toMinute(index)] = index;
        }
      }
      return res;
    },
    getSelections() {
      return Object.keys(this.getSelectionsObj());
    },
    optionIndex() {
      var selections = this.getSelections();
      var definedOpt = this.settings_val;
      if (this.optSetpointDef) {
        if (definedOpt === null) definedOpt = 'none';
        else definedOpt = this.toTemperature(definedOpt);
      }
      else if (this.optDelayWinDef) return parseInt(definedOpt) + 1;
      else if (this.optDelayUnocDef) return definedOpt;
      var optIndex = selections.indexOf(definedOpt);
      if (this.optSetpointDef && optIndex == -1)
        this.optPlaceholder = definedOpt;
      return optIndex;
    },
    fanspeedIndex() {
      return this.fanspeeds.indexOf(this.settings_val);
    },
    changeFanspeed() {
      var index = this.fanspeedIndex();
      index++;
      if (index >= this.fanspeeds.length) index = 0;
      this.settings_val = this.fanspeeds[index];
    },
    changeOption(value) {
      if (this.settings_val != value) {
        this.settings_val = this.getSelectionsObj()[value];
      }
    },
    changeSwitch(name, value) {
      this.settings_val = value;
    },
  },
  computed: {
    settings_val: {
      get() {
        var k = this.keys;
        switch (k.length) {
          case 1: return this.settingsCloned[k[0]];
          case 2: return this.settingsCloned[k[0]][k[1]];
          case 3: return this.settingsCloned[k[0]][k[1]][k[2]];
        }
        return null;
      },
      set(val) {
        var k = this.keys;
        switch (k.length) {
          case 1: this.settingsCloned[k[0]] = val; break;
          case 2: this.settingsCloned[k[0]][k[1]] = val; break;
          case 3: this.settingsCloned[k[0]][k[1]][k[2]] = val; break;
        }
      },
    }
  },
  components: {
    SelectOption,
    BtnSwitch
  },
}
</script>

<!-- // if (this.optStatusDef) return this.optStatus;
// if (this.optSetpointDef) return this.optSetpoint;
// if (this.optDelayWinDef) return this.optDelayWin;
// if (this.optDelayUnocDef) return this.optDelayUnoc; -->