<template>
  <div v-if="!showInterlockEditCond"
    class="pop-up user system-setting iaq auto-changeover device-setting schedule-program schedule-sub interlock">
    <div class="content-holder">
      <div class="left-content  ">
        <PopUpHead :details="details" :hideBorderBtm="true" :leftArr="true" :small="true" :plus="true" />
        <LoadingSpinner v-if="isLoading" :topSpacing="true" />
        <div class="nav-list">
          <div v-for="key in slicedList" :key="key">
            <div v-if="clonedProgramList[key]" class="frame">
              <NavHorizontal :name="clonedProgramList[key].name" :value=clonedProgramList[key] hideKeyValue="true"
                :hideChevron=true showDescription="false" :showSwitch="true" :id="key"
                :switchValue="clonedProgramList[key].exec" />
            </div>
          </div>
        </div>
        <div class="vertical-center">
          <NavPagination direction="up" :expandHori="true" />
          <NavPagination direction="down" :expandHori="true" />
        </div>
      </div>
      <div class="right-frame">
        <PopUpToast />
        <PopUpHead v-if="showEdit" :details="rightTitle" :leftBtnTxt="!this.isRegister ? 'delete' : ''" rightBtnTxt="save"
          :hideBorderBtm="true" />

        <div class="edit-section" v-show="showEdit">
          <!-- name -->
          <!-- {{ clonedGroupList }} -->
          <FrameInput v-if="programDetails.hasOwnProperty('name')" varStr="programDetails" varStr2="name"
            placeholder="name" />

          <div class="sel-holder vertical-center">
            <div class="target-holder">
              <div class="title vertical-center">
                {{ stringTable.word('condition') }}
                <img class="add-rotate" :src="ReiriIcons.cross" @click="addCond" />
              </div>
              <div class="reverse-frame">
                <div class="detail-holder reverse-frame-inner can-scroll">
                  <!-- {{ programDetails.exec_condition }} -->
                  <!-- {{ programDetails.action }} -->
                  <!-- Execute Condition -->
                  <div class="frame condition-option vertical-center" @click="toggleDropdown" role="button">
                    <div class="content w-100">
                      <div class="item-text can-scroll">{{ stringTable.word("exec_condition") }} </div>
                      <div class="description-text">{{ stringTable.word(execConditionDescp(programDetails.exec_condition))
                      }} </div>
                    </div>
                    <!-- {{ execConditionOptions }} -->
                    <SelectDropDown ref="selectDropdown" mainLabel="select_device" :items="execConditionOptions"
                      :plusIcon=true checkedString="stdUserChecked" :hideCheckBox="true" :showChevonRight="true"
                      :hideIcon="true" isExecCond="true" toChange="execConditionOptions" />
                  </div>

                  <!-- No of conditions -->
                  <div v-if="execConditionDescp(programDetails.exec_condition) == 'n_valid'"
                    class="frame condition-option vertical-center">
                    <div class="content valid-duration">
                      <div class="item-text can-scroll">{{ stringTable.word("n_cond") }} </div>
                    </div>
                    <SelectOption name="noOfCond" :selectList="noOfCondOption" :defaultSelectIndex="minNoOfCond"
                      :upsideDropdown=false :hideArrow="true" />
                  </div>

                  <!-- Valid Duration -->
                  <div class="frame condition-option vertical-center">
                    <div class="content valid-duration">
                      <div class="item-text can-scroll">{{ stringTable.word("timer") }} </div>
                      <div class="description-text">{{ stringTable.word("timer_explanation") }} </div>
                    </div>
                    <SelectOption class="duration-input" name="validDuration" :selectList="minutesDuration"
                      :defaultSelectIndex="programDetails.duration" :upsideDropdown=false :hideArrow="true" />
                    {{ stringTable.word("min_short") }}
                  </div>

                  <!-- Condition Tile -->
                  <div v-if="Object.keys(programDetails.condition).length !== 0" class="condition-list">
                    <div v-for="(value, key) in programDetails.condition" :key="key">
                      <!-- {{ key }} -->
                      <div v-if="getNameByKeyId(value.mp)" class="frame condition-option action">
                        <NavHorizontal :name="getNameByKeyId(value.mp)" :iconHelperValue="getObjByKeyId(value.mp)"
                          :value=value description="" hideKeyValue="true" :hideChevron=true showDescription="true"
                          :id="value.mp" :pointKey="value.mp" :showDelete="true" :commModule="commModule"
                          :type="getTypeByKeyId(value.mp)" :isCondition="true" :index="key" />
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
            <!-- Action -->
            <div class="target-holder">
              <div class="title vertical-center">{{ stringTable.word('action') }}
                <img class="add-rotate" :src="ReiriIcons.cross" @click="addAction" />
              </div>
              <div class="reverse-frame">
                <div class="detail-holder reverse-frame-inner can-scroll">
                  <!-- {{ programDetails }} -->
                  <!-- {{ programDetails.condition }} -->
                  <div v-for="(value, key) in  programDetails.action " :key="key">
                    <div v-if="getNameByKeyId(value[0])" class="frame condition-option action">
                      <NavHorizontal :name="getNameByKeyId(value[0])" :iconHelperValue="getObjByKeyId(value[0])"
                        :value=value description="" hideKeyValue="true" :hideChevron=true showDescription="true" :id="key"
                        :pointKey="value[0]" :showDelete="true" :deviceStatusList="value[1]" :commModule="commModule"
                        :type="getTypeByKeyId(value[0])" :isAction="true" :index="key" :verticalTop="true" />
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <InterlockEditCond v-if="showInterlockEditCond" :isNewCond="isNewCond" :commModule="clonedCommModule"
    :details="condDetails" :showLast="true" :selectedPoint="currentPoint" :index="selectedCondIndex"
    :allConditions="programDetails.condition" />
  <InterlockEditAction v-if="showInterlockEditAction" :isNewAction="isNewAction" :commModule="clonedCommModule"
    :details="actionDetails" :selectedAction="currentAction" :type="actionType" :index="selectedActionIndex"
    :actionKeyList="actionKeyList()" :allActions="programDetails.action" />
</template>

<style>
.interlock .nav-list {
  margin-bottom: 4px;
}

.interlock .sel-holder>div {
  width: 50%;
}

.interlock .condition-list {
  margin-top: 20px;
}

.interlock .edit-section .nav-horizontal .item-text {
  max-width: unset;
  height: auto;
  padding: 3px 0;
  margin: 0;
}

.interlock .edit-section .nav-horizontal {
  padding: 0px;
}

.interlock .edit-section .icon {
  width: 34px;
  height: 34px;
  padding: 0px;
  margin: 0 3px auto 3px;
}

.interlock .condition-option {
  padding: 5px 7px 5px 5px;
  margin: 3px 3px 6px 3px;
}

.interlock .condition-option .content {
  padding-left: 4px;
}

.interlock .nav-horizontal .condition-status {
  padding: 0px 5px 0px 15px;
  justify-content: unset;
  max-width: 260px;
}

.interlock .condition-option .content .cond-lbl {
  max-width: 90px;
}

.interlock .condition-option .content .cond-val {
  flex: 1;
  max-width: 120px;
}

.interlock .condition-option .description-text {
  color: var(--active);
  padding-right: 3px;
}

.interlock .condition-option .dropdown-top {
  width: 320px;
  margin-top: 40px !important;
  margin-left: -285px !important;
}

.interlock .condition-option .select-dropdown-holder {
  position: unset;
  margin-left: auto;
}

.interlock .condition-option .add-rotate {
  position: unset;
}

.interlock .duration-input {
  margin-right: 5px;
}

.interlock .select-option {
  width: 70px;
}

.interlock .edit-section {
  overflow-y: unset;
  height: 540px;
}

.interlock .detail-holder {
  height: 450px !important;
}

.interlock .reverse-frame-inner {
  padding: 0 !important;
}

.interlock .action .description-text {
  color: unset;
}

.interlock .arrow {
  margin-left: auto;
}

.interlock .add-rotate {
  padding: 0px;
  margin: 4px 8px 4px auto;
}
</style>

<script>
import PopUpHead from './PopUpHead.vue'
import FrameInput from "./FrameInput.vue";
import SelectDropDown from './SelectDropDown.vue'
import NavHorizontal from './NavHorizontal.vue'
import SelectOption from './SelectOption.vue'
import LoadingSpinner from './LoadingSpinner.vue';
import PopUpToast from './PopUpToast.vue';
import InterlockEditCond from "./InterlockEditCond.vue";
import InterlockEditAction from "./InterlockEditAction.vue";
import NavPagination from './NavPagination.vue'

export default {
  name: "PopUpInterlock",
  data() {
    return {
      toast: {},
      details: { name: "interlock" },
      clonedCommModule: {},
      showEdit: false,
      isNewIAQValue: false,
      showEditIAQValue: false,
      clonedPointList: {},
      clonedProgramList: {},
      isRegister: false,
      programId: '',
      programName: '',
      programDetails: { "name": '', "exec": false, "exec_condition": '', "duration": 0, "condition": [], "action": [] },
      // execConditionDescp: { "all": "all_valid", "one": "one_valid", "none": "n_valid" },
      execConditionOptions: ["all", "one", "none"],
      minutesDuration: Array.from(Array(61).keys()),
      pointsChecked: {},
      programKeyList: [],
      changeSwitch: false,
      actionDeviceLi: [],
      showInterlockEditCond: false,
      showInterlockEditAction: false,
      isNewCond: false,
      isNewAction: false,
      currentPoint: {},
      condDetails: {},
      actionDetails: {},
      actionType: '',
      selectedCondIndex: 0,
      selectedActionIndex: 0,
      page: 1,
      itemPerPage: 13,
      slicedList: [],
    }
  },
  props: {
    commModule: Object,
    cache: Object,
  },
  created() {
    this.isLoading = true
    this.getProgram()
    this.clonedCommModule = this.commModule
  },
  mounted() {
  },
  computed: {
    minNoOfCond() {
      return this.noOfCondOption.indexOf(parseInt(this.programDetails.exec_condition))
    },
    noOfCondOption() {
      const min = 2;
      const max = this.programDetails.condition.length;
      if (max < min) {
        return []; // If there are 1 or fewer items, return an empty array
      } else {
        // Generate an array containing numbers from min to max
        const result = Array.from({ length: max - min + 1 }, (_, index) => min + index)
        return result
      }
    },
    rightTitle() {
      var name = 'interlock_summary';
      return { name }
    },
    mainList() {
      return Object.keys(this.clonedProgramList)
    },
  },
  watch: {
    'commModule.interlock_get_program'() {
      this.isLoading = false
      var interlock = this.commModule.interlock_get_program.program;
      this.clonedProgramList = interlock ? JSON.parse(JSON.stringify(interlock)) : {};
      this.programKeyList = Object.values(this.clonedProgramList).map(item => item.name);
      this.commModule.getSchedule();
      this.isError = false
    },
    'commModule.get_schedule'() {
      this.actionDeviceLi = this.MixList.get(['point', 'group', 'scene', 'interlock', 'calendar', 'schedule']);
    },
    'commModule.interlock_add_program'() {
      var isNg = this.commModule.interlock_add_program.result == "NG";
      if (!isNg)
        this.page = this.ConvertHelper.getMaxPage(this.mainList.length + 1, this.itemPerPage);
      this.toast = { text: isNg ? 'update_fail' : 'succeed' }
      this.showEdit = false
      this.getProgram()
    },
    'commModule.interlock_modify_program'() {
      var text = this.commModule.interlock_modify_program.result == "OK" ?
        'succeed' : 'update_fail';
      if (!this.changeSwitch) {
        this.toast = { text }
        this.showEdit = false;
      }
      this.getProgram()
    },
    'commModule.interlock_delete_program'() {
      var text = this.commModule.interlock_delete_program.result == "OK" ?
        'succeed' : this.commModule.interlock_delete_program.result;
      this.toast = { text }
      this.showEdit = false
      this.getProgram()
    },
  },
  methods: {
    toggleDropdown() {
      // Access the SelectDropDown component using the ref attribute
      const selectDropdown = this.$refs.selectDropdown;

      // Toggle the dropdown menu
      if (selectDropdown) {
        selectDropdown.toggleDropdown();
      }
    },
    actionKeyList() {
      var result = [];
      for (var i = 0; i < this.programDetails.action.length; i++) {
        if (this.programDetails.action[i].length > 0) {
          result.push(this.programDetails.action[i][0]);
        }
      }
      return result
    },
    clickLeftBtn() {
      this.toast = {
        text: 'conf_del',
        pendingYesMethod: () => {
          this.commModule.deleteInterlock(this.programId);
        }
      }
    },
    clickRightBtn() {
      if (this.ConvertHelper.isEmptyString(this.programDetails.name) || Object.keys(this.programDetails).length === 0) {
        this.toast = { text: 'no_name', isError: true, }
        return
      } else if (this.programDetails.condition.length == 0) {
        this.toast = { text: 'no_condition', isError: true, }
        return
      } else if (this.programDetails.action.length == 0) {
        this.toast = { text: 'no_action', isError: true, }
        return
      } else if ((this.programDetails.exec_condition != 'one' && this.programDetails.exec_condition != 'all') && (isNaN(this.programDetails.exec_condition) || this.programDetails.exec_condition == null || this.programDetails.exec_condition == 'none')) {
        this.toast = { text: 'n_cond', isError: true, }
        return
      }

      if (this.isRegister) { // register 
        if (this.programKeyList.includes(this.programDetails.name)) {
          this.toast = { text: 'same_name', isError: true, }
          return
        }
        this.commModule.addInterlock(this.programDetails);
      } else { // edit
        if (this.programName !== this.programDetails.name && this.programKeyList.includes(this.programDetails.name)) {
          this.toast = { text: 'same_name', isError: true, }
          return
        }
        this.programDetails.exec = this.clonedProgramList[this.programId].exec
        this.clonedProgramList[this.programId] = this.programDetails
        this.commModule.editInterlock(this.clonedProgramList);
      }
    },
    deleteCondAction(index, isCond, isAction) {
      this.toastText = this.stringTable.word("")
      var pendingYesMethod;
      if (isCond) {
        pendingYesMethod = () => {
          // if now the exec_condition == max of noOfCondOption, delete a condition, exec_condition decrease 1 if total no of cond still >= 2
          if (this.programDetails.exec_condition == this.noOfCondOption[this.noOfCondOption.length - 1] && (this.noOfCondOption.length - 1 >= 2)) {
            this.programDetails.exec_condition = this.programDetails.exec_condition - 1
          }

          // delete selected condition
          this.programDetails.condition.splice(index, 1);

          // if no of condition less than 2, set exec_condition to 0
          if (this.noOfCondOption.length == 0 && typeof this.programDetails.exec_condition === 'number') {
            this.programDetails.exec_condition = null
          }
        }
      } else if (isAction) {
        pendingYesMethod = () => {
          this.programDetails.action.splice(index, 1);
        }
      }
      this.toast = { text: 'conf_del', pendingYesMethod }
    },
    updateCondDetails(detail) {
      if (this.selectedCondIndex == -1) {
        this.programDetails.condition.push(detail)
        // update exec condition to 2 (default) if min 2 condition added
        if ((this.programDetails.exec_condition == null || this.programDetails.exec_condition == 'none') && this.noOfCondOption.length != 0)
          this.programDetails.exec_condition = this.noOfCondOption[0]
      } else
        this.programDetails.condition[this.selectedCondIndex] = detail
    },
    updateActionDetails(detail) {
      if (this.selectedActionIndex == -1) {
        this.programDetails.action.push(detail)
      } else
        this.programDetails.action[this.selectedActionIndex] = detail
    },
    prev() {
      this.showEdit = false
    },
    addCond() {
      this.selectedCondIndex = -1
      this.NavAssistant.openPopUp(this);
      this.showInterlockEditCond = true
      this.isNewCond = true
    },
    addAction() {
      this.selectedActionIndex = -1
      this.NavAssistant.openPopUp(this);
      this.showInterlockEditAction = true
      this.isNewAction = true
    },
    getNameByKeyId(keyId) {
      const item = this.actionDeviceLi.find(item => item.key_id === keyId);
      return item ? item.name : false;
    },
    getTypeByKeyId(keyId) {
      const item = this.actionDeviceLi.find(item => item.key_id === keyId);
      return item ? item.icon_map : false;
    },
    getObjByKeyId(keyId) {
      const item = this.actionDeviceLi.find(item => item.key_id === keyId);
      return item ? item : {};
    },
    execConditionDescp(value) {
      if (value == "all")
        return "all_valid"
      else if (value == "one")
        return "one_valid"
      else
        return "n_valid"
      // { "all": "all_valid", "one": "one_valid", "none": "n_valid" },
    },
    changeOption(item, name) {
      if (this.execConditionOptions.includes(item)) {
        if (item == 'all' || item == 'one') {
          this.programDetails.exec_condition = item
        } else if (item == 'none') {
          if (this.noOfCondOption.length > 0) {
            this.programDetails.exec_condition = this.noOfCondOption[0]
          } else
            this.programDetails.exec_condition = item
        }
      } else if (name == 'noOfCond') {
        this.programDetails.exec_condition = Number(item)
      } else if (name == 'validDuration') {
        this.programDetails.duration = Number(item)
      }
    },
    plus() {
      this.isRegister = true
      this.programId = ''
      this.programDetails = { "name": '', "exec": false, "exec_condition": this.execConditionOptions[0], "duration": 0, "condition": [], "action": [] }
      this.showEdit = true
      // this.pointsChecked = {}
      // this.targetPointList = ['No device']
      // this.initializePointChecked()
      // this.initializePointList()
    },
    frameSelected(item) {
      if (item.isCond) {
        this.showInterlockEditCond = true
        this.currentPoint = this.commModule.point_list[item.key]
        this.condDetails = this.programDetails.condition[item.index]
        this.isNewCond = false
        this.selectedCondIndex = item.index
      } else if (item.isAction) {
        this.actionType = this.getTypeByKeyId(item.value[0])
        switch (this.actionType) {
          case 'point':
            this.currentAction = this.MixList.commModule.point_list[item.value[0]]
            break;
          case 'group':
            this.currentAction = this.MixList.commModule.group_list[item.value[0]]
            break;
          case 'scene':
            this.currentAction = this.MixList.commModule.scene_list[item.value[0]]
            break;
          case 'schedule':
            this.currentAction = this.MixList.commModule.get_schedule.program[item.value[0]]
            break;
          case 'interlock':
            this.currentAction = this.MixList.commModule.interlock_get_program.program[item.value[0]]
            break;
          default:
            break;
        }
        this.actionDetails = this.programDetails.action[item.index]
        this.isNewAction = false
        this.selectedActionIndex = item.index
        this.showInterlockEditAction = true
      } else {
        this.isRegister = false
        this.showEdit = true
        this.programDetails = JSON.parse(JSON.stringify(item.value))
        this.programId = item.key;
        this.programName = JSON.parse(JSON.stringify(this.programDetails.name))
      }
    },
    switchClicked(programKey) {
      this.clonedProgramList[programKey].exec = !this.clonedProgramList[programKey].exec
      this.changeSwitch = true
      this.commModule.editInterlock(this.clonedProgramList);
    },
    getProgram() {
      this.changeSwitch = false
      this.isError = false
      this.commModule.getInterlock();
    }
  },
  components: {
    PopUpHead,
    FrameInput,
    SelectDropDown,
    NavHorizontal,
    SelectOption,
    PopUpToast,
    LoadingSpinner,
    InterlockEditCond,
    InterlockEditAction,
    NavPagination
  }
}
</script>