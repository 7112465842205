<template>
  <div class="report bill-report">
    <PopUpToast />
    <div class="top-content">
      <div class="vertical-center">
        <div class="frame date-holder vertical-center">
          <VueDatePicker class="vue-datepicker start-date" v-model="startDate"
            :format="ConvertHelper.datePickerFormat(startDate)" :enable-time-picker="false" :clearable="false" auto-apply
            inputmode='none' :transitions="false" />
          <div class="date-dash">-</div>
          <VueDatePicker class="vue-datepicker end-date" v-model="endDate"
            :format="ConvertHelper.datePickerFormat(endDate)" :enable-time-picker="false" :clearable="false" auto-apply
            inputmode='none' :transitions="false" />
        </div>
        <div class="frame tenant-holder">
          <SelectOption :selectList="tenantsSel" placeholder="sel_tenant" />
        </div>
      </div>
      <div class="show-btn" role="button" @click="loadData()">
        {{ stringTable.word('show') }}
      </div>
    </div>
    <!-- <BarDateTime v-if="currentScreen == 'day'" />
    <BarDateRangeYear v-if="['month', 'year'].includes(currentScreen)"
      :dateRangeData="commModule.load_report_setting.data" :lyType="currentScreen" :firstLoad="firstLoad" /> -->
    <div class="tables-content can-scroll">
      <LoadingSpinner v-if="isLoading" />
      <div v-else v-for="data, index in tableData" :key="data">
        <div class="d-none">{{ hasData = data.all.length > 0 }}</div>
        <div v-if="hasData" class="title font-bold tenant"> {{ data.tenant }} </div>
        <CustomTable v-if="hasData || index == 0" class="all-unit"
          :headings="['timezone', 'power_consumption', 'unit_price', 'price']" :datas="data.all" />
        <div v-if="hasData" class="title font-bold"> {{ stringTable.word('power_consumption_header') }}[kWh] </div>
        <CustomTable v-if="hasData" class="each-unit" :headings="data.eachHeadings" :datas="data.each" />
      </div>
    </div>
  </div>
</template>

<style>
.bill-report .top-content {
  border-bottom: 2px solid var(--disable);
}

.bill-report .frame {
  padding: 2px 4px;
}

.bill-report .date-holder {
  padding: 3px 0;
  width: 58%;
}

.bill-report .tenant-holder {
  width: 40.8%;
  margin-left: auto;
  text-align: center;
}

.bill-report .tenant-holder select {
  width: 90%;
}

.bill-report .show-btn {
  border-radius: 30px;
  color: white;
  background-color: var(--active);
  width: 400px;
  text-align: center;
  padding: 6px 0;
  margin: 8px auto 5px auto;
}

.bill-report .start-date,
.bill-report .end-date {
  width: 150px;
}

.bill-report .start-date {
  margin-left: auto;
}

.bill-report .end-date {
  margin-right: auto;
}

.bill-report .date-dash {
  margin: 0 8px;
}

.bill-report .table-row {
  font-size: 12px;
}

.bill-report .all-unit .table-row>div:nth-child(1) {
  width: 500px;
}

.bill-report .all-unit .table-row>div:nth-child(2) {
  width: 300px;
}

.bill-report .all-unit .table-row>div:nth-child(3) {
  width: 150px;
}

.bill-report .all-unit .table-row>div:nth-child(4) {
  width: 100px;
}

.bill-report .each-unit .table-row>div:nth-child(1) {
  width: 300px;
}

.bill-report .each-unit .table-row>div:nth-child(n+2) {
  padding: 0 4px;
  min-width: 68px;
  max-width: 68px;
}

.bill-report .table-row>div:nth-child(n+2) {
  text-align: right;
}

.bill-report .tables-content {
  overflow-y: scroll;
  margin-top: 6px;
  padding: 0 6px;
  width: 100%;
  height: 410px;
}

.bill-report .tables-content .title.tenant {
  font-size: 18px;
}

.bill-report .custom-table {
  width: inherit;
  height: auto;
  position: relative;
  margin-top: 0;
}

.bill-report .custom-table .data {
  height: fit-content;
}
</style>

<script>
import PopUpToast from './PopUpToast.vue';
import SelectOption from './SelectOption.vue';
import CustomTable from './CustomTable.vue';
import LoadingSpinner from './LoadingSpinner.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name: "ReportBill",
  props: {
    commModule: Object,
  },
  data() {
    return {
      isLoading: false,
      toast: {},
      startDate: new Date(),
      endDate: new Date(),
      tenant: '',
      tenants: [],
      tenantsSel: [],
      tableData: [],
      allUnitData: [],
      eachUnitData: [],
      zoneNameMap: {
        zone1: "Zone 1",
        zone2: "Zone 2",
        zone3: "Zone 3",
        zone4: "Zone 4",
        zone5: "Zone 5",
      }
    }
  },
  created() {
    this.startDate = new Date();
    this.startDate.setMonth(this.startDate.getMonth() - 1);
    this.startDate.setDate(1);
    this.endDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth() + 1, 0);

    var tenantLoad = this.commModule.load_tenant;
    if (tenantLoad) {
      this.tenants = Object.keys(tenantLoad);
      this.tenantsSel = Object.keys(tenantLoad);
    }
    this.tenantsSel.push(this.stringTable.word('all_tenant'));
  },
  watch: {
    startDate() {
      this.changeDate(true, false);
    },
    endDate() {
      this.changeDate(false, true);
    },
    'commModule.get_bill_data'() {
      console.log(this.commModule.get_bill_data)
      this.reloadData();
    }
  },
  methods: {
    changeOption(newTenant) {
      this.tenant = newTenant;
    },
    convertToFix(val, fixNum) {
      var threshold = Math.pow(10, fixNum);
      return parseFloat((Math.trunc(val * threshold) / threshold).toFixed(fixNum));
    },
    changeDate(changeStartDate, changeEndDate) {
      const diffTime = Math.abs(this.endDate - this.startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 30 || this.startDate > this.endDate) {
        var tempDate;
        if (changeStartDate) {
          tempDate = new Date(this.startDate);
          tempDate.setDate(tempDate.getDate() + 30);
          this.endDate = tempDate;
        } else if (changeEndDate) {
          tempDate = new Date(this.endDate);
          tempDate.setDate(tempDate.getDate() - 30);
          this.startDate = tempDate;
        }
      }
    },
    loadData() {
      if (!this.tenant) {
        this.toast = { text: 'select_tenant', isError: true }
        return;
      } else {
        this.isLoading = true;
        var toDate = new Date(this.endDate);
        var inputPrep = {
          fy: this.startDate.getFullYear(),
          fm: this.startDate.getMonth() + 1,
          fd: this.startDate.getDate(),
          ty: toDate.getFullYear(),
          tm: toDate.getMonth() + 1,
          td: toDate.getDate(),
        }
        if (!this.is_all_tenants)
          inputPrep.tenant = this.tenant;
        console.log(inputPrep)
        this.commModule.getBill(inputPrep);
      }
    },
    reloadData() {
      var mainData = this.commModule.get_bill_data;
      this.tableData = [];
      var tenantLoop = [this.tenant];
      if (this.is_all_tenants)
        tenantLoop = this.tenants;
      tenantLoop.forEach(t => {
        var billData = mainData.bill[t];
        if (!billData) return;
        var eachData = mainData.data;
        var unitData = {
          tenant: t, all: [], each: [],
          eachHeadings: ['name']
        };
        var ttlPower = 0;
        var ttlPrice = 0;
        var targetZone;
        var enabledZone = {};
        const days = ['weekday', 'holiday'];
        var countEnabled = 0;
        days.forEach(day => {
          for (const zone in billData[day]) {
            if (!this.commModule.load_price_info.zone) continue;
            targetZone = this.commModule.load_price_info.zone[day][zone];
            if (!targetZone.enable) continue;
            if (!enabledZone[day]) enabledZone[day] = [];
            enabledZone[day].push(zone);
            countEnabled++;

            var rawData = billData[day][zone];
            var power = this.convertToFix(rawData[0], 3);
            // var unitPrice = rawData[3];
            var unitPrice = targetZone.price[0];
            var zoneName = targetZone.name ? targetZone.name : this.zoneNameMap[zone];
            var price = this.convertToFix((power * unitPrice), 2);
            ttlPower += power;
            ttlPrice += price;
            var dayLang = this.stringTable.word(day);
            var zoneNameCleaned = `${this.ConvertHelper.toCapitalize(dayLang)} ${zoneName}`;
            unitData.all.push([zoneNameCleaned, power, unitPrice, price]);
            unitData.eachHeadings.push(zoneNameCleaned);
          }
        });
        var dataRow = {};
        var tenant_points = this.commModule.load_tenant[t].points;
        tenant_points.forEach(point_id => {
          dataRow[point_id] = [];
        });

        const totalLang = this.stringTable.word('total');
        if (unitData.all.length > 0)
          unitData.all.push([totalLang, parseFloat(ttlPower).toFixed(3), '', parseFloat(ttlPrice).toFixed(2)]);
        days.forEach(day => {
          for (const point_id in eachData) {
            if (!tenant_points.includes(point_id)) continue;
            var point_data_day = eachData[point_id][day];
            // if (!dataRow[point_id]) dataRow[point_id] = [];
            for (const zone in point_data_day) {
              if (enabledZone[day] && enabledZone[day].includes(zone))
                dataRow[point_id].push(point_data_day[zone][0]);
              // else dataRow[point_id].push(0);
            }
          }
        });
        var ttlEachData;
        //        console.log(countEnabled)
        for (const point_id in dataRow) {
          var data = dataRow[point_id];
          if (data.length == 0)
            data = new Array(countEnabled).fill(0);
          if (!ttlEachData)
            ttlEachData = new Array(countEnabled).fill(0);
          data.forEach((d, i) => {
            var convertedData = this.convertToFix(d, 3);
            ttlEachData[i] += convertedData;
            if (d) data[i] = convertedData;
          });
          var point = this.commModule.point_list[point_id];
          var pointName = point ? point.name : point_id;
          data.unshift(pointName);
          unitData.each.push(data);
        }
        for (const index in ttlEachData) {
          ttlEachData[index] = ttlEachData[index].toFixed(3);
        }
        if (!ttlEachData) ttlEachData = new Array(countEnabled).fill(0);
        ttlEachData.unshift(totalLang);
        unitData.each.push(ttlEachData);
        this.tableData.push(unitData);
      });
      this.isLoading = false;
    }
  },
  computed: {
    is_all_tenants() {
      return this.tenant == this.tenantsSel[this.tenantsSel.length - 1]
    }
  },
  components: {
    PopUpToast,
    SelectOption,
    CustomTable,
    LoadingSpinner,
    VueDatePicker
  },
}
</script>
