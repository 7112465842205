<template>
  <div class="navigation navigation-top">
    <div class="btn-holder">
      <BtnNavIconText :iconDefault="activeNav.iconActive ? activeNav.iconActive : activeNav.icon"
        :compoForChangeScreen="$parent" :compoTarget="activeNav.tar" :text="stringTable.word(activeNav.name)"
        :isIconActiveColor="true" :textBlackBold="true" />

      <BtnNavIconText v-for="(nav, navIndex) in show_on_right_list" :key="navIndex" :iconDefault="nav.icon"
        :compoForChangeScreen="$parent" :compoTarget="nav.tar" @click="changePosition(navIndex)" :isToIconActive="true" />
    </div>
  </div>
</template>

<style>
.navigation-top {
  position: relative;
  z-index: 10;
  background-color: var(--navTop);
  padding: 0 40px;
}

/* Inside BtnNavIconText */
.navigation-top .item {
  width: 50px;
  font-size: 16px;
  padding: 6px;
}

.navigation-top .item img {
  width: 30px;
}

.navigation-top .item:nth-child(1) {
  width: fit-content;
  margin-right: auto;
  text-align: left;
}

.navigation-top .item:nth-child(1) .nav-text {
  display: inline-block;
  margin-left: 10px
}
</style>

<script>
import BtnNavIconText from './BtnNavIconText.vue'

export default {
  name: "NavTopHome",
  props: {
    accFunctions: Object,
    activeIndex: Number,
    isAdmin: Boolean,
  },
  data() {
    return {
      activeNav: '',
      navTopListAll: [
        {
          name: 'favorite', icon: 'favorite_default', iconActive: 'favorite_filled',
          tar: 'PageFavorite', showOnRight: false,
          visible: true
        },
        {
          name: 'iaq_monitoring', icon: 'iaq_monitoring',
          tar: 'PageIaqMoni', showOnRight: true,
          visible: this.accFunctions.iaq_mon
        },
        {
          name: 'layout_view', icon: 'layout',
          tar: 'PageLayout', showOnRight: true,
          visible: this.accFunctions.layout
        },
        {
          name: 'hotel_gr_man', icon: 'bed_room',
          tar: 'PageGuestRoom', showOnRight: true,
          visible: this.isAdmin && this.accFunctions.unit_manage
        },
        {
          name: 'real_time_energy_view', icon: 'electric',
          tar: 'PagePower', showOnRight: true,
          visible: this.isAdmin && this.accFunctions.rt_energy
        },
      ],
    }
  },
  created() {
    // "unit_manage":
    this.activeNav = this.nav_top_list[0];
  },
  watch: {
    'activeNav'(newActiveNav) {
      this.activeNav = newActiveNav;
    }
  },
  computed: {
    show_on_right_list() {
      return this.nav_top_list.filter(nav => nav.showOnRight);
    },
    nav_top_list() {
      return this.navTopListAll.filter(nav => nav.visible);
      // var li = [];
      // this.navTopListAll.forEach(nav => {
      //   if (
      //     (nav.name != 'hotel_gr_man' || this.accFunctions.unit_manage) &&
      //     (nav.name != 'layout' || this.accFunctions.layout)
      //   )
      //     li.push(nav);
      // });
      // return li;
    }
  },
  methods: {
    changePosition(navIndex) {
      this.activeNav = this.show_on_right_list[navIndex];
      this.nav_top_list.forEach((nav) => {
        nav.showOnRight = nav != this.activeNav;
      });
    },
  },
  components: {
    BtnNavIconText
  }
}
</script>

<!-- <div class="grid-item" :class="{ 'active-item': index == activeIndex }" role="button"
  v-for="(item, index) in nav_top_list" :key="index" @click="changeactiveNav(index)">
  <img :src="ReiriIcons[item.icon_def]" />
  <br />
  <span class="nav-text" :class="{ 'active-text': index == activeIndex }">
    {{ stringTable.word(item.name) }}
  </span>
</div> -->

<!-- 


pagesTop: ['PageFavorite', 'PageIaqMoni', 'PageLayout', 'PagePower'],
-->