// Controller information object

export class ControllerInfo {
	constructor(info) {
		this.model = info['model'];
		this.version = info['ver'];
		this.ssc = info['ssc'];
		this.url = info['url'];
		this.macaddr = info['macaddr'];
		this.name = info['name'];
	}
}
