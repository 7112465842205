<template>
  <div class="schedule-calendar schedule-sub">
    <PopUpToast />
    <PopUpHead :details="{ name: 'cal_set' }" :leftBtnTxt="leftBtnTxt" :rightBtnTxt="rightBtnTxt" :hideBorderBtm="true" />
    <div class="option-holder vertical-center justify-center">
      <SelectOption :selectList="ConvertHelper.listToIdNameList(target)" placeholder="sel_calendar" :useObject="true"
        :selectIndex="selIndex" />
      <img class="add-rotate" :src="ReiriIcons.cross" @click="addCalendar()" role="button" />
    </div>
    <div v-if="showCalSpecial || got_cal_special" class="input-content">
      <FrameInput varStr="calName" placeholder="name" />
      <!-- {{ specialDayCal }} {{ calDataInput }} -->
      <div class="of-day vertical-center">
        {{ stringTable.word('sp_cal') }}
        <img class="edit" :src="ReiriIcons.edit_2" @click="openCalSpecial()" role="button" />
      </div>
      <div class="detail-frame-holder can-scroll">
        <div v-if="specialDayCal.date.length > 0 || specialDayCal.dow.length > 0" class="cal-details frame">
          <div v-for="item in [{ title: 'specified_by_date', calKey: 'date' },
          { title: 'specified_by_day_of_week', calKey: 'dow' }]" :key="item.id">
            <div v-if="specialDayCal[item.calKey].length > 0">
              <div class="title">{{ stringTable.word(item.title) }}</div>
              <ul>
                <li v-for="d in specialDayCal[item.calKey]" :key="d">{{ d }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.schedule-calendar .cal-details {
  padding: 12px 16px 1px 16px;
}
</style>

<script>
import PopUpToast from './PopUpToast.vue'
import PopUpHead from './PopUpHead.vue'
import SelectOption from './SelectOption.vue'
import FrameInput from './FrameInput.vue'

export default {
  name: "ScheduleCalendar",
  props: {
    commModule: Object,
    cache: Object,
    page: Number,
  },
  data() {
    return {
      toast: {},
      cacheC: this.cache,
      calName: '',
      calId: '',
      calDataInput: {},
      selIndex: -1,
      addMode: false,
      showCalSpecial: false,
      target: JSON.parse(JSON.stringify(this.commModule.get_schedule.calendar)),
      specialDayCal: this.defCalSpecial(),
      pendingDel: ''
    }
  },
  mounted() {
    this.calDataInput = this.defCalInput();
    if (this.cacheC) {
      this.calDataInput = this.cacheC.calendar;
      this.selIndex = this.cacheC.selIndex;
      if (this.cacheC.calId == '') {
        this.addCalendar(true);
        this.calName = this.cacheC.calName;
        this.calDataInput = this.cacheC.calendar;
        this.assignSpecialDayCal();
        this.cacheC = null;
      }
    }
  },
  watch: {
    'commModule.add_calendar'() {
      this.$parent.completedOper();
    },
    'commModule.modify_calendar'() {
      this.$parent.completedOper();
    },
    'commModule.delete_calendar'() {
      this.$parent.completedOper();
    },
    'commModule.get_schedule'() {
      this.target = JSON.parse(JSON.stringify(this.commModule.get_schedule.calendar));
    }
  },
  methods: {
    defCalSpecial() {
      return { date: [], dow: [] };
    },
    defCalInput() {
      return { date: {}, dow: {} };
    },
    removeSel() {
      this.showCalSpecial = false;
      this.selIndex = -1;
      this.calDataInput = this.defCalInput();
      this.specialDayCal = this.defCalSpecial();
      this.toast = { text: 'succeed' }
    },
    addCalendar(ignoreSelInd) {
      if (!ignoreSelInd) {
        this.selIndex = -1;
      }
      this.showCalSpecial = true;
      this.addMode = true;
      this.rightBtnTxt = 'save';
      this.calName = '';
      this.calDataInput = this.defCalInput();
      this.assignSpecialDayCal();
    },
    assignSpecialDayCal() {
      this.specialDayCal = { date: [], dow: [] };
      for (const key in this.calDataInput) {
        const subCal = this.calDataInput[key];
        for (const month in subCal) {
          if (key == 'date') {
            const days = subCal[month];
            for (const daysInd in days) {
              var date = new Date();
              date.setFullYear(2024);
              date.setMonth(month - 1);
              date.setDate(days[daysInd]);
              this.specialDayCal.date.push(this.ConvertHelper.datePickerFormat(date, 'day_month'));
            }
          } else if (key == 'dow') {
            const weeks = subCal[month];
            for (var thWeek in weeks) {
              const thDay = weeks[thWeek];
              for (const thDayInd in thDay) {
                const monthTxt = this.ConvertHelper.getMonthsFull(month - 1);
                const weekTxt = this.ConvertHelper.getWeeksFull(thDay[thDayInd]);
                if ('last' == thWeek) thWeek = 6;
                this.specialDayCal.dow.push(`${monthTxt} ${this.stringTable.word(`week${thWeek}`)} ${weekTxt}`);
              }
            }
          }
        }
      }
    },
    changeOption(newCalId, name, index) {
      var gotSelection = index >= 0;
      this.calId = newCalId;
      const canAssignPrev = this.cacheC && this.target[this.cacheC.calId];
      if (canAssignPrev)
        this.target[this.cacheC.calId].action = this.cacheC.actionPrev;
      else this.target = JSON.parse(JSON.stringify(this.commModule.get_schedule.calendar));

      var calSettings = this.target[newCalId];
      if (!this.cacheC) {
        if (calSettings) {
          this.calDataInput = JSON.parse(JSON.stringify({
            date: calSettings.date, dow: calSettings.dow
          }));
        } else this.calDataInput = this.defCalInput();
      }
      this.assignSpecialDayCal();

      this.selIndex = index;
      if (!gotSelection) this.calName = '';
      else if (canAssignPrev) this.calName = this.cacheC.calName;
      else if (calSettings && calSettings.name)
        this.calName = calSettings.name;

      this.leftBtnTxt = gotSelection ? 'delete' : '';
      if (!this.showCalSpecial)
        this.rightBtnTxt = gotSelection ? 'save' : '';
      if (gotSelection) this.showCalSpecial = true;
      this.cacheC = null;
      this.addMode = false;
    },
    prev() {
      this.$parent.closeRightScreen();
    },
    openCalSpecial() {
      this.NavAssistant.showPopUp({
        name: 'PopUpCalendar',
        details: {
          screen: this.$options.name,
          name: 'edit_calendar',
          calendar: this.calDataInput,
          selIndex: this.selIndex,
          calId: this.calId,
          calName: this.calName,
          page: this.page
        },
      }, true);
    },
    clickLeftBtn() {
      this.pendingDel = this.calId;
      this.toast = {
        text: 'conf_del',
        pendingYesMethod: () => {
          this.commModule.delSchCalendar(this.pendingDel);
          this.removeSel();
        }
      }
    },
    clickRightBtn() {
      var text;
      if (!this.calName || !this.got_cal_special) {
        if (!this.calName) text = 'no_name';
        else if (!this.got_cal_special) text = 'no_date';
        this.toast = { text, isError: true, }
      } else {
        this.toast = {
          text: 'conf_save',
          pendingYesMethod: () => {
            var data = { name: this.calName, ...this.calDataInput }
            if (this.addMode) this.commModule.addSchCalendar(data);
            else this.commModule.editSchCalendar({
              [this.calId]: data
            });
            this.removeSel();
          }
        }
      }
    },
  },
  computed: {
    got_cal_special() {
      return (this.specialDayCal.date && this.specialDayCal.date.length != 0) ||
        this.specialDayCal.dow && this.specialDayCal.dow.length != 0;
    }
  },
  components: {
    PopUpToast,
    PopUpHead,
    SelectOption,
    FrameInput,
  }
}
</script>
