<template>
  <div class="pop-up device-detail " :class="{ 'ac': show_temp_circle, 'non-ac': !show_temp_circle }">
    <PopUpHead :details="detailsCloned" :isIconActive="stat || details.av > 0"
      :isIconFaded="!stat && !(details.av > 0 && details.type != 'Dehumi')" :showIcon="true" />
    <div class="content vertical-top">
      <div class="left-content" :class="{ 'got-right-content': got_right_content }">
        <div v-if="show_temp_circle" class="top-holder circle">
          <!-- <svg width="250" height="250"></svg> -->
          <div class="info-holder">
            <div class="temperature-set">
              {{ Math.trunc(activeSp) }}<span class="temperature-small">.{{ tempActiveDecimal }}</span>
              <span class="temperature-symbol">{{ stringTable.word('DEG') }}</span>
            </div>
            <div class="temperature-actual">
              <img :src="ReiriIcons.thermometer" />
              <span v-if="detailsCloned.temp">{{ tempConvert.tempConvert(detailsCloned.temp).toFixed(1) }}</span>
              <span v-else>--</span>
              {{ stringTable.word('DEG') }}
            </div>
            <div class="temperature-button">
              <div class="frame" @click="tempMinus">
                <div class="text vertical-center">-</div>
              </div>
              <div class="frame" @click="tempPlus">
                <div class="text vertical-center">+</div>
              </div>
              <div class="min-temp"> {{ formattedLimitSp('min') }} </div>
              <div class="max-temp"> {{ formattedLimitSp('max') }} </div>
            </div>
          </div>
        </div>
        <div v-else class="top-holder">
          <img :src="IconsHelper.iconMain(detailsCloned)"
            :class="{ 'to-active': stat || details.av > 0, 'to-faded': !stat && !(details.av > 0 && details.type != 'Dehumi') }" />
        </div>
        <!-- {{ details.stat }} -->
        <div class="operation-holder">
          <div class="top vertical-center"
            :class="{ 'small-top': Object.prototype.hasOwnProperty.call(detailsCloned, 'av') }">
            <BtnOperation :details="details_stat" type="stat" :isDeviceControl="true" />
            <BtnOperation v-if="!commModule.mode_proh" :details="details_mode" type="mode" :isDeviceControl="true" />
            <BtnOperation v-if="detailsCloned.fanstep_cap && detailsCloned.fanstep_cap.S != 1" :details="details_fanstep"
              type="fanstep" :isDeviceControl="true" />
            <BtnOperation :details="details_flap" type="flap" :isDeviceControl="true" />
            <BtnOperation v-if="!commModule.mode_proh && Object.prototype.hasOwnProperty.call(detailsCloned, 'vmode')"
              :details="details_vmode" type="vmode" :isDeviceControl="true" />
            <BtnOperation v-if="Object.prototype.hasOwnProperty.call(detailsCloned, 'vamount')" :details="details_vamount"
              type="vamount" :isDeviceControl="true" />
            <BtnOperation v-if="Object.prototype.hasOwnProperty.call(detailsCloned, 'freshup')" :details="details_freshup"
              type="freshup" :isDeviceControl="true" />
          </div>
          <div v-if="commModule.isAdmin" class="bottom">
            <!-- <div class="bottom"> -->
            <DroppableAc v-if="detailsCloned.usage != 'value'" :icon="ReiriIcons.timer"
              :label="stringTable.word('off_timer')" :showSwitch="true" type="off_timer"
              :switchVal="valueToBoolMap.off_timer[detailsCloned.off_timer]" />
            <DroppableAc v-if="detailsCloned.filter == 'on'" :icon="ReiriIcons.clean_filter"
              :label="stringTable.word('cl_filter')" @click="clearFilter()" role="button" />
          </div>
          <div class="av-slider frame"
            v-if="Object.prototype.hasOwnProperty.call(detailsCloned, 'av') && !commModule.sp_proh">
            <vue-slider v-model="sliderAvValue" :tooltip="'always'" :min="sliderMinMax()[0]" :max="sliderMinMax()[1]"
              :marks="avMarks" :interval="0.1"></vue-slider>
          </div>
        </div>
      </div>
      <div v-if="got_right_content" class="right-content">
        <div class="text-center adv-btn-label" :class="{ 'reverse-frame': showRight, 'frame': !showRight }"
          @click="startLoadingTimer">
          {{ stringTable.word('advanced') }}
        </div>
        <LoadingSpinner v-if="isLoading" class="right-display" />
        <div v-else-if="showRight" class="right-display can-scroll">
          <div class="duration-holder bar-spacing frame vertical-center">
            <div class="label">{{ stringTable.word('off_duration') }}</div>
            <SelectTime name="timerDuration" :minuteInterval="5"
              :selectValue="minutesToTimeString(detailsCloned.off_duration)" />
          </div>
          <DroppableAc v-if="details.sp_cap && detailsCloned.csp_limit"
            :label="`${stringTable.word('op_limit')}: ${stringTable.word('cool')}`" :showSwitch="true"
            :showSliderAndValue="true" :firstVal="min_csp_limit" :secondVal="max_csp_limit" type="csp_limit"
            :switchVal="details.csp_limit_valid" :range="csp_range" :interval="1" />
          <DroppableAc v-if="details.sp_cap && detailsCloned.hsp_limit"
            :label="`${stringTable.word('op_limit')}: ${stringTable.word('heat')}`" :showSwitch="true"
            :showSliderAndValue="true" :firstVal="min_hsp_limit" :secondVal="max_hsp_limit" type="hsp_limit"
            :switchVal="details.hsp_limit_valid" :range="hsp_range" :interval="1" />
          <DroppableAc v-if="details.sp_cap && sb_csp" :label="stringTable.word('sb_csp')" :showSwitch="true"
            :showSliderAndValue="true" :firstVal="sb_csp == '--' ? tempConvert.spConvert(28) : sb_csp"
            :switchVal="sb_csp != '--'" type="sb_csp" :interval="1"
            :range="[tempConvert.spConvert(24), tempConvert.spConvert(35)]" />
          <DroppableAc v-if="details.sp_cap && sb_hsp" :label="stringTable.word('sb_hsp')" :showSwitch="true"
            :showSliderAndValue="true" :firstVal="sb_hsp == '--' ? tempConvert.spConvert(18) : sb_hsp"
            :switchVal="sb_hsp != '--'" type="sb_hsp" :interval="1"
            :range="[Math.round(tempConvert.spConvert(12)), tempConvert.spConvert(20)]" />

          <!-- {{ detailsCloned }} -->
          <!-- {{ detailsCloned.stat }} -->
          <div v-if="detailsCloned.rc_proh_cap">
            <DroppableAc :label="stringTable.word('rop_proh')" :showSwitch="true" :switchVal="rop_proh ? rop_proh : false"
              type="rop_proh" />
            <DroppableAc v-if="detailsCloned.type != 'Hrv'" :label="stringTable.word('rsp_proh')" :showSwitch="true"
              :switchVal="rsp_proh ? rsp_proh : false" type="rsp_proh" />
            <DroppableAc v-if="detailsCloned.type != 'Hrv'" :label="stringTable.word('rmode_proh')" :showSwitch="true"
              :switchVal="rmode_proh ? rmode_proh : false" type="rmode_proh" />
            <DroppableAc v-if="detailsCloned.type != 'Hrv'" :label="stringTable.word('rfan_proh')" :showSwitch="true"
              :switchVal="rfan_proh ? rfan_proh : false" type="rfan_proh" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.pop-up.ac .loading-holder {
  margin-top: -80px !important;
}

.pop-up .small-top {
  margin-top: 10px !important;
}

.pop-up .av-slider {
  padding: 40px 25px 25px 25px;
  height: 80px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pop-up.ac .min-temp,
.pop-up.ac .max-temp {
  position: absolute;
  top: 165px;
}

.pop-up.ac .min-temp {
  left: -20px;
}

.pop-up.ac .max-temp {
  right: -20px;
}

.pop-up.device-detail .adv-btn-label {
  padding: 6px;
  color: var(--active);
  font-size: 1.1em;
}

.pop-up.device-detail .right-display {
  height: 490px;
  margin-top: 4px;
  padding: 6px 5px;
}

.pop-up.device-detail .content {
  padding-top: 4px;
}

.pop-up.device-detail .content>div {
  padding: 4px 10px;
  flex: 1;
}

.pop-up.device-detail .content .left-content {
  max-width: 500px;
  margin: 0 auto;
  height: 530px;
}

.pop-up.device-detail .content .left-content.got-right-content {
  border-right: 1px solid var(--disable);
}

.pop-up.device-detail .top-holder {
  position: relative;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  text-align: center;
}

.pop-up.non-ac .top-holder img {
  width: inherit;
  height: inherit;
}

.pop-up.ac .top-holder {
  border: 3px solid var(--hint);
}

.pop-up.ac .info-holder {
  position: absolute;
  width: inherit;
  margin: 50px auto 0 auto;
}

.pop-up.ac .temperature-set {
  font-size: 70px;
  height: 90px;
}

.pop-up.ac .temperature-small,
.pop-up.ac .temperature-symbol {
  font-size: 40px;
}

.pop-up.ac .temperature-symbol {
  display: inline-block;
  padding-top: 8px;
  vertical-align: text-top;
}

.pop-up.ac .temperature-actual {
  font-size: 28px;
  height: 65px;
}

.pop-up.ac .temperature-actual img {
  width: 35px;
}

.pop-up.ac .temperature-button .frame {
  position: relative;
  color: var(--fontGray);
  font-size: 40px;
  display: inline-block;
  padding: 0 25px;
  margin: 0;
  height: 75px;
  width: 90px;
}

.pop-up.ac .temperature-button .frame:nth-child(1) {
  border-radius: 50px 0 0 50px;
  border-right: 2px solid white;
}

.pop-up.ac .temperature-button .frame:nth-child(2) {
  border-radius: 0 50px 50px 0;
  border-left: 2px solid white;
}

.pop-up.ac .temperature-button .text {
  height: inherit;
  width: fit-content;
  text-align: center;
  margin: 0 auto;
}

.pop-up.device-detail .operation-holder .top {
  width: fit-content;
  margin: 60px auto 0 auto;
  text-align: center;
}

.pop-up.device-detail .operation-holder .bottom {
  margin-top: 20px;
}

.pop-up.device-detail .label {
  margin: 0 8px;
}
</style>

<script>
import PopUpHead from './PopUpHead.vue'
import BtnOperation from './BtnOperation.vue'
import DroppableAc from './DroppableAc.vue'
import SelectTime from './SelectTime.vue'
import VueSlider from 'vue-slider-component'
import LoadingSpinner from './LoadingSpinner.vue'

export default {
  name: "PopUpOperDev",
  props: {
    details: Object,
    commModule: Object,
  },
  data() {
    return {
      isLoading: false,
      showBottom: false,
      loadingTimer: null,
      page: 1,
      detailsCloned: {},
      itemPerPage: 35,
      slicedList: [],
      mainList: [],
      minTemp: 16,
      maxTemp: 32,
      clonedDetails: {},
      boolToValueMap: { 'stat': { true: 'on', false: 'off' }, 'off_timer': { true: 'on', false: 'off' }, 'freshup': { true: 'on', false: 'off' } },
      valueToBoolMap: { 'stat': { 'on': true, 'off': false }, 'off_timer': { 'on': true, 'off': false }, 'freshup': { 'on': true, 'off': false } },
      timer: null,
      sliderTimer: null,
      toChangeOptions: {},
      activeSp: 0,
      hideRightContent: false,
      showRight: false,
      canStartTimer: false,
      firstLoad: true,
      sliderAvValue: 0,
    }
  },
  created() {
    // this.isLoading = true
    // this.startLoadingTimer()
    this.detailsCloned = JSON.parse(JSON.stringify(this.details))
    this.setActiveSp();
    if (this.details.usage == 'value')
      this.hideRightContent = true;
    setTimeout(() => {
      this.canStartTimer = true;
    }, 500);
    if (Object.prototype.hasOwnProperty.call(this.detailsCloned, 'hum_sp'))
      this.sliderAvValue = JSON.parse(JSON.stringify(this.detailsCloned.hum_sp))
    else if (Object.prototype.hasOwnProperty.call(this.detailsCloned, 'av'))
      this.sliderAvValue = JSON.parse(JSON.stringify(this.detailsCloned.av))
    else
      this.sliderAvValue = 0
    // console.log(this.detailsCloned)
  },
  mounted() {
  },
  watch: {
    sliderAvValue() {
      this.startSliderTimer()
    },
    'commModule.op'() {
      this.toChangeOptions = {};
      // console.log(this.details)
    },
    'details.mode'() {
      this.setActiveSp();
    }
  },
  methods: {
    startLoadingTimer() {
      this.showRight = !this.showRight;
      if (!this.showRight) return;
      this.isLoading = true
      if (this.loadingTimer)
        clearTimeout(this.loadingTimer);
      this.loadingTimer = setTimeout(() => {
        this.isLoading = false
        this.showBottom = true
      }, 1);
    },
    setActiveSp() {
      this.activeSp = this.tempConvert.spConvert(this.isHeatMode ? this.details.hsp : this.details.csp);
    },
    clearFilter() {
      this.commModule.clearFilter(this.details.key_id)
    },
    sliderMinMax() {
      if (this.detailsCloned.type === 'Dehumi') {
        return this.detailsCloned.hum_range ? this.detailsCloned.hum_range : [0, 100]
      }
      else {
        return this.detailsCloned.limit ? this.detailsCloned.limit : [0, 100]
      }
    },
    startSliderTimer() {
      // Check if there's an existing timer, and if so, clear it
      if (!this.canStartTimer) return;
      if (this.sliderTimer)
        clearTimeout(this.sliderTimer);
      // Set a new timer to perform your function after 5 seconds
      this.sliderTimer = setTimeout(() => {
        if (this.detailsCloned.type == 'Dehumi')
          this.toChangeOptions['hum_sp'] = this.sliderAvValue;
        else {
          this.toChangeOptions['av'] = this.sliderAvValue;
          this.toChangeOptions['stat'] = this.boolToValueMap['stat'][this.sliderAvValue > 0];
          this.detailsCloned.stat = this.sliderAvValue > 0 ? 'on' : 'off'
        }
        this.startTimer();
      }, 1500);
    },
    limitSp(pos) {
      var setpoint;
      if (pos == 'min') setpoint = this.min_csp_range;
      if (pos == 'max') setpoint = this.max_csp_range;
      // if (this.details.mode == 'H') {
      if (this.isHeatMode) {
        if (this.details.hsp_limit_valid) {
          if (pos == 'min') setpoint = this.min_hsp_limit;
          if (pos == 'max') setpoint = this.max_hsp_limit;
        }
      } else if (this.details.csp_limit_valid) {
        if (pos == 'min') setpoint = this.min_csp_limit;
        if (pos == 'max') setpoint = this.max_csp_limit;
      }
      return setpoint;
    },
    formattedLimitSp(pos) {
      var setpoint = this.limitSp(pos);
      if (Number.isInteger(setpoint)) // Check if the value is an integer
        return `${setpoint}.0${this.stringTable.word('DEG')}`; // Add ".0" to the integer value
      return `${setpoint}${this.stringTable.word('DEG')}`; // Leave non-integer values unchanged
    },
    changeTime(name, newTime) {
      this.detailsCloned.off_duration = this.timeStringToMinutes(newTime)
      this.toChangeOptions.off_duration = this.detailsCloned.off_duration
      this.startTimer()
    },
    timeStringToMinutes(timeString) {
      // Split the time string into hours and minutes
      const [hours, minutes] = timeString.split(':').map(Number);
      // Convert hours to minutes and add to the minutes
      return hours * 60 + minutes;
    },
    minutesToTimeString(minutes) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      const hoursString = hours < 10 ? `0${hours}` : hours.toString();
      const minutesString = remainingMinutes < 10 ? `0${remainingMinutes}` : remainingMinutes.toString();
      return `${hoursString}:${minutesString}`;
    },
    changeSliderValue(newValue, type) {
      var isLimit = ['csp_limit', 'hsp_limit'].includes(type);
      var isSb = ['sb_csp', 'sb_hsp'].includes(type);
      if (isLimit || isSb) {
        if (isLimit) {
          newValue = this.tempConvert.spRevConvertDoubleItemArr(newValue);
          this.toChangeOptions[type] = newValue;
          this.detailsCloned[type] = newValue;
        }
        if (isSb) {
          newValue = this.tempConvert.spRevConvert(newValue);
          if (this.detailsCloned[type] != '--') {
            this.toChangeOptions[type] = newValue;
          }
        }
      }
      this.startTimer();
    },
    changeRightSwitch(newValue, type, sliderValue) {
      if (type == 'off_timer')
        this.toChangeOptions[type] = this.boolToValueMap[type][newValue]
      else if (['csp_limit', 'hsp_limit'].includes(type)) {
        const keyMap = { csp_limit: 'csp_limit_valid', hsp_limit: 'hsp_limit_valid' }
        this.toChangeOptions[keyMap[type]] = newValue;
      } else if (['sb_csp', 'sb_hsp'].includes(type)) {
        this.toChangeOptions[type] = newValue == false ? '--' : this.tempConvert.spRevConvert(sliderValue)
        this.detailsCloned[type] = newValue == false ? '--' : this.tempConvert.spRevConvert(sliderValue)
      }
      else if (['rop_proh', 'rsp_proh', 'rmode_proh', 'rfan_proh'].includes(type))
        this.toChangeOptions[type] = newValue
      this.startTimer()
    },
    changeBtnOperation(newValue, type) {
      if (type == 'stat')
        this.toChangeOptions[type] = this.boolToValueMap[type][newValue]
      else if (type == 'mode') {
        this.toChangeOptions[type] = this.details_mode.keys[newValue]
      } else if (type == 'vmode') {
        this.toChangeOptions[type] = this.details_vmode.keys[newValue]
      } else if (type == 'vamount') {
        this.toChangeOptions[type] = this.details_vamount.keys[newValue]
      } else if (type == 'freshup') {
        this.toChangeOptions[type] = this.boolToValueMap[type][newValue]
      } else if (type == 'fanstep') {
        this.toChangeOptions[type] = this.details_fanstep.keys[newValue]
      } else if (type == 'flap') {
        this.toChangeOptions[type] = this.details_flap.keys[newValue]
      }
      this.startTimer()
    },
    startTimer() {
      // Check if there's an existing timer, and if so, clear it
      if (!this.canStartTimer) return;
      if (this.timer)
        clearTimeout(this.timer);
      // Set a new timer to perform your function after 5 seconds
      this.timer = setTimeout(() => {
        this.performFunction();
      }, 1000);
    },
    performFunction() {
      var result = {}
      result[this.detailsCloned.key_id] = this.toChangeOptions
      // console.log(this.toChangeOptions)
      this.commModule.editPoint(result)
    },
    setCspOrHsp() {
      var celciusSp = this.tempConvert.spRevConvert(this.activeSp);
      this.toChangeOptions[this.isHeatMode ? 'hsp' : 'csp'] = Math.round(celciusSp * 2) / 2;
    },
    tempMinus() {
      if (this.activeSp - this.tempConvert.spGap() >= this.limitSp('min')) {
        this.activeSp = this.activeSp - this.tempConvert.spGap();
        this.setCspOrHsp();
        this.startTimer();
      }
    },
    tempPlus() {
      if (this.activeSp + this.tempConvert.spGap() <= this.limitSp('max')) {
        this.activeSp = this.activeSp + this.tempConvert.spGap();
        this.setCspOrHsp();
        this.startTimer();
      }
    },
    getIconLi(capName, capKey, iconListMapKey) {
      //'flap_cap', 'D', 'flap'
      var iconLi = [];
      var cap = this.detailsCloned[capName];
      if (capName == 'fanstep_cap' && !Object.prototype.hasOwnProperty.call(cap, 'A')) {
        cap['A'] = true
      }
      const iconMap = this.ReiriIcons.iconList[iconListMapKey];
      if (!cap || !iconMap) return [];
      for (const key in cap) {
        var val = cap[key];
        if (key == capKey) {
          for (let index = 0; index < val; index++) {
            iconLi.push(index);
          }
        } else if (val) {
          iconLi.push(key)
        }
      }
      var stringKeys = JSON.parse(JSON.stringify(iconLi))

      iconLi.forEach((iconKey, index) => {
        if (this.ConvertHelper.isInteger(iconKey)) //capName == 'fanstep_cap')
        {
          iconLi[index] = iconMap[Object.keys(iconMap)[iconKey]];
          stringKeys[index] = Object.keys(iconMap)[index]
        }

        else// if (capName == 'flap_cap')
          iconLi[index] = iconMap[iconKey];
      });

      const keys = stringKeys.map((element) => {
        if (!isNaN(element)) {
          // Use parseInt if you want to parse integers
          return parseInt(element);
        }
        return element; // If it's not a number, leave it as it is
      });
      return { iconLi, keys };
    },
  },
  computed: {
    got_right_content() {
      return !this.hideRightContent && this.commModule.isAdmin;
    },
    isHeatMode() {
      return this.details.mode == 'H' || (this.details.mode == 'A' && this.details.actual_mode == 'H')
    },
    avMarks() {
      // if (Object.prototype.hasOwnProperty.call(this.detailsCloned, 'av') && Object.prototype.hasOwnProperty.call(this.detailsCloned, 'limit'))
      //   return { '0': { 'label': `${this.detailsCloned.limit[0].toFixed(1)}%` }, '100': { 'label': `${this.detailsCloned.limit[1].toFixed(1)}%` } }
      // else
      //   return [0, 100]
      if (this.detailsCloned.type === 'Dehumi') {
        return {
          [`${this.detailsCloned.hum_range[0].toFixed(0)}`]: {
            'label': `${this.detailsCloned.hum_range[0].toFixed(1)}%`
          },
          [`${this.detailsCloned.hum_range[1].toFixed(1)}`]: {
            'label': `${this.detailsCloned.hum_range[1].toFixed(1)}%`
          }
        };
      }
      else if (Object.prototype.hasOwnProperty.call(this.detailsCloned, 'av')) {
        return {
          [`${this.detailsCloned.limit[0].toFixed(0)}`]: {
            'label': `${this.detailsCloned.limit[0].toFixed(1)}%`
          },
          [`${this.detailsCloned.limit[1].toFixed(1)}`]: {
            'label': `${this.detailsCloned.limit[1].toFixed(1)}%`
          }
        };
      }
      else
        return [0, 100]
    },
    show_temp_circle() {
      return !this.commModule.sp_proh && this.details.usage == 'ac' && !['D', 'F'].includes(this.details.mode) &&
        this.details.sp_cap;
    },
    stat() {
      return this.details.stat == 'on';
    },
    csp_range() {
      return this.tempConvert.spConvertDoubleItemArr(this.detailsCloned.csp_range);
    },
    min_csp_range() {
      return this.csp_range[0];
    },
    max_csp_range() {
      return this.csp_range[1];
    },
    hsp_range() {
      return this.tempConvert.spConvertDoubleItemArr(this.detailsCloned.hsp_range);
    },
    min_hsp_range() {
      return this.hsp_range[0];
    },
    max_hsp_range() {
      return this.hsp_range[1];
    },
    min_csp_limit() {
      return this.tempConvert.spConvert(this.detailsCloned.csp_limit[0])
    },
    max_csp_limit() {
      return this.tempConvert.spConvert(this.detailsCloned.csp_limit[1])
    },
    min_hsp_limit() {
      return this.tempConvert.spConvert(this.detailsCloned.hsp_limit[0])
    },
    max_hsp_limit() {
      return this.tempConvert.spConvert(this.detailsCloned.hsp_limit[1])
    },
    sb_csp() {
      return this.tempConvert.spConvert(this.detailsCloned.sb_csp)
    },
    sb_hsp() {
      return this.tempConvert.spConvert(this.detailsCloned.sb_hsp)
    },
    rop_proh() {
      return this.details.rop_proh
    },
    rsp_proh() {
      return this.details.rsp_proh
    },
    rmode_proh() {
      return this.details.rmode_proh
    },
    rfan_proh() {
      return this.details.rfan_proh
    },
    tempActiveDecimal() {
      return (this.activeSp.toFixed(1) + '').split('.')[1];
    },
    details_stat() {
      if (this.detailsCloned.op_cap && this.detailsCloned.usage != 'value') return {
        icons: [this.ReiriIcons.power],
        value: this.stat
      };
      else return {};
    },
    details_freshup() {
      return {
        icons: [this.ReiriIcons.freshup_default, this.ReiriIcons.freshup_selected],
        value: this.valueToBoolMap['freshup'][this.detailsCloned.freshup]
      }
    },
    details_mode() {
      var iconLi = [];
      var mode_cap = this.detailsCloned.mode_cap;
      if (!mode_cap) return {};
      var capKeys = Object.keys(mode_cap);
      const sortedKeys = Object.keys(this.detailsCloned.mode_cap);
      // Create a new object with the sorted mode keys based on coming device data
      const iconObj = {};
      for (const key of sortedKeys) {
        iconObj[key] = this.ReiriIcons.iconList.mode[key];
      }
      var keyList = [];
      for (const key in iconObj) {
        var icon = iconObj[key];
        if (capKeys.includes(key) && mode_cap[key])
          iconLi.push(icon)
        if (mode_cap[key] === true)
          keyList.push(key)
      }
      return {
        icons: iconLi,
        value: iconObj[this.detailsCloned.mode],
        keys: keyList
      }
    },
    details_vmode() {
      var iconLi = [];
      var vmode_cap = this.detailsCloned.vmode_cap;
      if (!vmode_cap) return {};
      var capKeys = Object.keys(vmode_cap);
      const sortedKeys = Object.keys(this.detailsCloned.vmode_cap);
      // Create a new object with the sorted vmode keys based on coming device data
      const iconObj = {};
      for (const key of sortedKeys) {
        iconObj[key] = this.ReiriIcons.iconList.vmode[key];
      }
      var keyList = [];
      for (const key in iconObj) {
        var icon = iconObj[key];
        if (capKeys.includes(key) && vmode_cap[key])
          iconLi.push(icon)
        if (vmode_cap[key] === true)
          keyList.push(key)
      }
      return {
        icons: iconLi,
        value: iconObj[this.detailsCloned.vmode],
        keys: keyList
      }
    },
    details_vamount() {
      if (Object.prototype.hasOwnProperty.call(this.detailsCloned.vamount_cap, 'F'))
        delete this.detailsCloned.vamount_cap['F']

      var iconLi = [];
      var vamount_cap = this.detailsCloned.vamount_cap;
      if (!vamount_cap) return {};
      var capKeys = Object.keys(vamount_cap);
      const sortedKeys = Object.keys(this.detailsCloned.vamount_cap);
      // Create a new object with the sorted vamount keys based on coming device data
      const iconObj = {};
      for (const key of sortedKeys) {
        iconObj[key] = this.ReiriIcons.iconList.vamount[key];
      }
      var keyList = [];
      for (const key in iconObj) {
        var icon = iconObj[key];
        if (capKeys.includes(key) && vamount_cap[key])
          iconLi.push(icon)
        if (vamount_cap[key] === true)
          keyList.push(key)
      }
      return {
        icons: iconLi,
        value: iconObj[this.detailsCloned.vamount],
        keys: keyList
      }
    },
    details_fanstep() {
      if (!this.detailsCloned.fanstep || !this.detailsCloned.fanstep_key ||
        !this.detailsCloned.fanstep_cap) return {};
      return {
        icons: this.getIconLi('fanstep_cap', 'S', this.detailsCloned.fanstep_key).iconLi,
        value: this.ReiriIcons.iconList[this.detailsCloned.fanstep_key][this.detailsCloned.fanstep],
        keys: this.getIconLi('fanstep_cap', 'S', this.detailsCloned.fanstep_key).keys
      }
    },
    details_flap() {
      var iconLi = [];
      if (this.detailsCloned.flap == undefined || !this.detailsCloned.flap_key ||
        !this.detailsCloned.flap_cap || !this.detailsCloned.flap2_cap) return {};
      var flapIcons = this.getIconLi('flap_cap', 'D', 'flap').iconLi;
      var flap2Icons = this.getIconLi('flap2_cap', 'D', 'flap').iconLi;
      if (flapIcons.length > 0) iconLi = flapIcons;
      else if (flap2Icons.length > 0) iconLi = flap2Icons;
      return {
        icons: iconLi,
        value: this.ReiriIcons.iconList[this.detailsCloned.flap_key][this.detailsCloned.flap],
        keys: this.getIconLi('flap_cap', 'D', 'flap').keys
      }
    },
  },
  components: {
    PopUpHead,
    BtnOperation,
    DroppableAc,
    SelectTime,
    VueSlider,
    LoadingSpinner,
  },
};
</script>
