<template>
  <div class="empty-info vertical-center">
    <div class="holder">
      <img :src="icon" class="icon" />
      <br />
      {{ display_name }}
    </div>
  </div>
</template>

<style>
.empty-info .icon {
  margin-bottom: 10px;
}

.empty-info {
  text-align: center;
  height: 100%;
}

.empty-info .holder {
  margin: 0 auto;
}
</style>

<script>
export default {
  name: "NoFavorite",
  props: {
    name: String,
  },
  computed: {
    icon() {
      if (['no_fav_dev', 'no_fav_group', 'no_fav_scene'].includes(this.name))
        return this.ReiriIcons.add_to_favorite;
      if (['no_group', 'no_scene'].includes(this.name))
        return this.ReiriIcons[this.name];
      return this.ReiriIcons.no_data;
    },
    display_name() {
      var name = this.name;
      if (!this.name) name = 'nodata';
      return this.stringTable.word(name);
    }
  }
}
</script>
