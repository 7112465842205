<template>
  <div class="report error-report">
    <div class="navigation btm-line expand">
      <div class="btn-holder">
        <BtnNavIconText text="day" :compoForChangeScreen="this" />
        <BtnNavIconText text="month" :compoForChangeScreen="this" />
      </div>
    </div>
    <BarDateTime v-if="currentScreen == 'day'" />
    <BarDateRangeYear v-if="['month', 'year'].includes(currentScreen)"
      :dateRangeData="commModule.load_report_setting.data" :lyType="currentScreen" :firstLoad="firstLoad" />
    <CustomTable :isLoading="isLoading" :headings="['dev', 'err_code', 'err_code_freq']" :datas="tableData" />
  </div>
</template>

<style>
.error-report .table-row>div:nth-child(1) {
  width: 100%;
}

.error-report .table-row>div:nth-child(2) {
  width: 200px;
}

.error-report .table-row>div:nth-child(3) {
  width: 200px;
}
</style>

<script>
import BtnNavIconText from './BtnNavIconText.vue'
import BarDateTime from './BarDateTime.vue'
import BarDateRangeYear from './BarDateRangeYear.vue'
import CustomTable from './CustomTable.vue'

export default {
  name: "ReportError",
  props: {
    commModule: Object,
  },
  data() {
    return {
      isLoading: false,
      firstLoad: true,
      currentScreen: 'day',
      startDtInput: new Date(),
      dateRange: {},
      tableData: [],
      queryError: {
        day: 'daily_error_info',
        month: 'monthly_error_info',
      }
    }
  },
  created() {
    this.loadData();
  },
  watch: {
    currentScreen() {
      this.firstLoad = true;
      if (this.currentScreen == 'day') {
        this.startDtInput = null;
        this.loadData();
      }
    },
    startDtInput() {
      if (!this.firstLoad)
        this.loadData();
    },
    'commModule.daily_error_info'() {
      this.reloadData();
    },
    'commModule.monthly_error_info'() {
      this.reloadData();
    },
  },
  methods: {
    loadData() {
      this.isLoading = true;
      var fromDate = this.startDtInput ? this.ConvertHelper.dtInputToDtObj(this.startDtInput) : new Date();
      var dataPrep;
      switch (this.currentScreen) {
        case 'day':
          dataPrep = {
            year: fromDate.getFullYear(),
            month: fromDate.getMonth() + 1,
            day: fromDate.getDate()
          }
          break;
        case 'month':
          dataPrep = this.dateRange;
          break;
      }
      console.log(dataPrep)
      this.commModule.getData(this.queryError[this.currentScreen], dataPrep);
    },
    reloadData() {
      var response = this.commModule[this.queryError[this.currentScreen]];
      if (!response) return;
      var data = response.data;
      this.tableData = [];
      for (const point_id in data) {
        var errObj = data[point_id];
        var errCode = Object.keys(errObj);
        var errCodeFreq = Object.values(errObj);
        if (errCode.length > 0 && errCodeFreq.length > 0)
          this.tableData.push([
            this.commModule.point_list[point_id].name,
            errCode, errCodeFreq,
          ]);
      }
      this.isLoading = false;
      this.firstLoad = false;
    },
    changeDateRange(newDateRange) {
      this.dateRange = newDateRange;
      this.loadData();
    }
  },
  computed: {
  },
  components: {
    BtnNavIconText,
    BarDateTime,
    BarDateRangeYear,
    CustomTable
  },
}
</script>
