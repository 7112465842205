<template>
  <div class="report energy-report">
    <div class="navigation btm-line expand">
      <div class="btn-holder">
        <BtnNavIconText changeScreenVarName="screenType" text="emeter" :compoForChangeScreen="this" />
        <BtnNavIconText changeScreenVarName="screenType" text="water_devices" :compoForChangeScreen="this" />
      </div>
    </div>
    <div class="navigation btm-line expand">
      <div class="btn-holder">
        <BtnNavIconText changeScreenVarName="lyType" text="day" :compoForChangeScreen="this" />
        <BtnNavIconText changeScreenVarName="lyType" text="month" :compoForChangeScreen="this" />
        <BtnNavIconText changeScreenVarName="lyType" text="year" :compoForChangeScreen="this" />
      </div>
    </div>
    <div class="info-holder d-flex">
      <BarDateTime v-if="lyType == 'day'" />
      <BarDateRangeYear v-if="['month', 'year'].includes(lyType)" :dateRangeData="settings_data" :lyType="lyType"
        :firstLoad="firstLoad" />

      <!--is_energy &&    || (is_water && !settings_cat.is_recycled_water_enabled && !settings_cat.is_water_consumption_enabled) -->
      <SelectDropDown v-if="!settings_cat.is_show_energy_categories && metersDropDownDisplay.length > 0" mainLabel="select_meters"
        :items="metersDropDownDisplay" checkedString="chartChecked" :disableToggle="isChartLoading" />
      <!-- :isCloseFalse="true" :maxSelection="4" -->
    </div>
    <Chart :data="data" :options="options" :plugins="plugins" :legend="legend" :isLoading="isChartLoading"
      :legendDisable="true" :showDataEmpty="true" />
  </div>
</template>

<style>
.report {
  padding: 6px 10px;
}

.report .navigation {
  margin-bottom: 4px;
}

.energy-report .info-holder {
  height: 45px;
}

.energy-report .chart-holder {
  height: 390px;
}

.energy-report .date-time-holder,
.energy-report .bar-date-range-year {
  width: 100%;
}

.energy-report .select-dropdown-holder {
  margin-left: 6px;
}

.energy-report .select-dropdown-holder .dropdown-menu {
  width: 420px;
  margin-left: -80px;
}
</style>

<script>
import BtnNavIconText from './BtnNavIconText.vue'
import BarDateTime from './BarDateTime.vue'
import BarDateRangeYear from './BarDateRangeYear.vue'
import SelectDropDown from './SelectDropDown.vue'
import Chart from './Chart.vue'

const redLineColor = '#cc0000';

export default {
  name: "ReportEnergy",
  props: {
    commModule: Object,
  },
  data() {
    return {
      isChartLoading: true,
      screenType: 'emeter',
      lyType: 'day',
      startDtInput: new Date(),

      queryMeter: {
        day: 'hourly_meter',
        month: 'daily_meter',
        year: 'monthly_meter',
      },
      // startYear: '',
      monthRange: {},

      savedDataSets: [],
      accumulateEnergy: [],
      metersDropDownDisplay: [],
      savedDataMeter: {},
      firstLoad: true,
      data: {
        labels: [],
        datasets: [],
      },
      legend: {
        reverse: true
      },
      options: {
        // responsive: true,
        // interaction: {
        //   mode: 'index',
        //   intersect: false,
        // },
        scales: {
          x: {
            grid: { display: false },
            stacked: true
          },
          y: {
            title: {
              display: true,
              // text: '[kWh]'
            },
            grid: { drawOnChartArea: false, },
            type: 'linear',
            display: true,
            stacked: true,
            position: 'left',
            beginAtZero: true,
            ticks: {
              min: 0,
              stepSize: 1
            }
          },
          y1: {
            title: {
              display: true,
              // text: `${this.stringTable.word('total')} [kWh]`,
              color: redLineColor
            },
            grid: { drawOnChartArea: false, },
            type: 'linear',
            display: true,
            // beginAtZero: true,
            position: 'right',
            ticks: {
              color: redLineColor,
            },
          },
        }
      },
    }
  },
  created() {
    this.commModule.getMeterPoints();
    for (const id in this.commModule.point_list) {
      var point = this.commModule.point_list[id];
      delete point.chartChecked;
    }
  },
  watch: {
    startDtInput() {
      if (!this.firstLoad && this.lyType == 'day')
        this.loadChart();
    },
    lyType() {
      this.firstLoad = true;
      this.startDtInput = new Date();
      if (this.lyType == 'day')
        this.loadChart();
    },
    screenType() {
      this.firstLoad = true;
      this.loadChart();
    },
    'commModule.meter_point_list'() {
      this.loadChart();
    },
    'commModule.hourly_meter'(newHourlyMeter) {
      this.loadDatasets(newHourlyMeter);
    },
    'commModule.daily_meter'(newDailyMeter) {
      this.loadDatasets(newDailyMeter);
    },
    'commModule.monthly_meter'(newMonthlyMeter) {
      if (this.lyType == 'year' && this.settings_data.start_day > 15) {
        for (const pointId in newMonthlyMeter.data) {
          var data = newMonthlyMeter.data[pointId];
          for (const serverDate in data) {
            var serverDtObj = this.ConvertHelper.dtServerToDtObj(`${serverDate}010000`);
            serverDtObj.setMonth(serverDtObj.getMonth() - 1);
            var newDtServer = this.ConvertHelper.dtObjToDtServer(serverDtObj);
            newDtServer = newDtServer.slice(0, 6);
            data[newDtServer] = data[serverDate];
            delete data[serverDate];
          }
        }
      }
      this.loadDatasets(newMonthlyMeter);
    },
  },
  methods: {
    changeDateRange(newDateRange) {
      console.log(newDateRange)
      this.monthRange = newDateRange;
      this.loadChart();
    },
    processDataList(dataList, currMeter) {
      if (!dataList) return dataList;
      var serverDataObj = {};
      Object.keys(dataList).forEach(date => {
        serverDataObj[date] = dataList[date];
      });
      var rangeDates = this.getRangeDateFromTo();
      var result = [];
      if (this.lyType == 'day')
        result = Object.values(dataList);
      else if (this.lyType == 'month') {
        rangeDates.forEach(range => {
          var serverDate = this.ConvertHelper.dtObjToDtServer(range);
          var val = serverDataObj[serverDate];
          result.push(val ? val : 0);
        });
      }
      else if (this.lyType == 'year') {
        rangeDates.forEach(range => {
          var serverDate = this.ConvertHelper.dtObjToYearMthServer(range);
          var val = serverDataObj[serverDate];
          result.push(val ? val : 0);
        });
      }

      // if ((this.is_energy && this.settings_cat.is_show_energy_categories) ||
      //   (this.is_water && this.settings_cat.is_recycled_water_enabled)) {
      if (this.settings_cat.is_show_energy_categories) {
        var energyGen = this.settings_cat.energy_generator;
        var recWater = this.settings_cat.recycled_water;
        var negativePoints = this.is_energy && energyGen ? energyGen.points :
          this.is_water && recWater ? recWater.points : [];

        if (negativePoints && negativePoints.includes(currMeter.key_id))
          for (const resultIndex in result)
            result[resultIndex] = -result[resultIndex];
      }
      dataList = result;
      return dataList;
    },
    getRangeDateFromTo() {
      var result = [];
      if (this.lyType == 'day') return result;
      var tempFromMonth = new Date(this.monthRange.from);
      if (this.lyType == 'year')
        tempFromMonth = new Date(tempFromMonth.getFullYear(), tempFromMonth.getMonth(), 1);
      while (tempFromMonth <= this.monthRange.to) {
        result.push(new Date(tempFromMonth));
        if (this.lyType == 'month')
          tempFromMonth.setDate(tempFromMonth.getDate() + 1);
        else if (this.lyType == 'year')
          tempFromMonth.setMonth(tempFromMonth.getMonth() + 1);
      }
      // if (this.lyType == 'year' && result.length == 13) {
      // result.pop();
      // var fromDay = this.monthRange.fd;
      // if (fromDay <= 15) result.pop();
      // else result.shift();
      // }
      return result;
    },
    getLabelsFromScreen() {
      var result = [];
      var rangeDates = this.getRangeDateFromTo();
      switch (this.lyType) {
        case 'day':
          for (let i = 0; i < 24; i++) {
            result.push(this.ConvertHelper.intToNumOfDigits(i));
          }
          break;
        case 'month':
          rangeDates.forEach(date => {
            result.push(date.getDate());
          });
          break;
        case 'year':
          rangeDates.forEach(date => {
            if (result.length < 12)
              result.push(this.ConvertHelper.dtObjToMthName(date));
          });
          break;
      }
      return result;
    },
    loadChart() {
      var startDTObj = this.ConvertHelper.dtInputToDtObj(this.startDtInput);
      var inputPrep = {};
      switch (this.lyType) {
        case 'day':
          inputPrep = {
            year: startDTObj.getFullYear(),
            month: startDTObj.getMonth() + 1,
            day: startDTObj.getDate(),
          };
          break;
        case 'month':
        case 'year':
          inputPrep = this.monthRange;
          break;
      }
      if (!inputPrep) return;
      inputPrep.points = this.commModule.meter_point_list;
      this.isChartLoading = true;
      this.commModule.getData(this.queryMeter[this.lyType], inputPrep);
    },
    loadDatasets(lyMeter) {
      var dataMeter;
      if (lyMeter) {
        dataMeter = lyMeter.data;
        if (!dataMeter) return;
      } else {
        dataMeter = this.savedDataMeter;
      }
      // DUMMY
      // var i = 0;
      // for (const key in dataMeter) {
      //   dataMeter[`wmeter-dummy-${3 + i}`] = dataMeter[key];
      //   i++;
      // }
      console.log(lyMeter)

      this.firstLoad = false;
      this.isChartLoading = false;
      var meter_point_list = [];
      this.metersDropDownDisplay = [];
      this.savedDataSets = {};

      for (const id in this.commModule.get_splist) {
        var point = this.commModule.point_list[id];
        if (point && point.usage == this.point_usage) {
          meter_point_list.push(point);
          if (point.chartChecked == undefined)
            point.chartChecked = true;
          this.metersDropDownDisplay.push(point);
        }
      }
      var usedPointNames = [];
      meter_point_list.forEach(currMeter => {
        if (!currMeter.chartChecked) return;
        var dataList = dataMeter[currMeter.key_id];
        dataList = this.processDataList(dataList, currMeter);
        var meterName = currMeter.name;
        usedPointNames.push(meterName);
        var count = usedPointNames.filter(name => name == meterName).length;
        if (count > 1) {
          meterName += `-${count}`;
        }
        this.savedDataSets[currMeter.key_id] = {
          type: 'bar',
          label: meterName,
          data: dataList,
          yAxisID: 'y',
          order: 2,
        };
      });
      console.log(this.savedDataSets)
      this.data.labels = this.getLabelsFromScreen();
      this.reloadChart();
      this.savedDataMeter = dataMeter;
    },
    reloadChart() {
      let getNamePoints = (name, points) => {
        return { name: this.stringTable.word(name), points }
      }
      let gotPoints = (points) => points ? Object.keys(points).length != 0 : true;
      let getPointUnit = (pId) => {
        var point = this.commModule.point_list[pId];
        return point ? point.unit : '';
      }

      this.data.datasets = [];
      var dataListLength = 0;

      var enabledEnergyCat = this.settings_cat.is_show_energy_categories;
      var catObj = {};
      var points;
      var unit = '';
      if (this.is_energy) {
        if (enabledEnergyCat)
          catObj = { ...this.settings_cat.energy };
        points = this.settings_cat.energy_generator.points;
        if (this.settings_cat.is_energy_generator_enabled && gotPoints(points)) {
          catObj.energy_gen = getNamePoints('energy_gen', points);
        }
      } else if (this.is_water) {
        var watCons = this.settings_cat.is_water_consumption_enabled;
        var watRecy = this.settings_cat.is_recycled_water_enabled;
        var bothEnabled = watCons && watRecy;
        points = this.settings_cat.water_consumption.points;
        if (bothEnabled || (watCons && gotPoints(points)))
          catObj.water_consumption = getNamePoints('water_consumption', points);
        points = this.settings_cat.recycled_water.points;
        if (bothEnabled || (watRecy && gotPoints(points)))
          catObj.water_recycle = getNamePoints('water_recycle', points);
      }

      var tempRawDataset = [];
      for (const dsKey in this.savedDataSets) {
        const ds = this.savedDataSets[dsKey];
        if (ds.data && ds.data.length > dataListLength)
          dataListLength = ds.data.length;
        if (unit == '') unit = getPointUnit(dsKey);
        tempRawDataset.push(ds);
      }

      if (enabledEnergyCat) {
        for (const key in catObj) {
          var cat = catObj[key];
          if (cat.is_enabled || cat.is_enabled == undefined) {
            var dataLi = new Array(dataListLength).fill(0);
            cat.points.forEach(p => {
              if (unit == '') unit = getPointUnit(p);
              var datasetData = this.savedDataSets[p] ? this.savedDataSets[p].data : undefined;
              if (datasetData)
                datasetData.forEach((d, i) => {
                  if (d) dataLi[i] += d;
                })
            });
            this.data.datasets.push({
              type: 'bar',
              label: cat.name,
              data: dataLi,
              yAxisID: 'y',
              order: 2,
            })
          }
        }
      } else this.data.datasets = tempRawDataset;

      // console.log(this.data.datasets)

      this.accumulateEnergy = new Array(dataListLength).fill(0);
      this.data.datasets.forEach((subDataObj, index) => {
        subDataObj.backgroundColor = this.ColorHelper.get(index);// borderColor

        if (!subDataObj.data) return;
        subDataObj.data.forEach((data, index) => {
          this.accumulateEnergy[index] += data;
        });
      });
      this.accumulateEnergy.forEach((accEnergy, index) => {
        if (index != 0)
          this.accumulateEnergy[index] += this.accumulateEnergy[index - 1];
      });

      if (this.data.datasets.length > 0) {
        this.data.datasets = this.data.datasets.reverse();
        if (this.accumulateEnergy.length == 0)
          this.accumulateEnergy = new Array(this.data.labels.length).fill(0);
        this.data.datasets.push({
          type: 'line',
          label: this.stringTable.word('total'),
          data: this.accumulateEnergy,
          borderColor: redLineColor,
          backgroundColor: redLineColor,
          yAxisID: 'y1',
          order: 1,
          borderWidth: 2
        });
      }


      var stepMax = { y: 0, y1: 0 };
      this.data.datasets.forEach((dataset, index) => {
        var datasets = dataset.data ? [...dataset.data] : [];
        for (const i in datasets)
          datasets[i] = Math.abs(datasets[i]);

        if (index == this.data.datasets.length - 1) stepMax.y1 = Math.max(...datasets);
        else if (Array.isArray(datasets)) {
          var datasetMax = Math.max(...datasets);
          if (Number.isFinite(datasetMax) && stepMax.y < datasetMax)
            stepMax.y = datasetMax;
        }
      });
      stepMax.y = this.ConvertHelper.chartPrecision(stepMax.y);
      this.options.scales.y.ticks.stepSize = stepMax.y;
      this.options.scales.y.title.text = `[${unit}]`;
      this.options.scales.y1.title.text = `${this.stringTable.word('total')} [${unit}]`;

      // this.options.scales.y1.beginAtZero = this.accumulateEnergy.filter(e => e > 0).length == 0;
      // stepMax.y1 = this.ConvertHelper.chartPrecision(stepMax.y1,
      //   this.lyType == 'month' ? 10 : this.lyType == 'year' ? 40 : 15);
      // this.options.scales.y1.ticks.stepSize = stepMax.y1;
    },
    clickedCheckBox(newMeter) {
      console.log(newMeter)
      this.loadDatasets();
    }
  },
  computed: {
    is_energy() {
      return this.screenType == 'emeter';
    },
    is_water() {
      return this.screenType == 'water_devices';
    },
    point_usage() {
      if (this.is_energy) return 'emeter';
      return 'wmeter';
    },
    settings_data() {
      return this.commModule.load_report_setting.data;
    },
    settings_cat() {
      var settingsData = this.settings_data;
      return settingsData ? settingsData.categories : {};
    }
  },
  components: {
    BarDateTime,
    BarDateRangeYear,
    BtnNavIconText,
    SelectDropDown,
    Chart,
  },
}
</script>

<!-- 
// shuffle(array) {
//   let currentIndex = array.length, randomIndex;
//   // While there remain elements to shuffle.
//   while (currentIndex != 0) {
//     // Pick a remaining element.
//     randomIndex = Math.floor(Math.random() * currentIndex);
//     currentIndex--;
//     // And swap it with the current element.
//     [array[currentIndex], array[randomIndex]] = [
//       array[randomIndex], array[currentIndex]];
//   }
//   return array;
// },
// {
//   type: 'line',
//   label: 'Dataset 2',
//   data: this.shuffle([0.3, 39, 360]),
//   borderColor: redLineColor,
//   backgroundColor: redLineColor,
//   yAxisID: 'y1',
// },
// {
//   type: 'bar',
//   label: 'Dataset 1',
//   data: [0.4, 3, 1],
//   borderColor: '#f87979',
//   backgroundColor: '#f87979',
//   yAxisID: 'y',
// },
// {
//   type: 'bar',
//   label: 'Dataset 3',
//   data: this.shuffle([0.15, 1, 1.2]),
//   borderColor: '#f87979',
//   backgroundColor: '#235123',
//   yAxisID: 'y',
// },


// type: 'line',
// data: {
//   labels: ['labels', 'labels', 'labels', 'labels', 'labels', 'labels', 'labels',],
//   datasets: [
//     {
//       label: 'Dataset 1',
//       data: [2, 11, 34, 1, 6, 6, 64, 3],
//       backgroundColor: '#f87979',
//       // backgroundColor: Utils.transparentize(Utils.CHART_COLORS.red, 0.5),
//       // stack: 'combined',
//       type: 'Line'
//     },
//     {
//       label: 'Dataset 2',
//       data: [25, 11, 4, 1, 6, 6, 64, 3],
//       borderColor: '#f87979',
//       backgroundColor: '#444444',
//       stack: 'combined'
//     }
//   ]
// },
// options: {
//   maintainAspectRatio: false,
//   plugins: {
//     title: {
//       display: true,
//       text: 'Chart.js Stacked Line/Line Chart'
//     }
//   },
//   scales: {
//     y: {
//       stacked: true
//     }
//   }
// },
// data: {
//   labels: [
//     'January',
//     'February',
//     'March',
//     'April',
//     'May',
//     'June',
//     'July',
//     'August',
//     'September',
//     'October',
//     'November',
//     'December'
//   ],
//   datasets: [
//     {
//       label: 'Data One',
//       backgroundColor: '#f87979',
//       data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
//     }
//   ]
// },
// options: {
//   responsive: true,
//   maintainAspectRatio: false
// } 
// else if (waterConPoints) {
//   waterConPoints.forEach((p, i) => {
//     var pointObj = this.commModule.point_list[p];
//     catObj[`water_consumption_${i}`] = { 
//       name: pointObj ? pointObj.name : p,
//       points: [p]
//     };
//   })
// }
// else if (waterRecPoints) {
//   waterRecPoints.forEach((p, i) => {
//     var pointObj = this.commModule.point_list[p];
//     catObj[`water_recycle_${i}`] = {
//       name: pointObj ? pointObj.name : p,
//       points: [p]
//     };
//   })
// }

      -->
