<template>
  <div class="pop-up device-detail icon">
    <PopUpHead :details="detailsCloned" :leftArr="true" :hideCross="true" :wholeScreen="true" />
    <div class="icon-list can-scroll">
      <div v-for="(value, key) in icon_objs" :key="key">
        <img class="icon-img" :src="value" :class="{ 'to-active': isActive(key) }" @click="iconSelected(key)" />
      </div>
    </div>
  </div>
</template>

<style>
.icon .icon-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  overflow-y: scroll;
  max-height: 500px;
}

.icon .icon-img {
  height: 100px;
  width: 100px;
  padding: 10px;
}
</style>

<script>
import PopUpHead from "./PopUpHead.vue";

export default {
  name: "PopUpIcons",
  props: {
    details: Object,
  },
  data() {
    return {
      detailsCloned: this.details,
    }
  },
  created() {
    if (this.detailsCloned) {
      if (!this.detailsCloned.name)
        this.detailsCloned.name = 'sel_icon';
    }
  },
  watch: {
  },
  methods: {
    iconSelected(key) {
      if (!this.detailsCloned.content) return;
      this.detailsCloned.content.icon = key;
      this.detailsCloned.content.iconDisplay = this.icon_objs[key];
    },
    isActive(key) {
      var same = this.detailsCloned.content.icon;
      var keys = Object.keys(this.icon_objs);
      return same ? same == key :
        (keys && keys.length > 0) ? keys[0] == key : false;
    }
  },
  computed: {
    icon_objs() {
      var det = this.detailsCloned;
      if (det.iconMap) return this.ReiriIcons[det.iconMap];
      else if (det.iconObjs) return det.iconObjs;
      return {}
    },
  },
  components: {
    PopUpHead,
  },
}
</script>