<template>
  <div class="item-list vertical-center" role="button" @click="toggleFav">
    <img class="icon" :src="icon" />
    <div class="content">
      <div class="item-text one-line can-scroll"> {{ stringTable.word(name) }} </div>
    </div>
    <img class="fav-icon" :src="favSelected ? ReiriIcons.favorite_filled : ReiriIcons.favorite_default"
      :class="{ 'to-fav': !favSelected }" />
  </div>
</template>

<style>
.item-list .content {
  align-items: center;
}

.item-list .icon {
  width: 28px;
  display: block;
  margin: 7px;
}

.item-list .item-text {
  font-size: 14px;
}

.item-list .description-text {
  font-size: 16px;
  margin: 0 6px;
  color: grey;
}

.item-list .fav-icon {
  margin-left: auto !important;
  margin-right: 10px;
}
</style>

<script>
export default {
  name: "ItemList",
  data() {
    return {
      favSelected: false
    }
  },
  props: {
    icon: String,
    name: String,
    isFav: Boolean,
    favType: String,
    favKey: String
  },
  created() {
    this.favSelected = this.isFav
  },
  methods: {
    toggleFav() {
      this.favSelected = !this.favSelected
      this.$emit('toggle-favorite', { favKey: this.favKey, isFav: this.favSelected, type: this.favType })
    }
  }
}
</script>
