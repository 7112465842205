<template>
  <div class="pop-up-head vertical-center" :class="{ 'border-btm': !hideBorderBtm, 'small': small }">
    <div class="btn-left">
      <div v-if="leftArr" class="arr-holder" @click="leftIconOnly" role="button">
        <img :src="ReiriIcons.arrow_left" />
      </div>
      <div v-if="leftBtnTxt" class="btn-holder font-bold left frame shadow-only" @click="clickLeftBtn()" role="button">
        {{ stringTable.word(leftBtnTxt) }}
      </div>
    </div>
    <div class="icon-text-label">
      <img v-if="showIcon" :src="IconsHelper.iconMain(details)"
        :class="{ 'to-active': isIconActive, 'to-faded': isIconFaded }" />
      <div v-if="circle" class="circle medium" :class="circle"></div>
      <div v-if="directText">
        {{ stringTable.word(details) }}
      </div>
      <div v-else>
        {{ stringTable.word(details.name) }}
      </div>
    </div>
    <!-- order button -->
    <div v-if="showOrderBtn" class="order-btn-holder" @click="clickOrderBtn()" role="button"
      :style="{ right: firstDivRight }">
      <div class v-if="reverseBtndown"> <img class="order-btn rotate-frame vertical-center reverse"
          :src="ReiriIcons.up" /></div>
      <div class v-else> <img class="order-btn frame vertical-center" :src="ReiriIcons.up" />
      </div>
    </div>
    <!-- right button -->
    <div class="btn-right vertical-center" :class="{ 'whole-screen-right-btn': wholeScreen }" ref="rightBtn">
      <div v-if="rightBtnTxt" class="btn-holder font-bold right frame shadow-only" @click="clickRightBtn()" role="button">
        {{ stringTable.word(rightBtnTxt) }}
      </div>
      <div v-if="!hideCross" class="cross-holder vertical-center" :class="{ 'frame': !plus }" @click="rightIconOnly"
        role="button">
        <img :src="ReiriIcons.cross" :class="{ 'add-rotate': plus }" />
      </div>
    </div>
  </div>
</template>

<style>
.pop-up-head {
  height: 60px;
  padding: auto;
  display: flex;
  position: relative;
}

.right-frame .pop-up-head {
  height: 45px;
}

.pop-up-head.border-btm {
  border-bottom: 2px solid var(--disable);
}

.pop-up-head .icon-text-label {
  width: 100%;
  font-size: 24px;
  text-align: center;
}

.pop-up-head .icon-text-label img {
  display: inline-block;
  margin-right: 8px;
  width: 40px;
}

.pop-up-head .icon-text-label>div {
  display: inline-block;
}

.pop-up-head .btn-left,
.pop-up-head .btn-right {
  position: absolute;
}

.pop-up-head .btn-left {
  left: 6px;
}

.pop-up-head .btn-right {
  right: 6px;
  margin-left: 10px;
}

.pop-up-head .whole-screen-right-btn {
  right: 30px !important;
}

.pop-up-head .order-btn-holder {
  position: absolute;
  border-radius: 50% !important;
  width: 32px;
  height: 32px;
}

.pop-up-head .order-btn {
  border-radius: 50% !important;
  width: 100%;
  height: 100%;
}

.pop-up-head .arr-holder {
  padding: 12px;
}

.pop-up-head .btn-holder {
  padding: 6px 18px;
  border-radius: 30px;
  color: white;
}

.pop-up-head .btn-holder.left {
  background-color: var(--alert);
}

.pop-up-head .btn-holder.right {
  background-color: var(--active);
}

.pop-up-head .cross-holder {
  display: inline-block;
  padding: 3px;
  margin-left: 8px;
  border-radius: 50% !important;
  width: 43px;
  height: 43px;
}

.pop-up-head .cross-holder img {
  display: block;
  margin: 0 auto;
}

.pop-up-head.small {
  height: 35px;
}

.pop-up-head.small .icon-text-label {
  font-size: 1.25em;
  max-width: 220px;
  margin: 0 auto;
  line-height: 1;
}
</style>

<script>
export default {
  name: "PopUpHead",
  props: {
    details: Object,
    isIconActive: Boolean,
    isIconFaded: Boolean,
    leftBtnTxt: String,
    rightBtnTxt: String,
    showIcon: Boolean,
    showLast: Boolean,
    showPrevPopup: Boolean,
    // navBack: Boolean,
    hideBorderBtm: Boolean,
    leftArr: Boolean,
    hideCross: Boolean,
    plus: Boolean,
    small: Boolean,
    directText: Boolean,
    isSamePageHide: Boolean,
    circle: String,
    wholeScreen: Boolean,
    reverseBtndown: Boolean,
    showOrderBtn: Boolean,
  },
  data() {
    return {
      rightBtnElement: {},
    }
  },
  watch: {
    // navBack() {
    //   if (this.navBack && this.ConvertHelper.parentMethodsGot(this, 'back')) {
    //     this.back()
    //   }
    // }
  },
  methods: {
    // parentMethodsGot(methodName) {
    //   var parentMethods = this.$parent.$options.methods;
    //   parentMethods = parentMethods ? parentMethods : {};
    //   const parentMethodsKeys = Object.keys(parentMethods);
    //   return parentMethodsKeys.includes(methodName);
    // },
    leftIconOnly() {
      if (this.isSamePageHide) {
        this.$parent.back()
        return
      }
      else if (this.ConvertHelper.parentMethodsGot(this, 'prev'))
        this.$parent.prev();
      this.tryClose();
    },
    rightIconOnly() {
      if (this.isSamePageHide) {
        this.$parent.back()
        return
      }
      else if (this.plus)
        this.$parent.plus();
      else if (this.ConvertHelper.parentMethodsGot(this, 'prev'))
        this.$parent.prev();
      else this.tryClose();
    },
    tryClose() {
      if (!this.showPrevPopup) {
        this.NavAssistant.hidePopUp();
        if (this.showLast) {
          this.NavAssistant.showLast();
        }
      } else {
        this.NavAssistant.closePopUp()
      }
    },
    clickLeftBtn() {
      this.$parent.clickLeftBtn();
    },
    clickRightBtn() {
      this.$parent.clickRightBtn();
    },
    clickOrderBtn() {
      this.$parent.clickOrderBtn();
    }
  },
  mounted() {
    if (this.$refs.rightBtn)
      this.rightBtnElement = this.$refs.rightBtn;
  },
  computed: {
    firstDivRight() {
      // Access the second div element using $refs
      if (this.rightBtnElement) {
        // Check if the secondDivElement exists
        // Calculate the desired right value (width of second div + 50)
        const rightBtnWidth = this.rightBtnElement.clientWidth;
        const rightValue = rightBtnWidth + 50;

        // Return the right value as a string with 'px' unit
        return `${rightValue}px`;
      } else  // Return a default value if the second div doesn't exist
        return 'auto';

    },
  },
  components: {
  }
}
</script>
