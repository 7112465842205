<template>
  <PopUpToast />
  <div class="bar-zone-price frame" :class="{ 'hidden': !current_zone }">
    <div v-if="current_zone" class="content-holder">
      <div class="input-holder">
        <div class="vertical-center">
          <input type="text" :placeholder="stringTable.word(`zone${num}`)" v-model="current_zone.name">
          <div class="point circle" :style="`background-color:${zoneColors[num - 1]}; `"></div>
        </div>
        <div class="vertical-center">
          <input type="text" :placeholder="stringTable.word('currency')" v-model="current_zone.price[0]"
            @input="checkInput">
          <div class="unit">{{ priceInfo.currency }}/kWh</div>
        </div>
      </div>
      <div class="selection-holder">
        <BtnSwitch :default="current_zone.enable" />
        <div class="select-times vertical-center">
          <SelectTime name="startTime" :minuteInterval="15" :includeLastHour="true" :selectValue="start_time" />
          <div class="dash">-</div>
          <SelectTime name="endTime" :minuteInterval="15" :includeLastHour="true" :selectValue="end_time" />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.bar-zone-price {
  display: inline-block;
  width: 490px;
  margin: 1px 5px;
  padding: 4px;
}

.bar-zone-price .input-holder,
.bar-zone-price .selection-holder {
  display: table-cell;
}

.bar-zone-price .input-holder {
  width: 50% !important;
}

.bar-zone-price .input-holder input {
  width: 70%;
  margin-right: 6px;
}

.bar-zone-price .point {
  width: 16px;
  height: 16px;
}

.bar-zone-price .selection-holder {
  text-align: right;
  padding: 0 6px;
}

.bar-zone-price .selection-holder .select-times {
  text-align: right;
  padding: 0;
  margin: 12px 0 0 0;
}

.bar-zone-price .selection-holder .select-time:first-child {
  margin-left: auto;
}

.bar-zone-price .selection-holder .select-time {
  width: 90px;
}

.bar-zone-price .selection-holder .dash {
  margin: 0 6px;
}
</style>

<script>
import PopUpToast from './PopUpToast.vue'
import BtnSwitch from "./BtnSwitch.vue"
import SelectTime from "./SelectTime.vue"

export default {
  name: "BarZonePrice",
  props: {
    priceInfo: Object,
    zone: String,
    num: Number,
    zoneColors: Array
  },
  data() {
    return {
      toast: {},
    }
  },
  created() {
  },
  watch: {
    'current_zone'() {
    }
  },
  methods: {
    changeSwitch(name, newSwitch) {
      this.current_zone.enable = newSwitch;
    },
    changeTime(name, newTime) {
      var timeNames = ['startTime', 'endTime'];
      var periodIndex = timeNames.indexOf(name);
      this.current_zone.period[periodIndex] = parseInt(newTime.replace(':', ''));
    },
    checkInput() {
      var input = this.current_zone.price[0];
      if (!this.ConvertHelper.isNumberOrFloat(input)) {
        this.toast = { text: 'invalid_input', isError: true }
      }
    }
  },
  computed: {
    current_zone() {
      return this.priceInfo.zone[this.zone][`zone${this.num}`]
    },
    start_time() {
      return this.ConvertHelper.zoneTimeToSelectTime(this.current_zone.period[0]);
    },
    end_time() {
      return this.ConvertHelper.zoneTimeToSelectTime(this.current_zone.period[1]);
    }
  },
  components: {
    PopUpToast,
    BtnSwitch,
    SelectTime
  }
}

</script>