<template>
  <div v-if="!details" class="tile frame hidden">
  </div>
  <div v-else class="tile tile-device frame" @click="tileClicked" role="button">
    <div v-if="!details.comm_stat" class="white-transparent"></div>
    <div class="top-content vertical-center">
      <div class="icon-holder">
        <img :src="IconsHelper.iconMain(details)" class="icon-main"
          :class="{ 'to-active': onState() && details.comm_stat }" />
      </div>
      <div class="name-text" :class="{ 'alert-name': details.foff == 'on' }">{{ details.name }}</div>
      <div v-if="details.comm_stat" class="error-alert-holder vertical-center can-scroll">
        <div v-for="err in getKeysFromObj(details.error)" :key="err" class="detail-frame icon-text error-show">
          <img :src="ReiriIcons.warning" class="to-error" />
          <span class="to-error">{{ err }}</span>
        </div>
        <div v-for="alrt in getKeysFromObj(details.alert)" :key="alrt" class="detail-frame icon-text alert-show">
          <img :src="ReiriIcons.warning" class="to-alert" />
          <span class="to-alert">{{ alrt }}</span>
        </div>
      </div>
    </div>
    <div class="btm-content vertical-center can-scroll" :class="{ 'hidden': !details.comm_stat }">
      <div v-if="details.filter == 'on'" class="detail-frame-plain only-icon">
        <img :src="ReiriIcons.clean_filter" />
      </div>
      <div v-if="details.ch_master" class="detail-frame-plain only-icon">
        <img :src="ReiriIcons.ch_master" />
      </div>
      <div v-if="details.freshup == 'on'" class="detail-frame-plain only-icon">
        <img :src="ReiriIcons.freshup_default" />
      </div>
      <div v-if="details.mode" class="detail-frame"
        :class="[class_aircon_temp, { 'to-black-font': details.sb_stat == 'on' }]">
        <!-- {{ details.mode }} -->
        <!-- <img :src="details.mode == 'F' ? ReiriIcons.fan_1 : ReiriIcons.iconList.mode[details.mode]"
          :class="{ 'to-white': onState('airconTemp') }" /> -->
        <img :src=displayModeIcon() :class="{ 'to-white': onState('airconTemp') }" />
        <span v-if="show_sp_value">
          {{ ac_temp }} {{ stringTable.word('DEG') }}
        </span>
      </div>
      <div v-if="details.av != undefined" class="detail-frame only-text">
        <span v-if="details.unit == 'DEG'">
          {{ tempConvert.tempConvert(details.av) }} {{ stringTable.word(details.unit) }}
        </span>
        <span v-else>
          {{ details.av }} {{ stringTable.word(details.unit) }}
        </span>
      </div>
      <div v-for="icon in icons_from_list" :key="icon" class="detail-frame only-icon">
        <img :src="icon" />
      </div>
      <div v-if="details.temp" class="detail-frame icon-text actual-temperature">
        <img :src="ReiriIcons.thermometer" />{{ tempConvert.tempConvert(details.temp).toFixed(1) }} {{
          stringTable.word('DEG') }}
      </div>
      <div v-if="details.meter != undefined" class="detail-frame only-text">
        {{ details.meter.toFixed(2) }} {{ stringTable.word(details.unit) }}
      </div>
      <div v-if="details.value != undefined" class="detail-frame only-text">
        {{ details.value.toFixed(2) }} {{ stringTable.word(details.sub_unit) }}
      </div>
      <div v-if="details.bat != undefined" class="detail-frame-plain icon-text" :class="{ 'to-error': details.bat == 0 }">
        <img :src="icon_battery" /> {{ details.bat }}%
      </div>
      <div v-if="details.hum_sp" class="detail-frame icon-text text-white aircon-temp-cool">
        <img :src="ReiriIcons.humid" class="to-white" /> {{ details.hum_sp }}%
      </div>
      <div v-if="details.rgbw" class="detail-frame only-text">
        {{ details.rgbw }}
      </div>
      <div v-if="details.mstat" class="detail-frame only-text">
        {{ details.mstat }}
      </div>
    </div>
  </div>
</template>

<style>
.detail-frame,
.detail-frame-plain {
  margin-left: 1px;
  width: fit-content;
}

.detail-frame {
  background-color: var(--detailBkg);
  border-radius: 15px;
}

.tile {
  position: relative;
  overflow: hidden;
  padding: 2px;
  height: 67px;
}

.layout .error-holder,
.tile .error-holder,
.tile .error-show {
  background-color: rgba(255, 202, 202) !important;
}

.layout .alert-holder,
.tile .alert-holder,
.tile .alert-show {
  background-color: rgb(255, 235, 209) !important;
}

.tile .only-icon {
  padding: 3px;
}

.tile .only-icon.detail-frame-plain {
  padding: 1px;
}

.tile .only-icon img {
  display: block;
  margin: 0 auto;
}

.tile .to-black-font {
  color: black !important;
}

.icon-text {
  display: flex;
  align-items: center;
  padding: 2px 8px 2px 4px;
}

.tile .icon-text,
.tile .only-text {
  font-size: .65em;
  font-family: 'Poppins-bold';
  min-width: fit-content;
}

.icon-text span {
  width: max-content;
}

.icon-text img {
  display: inline-block;
  margin-right: 2px;
}

.tile .icon-text img,
.tile .only-icon img {
  width: 15px;
  height: 15px;
}

.tile .name-text {
  font-size: .8em;
}

.tile .name-text.alert-name {
  color: var(--alert);
}

.tile .only-text {
  padding: 2px 8px;
}

.white-transparent {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(235, 235, 235, 0.6);
  z-index: 1;
}

.tile-device .icon-holder {
  width: 42px;
  height: 42px;
}

.tile-device .icon-holder .icon-main {
  width: 100%;
  height: 100%;
}

.tile-device .top-content {
  position: relative;
}

.tile-device .name-text {
  width: 130px;
  margin: 0 2px 0 4px;
}

.tile-device .error-alert-holder {
  position: absolute;
  width: 100%;
  overflow-x: scroll;
  bottom: -0.5px;
  z-index: 2;
  font-size: .89em;
}

.tile-device .error-alert-holder img {
  width: 12px;
  height: 12px;
}

.tile-device .error-alert-holder .icon-text {
  padding-left: 3px;
  padding-right: 7px;
}

.tile-device .btm-content {
  height: 22px;
  width: 100%;
  overflow-x: scroll;
}

/* .tile-device .btm-content>div:first-child {
  margin-left: auto;
} */

.aircon-temp-cool {
  background-color: var(--cool) !important;
}

.aircon-temp-heat {
  background-color: var(--heat) !important;
}

.aircon-temp-other {
  background-color: var(--mode) !important;
}
</style>

<script>
export default {
  name: "TileDevice",
  props: {
    details: Object,
    detailsKey: String,
    popUpWithCache: Boolean,
    commModule: Object,
  },
  data() {
    return {
    }
  },
  created() {
  },
  computed: {
    ac_temp() {
      var sp;
      var mode = this.details.mode;
      if (mode == 'A') {
        if (this.details.actual_mode == 'C') mode = 'C';
        else if (this.details.actual_mode == 'H') mode = 'H';
      }
      if (mode == 'C') sp = this.details.csp;
      else if (mode == 'H') sp = this.details.hsp;
      return sp ? this.tempConvert.spConvert(sp).toFixed(1) : null;
    },
    icons_from_list() {
      var temp = [];
      const iconList = this.ReiriIcons.iconList;
      var iconObj = iconList[this.details.fanstep_key];
      if (iconObj) temp.push(iconObj[this.details.fanstep]);
      iconObj = iconList[this.details.flap_key];
      if (iconObj) temp.push(iconObj[this.details.flap]);

      var iconListMappedKeys = ['vmode', 'vamount'];
      iconListMappedKeys.forEach(k => {
        if (this.details[k] != undefined) {
          var iconPath = this.ReiriIcons.iconList[k][this.details[k]];
          if (iconPath) temp.push(iconPath);
        }
      });
      return temp;
    },
    icon_battery() {
      var bat = this.details.bat;
      if (bat == 0)
        return this.ReiriIcons.battery_0;
      else if (bat <= 20)
        return this.ReiriIcons.battery_20;
      else if (bat <= 50)
        return this.ReiriIcons.battery_50;
      else if (bat <= 80)
        return this.ReiriIcons.battery_80;
      else if (bat <= 100)
        return this.ReiriIcons.battery_100;
      return '';
    },
    class_aircon_temp() {
      var classes = this.show_sp_value ? ' icon-text' : " only-icon";
      if (this.onState()) {
        var mode = this.details.mode;
        var actualMode = this.details.actual_mode;
        classes += ' text-white aircon-temp-';
        if (['C', 'D'].includes(mode)) classes += "cool";
        else if (mode == 'A') actualMode == 'C' ? classes += "cool" : classes += "heat";
        else if (mode == 'H') classes += "heat";
        else classes += "other";
      }
      return classes;
    },
    is_mode_CH() {
      return ['C', 'H', 'A'].includes(this.details.mode);
    },
    show_sp_value() {
      return this.details.sp_cap && this.is_mode_CH;
    }
  },
  methods: {
    displayModeIcon() {
      if (this.details.mode == 'F') {
        return this.ReiriIcons.fan_1
      } else if (this.details.mode == 'A') {
        return this.ReiriIcons.auto_default
      } else {
        return this.ReiriIcons.iconList.mode[this.details.mode]
      }
    },
    getKeysFromObj(theObj) {
      if (!theObj) return [];
      var keys = [];
      for (const i in theObj) {
        if (theObj[i])
          keys.push(this.stringTable.word(i));
      }
      return keys;
    },
    onState(item) {
      console.log(this.details);
      if (item == 'airconTemp')
        return (this.details.stat == 'on' || this.details.lock == 'open') && this.details.sb_stat == 'off';
      else
        return this.details.stat == 'on' || this.details.lock == 'open' || (this.details.av > 0 && !['Value', 'Dehumi'].includes(this.details.type));
    },
    tileClicked() {
      if (this.details.comm_stat) {
        if (this.ConvertHelper.pointGotOper(this.details))
          this.NavAssistant.showPopUp({
            name: 'PopUpOperDev',
            details: this.details,
            page: this.$parent.page,
            commModule: this.commModule,
          }, this.popUpWithCache);
      }
    }
  },
}
</script>