<template>
  <div class="pop-up user user-page system-setting">
    <PopUpToast />
    <div class="content-holder">
      <div class="left-content ">
        <PopUpHead :details="{ name: 'user_admin' }" :hideBorderBtm="true" :leftArr="true" :plus="true" :small="true" />
        <LoadingSpinner v-if="isLoading" :topSpacing="true" />
        <div v-else>
          <div class="nav-list ">
            <div v-for="user in slicedList" :key="user">
              <div v-if="user" class="frame">
                <NavHorizontal :icon="userClassMap[user.class]" :name="user.name" hideKeyValue="true"
                  :description="stringTable.word(userClassMap[user.class])" showDescription="true"
                  @click="showUserDetails(user)" />
              </div>
            </div>
          </div>
          <div class="vertical-center">
            <NavPagination direction="up" :expandHori="true" />
            <NavPagination direction="down" :expandHori="true" />
          </div>
        </div>
      </div>
      <div class="right-frame">
        <PopUpHead v-if="showEdit" :details="head_detail" :leftBtnTxt="showDeleteBtn ? 'delete' : ''" rightBtnTxt="save"
          :hideBorderBtm="true" />
        <!-- {{ userList }} -->
        <div class="edit-content" v-show="showEdit">
          <!-- username -->
          <div class="frame" :class="[isRegister ? 'enable-input' : 'disable-input']">
            <input :class="[isRegister ? 'enable-input' : 'disable-input']" :type="'text'"
              :placeholder="stringTable.word('usrname')" maxlength="30" v-model="userName" />
          </div>
          <!-- password -->
          <FrameInputPwd varStr="userNewPassword" placeholder="passwd" :toggleToInvisible="toggleToInvisible" />
          <div class="info-box frame">
            <div class="upper-content">
              <!-- accountType -->
              <div class="left-text vertical-center">
                <div>{{ stringTable.word("user_class") }}</div>
              </div>
              <div class="right-content">
                <select v-if="isRegister" class="input-holder" v-model="userType">
                  <option value="Admin">{{ stringTable.word("admin") }}</option>
                  <option value="User">{{ stringTable.word("user") }}</option>
                </select>
                <div v-else-if="!isRegister">{{ stringTable.word(userClassMap[userType]) }}</div>
              </div>
            </div>
            <div v-if="userType == 'Admin'">
              <div class="upper-content">
                <div class="left-text vertical-center">
                  <div>{{ stringTable.word("lang_notify") }}</div>
                </div>
                <!-- language -->
                <div class="right-content">
                  <select class="lang-holder" v-model="userLanguage">
                    <option value="EN">{{ stringTable.word("EN") }}</option>
                    <option value="ES">{{ stringTable.word("ES") }}</option>
                    <option value="BR">{{ stringTable.word("BR") }}</option>
                    <option value="VN">{{ stringTable.word("VN") }}</option>
                    <option value="THAI">{{ stringTable.word("THAI") }}</option>
                    <option value="BAH">{{ stringTable.word("BAH") }}</option>
                    <option value="CH">{{ stringTable.word("CH") }}</option>
                    <option value="TW">{{ stringTable.word("TW") }}</option>
                    <option value="HK">{{ stringTable.word("HK") }}</option>
                    <option value="JP">{{ stringTable.word("JP") }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="info-box frame ">
            <div class="upper-content">
              <div class="left-text">
                <!-- pushNotification -->
                <div>{{ userType == 'Admin' ? stringTable.word("push_notification") : stringTable.word("op_limit") }}
                </div>
              </div>
              <div class="right-content">
                <label class="switch">
                  <input v-if="userType == 'Admin'" type="checkbox" checked v-model="userPushNoti">
                  <input v-else-if="userType == 'User'" type="checkbox" checked v-model="userAuth.sp_proh">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div>
              <div class="upper-content">
                <div class="left-text">
                  <!-- mail alert -->
                  <div>{{ userType == 'Admin' ? stringTable.word("mail_alert") : stringTable.word("mode_limit") }}</div>
                </div>
                <div class="right-content">
                  <label class="switch">
                    <input v-if="userType == 'Admin'" type="checkbox" checked v-model="userEmailNoti">
                    <input v-else-if="userType == 'User'" type="checkbox" checked v-model="userAuth.mode_proh">

                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="mail-addr" v-show="showMail || userType == 'User'">
            <div class="nomargin-content vertical-center ">
              <div v-if="userType == 'Admin'" class="vertical-center max-width">
                <div class="max-width"> {{ stringTable.word("mailaddr") }}</div>
                <img class="add-rotate" :src="ReiriIcons.cross" @click="addMail" />
              </div>
              <SelectDropDown v-else-if="userType == 'User'" class="select-device" title="select_devices"
                mainLabel="select_device" :items="clonePointList" :plusIcon="true" checkedString="stdUserChecked" />
            </div>
          </div>

          <div class="reverse-frame mail" v-if="showMail && userType == 'Admin'">
            <div class="info-box mails-box can-scroll reverse-frame-inner"
              v-if="(mailAddressList.length > 0) && (showMail && userType == 'Admin')">
              <div class="upper-content frame" v-for="(mail, index) in mailAddressList" :key="index">
                <div class="left-text input-add">
                  <input type="text" v-model="mailAddressList[index]" :placeholder="stringTable.word('mailaddr')"
                    style="background-color: var(--disable);">
                </div>
                <div class="right-add vertical-center">
                  <img class="cross to-error" :src="ReiriIcons.delete" @click="deleteMail(index)" />
                </div>
              </div>
            </div>
          </div>



          <div class="reverse-frame device" v-if="(userType == 'User')">
            <div class="info-box devices-box can-scroll reverse-frame-inner">
              <div v-for="(pointValue, pointKey) in pointsChecked" :key="pointKey">
                <div v-if="pointValue == true && commModule.point_list[pointKey]" class="upper-content-points">
                  <div class="left-text input-add frame">
                    <div class="icon-holder">
                      <img class="icon-point" :src="IconsHelper.iconMain(commModule.point_list[pointKey])" />
                    </div>
                    <div>{{ commModule.point_list[pointKey].name }}</div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.user .reverse-frame.mail {
  height: 205px;
  margin-top: 10px;
}

.user .reverse-frame.device {
  height: 230px;
}

.user .upper-content-points {
  padding: 2px 0 4px 0;
}

.user .add-rotate {
  margin-left: auto;
}

.user .max-width {
  width: 100%;
}

.user .select-dropdown-holder .dropdown-top {
  margin-left: -390px !important;
}

.edit-header {
  width: 100%;
}

.user .icon-point {
  margin: 0px 10px;
}

.user .right-title {
  margin: 0 auto;
}

.user .select-device {
  font-size: 16px;
}

.mail-addr .right-content {
  padding: 0 !important;
}

.user .input-add {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}

.user .right-add {
  margin-left: auto;
  margin-right: 10px;
}

.user .mail-addr {
  border-bottom: 1px solid var(--disable);
  align-items: center;
  margin-top: 10px;
  width: 100%;
  padding-left: 5px;
}

.user .disable-input {
  background-color: var(--disable);
  pointer-events: none;
}

.user .enable-input {
  background-color: var(--background);
  pointer-events: all;
}

.user .input-holder {
  width: 220px;
  font-size: 16px;
  text-align: center;
  margin-right: 0px;
  appearance: none;
  margin-top: 0px;
}

.user select {
  height: 36px !important;
}

.user .lang-holder select option {
  font-size: 16px;
}

.user .lang-holder select {
  font-size: 16px;
  max-height: 150px;
}

.user .lang-holder {
  width: 220px;
  font-size: 16px;
  text-align: center;
  margin-right: 0px;
  appearance: none;
  margin-top: 0px;
}

.user input[type=text],
.user input[type=password] {
  font-size: 16px;
}

.user .info-box {
  margin-top: 10px;
  padding: 4px;
  width: 100%;
}

.user .upper-content {
  margin: 6px 0;
  padding-left: 10px;
  display: flex;
  width: 100%;
}

.user .nomargin-content {
  display: flex;
  width: 100%;
}

.user .right-content {
  margin-left: auto;
  text-align: right;
  width: 45%;
  padding: 0 10px;
}

.user .left-text {
  text-align: left;
}

.user .password-input {
  margin-top: 10px;
}

.user .top-btn,
.favorite .top-btn {
  margin-top: 12px;
  text-align: center;
  position: absolute;
  padding: 5px 10px;
  height: 35px;
  font-size: 18px;
  font-family: 'Poppins-bold';
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.user .save-btn {
  color: #25bcf5;
  margin-top: -5px;
  right: 80px;
}

.favorite .save-btn {
  color: #25bcf5;
  right: 80px;
}

.user .delete-btn {
  left: 5px;
  color: white;
  background-color: red;
  position: absolute;
  top: 0;
}

.user .edit-content {
  width: 100%;
  padding: 5px;
}

.user .cross {
  margin-left: 12px;
}

.user .head-title img {
  display: inline-block;
  width: 30px;
  box-shadow: 2px 2px 4px #b8b8b8,
    -2px -2px 4px #ffffff;
  border-radius: 15px;
}

.user .edit-title {
  font-size: 24px;
  text-align: center;
  width: 100%;
  height: 60px;
  /* margin-left: 200px; */
  position: relative;
  /* justify-content: center; */
  align-items: center;
  /* display: flex; */
}

.user .pop-up-head {
  width: 100%;
}

.user .devices-box {
  height: inherit;
}

.user .mail-addr .dropdown-top {
  margin-top: -35px !important;
}

.user .mails-box {
  overflow-y: scroll;
  height: inherit;
  padding: 0px 4px;
}

.user .mails-box input[type=text] {
  font-size: 1em;
  height: 30px !important;
}

.user-page .nav-list {
  height: 518px !important;
}

.user-page .nav-list .frame {
  margin-bottom: 6.5px;
}
</style>

<script>
import PopUpHead from './PopUpHead.vue'
import FrameInputPwd from './FrameInputPwd.vue'
import NavHorizontal from './NavHorizontal.vue'
import SelectDropDown from './SelectDropDown.vue'
import PopUpToast from './PopUpToast.vue'
import LoadingSpinner from './LoadingSpinner.vue'
import NavPagination from './NavPagination.vue'

export default {
  name: "PopUpUserAdmin",
  data() {
    return {
      userClassMap: { Admin: "admin", User: "user", Service: "service" },
      userList: [],
      showEdit: false,
      newUserType: "Admin",
      rightTitle: '',
      isRegister: false,
      showMail: false,
      showAddMail: false,
      mailAddressList: [],
      isLoading: false,
      toast: {},

      userId: 0,
      userName: '',
      userNewPassword: '',
      userOriginalPassword: '',
      userType: 'Admin',
      userLanguage: "EN",
      userPushNoti: false,
      userEmailNoti: false,
      userAuth: { 'user_reg': false, 'sp_proh': false, 'mode_proh': false, 'points': {}, 'sites': {} },
      userSPLimit: false,
      userModeLimit: false,

      devicesList: [],
      passwordVisible: false,
      toggleToInvisible: false,
      pointsChecked: {}, // true and false
      gotAnyPoint: false,
      toastText: '',
      showDeleteBtn: true,
      isError: false,
      page: 1,
      itemPerPage: 9,
      slicedList: [],
    }
  },
  props: {
    commModule: Object,
  },
  created() {
    this.getUserList();
    this.generateDeviceList();
    this.initializePointList()
  },
  computed: {
    head_detail() {
      var name = '';
      if (this.showEdit)
        name = this.isRegister ? 'add_account' : 'edit_account';
      return { name }
    },
    emptyPointChecked() {
      return Object.values(this.pointsChecked).every(value => value === false);
    },
    mainList() {
      return this.userList;
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    updateNewPassword(pw) {
      this.userPassword = pw
    },
    generateDeviceList() {
      this.devicesList = Object.values(this.commModule.point_list).map((item) => item.name);
    },
    showDevices() {
      alert("!!")
    },
    deleteMail(index) {
      this.mailAddressList.splice(index, 1);
    },
    addMail() {
      // this.showAddMail = true
      this.mailAddressList.push('')
    },
    showUserDetails(user) {
//      console.log(user)
      if (user.name.localeCompare(this.commModule.user) == 0)
        this.showDeleteBtn = false
      else
        this.showDeleteBtn = true
      const gotUser = user != undefined;
      this.showEdit = gotUser
      this.isRegister = !gotUser;
      if (!user) return;
      this.userId = user.id;
      this.userName = user.name;
      this.userNewPassword = '';
      this.userOriginalPassword = user.passwd;
      this.userLanguage = user.lang;
      this.userType = user.class;
      this.lang = user.lang;
      this.userPushNoti = user.notify;
      this.userEmailNoti = user.mail_alert;
      this.userAuth = user.auth;
      this.mailAddressList = user.mail_addr;
      // show selected devices
      this.pointsChecked = user.auth.points;
      this.initializePointList(this.pointsChecked);
      this.canClose = false;
      this.toggleToInvisible = !this.toggleToInvisible;
    },
    initializePointList(pointsChecked) {
      this.clonePointList = this.commModule.point_list
      for (const key in this.clonePointList) {
        this.clonePointList[key].stdUserChecked = pointsChecked ? pointsChecked[key] : false
      }
    },
    plus() {//registerUser
      this.showEdit = true
      this.isRegister = true
      this.showDeleteBtn = false
      this.clearUser()
      this.initializePointList()
      this.toggleToInvisible = !this.toggleToInvisible;
    },
    clearUser() {
      this.showAddMail = false
      this.pointsChecked = {}
      this.userId = 0
      this.userName = ''
      this.userNewPassword = ''
      this.userOriginalPassword = ''
      this.userType = 'Admin'
      this.userLanguage = 'EN'
      this.userPushNoti = false
      this.userEmailNoti = false
      this.mailAddressList = []
      this.userSPLimit = false
      this.userModeLimit = false
      this.userAuth = { 'user_reg': false, 'sp_proh': false, 'mode_proh': false, 'points': {}, 'sites': {} }
    },
    removeEditor() {
      this.clearUser()
      this.showEdit = false
    },
    clickLeftBtn() {
      this.toast = {
        text: 'conf_del',
        pendingYesMethod: () => {
          this.commModule.delAccount({ name: this.userName });
        }
      }
    },
    clickRightBtn() {
      this.userAuth = {
        'user_reg': (this.userType == 'Admin') ? true : false, 'sp_proh': this.userAuth.sp_proh, 'mode_proh': this.userAuth.mode_proh,
        'points': (this.userType == 'User') ? this.pointsChecked : {}, 'sites': {}
      }
      this.mailAddressList = this.mailAddressList.filter(item => item !== '');
      // check input value
      if (this.userName == '') {
        this.toast = { text: 'no_username', isError: true, }
        return
      } else if (this.userName.includes(' ')) {
        this.toast = { text: 'user_req', isError: true, }
        return
      }
      if (this.isRegister) {
        if (this.userNewPassword == '') {
          this.toast = { text: 'no_password', isError: true, }
          return
        }
        const userData = {
          "name": this.userName, "passwd": this.userNewPassword, "class": this.userType, "auth": this.userAuth, "notify": this.userPushNoti,
          "mail_alert": this.userEmailNoti, "mail_addr": this.mailAddressList, "lang": this.userLanguage
        }
        this.commModule.addAccount(userData);

      } else if (this.showEdit) {
        const userData = {
          "id": this.userId, "name": this.userName, "passwd": this.userNewPassword != '' ? this.userNewPassword : null,
          "class": this.userType, "auth": this.userAuth, "notify": this.userPushNoti,
          "mail_alert": this.userEmailNoti, "mail_addr": this.mailAddressList, "lang": this.userLanguage
        }
        this.commModule.editAccount(userData);
      }
    },
    getUserList() {
      this.commModule.getAccounts();
      this.isLoading = true;
      this.isError = false
    },
    adminTileClicked() {
      this.showEdit = true
      this.isRegister = false
    },
    clickedCheckBox() {
      this.pointsChecked = Object.entries(this.commModule.point_list).reduce((result, [key, value]) => {
        if (value.stdUserChecked) {
          result[key] = true;
        } else {
          result[key] = false;
        }
        return result;
      }, {});
    },
    prev() {
      this.showEdit = false
    },
  },
  watch: {
    'commModule.get_account_list'() {
      var accList = this.commModule.get_account_list;
      this.isLoading = false;
      this.isError = false;
      if (accList.result == 'no_auth') {
        this.userList = []; return;
      }
      this.userList = Object.values(this.commModule.get_account_list).filter(value => value.class !== "Service");
    },
    'commModule.add_account'() {
      if (this.commModule.add_account.result == "OK") {
        this.toast = { text: 'succeed' }
        this.removeEditor()
        this.getUserList()
      } else if (this.commModule.add_account.result == "ng_passwd") {
        this.toast = { text: 'pass_req' }
      } else {
        this.toast = { text: this.commModule.add_account.result }
      }
      this.isError = false
    },
    'commModule.delete_account'() {
      if (this.commModule.delete_account.result == "OK") {
        this.toast = { text: 'succeed' }
        this.removeEditor()
        this.getUserList()
      } else {
        this.toast = { text: this.commModule.delete_account.result }
      }
      this.isError = false
    },
    'commModule.modify_account'() {
      if (this.commModule.modify_account.result == "OK") {
        this.toast = { text: 'succeed' }
        this.removeEditor()
        this.getUserList()
      } else if (this.commModule.modify_account.result == "ng_passwd") {
        this.toast = { text: 'pass_req' }
      } else {
        this.toast = { text: this.commModule.modify_account.result }
      }
      this.isError = false
    },
    'userEmailNoti'() {
      if (this.userEmailNoti == true) {
        this.showMail = true
      } else {
        this.showMail = false
      }
      this.isError = false
    }
  },
  components: {
    PopUpHead,
    FrameInputPwd,
    NavHorizontal,
    SelectDropDown,
    LoadingSpinner,
    PopUpToast,
    NavPagination
  }
}
</script>
