<template>
  <div class="pop-up owner-settings-form settings-form">
    <PopUpHead :details="details" />
    <div class="form-holder frame">
      <div v-for="fd in formDetails" :key="fd" class="vertical-center">
        <div class="lbl">{{ stringTable.word(fd.title) }}</div>
        <div class="input-holder">
          <input type="text" :placeholder="stringTable.word(fd.title)" v-model="fd.model" @input="inputModel(fd)">
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.pop-up.settings-form .form-holder {
  width: 800px;
  margin: 6px auto;
  padding: 6px;
}

.pop-up.settings-form .form-holder .lbl {
  width: 35%;
  margin: auto 8px;
}

.pop-up.settings-form .form-holder .input-holder {
  width: 65%;
}
</style>

<script>
import PopUpHead from "./PopUpHead.vue"

export default {
  name: "PopUpOwnerSet",
  props: {
    commModule: Object,
    details: Object,
  },
  data() {
    return {
      detailsCloned: this.details,
      formDetails: [
        { title: 'owner' },
        { title: 'address' },
        { title: 'address2' },
        { title: 'postal_code' },
        { title: 'contact' },
        { title: 'tel' },
        { title: 'email' },
      ],
      formSettingsMap: {
        owner: 'name',
        address: 'addr1',
        address2: 'addr2',
        postal_code: 'postalcode',
        contact: 'contact',
        tel: 'tel',
        email: 'email',
      }
    }
  },
  created() {
    this.detailsCloned.load_owner_info = this.commModule.load_owner_info;
    this.formDetails.forEach(fd => {
      fd.mapKey = this.formSettingsMap[fd.title];
      fd.model = this.detailsCloned.load_owner_info[fd.mapKey];
    });
  },
  methods: {
    inputModel(formDetail) {
      this.detailsCloned.load_owner_info[formDetail.mapKey] = formDetail.model;
    }
  },
  components: {
    PopUpHead,
  },
}
</script>
