<template>
  <div class="login-page">
    <img class="logo" src="/images/logo.png" />
    <div class="input-holder">
      <select v-model="lang">
        <option value="EN">{{ stringTable.word("EN") }}</option>
        <option value="ES">{{ stringTable.word("ES") }}</option>
        <option value="BR">{{ stringTable.word("BR") }}</option>
        <option value="VN">{{ stringTable.word("VN") }}</option>
        <option value="THAI">{{ stringTable.word("THAI") }}</option>
        <option value="BAH">{{ stringTable.word("BAH") }}</option>
        <option value="CH">{{ stringTable.word("CH") }}</option>
        <option value="TW">{{ stringTable.word("TW") }}</option>
        <option value="HK">{{ stringTable.word("HK") }}</option>
        <option value="JP">{{ stringTable.word("JP") }}</option>
      </select>

      <input type="text" :placeholder="stringTable.word('usrname')" maxlength=30 v-model="user">
      <FrameInputPwd varStr="passwd" placeholder="passwd" />

      <!-- <input type="password" :placeholder="stringTable.word('passwd')" maxlength=30 minlength=10 v-model="passwd"> -->

      <div class='err-message'>{{ stringTable.word(this.err) }}</div>
    </div>
    <div class="termscond-holder">
      <input class="form-check-input" type="checkbox" value="" v-model="termsCond">
      <a class="link-termscond" @click="showTermAndCond">
        {{ stringTable.word('conf_terms_conditions') }}
      </a>
    </div>
    <div class="submit-holder">
      <input type="submit" :value="stringTable.word('login')" @click="login" v-bind:disabled="!termsCond"
        :class="btn_enable">
    </div>
  </div>
</template>
  
<style>
.login-page {
  background: linear-gradient(0, white 20%, var(--active) 100%);
  padding: 10px;
  padding-bottom: 140px;
}

.login-page .logo {
  width: 200px;
}

.login-page .input-holder {
  padding-top: 100px;
}

.login-page .termscond-holder,
.pw-not-match {
  text-align: center;
  margin: 10px 0 20px 0;
}

.login-page .input-holder,
.login-page .submit-holder {
  width: 300px;
  text-align: center;
  margin: 0 auto;
}

.login-page select {
  margin-bottom: 20px;
}

.login-page select,
.login-page .frame,
.login-page input[type=text] {
  box-shadow: 1px 1px 2px gray;
  background-color: var(--background);
}

.login-page input[type=checkbox] {
  border: none;
  font-size: 0.8em;
}

.login-page input[type=checkbox] {
  width: 20px;
  height: 20px;
}

.login-page .link-termscond {
  color: var(--active);
  font-size: 1em;
  cursor: pointer;
}

.login-page .form-check-input {
  margin: 2px 8px 0 0;
}

.login-page input[type=submit],
.passwd input[type=submit] {
  width: 100%;
  color: white;
  padding: 10px 0;
  border: none;
  border-radius: 40px;
  font-size: 1.2em;
  cursor: pointer;
}

.button-disable {
  color: var(--fontGray) !important;
  background-color: var(--disable);
}

.button-enable {
  background-color: var(--active);
}

.login-page .err-message {
  font-size: 12px;
  color: var(--alert);
}
</style>
  
<script>
import FrameInputPwd from "./FrameInputPwd.vue";

export default {
  name: "PageLogin",
  props: {
    commModule: Object,
    err: String,
  },
  data() {
    return {
      lang: 'EN',
      user: '',
      passwd: '',
      termsCond: false,
      btn_enable: 'button-disable'
    }
  },
  created() {
    this.lang = localStorage.getItem('Lang')
    if (this.lang == undefined) this.lang = 'EN'
    this.stringTable.setLang(this.lang);
    this.user = localStorage.getItem('User')
    if (this.user == undefined) this.user = ''
    this.passwd = localStorage.getItem('Passwd')
    if (this.passwd == undefined) this.passwd = ''
    if (localStorage.getItem('TaC') == "true") {
      this.termsCond = true
      this.btn_enable = 'button-enable'
    } else {
      this.termsCond = false
      this.btn_enbale = 'button-disable'
    }
  },
  watch: {
    lang(newLang) {
      if (newLang != null) {
        localStorage.setItem('Lang', newLang);
        this.stringTable.setLang(newLang);
      }
    },
    termsCond(newCond) {
      if (newCond != null) {
        localStorage.setItem('TaC', newCond);
        if (newCond == true) this.btn_enable = 'button-enable'
        else this.btn_enable = 'button-disable'
      }
    },
  },
  methods: {
    login() {
      if (this.termsCond) {
        localStorage.setItem('User', this.user);
        localStorage.setItem('Passwd', this.passwd);
				var theIp = 'localhost';
        localStorage.setItem('IpAddr', theIp);
        this.commModule.connect(theIp, this.user, this.passwd);
        this.$parent.isLoading = true;
      }
    },
    showTermAndCond() {
      this.NavAssistant.showPopUp({
        name: 'PopUpTermAndCond'
      });
    }
  },
  computed: {
  },
  components: {
    FrameInputPwd
  }
}
</script>
