<template>
  <div class="nav-horizontal" role="button"
    :class="{ 'vertical-center': !verticalTop, 'vertical-top': verticalTop, 'chevron': !showSwitch && !showPlus }">
    <img v-if="iconHelperValue" class="icon" :src="IconsHelper.iconMain(iconHelperValue)"
      :class="{ 'to-active': isIconActiveColor }" />
    <img v-else-if="icon" class="icon" :src="ReiriIcons[icon] ? ReiriIcons[icon] : icon"
      :class="{ 'to-active': isIconActiveColor }" />
    <div class="content" @click="contentClicked">
      <div class="item-text can-scroll-x vertical-center"
        :class="{ 'font-bold': bold, 'big': bigText, 'one-line': !noOneLine }">
        {{ hideKeyValue ? name : stringTable.word(name) }}
      </div>
      <div v-if="showDescription && description" class="description-text">{{ stringTable.word(description) }} </div>
      <!-- {{ type }} -->
      <!-- interlock condition -->
      <div v-if="isCondition">
        <div class="vertical-center condition-status">
          <div v-if="!['stat', 'mode'].includes(value.type)" class="status-name cond-lbl txt-jump-down">
            {{ stringTable.word(value.type) }}
          </div>
          <div class="status-name">{{ stringTable.word(value.condition) }}</div>
          <div class="status-name cond-val txt-jump-down">{{ displayCondValue(value) }}</div>
        </div>
      </div>

      <!-- interlock action -->
      <div v-if="deviceStatusList">
        <div v-for="(value, key) in deviceStatusList" :key="key">
          <div class="vertical-top device-status">
            <div>{{ displayDeviceStatusName(key) }}
            </div>
            <img class="status-img" v-if="key === 'fanstep' || key === 'vmode' || key === 'mode' || key === 'vamount'"
              :src="displayActionValue(key, value)" />
            <div v-else class="status-value">{{ displayActionValue(key, value) }}</div>
          </div>
        </div>
      </div>

    </div>
    <img v-if="showDelete" class="delete to-error" :src="ReiriIcons.delete" @click.stop="deleteTile" />
    <BtnSwitch v-else-if="showSwitch" class="switch" :default="switchValueCopy" />
    <div v-else-if="showPlus" class="plus-icon">+</div>
    <img v-else class="arrow" :src="ReiriIcons.chevron_right" />
  </div>
</template>

<style>
.cut-text {
  margin-right: 2px;
  /* overflow: scroll; */
  /* text-overflow: ellipsis;
  overflow: hidden !important; */
  /* height: 1.2em; */
  /* white-space: nowrap; */
}

.cut-text.disabled {
  text-overflow: initial;
  overflow: hidden !important;
  height: fit-content;
  white-space: initial;
}

.nav-horizontal.chevron .item-text {
  padding-right: 0;
}

.nav-horizontal .status-name {
  margin-right: 5px;
  color: var(--hint);
}

.nav-horizontal .status-img {
  margin-right: -3px;
}

.nav-horizontal .status-value {
  color: var(--hint);
}

.nav-horizontal .device-status {
  padding: 0px 4px;
  justify-content: space-between;
}

.nav-horizontal .device-status div:first-child {
  padding-right: 3px;
}

.nav-horizontal .content {
  align-items: center;
  flex: 1;
}

.nav-horizontal .icon {
  width: 28px;
  display: block;
  margin: 4px;
}

.nav-horizontal .item-text {
  font-size: 16px;
  padding: 0 6px;
  margin: 2px 0;
  /* line-height: 1.4; */
  /* overflow-x: scroll; */
  width: 100%;
}

.nav-horizontal .item-text.big {
  font-size: 20px;
}

.nav-horizontal .description-text {
  font-size: 14px;
  margin: -6px 6px 0 6px;
  color: grey;
}

.nav-horizontal .arrow,
.nav-horizontal .plus-icon,
.nav-horizontal .switch {
  margin-left: auto !important;
}

.nav-horizontal .switch {
  margin-right: 4px;
}

.nav-horizontal .plus-icon {
  margin-right: 6px;
}
</style>

<script>
import BtnSwitch from './BtnSwitch.vue'

export default {
  name: "NavHorizontal",
  props: {
    id: String,
    value: Object,
    icon: String,
    name: String,
    description: String,
    showDescription: Boolean,
    hideKeyValue: Boolean,
    showPlus: Boolean,
    isIconActiveColor: Boolean,
    showSwitch: Boolean,
    switchValue: Boolean,
    showDelete: Boolean,
    iconHelperValue: Object,
    deviceStatusList: Object,
    pointKey: String,
    commModule: Object,
    type: String,
    isCondition: Boolean,
    isAction: Boolean,
    index: Number,
    bold: Boolean,
    bigText: Boolean,
    verticalTop: Boolean,
    noOneLine: Boolean,
  },
  data() {
    return {
      switchValueCopy: this.switchValue,
      firstLoad: true,
      actionDisplayList: {
        stat: { on: 'on', off: 'off' }, off_timer: { on: 'enable', off: 'disable' }, av: {},
        rop_proh: { true: 'enable', false: 'disable' }, 'rmode_proh': { true: 'enable', false: 'disable' },
        rsp_proh: { true: 'enable', false: 'disable' }, 'rfan_proh': { true: 'enable', false: 'disable' },
        fanstep: {
          2: { L: this.ReiriIcons.fan2_1, H: this.ReiriIcons.fan2_2, A: this.ReiriIcons.fan2_auto },
          3: { L: this.ReiriIcons.fan3_1, M: this.ReiriIcons.fan3_2, H: this.ReiriIcons.fan3_3, A: this.ReiriIcons.fan3_auto },
          5: {
            L: this.ReiriIcons.fan5_1, LM: this.ReiriIcons.fan5_2, M: this.ReiriIcons.fan5_3, MH: this.ReiriIcons.fan5_4, H: this.ReiriIcons.fan5_4, A: this.ReiriIcons.fan5_auto
          }
        },
        vmode: {
          A: this.ReiriIcons.HRV_auto, HX: this.ReiriIcons.HRV_ex, S: this.ReiriIcons.HRV_bypass
        },
        mode: {
          C: this.ReiriIcons.cooling, H: this.ReiriIcons.sun, F: this.ReiriIcons.fan, D: this.ReiriIcons.dry, A: this.ReiriIcons.auto_default
        },
        vamount: {
          A: this.ReiriIcons.fan2_auto, L: this.ReiriIcons.fan2_1, H: this.ReiriIcons.fan2_2
        },
        backupActionDesc: {},
      }
    }
  },
  created() {
    if (this.showSwitch)
      if (!this.switchValueCopy)
        this.firstLoad = false
  },
  methods: {
    deleteTile() {
      this.$parent.deleteCondAction(this.index, this.isCondition, this.isAction)
    },
    displayUnit(targetKeyId) {
      const pointObj = this.commModule.point_list[targetKeyId]
      if (pointObj == null)
        return ''
      else if (Object.prototype.hasOwnProperty.call(pointObj, 'unit')) {
        return pointObj.unit == 'DEG' ? this.stringTable.word('DEG') : pointObj.unit
      } else if (pointObj.usage == 'ac') {
        return this.stringTable.word('DEG')
      } else {
        return ''
      }
    },
    displayValue(targetKeyId, value, isOfset) {
      const pointObj = this.commModule.point_list[targetKeyId]
      var result = ''
      if (pointObj == null)
        result = value
      else if (Object.prototype.hasOwnProperty.call(pointObj, 'unit')) {
        result = pointObj.unit == 'DEG' ? (isOfset ? this.tempConvert.tempDiffConvert(value) : this.tempConvert.tempConvert(value)) : value
      } else if (pointObj.usage == 'ac') {
        result = (isOfset ? this.tempConvert.tempDiffConvert(value) : this.tempConvert.tempConvert(value))
      }
      if (this.ConvertHelper.isIntegerWithoutDecimal(result))
        result = result.toFixed(1)
      return result
    },
    displayCondValue(value) {
      if (['value', 'rt'].includes(value.type)) {
        if (value.value.mp) {
          return (Object.prototype.hasOwnProperty.call(this.commModule.point_list, value.value.mp) ? this.commModule.point_list[value.value.mp].name : 'null') + ' + ' +
            this.displayValue(value.value.mp, value.value.ofset, true) + this.displayUnit(value.value.mp)
        } else
          return this.displayValue(value.mp, value.value.fix, false) + this.displayUnit(value.mp)
      } else if (value.type == 'error' || value.type == 'alert')
        return Object.values(value.value)[0] ? this.stringTable.word('occur') : this.stringTable.word('clear')
      else return this.convertToDecimal(this.stringTable.word(Object.values(value.value)[0]))
    },
    convertToDecimal(value) {
      // Check if the value is an integer
      if (Number.isInteger(value)) {
        // Convert it to a double with one decimal place string
        return value.toFixed(1)
      } else {
        // If it's not an integer, return it as-is
        return value;
      }
    },
    displayDeviceStatusName(value) {
      if (this.type == 'interlock')
        return this.stringTable.word('interlock')
      else if (this.type == 'schedule')
        return this.stringTable.word('schedule')
      if (value == 'exec')
        return this.stringTable.word("program")
      else
        return this.stringTable.word(value)
    },
    displayActionValue(key, value) {
      if (key === 'sp' || key === 'sb_csp' || key === 'sb_hsp') {
        return value == '--' ? value : `${this.tempConvert.spConvert(value).toFixed(1)}${this.stringTable.word('DEG')}`;
      } else if (['off_timer', 'rop_proh', 'rmode_proh', 'rsp_proh', 'rfan_proh'].includes(key)) {
        return this.stringTable.word(this.actionDisplayList[key][value]);
      } else if (key === 'fanstep') {
        if (this.type == 'point')
          return this.actionDisplayList[key][this.commModule.point_list[this.pointKey].fanstep_cap['S']][value]
        else // group
          return this.actionDisplayList[key][3][value]
      } else if (key === 'vmode' || key === 'mode' || key === 'vamount') {
        return this.actionDisplayList[key][value]
      } else if (key === 'hum_sp') {
        return `${value}%`;
      } else if (key === 'av') {
        return `${value}%`;
      } else if (key === 'rgbw') {
        return '0x' + value.toString(16).slice(0, -2).toUpperCase()
      } else if (key === 'threshold_red' || key === 'threshold_yellow') {
        if (this.displayDesc.type == 'temp' || this.displayDesc.type == 'Ac') {
          return `${this.tempConvert.spConvert(value)}${this.stringTable.word('DEG')}`;
        } else if (this.displayDesc.type == 'humi' || this.displayDesc.type == 'dehumi') {
          return `${value}%`;
        } else if (this.displayDesc.type == 'pm25') {
          return `${value}µg/m3`;
        } else if (this.displayDesc.type == 'co2') {
          return `${value}.0ppm`;
        } else if (this.displayDesc.type == 'tvoc') {
          return `${value}mg/m3`;
        }
      } else if (key === 'exec') {
        return (value) ? this.stringTable.word("execute") : this.stringTable.word("disable")
      } else if (key === 'enable') {
        return (value) ? this.stringTable.word("enable") : this.stringTable.word("disable")
      } else {
        return this.stringTable.word(value)
      }
    },
    changeSwitch() {
      if (!this.firstLoad) {
        this.$parent.switchClicked(this.id)
      }
      this.firstLoad = false
    },
    contentClicked() {
      if (this.showSwitch)
        this.$parent.frameSelected({ key: this.id, value: this.value })
      else if (this.isCondition)
        this.$parent.frameSelected({ key: this.id, value: this.value, isCond: this.isCondition, index: this.index })
      else if (this.isAction)
        this.$parent.frameSelected({ key: this.id, value: this.value, isAction: this.isAction, index: this.index })
    }

  },
  components: {
    BtnSwitch,
  }
}
</script>
