<template>
  <div class="pop-up action-of-day">
    <PopUpToast />
    <PopUpHead :details="details" :rightBtnTxt="rightBtnTxt" />
    <!-- {{ defActionDayList }}
    {{ details.edit.action }} -->
    <div class="body-content">
      <LoadingSpinner v-if="isLoading" />
      <div v-if="showBottom">
        <div class="time-holder vertical-center">
          <div class="time-lbl">{{ stringTable.word('time') }}</div>
          <SelectTime name="time" :minuteInterval="1" :selectValue="time" />
        </div>
        <!-- {{ details.edit }}
      {{ defActionDayList }} -->
        <div class="control-holder d-flex ">
          <div class="general-holder can-scroll">
            <div class="title font-bold">{{ stringTable.word('general') }}</div>
            <DroppableControl v-for="(value, key) in defActionDayList.general" :key="key" :actionValue="key"
              :detailsList="defActionDayList.general" />
          </div>
          <div class="ac-holder can-scroll">
            <div class="title font-bold">{{ stringTable.word('ac') }}</div>
            <DroppableControl v-for="(value, key) in defActionDayList.ac" :key="key" :actionValue="key"
              :detailsList="defActionDayList.ac" />
          </div>
          <div class="program-holder can-scroll">
            <div class="title font-bold">{{ stringTable.word('level') }}</div>
            <DroppableControl actionValue="value" :detailsList="defActionDayList.level" :showInput="true" />
            <div class="title font-bold">{{ stringTable.word('program') }}</div>
            <DroppableControl actionValue="interlock" :detailsList="defActionDayList.program" />
            <div class="title font-bold">{{ stringTable.word('scene') }}</div>
            <DroppableControl actionValue="execute" :detailsList="defActionDayList.scene" />
          </div>
          <div class="hrv-holder can-scroll">
            <div class="title font-bold">{{ stringTable.word('hrv') }}</div>
            <DroppableControl v-for="(value, key) in defActionDayList.venti" :key="key" :actionValue="key"
              :detailsList="defActionDayList.venti" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.pop-up.action-of-day .time-holder {
  width: fit-content;
  margin: 8px auto;
}

.pop-up.action-of-day .time-lbl {
  margin-right: 8px;
}

.pop-up.action-of-day .loading-container {
  margin-top: 170px !important;
}

.pop-up.action-of-day .control-holder {
  justify-content: space-evenly;
}

.pop-up.action-of-day .control-holder>div {
  width: 100%;
  height: 500px;
  overflow-y: scroll;
  padding: 0 6px;
}

.pop-up.action-of-day .control-holder>div:not(:last-child) {
  border-right: 2px solid var(--disable);
}

.pop-up.action-of-day .control-holder .title {
  text-align: center;
  margin-bottom: 6px;
}
</style>

<script>
import PopUpToast from "./PopUpToast.vue";
import PopUpHead from "./PopUpHead.vue";
import SelectTime from "./SelectTime.vue";
import DroppableControl from "./DroppableControl.vue";
import LoadingSpinner from './LoadingSpinner.vue'

export default {
  name: "PopUpActionOfDay",
  props: {
    commModule: Object,
    details: Object,
  },
  data() {
    return {
      toast: {},
      isLoading: false,
      showBottom: false,
      timer: null,
      pendingYesMethod: () => { },
      action: this.commModule.get_schedule.pattern,
      detailsCloned: this.details,
      time: '',
      rightBtnTxt: 'done',
      defActionDayList: {
        general: {
          stat: { value: 'off' },
          off_timer: { value: 'off' },
          notify: { value: false }
        },
        ac: {
          sp: { value: 24 }, fanstep: { value: 'L' }, mode: { value: 'C' },
          hum_sp: { value: 50 }, sb_csp: { value: 28 }, sb_hsp: { value: 18 },
          rop_proh: { value: 'disable' }, rsp_proh: { value: 'disable' },
          rmode_proh: { value: 'disable' }, rfan_proh: { value: 'disable' },
        },
        program: {
          interlock: { value: 'disable' },
        },
        scene: {
          execute: {},
        },
        level: {
          value: { value: 0 },
        },
        venti: {
          vmode: { value: 'A' }, vamount: { value: 'A' },
        }
      },
      finalActionMap: {
        notify: 'notify',
        value: 'av',
        interlock: 'enable',
        execute: 'exec'
      },
      firstLoad: true
    }
  },
  created() {
    this.isLoading = true
    this.startTimer()
    var edit = this.details.edit;
    if (edit) {
      this.time = edit.name;
      for (const defActionDayListKey in this.defActionDayList) {
        const eachList = this.defActionDayList[defActionDayListKey];
        for (const eachListKey in eachList) {
          var each = eachList[eachListKey];
          var mappedKey = this.getCtrlerMappedKey(eachListKey)
          if (Object.keys(edit.action).includes(mappedKey)) {
            each.toShow = true;
            if (each.value !== undefined || (defActionDayListKey == 'level' && eachListKey == 'value') || eachListKey == 'notify') {
              var value = edit.action[mappedKey];
              if (eachListKey == 'interlock' ||
                !Object.keys(this.finalActionMap).includes(eachListKey)) {
                if (value === false) value = 'disable';
                else if (value === true) value = 'enable';
              }
              each.value = value;
            }
          }
        }
      }
    }
  },
  watch: {
    'defActionDayList.level.value.value'(newValue) {
      if (!this.firstLoad) {
        if (this.ConvertHelper.notNumberOrIsFloat(newValue))
          this.toast = { text: 'invalid_input', isError: true }
      }
      this.firstLoad = false;
    }
  },
  methods: {
    startTimer() {
      if (this.timer)
        clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.isLoading = false
        this.showBottom = true
      }, 1);
    },
    getCtrlerMappedKey(key) {
      var mappedKey = this.finalActionMap[key];
      return mappedKey ? mappedKey : key;
    },
    changeTime(name, newTime) {
      this.time = newTime;
    },
    clickRightBtn() {
      var finalAction = {};
      for (const defActionDayListKey in this.defActionDayList) {
        const eachList = this.defActionDayList[defActionDayListKey];
        for (const eachListKey in eachList) {
          if (eachList[eachListKey].toShow) {
            var mappedKey = this.getCtrlerMappedKey(eachListKey);
            var value = eachList[eachListKey].value;
            if (value == 'disable') value = false;
            else if (value == 'enable') value = true;
            else if (mappedKey == 'exec') value = true;
            else if (mappedKey == 'av') {
              value = parseInt(value);
              if (!value) value = 0;
            }
            finalAction[mappedKey] = value;
          }
        }
      }
      this.detailsCloned.time = this.time;
      this.detailsCloned.action = finalAction;
      this.NavAssistant.hidePopUp();
    }
  },
  components: {
    PopUpToast,
    PopUpHead,
    SelectTime,
    DroppableControl,
    LoadingSpinner,
  }
}
</script>
