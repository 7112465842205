<template>
  <div class="schedule-program schedule-sub">
    <PopUpToast />
    <PopUpHead :details="{ name: cacheCloned.isEdit ? 'edit_sched' : 'add_sched' }"
      :leftBtnTxt="cacheCloned.isEdit ? 'delete' : ''" rightBtnTxt="save" :hideBorderBtm="true" />
    <div class="input-contain">
      <FrameInput varStr="programName" placeholder="name" />
    </div>
    <div class="sel-holder vertical-center">
      <div class="target-holder">
        <SelectDropDown :items="targetLi" :currentPoint="{}" title="sel_target" :plusIcon="true"
          checkedString="sch_target" />
        <div class="detail-holder reverse-frame can-scroll">
          <BarSchedule :items="targetLi" checkedString="sch_target" />
        </div>
      </div>
      <div class="week-holder">
        <SelectDropDown title="day_of_the_week" :plusIcon="true" :plusIconHidden="true" />
        <div class="detail-holder reverse-frame can-scroll">
          <BarSchedule :items="weekLi" :selectList="actionLi" />
        </div>
      </div>
      <div class="cal-holder">
        <SelectDropDown :items="calendarLi" :currentPoint="{}" title="sp_cal" :plusIcon="true"
          checkedString="sch_calendar" />
        <div class="detail-holder reverse-frame can-scroll">
          <BarSchedule :items="calendarLi" checkedString="sch_calendar" :selectList="actionLi" />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.schedule-program .input-contain {
  padding: 6px 8px;
  border-bottom: 2px solid var(--disable);
}

.schedule-program .sel-holder>div {
  width: 100%;
  margin: 0 3px;
}

.schedule-program .sel-holder {
  padding: 6px 0;
}

.schedule-program .sel-holder .detail-holder {
  font-size: .85em;
  padding: 5px;
  height: 440px;
  overflow-y: scroll;
  border-radius: 8px;
}

.schedule-program .dropdown-menu {
  max-height: 415px;
}

.schedule-program .dropdown-top {
  margin-top: 0 !important;
}

.schedule-program .select-option {
  width: 110px;
  margin: 0 0 0 auto;
  height: 30px !important;
}

.schedule-program .target-holder .bar-img-text .can-scroll {
  width: 185px;
}

.schedule-program .cal-holder .bar-img-text .can-scroll {
  width: 66px;
}
</style>

<script>
import PopUpToast from './PopUpToast.vue'
import PopUpHead from './PopUpHead.vue'
import FrameInput from './FrameInput.vue'
import SelectDropDown from './SelectDropDown.vue'
import BarSchedule from './BarSchedule.vue'

export default {
  name: "ScheduleProgram",
  props: {
    commModule: Object,
    cache: Object,
  },
  data() {
    return {
      toast: {},
      cacheCloned: this.cache,
      programName: '',
      targetLi: [],
      calendarLi: [],
      actionLi: []
    }
  },
  created() {
    this.loadCache();
  },
  mounted() {
  },
  watch: {
    cache() {
      this.cacheCloned = this.cache;
      this.loadCache();
    },
    'commModule.add_program'() {
      this.$parent.completedOper(true);
    },
    'commModule.modify_program'() {
      this.$parent.completedOper();
    },
    'commModule.delete_program'() {
      this.$parent.completedOper();
    }
  },
  methods: {
    completedOperation() {
      this.$parent.getProgram();
      this.$parent.closeRightScreen(true);
    },
    init() {
      this.programName = '';
      // this.targetLi = this.createLi('point', this.commModule.point_list);
      // this.targetLi = this.targetLi.concat(
      //   this.createLi('group', this.commModule.group_list));
      // this.targetLi = this.targetLi.concat(
      //   this.createLi('scene', this.commModule.scene_list));
      // this.targetLi = this.targetLi.concat(
      //   this.createLi('interlock', this.commModule.interlock_get_program.program));
      this.targetLi = this.MixList.get(['point', 'group', 'scene', 'interlock', 'schedule']);
      if (this.cacheCloned)
        this.targetLi = this.targetLi.filter(tar => tar.key_id != this.cacheCloned.patnId);

      this.calendarLi = this.MixList.get(['calendar']);

      this.actionLi = this.ConvertHelper.listToIdNameList(this.commModule.get_schedule.pattern);
      this.actionLi.unshift({ id: 'patn', name: this.stringTable.word('no_act') });
      this.actionLiMapped = this.actionLi.map(e => e.id);

      this.weekLi = [];
      this.ConvertHelper.getWeeksFull().forEach((week, id) => {
        this.weekLi.push({ key_id: `week${id}`, name: week, actionIndex: 0 });
      });
    },
    loadCache() {
      this.init();
      if (this.cacheCloned) {
        this.programName = this.cacheCloned.name;
        if (!this.cacheCloned.target) this.cacheCloned.target = [];
        this.cacheCloned.target.forEach(cacheTar => {
          this.targetLi.forEach(tar => {
            if (cacheTar == tar.key_id)
              tar.sch_target = true;
          });
        });
        for (const id in this.cacheCloned.action) {
          const action = this.cacheCloned.action[id];
          var actionIndex = this.actionLiMapped.indexOf(id);
          if (action.exec_dow)
            action.exec_dow.forEach(dow => {
              if (actionIndex >= 0 && this.weekLi[dow])
                this.weekLi[dow].actionIndex = actionIndex;
            });
          action.exec_cal.forEach(cal => {
            var calendar = this.calendarLi.find(calendar => calendar.key_id == cal);
            if (!calendar || actionIndex == -1) return;
            calendar.sch_calendar = true;
            calendar.actionIndex = actionIndex;
          });
        }
      }
      else {
        this.cacheCloned = {
          exec: false,
          target: [],
          action: {},
        }
      }
    },
    prev() {
      this.$parent.closeRightScreen();
    },
    createLiSel(loopLi, trueKey) {
      var res = [];
      for (const key in loopLi) {
        const item = loopLi[key];
        if (item[trueKey]) res.push(item);
      }
      return res;
    },
    getCheckedCalIds() {
      return this.calendarLi.filter(c => c.sch_calendar).map((c) => c.key_id)
    },
    clickedCheckBox(item) {
      if (!this.cacheCloned) this.cacheCloned = { action: {} };
      this.cacheCloned.target = [];
      this.targetLi.forEach(tar => {
        if (tar.sch_target)
          this.cacheCloned.target.push(tar.key_id);
      });
      var checkedCalIds = this.getCheckedCalIds();
      for (const key in this.cacheCloned.action) {
        this.cacheCloned.action[key].exec_cal =
          this.cacheCloned.action[key].exec_cal.filter(id =>
            id != item.key_id && checkedCalIds.includes(id))
      }
    },
    assignActionExec(actionExec, execKey, loopLi) {
      var checkedCalIds = this.getCheckedCalIds();
      loopLi.forEach((item, index) => {
        const action = this.actionLi[item.actionIndex];
        if (action && action.id != 'patn') {
          if (!actionExec[action.id]) {
            actionExec[action.id] = { exec_cal: [], exec_dow: [] }
          }
          var itemToAdd;
          if (execKey == 'exec_dow') itemToAdd = index;
          else if (execKey == 'exec_cal' && checkedCalIds.includes(item.key_id))
            itemToAdd = item.key_id;
          actionExec[action.id][execKey].push(itemToAdd);
        }
      });
    },
    changeOption(newActionId, key_id) { //index
      var actionExec = {};
      var actionIndex = this.actionLiMapped.indexOf(newActionId);
      if (key_id.includes('week')) {
        this.weekLi[key_id.replace('week', '')].actionIndex = actionIndex;
      }
      else if (key_id.includes('cal')) {
        var calendarSel = this.calendarLi.find(calendar => calendar.key_id == key_id);
        calendarSel.actionIndex = actionIndex;
      }
      this.assignActionExec(actionExec, 'exec_dow', this.weekLi);
      this.assignActionExec(actionExec, 'exec_cal', this.calendarLi);
      if (!this.cacheCloned) this.cacheCloned = { action: {} };
      this.cacheCloned.action = actionExec;
    },
    clickLeftBtn() {
      this.toast = {
        text: 'conf_del',
        pendingYesMethod: () => {
          this.commModule.delSchedule(this.cacheCloned.patnId);
        }
      }
    },
    clickRightBtn() {
      var text;
      var no_name = this.ConvertHelper.isEmptyString(this.programName);
      var no_target = this.cacheCloned.target.length == 0;
      var no_action = Object.keys(this.cacheCloned.action).length == 0;
      if (no_name || no_target || no_action) {
        this.toast_type = '';
        if (no_name) text = 'no_name';
        else if (no_target) text = 'no_target';
        else if (no_action) text = 'no_action';
        this.toast = { text, isError: true, }
      } else {
        this.toast = {
          text: 'conf_save',
          pendingYesMethod: () => {
            var dataInput = {
              name: this.programName,
              exec: this.cacheCloned.exec,
              target: this.cacheCloned.target,
              action: this.cacheCloned.action,
            }
            if (this.cacheCloned.isEdit) {
              this.commModule.editSchedule({
                [this.cacheCloned.patnId]: dataInput
              });
            } else
              this.commModule.addSchedule(dataInput);
          }
        }
      }
    }
  },
  computed: {

  },
  components: {
    PopUpToast,
    PopUpHead,
    FrameInput,
    SelectDropDown,
    BarSchedule,
  }
}
</script>
