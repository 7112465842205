<template>
	<NoList v-if="!mainList || mainList.length == 0" name="no_fav_group" />
	<div v-else class="fav-page bef-tile-section">
		<NavPagination direction="left" />
		<div class="tile-section">
			<TileGroup v-for="(key, index) in slicedList" :key="index" :detailsKey="key"
				:details="commModule.group_list[key]" />
		</div>
		<NavPagination direction="right" />
	</div>
</template>

<script>
import TileGroup from './TileGroup.vue'
import NavPagination from './NavPagination.vue'
import NoList from './NoList.vue'

export default {
	name: "PageFavGroup",
	props: {
		commModule: Object,
	},
	data() {
		return {
			page: 1,
			itemPerPage: 30,
			slicedList: [],
			showNoList: false
		}
	},
	created() {
		this.NavAssistant.push(this);
	},
	watch: {
	},
	methods: {
	},
	computed: {
		mainList() {
			var fav = this.commModule.get_favorite;
			if (fav) {
				var res = [];
				fav.group.forEach(grpId => {
					var group = this.commModule.group_list[grpId];
					var vaildPoint = group.mp.filter(pId => this.commModule.point_list[pId]);
					if (vaildPoint.length > 0) res.push(grpId);
				});
				return res;
			} return [];
		}
	},
	components: {
		TileGroup,
		NavPagination,
		NoList
	},
}
</script>