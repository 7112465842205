<template>
  <div class="pop-up term-and-cond">
    <PopUpHead :details="details" :showIcon="false" :leftArr="true" :hideCross="true" />
    <div style="height: 538px;" class=" can-scroll">

      <p class="title">Terms of Use for Daikin Reiri for Office Solution</p>
      <p class="subtitle">1.Definitons and Interpretation</p>

      <div class="info">
        1.1 The following expressions shall have the following meanings:
      </div>
      <div class="info">
        <p class="paragraph"><b>"Confidential Information" </b>means any business, marketing,
          technical,
          scientific or other information including but not limited to all copies, excerpts, modifications,
          translations, enhancements and adaptations of all such information, as well as technical information,
          including but not limited to source code, object code, composition and operating method of equipment or
          system, documentation, manuals, product development and processes, product plans, technical know-how,
          technical data, performance data, product specifications and other information of a technical nature
          whether
          or not contained or incorporated, in any manner, form or format, in drawings, photographs, memoranda,
          operational documents, models, prototypes, designs, quality control and test charts, manuals and
          methods;
        </p>
      </div>
      <div class="info">
        <p class="paragraph"><b>"Daikin" </b> means Daikin Holdings Singapore Pte. Ltd. (Company
          Registration No.: 198905453G), a company incorporated in Singapore with its registered address at 10 Ang
          Mo
          Kio Industrial Park 2, Singapore 569501; </p>
      </div>
      <div class="info">
        <p class="paragraph"><b>"ReiriOffice App" </b> means the ReiriOffice mobile application
          through
          which Users can access ReiriOffice services;</p>
      </div>
      <div class="info">
        <p class="paragraph"><b>"ReiriOffice Services" </b>means the intelligent home automation
          services
          provided by Daikin via the ReiriOffice mobile application as well as any services and accessories which
          are
          associated with such intelligent home automation services;</p>
      </div>
      <div class="info">
        <p class="paragraph"><b>"Intellectual Property" </b>means any and all rights in any invention,
          discovery, improvement, utility, model, copyrightable work, industrial design or mask work, algorithm,
          data
          structure, trade secrets or know-how, Confidential Information, or any idea having commercial value and
          shall include any patent, copyright, registered designed trademark, trade dress, trade name, domain
          name, or
          other marks that serve to identify and distinguish goods or services as associated with or relating to
          the
          Products;</p>
      </div>
      <div class="info">
        <p class="paragraph"><b>"User" </b>means any person or entity using the ReiriOffice App.</p>
      </div>
      <div class="info">
        <p class="paragraph">1.2 The headings in these Terms are inserted for convenience only and
          shall
          be ignored in construing these Terms.</p>
      </div>

      <p class="subtitle">2. Terms of Use</p>


      <div class="info">
        <p class="paragraph">2.1 These Terms specify terms and conditions for the ReiriOffice App. By
          downloading, browsing, accessing or using the ReiriOffice App, the User agrees to be bound by these
          Terms.
          Daikin reserves the right to amend these Terms at any time without any notice. If the User disagrees
          with
          any of these Terms, the User must immediately discontinue the access to the ReiriOffice App and the use
          of
          the services offered on the ReiriOffice App. For avoidance of doubt, the User’s continued use of the
          ReiriOffice App will constitute acceptance of these Terms, as may be amended from time to time.</p>
      </div>
      <div class="info">
        <p class="paragraph">2.2 Daikin has the right to modify or change the paid services provided,
          the
          charging criteria, charging method, service charges and service terms (if any) from time to time. Daikin
          may
          start to charge User certain fees either now or in the future. If the User refuses to pay such fees,
          Daikin
          may not be able to provide such services after the new charge begins.</p>
      </div>
      <div class="info">
        <p class="paragraph">2.3 These Terms shall be governed by and construed in accordance with the
          laws of Singapore.</p>
      </div>


      <p class="subtitle">3. Intellectual Property and Trade Marks</p>

      <div class="info">
        <p class="paragraph">All trademarks, product names and company names or logos cited anywhere
          in
          the application are the Intellectual Property of Daikin. The User will not obtain any intellectual
          property
          right in respect of the services in the ReiriOffice App. All Intellectual Property rights arising in the
          course of accepting Daikin’s services, including but not limited to copyrights, patents, patent
          applications, trademarks, trademark applications, software, knowhow, technological data and trade
          secrets,
          shall belong to Daikin.</p>
      </div>

      <p class="subtitle">4. User’s Obligations</p>

      <div class="info">
        <p class="paragraph">4.1 The User agrees to use the ReiriOffice App in accordance with the
          Terms
          and the applicable laws. </p>
      </div>
      <div class="info">
        <p class="paragraph">4.2 The User shall, in accordance with these Terms: </p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">4.2.1 ensure that they have sufficient quality and coverage of the
          Internet signal; </p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">4.2.2 in the event of using the ReiriOffice Services with mobile
          devices,
          comply with the requirements of the relevant app store which provides the ReiriOffice App (including but
          not
          limited to the Apple App Store, Google Play Store, BlackBerry OS App Stores, MyApp store, Huawei
          AppGallery,
          and Xiaomi App Store); </p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">4.2.3 be fully responsible for the security of digital content of the
          User, including in particular the use of anti-virus software and the use of unique passwords with a high
          degree of complexity and taking all reasonable measures to ensure the confidentiality of these
          passwords.
          The User should ensure that the ReiriOffice App is updated, and the User acknowledges that failure to
          update
          may cause inconsistencies between the current software of Daikin and the User’s software; </p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">4.2.4 not use third party devices (which are not within the
          recommended
          means of use by Daikin) including cryptographic technologies. The User selects such third party devices
          at
          his/her own risk and bears full and sole responsibility for the consequences of such actions, including
          in
          particular the consequences of a failure to fulfil the obligation of the protection of personal data and
          other information whose protection is required by law;</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">4.2.5 not use the ReiriOffice App to transfer or transmit any illegal
          content, in particular the content violating third party rights, personal rights or copyrights, or to
          use
          the ReiriOffice App to record, store and transfer content and to process personal data unlawfully;</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">4.2.6 not use the ReiriOffice App contrary to its purpose and proper
          operation requirements;</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">4.2.7 not access or attempt to access through the ReiriOffice App
          other
          services provided by Daikin or third parties in a manner that is unlawful or contrary to their terms of
          use;
        </p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">4.2.8 not modify, prepare derivative works of, or reverse engineer,
          the
          ReiriOffice App or ReiriOffice Services;</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">4.2.9 not knowingly or negligently use, the ReiriOffice App or
          ReiriOffice
          Services in a way that abuses or disrupts Daikin’s networks, user accounts or the ReiriOffice Services,
          or
          in a manner that causes disruption of computer and telecommunications systems of Daikin and/or third
          parties, in particular, to carry out DDoS attacks (distributed denial of service);</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">4.2.10 not transmit through the ReiriOffice App any harassing,
          indecent,
          obscene, fraudulent, or unlawful material;</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">4.2.11 not market, or resell the ReiriOffice App or ReiriOffice
          Services
          to any third party;</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">4.2.12 not use the ReiriOffice App or ReiriOffice Services in
          violation of
          applicable laws or regulations, whether in the Republic of Singapore or elsewhere;</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">4.2.13 not use the ReiriOffice App to send unauthorised advertising,
          or
          spam;</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">4.2.14 not use the ReiriOffice App to harvest, collect, or gather user
          data without their consent.</p>
      </div>
      <div class="info">
        <p class="paragraph">4.3 The User further agrees and acknowledges that he/she will comply with
          all
          laws, statutes and regulations applicable or relating to ReiriOffice App or the ReiriOffice Services,
          while
          using the same, whether in Singapore or in any other relevant jurisdiction.</p>
      </div>

      <p class="subtitle">5. Limitation of Liability</p>

      <div class="info">
        <p class="paragraph">5.1 Notwithstanding anything to the contrary in these Terms, Daikin and
          its
          officers, directors, shareholders, employees, affiliates, agents, licensors, licensees, or associates
          shall
          not be liable for any claims for losses, indirect or consequential losses, loss of profits or loss of
          business suffered by the User arising out of or in consequence of or in connection with the ReiriOffice
          App
          and the ReiriOffice Services. Access to the ReiriOffice App and the ReiriOffice Services is voluntary
          and
          use of the ReiriOffice App and the ReiriOffice Services is at the User’s sole risk. Daikin shall have no
          responsibility for any loss (whether direct, consequential, punitive, special or otherwise) arising out
          of
          use of information obtained from Daikin’s website/ Mobile App or other websites/Mobile App linked to
          Daikin’s website.
          and the applicable laws. </p>
      </div>
      <div class="info">
        <p class="paragraph">5.2 Information provided by Daikin through the ReiriOffice Application,
          is
          provided on an "as is" and "as and when available" basis to Users, and no warranty or fitness for
          purpose is
          implied or expressed. Any liability which may arise as a result of the use of the ReiriOffice
          Application or
          any information is excluded and limited, to the fullest extent permitted by law. </p>
      </div>
      <div class="info">
        <p class="paragraph">5.3 Daikin does not guarantee that the application will be uninterrupted
          or
          error-free. The User will not hold Daikin liable for any unauthorised disclosure, loss or destruction of
          the
          User’s personal data arising from cyber-attacks, such as hacking, spyware and viruses. Daikin will not
          be
          held responsible for the quality of the promotions (if any) or information listed in the ReiriOffice
          Application. The User is encouraged and advised to check and confirm the accuracy of such information
          and
          the proper use of such information. Daikin shall not be liable for any losses that result from the
          User’s
          use and reliance on the application, inability to use the application, mistakes, errors, omissions,
          application problems and failures of operation (including but not limited to viruses, deletion of files
          and
          delays); or the use of any offer or information available via the application.</p>
      </div>

      <div class="info">
        <p class="paragraph">5.4 The use of ReiriOffice Application may require the User to pay fees
          to
          third parties, such as mobile operators, internet service providers or others. Daikin is not liable for
          any
          such third-party fees imposed on the User. By accepting the Terms, the User agrees to bear an
          independent
          liability for payments to third parties.</p>
      </div>
      <div class="info">
        <p class="paragraph">5.5 Daikin shall not be liable for any losses, damages or claims in
          connection with the use or access to the ReiriOffice App and the ReiriOffice Services as a result of any
          hardware or software defect, malfunction or misuse or mishandling caused directly or indirectly by the
          User
          or the User’s devices, including but not limited to:</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">5.5.1 any mishandling of the User’s devices while using the
          ReiriOffice
          App and the ReiriOffice Services, including any misuse of, tempering with, alteration of, modification
          of,
          prolonged and excessive use of, the User’s devices;</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">5.5.2 the User’s failure to strictly follow Daikin’s instruction with
          regards to application, installation, use, service or maintenance while using the ReiriOffice App and
          the
          ReiriOffice Services;</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">5.5.3 the User’s failure to strictly comply with the applicable codes,
          standards, ordinances or industrial specifications while using the ReiriOffice App and the ReiriOffice
          Services; or</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">5.5.4 any unauthorized installation or use of unapproved parts or
          unproved
          alterations, or any alterations made by unauthorized persons to the User’s device.</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">5.5.5 operating or keeping the device in extremely adverse conditions
          such
          as extreme ambient temperature, humidity, or dust environment.</p>
      </div>
      <div class="info">
        <p class="paragraph">5.6 In order to protect the Daikin’s business development and right to
          make
          adjustments, Daikin has the right to modify or suspend the service at any time without notice to the
          User
          and without any liability to the User or any third party.</p>
      </div>


      <p class="subtitle">6. GOVERNING LAW</p>

      <div class="info">
        <p class="paragraph">6.1 Governing Law and Dispute Resolution: These Terms of Use are governed
          by
          the laws of Singapore. Any dispute arising from the User’s use of our App shall be subject to the laws
          of
          Singapore, notwithstanding that ReiriOffice App and ReiriOffice Services can be accessed from
          jurisdictions
          outside of Singapore.</p>
      </div>
      <p class="title">Privacy Policy for Daikin Reiri Solution</p>
      <p class="subtitle">1.Definitons and Interpretation</p>
      <div class="info">
        <p class="paragraph">1.1 The following expressions shall have the following meanings:'
        </p>
      </div>
      <div class="info">
        <p class="paragraph"><b>"Confidential Information" </b>means any business, marketing,
          technical,
          scientific or other information including but not limited to all copies, excerpts, modifications,
          translations, enhancements and adaptations of all such information, as well as technical information,
          including but not limited to source code, object code, composition and operating method of equipment or
          system, documentation, manuals, product development and processes, product plans, technical know-how,
          technical data, performance data, product specifications and other information of a technical nature
          whether
          or not contained or incorporated, in any manner, form or format, in drawings, photographs, memoranda,
          operational documents, models, prototypes, designs, quality control and test charts, manuals and
          methods;
        </p>
      </div>
      <div class="info">
        <p class="paragraph"><b>"Daikin" </b>means Daikin Holdings Singapore Pte. Ltd. (Company
          Registration No.: 198905453G), a company incorporated in Singapore with its registered address at 10 Ang
          Mo
          Kio Industrial Park 2, Singapore 569501;
        </p>
      </div>
      <div class="info">
        <p class="paragraph"><b>"Reiri App" </b>means the ReiriOffice/ReiriOffice mobile application
          through which Users can access ReiriOffice/ReiriOffice services;
        </p>
      </div>
      <div class="info">
        <p class="paragraph"><b>"Reiri Services" </b>means the intelligent automation services
          provided by
          Daikin via the ReiriOffice/ReiriOffice mobile application as well as any services and accessories which
          are
          associated with such intelligent automation services;
        </p>
      </div>
      <div class="info">
        <p class="paragraph"><b>"User" </b>means any person or entity using the Reiri App.
        </p>
      </div>
      <div class="info">
        <p class="paragraph"><b>"Telemetry Data" </b>means Device Information, Operation Information,
          Control Functions, and Error and Alert Information.
        </p>
      </div>
      <div class="info">
        <p class="paragraph"><b>"Information" </b>means device usage patterns, device setting, device
          error alert/ type.
        </p>
      </div>
      <div class="info">
        <p class="paragraph"><b>"User Login Account Information" </b>means User login name and
          password.
        </p>
      </div>
      <div class="info">
        <p class="paragraph">1.2 The headings in these Terms are inserted for convenience only and
          shall
          be ignored in construing these Terms.
        </p>
      </div>
      <p class="subtitle">2.Privacy Policy</p>
      <div class="info">
        <p class="paragraph">2.1 These Terms specify Privacy Policy for the Reiri App. By downloading,
          browsing, accessing or using the Reiri App, the User agrees to be bound by these Terms. Daikin reserves
          the
          right to amend these Terms at any time without any notice. If the User disagrees with any of these
          Terms,
          the User must immediately discontinue the access to the Reiri App and the use of the services offered on
          the
          Reiri App. For avoidance of doubt, the User’s continued use of the Reiri App will constitute acceptance
          of
          these Terms, as may be amended from time to time.
        </p>
      </div>
      <div class="info">
        <p class="paragraph">2.2 Telemetry Data will be collected and stored on cloud for data
          visualization and machine learning purpose to improve user experiences.
        </p>
      </div>
      <div class="info">
        <p class="paragraph">2.3 These Terms shall be governed by and construed in accordance with the
          laws of Singapore.
        </p>
      </div>
      <p class="subtitle">3.Personal Data</p>
      <div class="info">
        <p class="paragraph">3.1 Daikin shall take measures aimed at continuous development and
          improvement of the Reiri App and the Reiri Services and retains the right to add new, modify and delete,
          or
          change the scope of the available existing Reiri Services with or without notice to the User, and, to
          the
          full extent permitted by law, Daikin shall not be liable to the User or to any third party should Daikin
          exercise such rights.
        </p>
      </div>
      <div class="info">
        <p class="paragraph">3.2 By providing the User’s personal data to Daikin, the User consents to
          Daikin’s collection, use and disclosure of the User’s personal data for the purposes set out below. If
          Daikin wishes to use, collect or disclose the User’s personal data for any other purposes not listed
          below,
          Daikin will seek the User’s consent beforehand.
        </p>
      </div>
      <div class="info">
        <p class="paragraph">3.3 If Daikin in its sole and absolute discretion determines that the
          User
          has violated the provisions of the Terms, Daikin shall, without notice to the User, (i) have the right
          to
          suspend the provision of Reiri Services to the User or the use of the Reiri App for an indefinite
          period,
          (ii) terminate the User’s account and/or (iii) take any other action as necessary to protect the
          security or
          operation of the Reiri Services.
        </p>
      </div>
      <div class="info">
        <p class="paragraph">3.4 When the User creates a Daikin account, or otherwise provides Daikin
          with
          the User’s personal information through the Reiri App, the personal information Daikin collects may
          include
          the following:
        </p>
      </div>

      <div class="info">
        <p class="term-and-cond subParagraph">3.4.1 Name</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">3.4.2 Delivery Address</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">3.4.3 Type of Residence/ Office</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">3.4.4 Email Address</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">3.4.5 Contact Number</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">3.4.6 Mobile Number</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">3.4.7 Date of Birth</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">3.4.8 Gender</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">3.4.9 Information about the User’s interaction and usage with our App
          and/or services</p>
      </div>
      <div class="info">
        <p class="paragraph">3.5 User Login Account Information is only used for authenticating the
          login
          request, and User Login Account Information will not be collected to the cloud.
        </p>
      </div>
      <div class="info">
        <p class="paragraph">3.6 If the User provides personal information of any third party to
          Daikin,
          Daikin assumes that the User has obtained the required consent from the relevant third party to share
          and
          transfer his/her personal information to Daikin.
        </p>
      </div>
      <div class="info">
        <p class="paragraph">3.7 If the User signs up with Daikin using the User’s social media
          account or
          links the User’s Daikin account to the User’s social media account or uses certain other Daikin social
          media
          features, Daikin may access information about the User which the User has voluntarily provided under the
          User’s social media provider in accordance with the provider's policies and Daikin will manage the
          User’s
          personal data which Daikin have collected in accordance with Daikin’s privacy policy.
        </p>
      </div>
      <div class="info">
        <p class="paragraph">3.8 The personal information that Daikin collects from the User will be
          used,
          or shared with third parties (including related companies, third party service providers, and third
          party
          sellers), for some or all of the following purposes:
        </p>
      </div>

      <div class="info">
        <p class="term-and-cond subParagraph">3.8.1 To facilitate the User’s use of our services and/or access to
          the
          Reiri App and the Reiri Services;</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">3.8.2 To respond to the User’s inquiry or request, where necessary;
        </p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">3.8.3 To inform or update the User of other products or services
          available, where the User consents to be contacted for such purposes;</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">3.8.4 To update the User on changes to our applications and services;
        </p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">3.8.5 To maintain the User’s membership account with Daikin;</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">3.8.6 For legal purposes; and</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">3.8.7 Any other purpose(s) which Daikin may inform the User of in
          writing
          from time to time, but for which Daikin will seek the User’s separate consent.</p>
      </div>
      <div class="info">
        <p class="paragraph">3.9 When the User registers an account with Daikin or otherwise provides
          Daikin with the User’s personal information through the Reiri App, Daikin shall also have the right to
          use
          the User’s personal information to send the User marketing and/or promotional materials about our or
          third
          party sellers’ products and services from time to time.</p>
      </div>
      <div class="info">
        <p class="paragraph">3.10 Daikin may share the User’s personal information with third parties
          and
          Daikin’s affiliates for the abovementioned purposes, specifically, completing a transaction with or for
          the
          User, managing the User’s account and Daikin’s relationship with the User, marketing and fulfilling any
          legal or regulatory requirements and requests as deemed desirable or necessary by Daikin.</p>
      </div>
      <div class="info">
        <p class="paragraph">3.11 Daikin or our authorized service providers may use cookies, web
          beacons,
          and other similar technologies for storing information to help provide the User with a better, faster,
          safer
          and personalized experience when the User uses the Reiri App and the Reiri Services. When the User visit
          the
          Reiri App, Daikin’s company servers will automatically record information that the User’s browser sends
          whenever the User uses the App.</p>
      </div>
      <div class="info">
        <p class="paragraph">3.12 This data may include –</p>
      </div>

      <div class="info">
        <p class="term-and-cond subParagraph">3.12.1 Browser type;</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">3.12.2 Webpage the User was visiting before the User came to our Reiri
          App;</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">3.12.3 The pages within the Reiri App which the User has visited; and
        </p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">3.12.4 The time spent on those pages, items and information searched
          for
          on the Reiri App , access times and dates, and other statistics.</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">3.12.5 Mobile phone related information: Hardware-based identifiers
          (IMEI/OAID, MAC address, Android ID), phone models, Operating system information (version, language
          country
          or region, App store version, screen size and resolution, CPU and display related information)</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">3.12.6 Network information SSID, BSSID, MAC address of Wi-Fi, Wi-Fi
          password</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">3.12.7 Smart device information connected to Reiri for Home: MAC
          address
          of the device, and device ID</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">3.12.8 Statistical data generated by using the smart devices such as
          device operation time, device status, and malfunction information.</p>
      </div>
      <div class="info">
        <p class="paragraph">3.13 Cookies are small text files (typically made up of letters and
          numbers)
          placed in the memory of the User’s browser or device when the User visit a website or view a message.
          Cookies allow Daikin to recognize a particular device or browser and help Daikin to personalize the
          content
          to match the User’s preferred interests more quickly, and to make our Services and Reiri App more
          convenient
          and useful to you.</p>
      </div>
      <div class="info">
        <p class="paragraph">3.14 Web beacons are small graphic images that shall be included on our
          Service and the Reiri App. They allow Daikin to count users who have viewed these pages so that Daikin
          can
          better understand the User’s preference and interests.</p>
      </div>
      <p class="subtitle">4.Personal Data Protection Act</p>
      <div class="info">
        <p class="paragraph">4.1 For the purposes of these Terms, any capitalised terms used in these
          Terms which are not defined here but are defined in the Personal Data Protection Act 2012 (“PDPA”) shall
          have the same meaning as in the PDPA.</p>
      </div>
      <div class="info">
        <p class="paragraph">4.2 Daikin agrees and shall procure that its employees, representatives,
          agents and subcontractors shall:</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">4.2.1 operate at all times within the requirements of all relevant
          data
          protection laws, including the PDPA, and any requirements set out in any advisory or other guidelines
          issued
          from time to time by the Personal Data Protection Commission, and not to act in a way which would cause
          Daikin to be in breach of its obligations under such legislation;</p>
      </div>
      <div class="info">
        <p class="term-and-cond subParagraph">4.2.2 only disclose any personal data in compliance with the PDPA;</p>
      </div>

      <div class="info">
        <p class="term-and-cond subParagraph">4.2.3 protect personal data in its possession or under its control by
          making reasonable security arrangements to prevent unauthorised access, collection, use, disclosure,
          copying, modification, disposal or similar risks;</p>
      </div>

      <div class="info">
        <p class="term-and-cond subParagraph">4.2.4 ensure that its employees, agents and sub-contractors who may
          receive or have access to any of personal data are aware of the obligations specified under these Terms
          and
          agree to abide by the same.</p>
      </div>
      <div class="info">
        <p class="paragraph">4.3 Without prejudice to the generality of the foregoing, Daikin and its
          associates and their authorised representatives shall have the right to use the personal data in
          accordance
          with the PDPA including but not limited to internal data analytics.</p>
      </div>
      <div class="info">
        <p class="paragraph">4.4 The User acknowledges and agrees that Daikin has the right to
          disclose
          the User’s personal information (if any) to any legal, regulatory, governmental, tax, law enforcement or
          other authorities or the relevant right owners, if Daikin has reasonable grounds to believe that
          disclosure
          of the User’s personal information is necessary or desirable for the purpose of meeting any obligations,
          requirements or arrangements, whether voluntary or mandatory and/or as a result of cooperating with an
          order, an investigation and/or a request of any nature by such parties. To the extent permissible by
          applicable law, the User agrees not to take any action and/or waive his/ her rights to take any action
          against Daikin for the disclosure of his/ her personal information in these circumstances.</p>
      </div>

      <p class="subtitle">5.WITHDRAWAL OF CONSENT</p>
      <div class="info">
        <p class="paragraph">5.1. User may communicate the objection to Daikin’s continued use and/or
          disclosure of the User’s personal information for any of the purposes and in the manner as stated above
          at
          any time by contacting Daikin at address below.</p>
      </div>
      <div class="info">
        <p class="paragraph">5.2. Please note that if the User communicate the objection to Daikin’s
          use,
          continued use and/or disclosure of the User’s personal information for the purposes and in the manner as
          stated above, depending on the nature of the objection, Daikin may not be in a position to continue to
          provide the products or services to the User or perform on any contract Daikin has with the User.
          Daikin’s
          legal rights and remedies are expressly reserved in such event.</p>
      </div>
      <div class="info">
        <p class="paragraph">5.3. Please further note that should the User communicate the objection
          to
          Daikin’s use, continued use and/or disclosure of the User’s personal information, it is likely to take
          some
          time before such objection is taken note of and implemented.</p>
      </div>

      <p class="subtitle">6.GOVERNING LAW</p>
      <div class="info">
        <p class="paragraph">6.1 Governing Law and Dispute Resolution: These Privacy terms are
          governed by
          the laws of Singapore. Any dispute arising from the User’s use of our App or data privacy shall be
          subject
          to the laws of Singapore, notwithstanding that our website, Reiri App and Reiri Services can be accessed
          from jurisdictions outside of Singapore.</p>
      </div>
      <p class="subtitle">7.CONTACT US</p>
      <div class="info">
        <p class="paragraph">7.1. If the User has any questions or comments on this Privacy Policy,
          please
          contact us at the following address</p>
      </div>
      <div class="info">
        <p class="paragraph">ADDRESS:</p>
      </div>
      <div class="info">
        <p class="paragraph">DAIKIN HOLDINGS SINGAPORE PTE. LTD.</p>
      </div>
      <div class="info">
        <p class="paragraph">10 Ang Mo Kio Industrial Park 2, Singapore 569501</p>
      </div>
    </div>

  </div>
</template>

<style>
.term-and-cond .title {
  font-size: 130%;
  text-align: center;
  font-family: 'Poppins-bold';
  margin: 6px 0px 0px 0px;
}

.term-and-cond .subtitle {
  margin: 5px 10px 0px 20px;
  font-size: large;
  font-family: 'Poppins-bold';
}

.term-and-cond .info {
  margin: 10px 10px 0px 20px;
  display: flex;
}

.term-and-cond .paragraph {
  margin: 0px 0px 0px 0px;
}

.term-and-cond .subParagraph {
  margin: 0px 0px 0px 25px;
}
</style>

<script>
import PopUpHead from './PopUpHead.vue';

export default {
  name: "TermAndCondition",
  data() {
    return {
      details: { name: "term_cond" }
    };
  },
  components: { PopUpHead }
}
</script>