<template>
  <PopUpHead :details="details" :hideBorderBtm="true" :hideCross="true" rightBtnTxt="save" />
  <div class="report settings can-scroll">
    <div class="frame-holder">
      <div class="month-date-holder">
        <div class="title-heading font-bold">{{ stringTable.word('start_date_month') }}</div>
        <div class="reverse-frame">
          <div class="month-holder vertical-center">
            <div class="lbl">{{ stringTable.word('month') }}</div>
            <SelectOption :frame="true" :selectList="allMonths" :defaultSelectIndex="monthIndex" />
          </div>
          <div class="date-holder vertical-center">
            <div class="lbl">{{ stringTable.word('date') }}</div>
            <SelectOption :frame="true" :selectList="days_from_month" :defaultSelectIndex="dateIndex" />
          </div>
        </div>
      </div>
      <div class="energy-holder" v-if="avlFunc.energy_graph">
        <div class="title-heading font-bold">{{ stringTable.word('energy_meter_cat') }}</div>
        <div class="reverse-frame">
          <BarRepSettings class="energy-water-holder" title="show_cat"
            :mainSwitch="reportCategories.is_show_energy_categories" :categorys="reportCategories.energy" />
          <BarRepSettings v-if="showEnergyMeterCatSub" class="energy-water-holder" title="energy_gen"
            :mainSwitch="reportCategories.is_energy_generator_enabled"
            :points="getDisplayPoints('energy', 'energy_gen')" />
        </div>
      </div>
      <div class="water-holder" v-if="avlFunc.energy_graph && showEnergyMeterCatSub">
        <div class="title-heading font-bold">{{ stringTable.word('water_man') }}</div>
        <div class="reverse-frame">
          <BarRepSettings class="energy-water-holder" title="water_consumption"
            :mainSwitch="reportCategories.is_water_consumption_enabled"
            :points="getDisplayPoints('water', 'water_con')" />
          <BarRepSettings class="energy-water-holder" title="water_recycle"
            :mainSwitch="reportCategories.is_recycled_water_enabled" :points="getDisplayPoints('water', 'water_rec')" />
        </div>
      </div>
      <div v-if="avlFunc.ppd" class="ppd-holder">
        <div class="title-heading font-bold">{{ stringTable.word('ppd_setting') }}</div>
        <div class="reverse-frame">
          <div class="frame">
            <NavHorizontal class="settings-owner" name="owner_settings" @click="clickSettings('owner')" />
            <NavHorizontal class="settings-unit-price" name="unit_price_settings" @click="clickSettings('unit_price')" />
          </div>
        </div>
      </div>
      <div v-if="avlFunc.ppd_billing" class="tenant-holder">
        <NavHorizontal name="tenant_settings" :showPlus="true" :bold="true" @click="clickSettings('tenant')" />
        <div class="reverse-frame">
          <div v-for="points, tenant in tenants" :key="tenant" class="tenant-name-holder frame vertical-center">
            <div class="naming" @click="clickSettings('tenant', { points: points.points, tenant })" role="button">
              {{ tenant }}
            </div>
            <img :src="ReiriIcons.delete" class="btn-delete to-error" @click="deleteTenant(tenant)" role="button" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.report.settings .pop-up-head {
  height: 45px;
}

.report.settings {
  padding: 5px 10px;
  max-height: 90%;
  overflow-y: scroll;
}

.report.settings .frame-holder {
  font-size: 14px;
}

.report.settings .naming {
  padding: 0 8px;
  width: 100%;
}

.report.settings .tenant-name-holder .naming {
  padding-left: 0;
}

.report.settings .main-title {
  position: relative;
  padding-bottom: 10px;
}

.report.settings .main-title .lbl {
  font-size: 20px;
  margin: 0 auto;
}

.report.settings .main-title .save-btn {
  position: absolute;
  right: 0;
  font-size: 14px;
  color: var(--active);
}

.settings .title-heading {
  margin: 0 6px 4px 6px;
  font-size: 1.1em;
}

.report.settings .tenant-holder .item-text {
  font-size: 16px !important;
}

.report.settings .reverse-frame>.frame {
  padding: 6px 12px;
}

.settings .reverse-frame {
  padding: 4px 12px;
  min-height: 50px;
}

.report.settings .energy-holder .reverse-frame,
.report.settings .water-holder .reverse-frame,
.report.settings .tenant-holder .reverse-frame {
  padding: 4px 12px;
}

.settings .frame-holder>div {
  margin-bottom: 8px;
}

.settings .reverse-frame>div {
  margin: 6px 0;
}

.report.settings .ppd-holder .frame {
  padding: 6px;
}

.report.settings .date-holder {
  margin-top: 4px;
}

.report.settings select {
  width: 140px;
  height: 30px !important;
}

.report.settings select,
.report.settings .switch {
  margin-left: auto;
}

.report.settings .settings-owner {
  margin-bottom: 6px;
}

.report.settings .btn-delete {
  margin-left: auto;
}
</style>

<script>
import SelectOption from './SelectOption.vue'
import BarRepSettings from './BarRepSettings.vue'
import NavHorizontal from './NavHorizontal.vue'
import PopUpHead from './PopUpHead.vue'

export default {
  name: "ReportSettings",
  props: {
    commModule: Object,
  },
  data() {
    return {
      details: { name: "report_settings" },
      cache: {},
      allMonths: this.ConvertHelper.getMonthsFull(),
      startMonth: '',
      startDate: '',
      monthIndex: 0,
      dateIndex: 0,
      showEnergyMeterCatSub: false,
      settingsData: {},
      reportCategories: {},
      pointsEnergy: {},
      pointsWater: {},
      pointsEnergyGen: [],
      pointsWaterCon: [],
      pointsWaterRec: [],
      tenants: this.commModule.load_tenant,
      savedResponse: [],
      avlFunc: this.commModule.account.available_functions
    }
  },
  mounted() {
    this.NavAssistant.push(this);
    this.settingsData = this.commModule.load_report_setting.data;
    this.monthIndex = this.settingsData.start_month;
    this.dateIndex = this.settingsData.start_day - 1;

    this.reportCategories = this.settingsData.categories;

    this.pointsEnergy = {};
    this.pointsWater = {};
    for (const point_id in this.commModule.point_list) {
      var point = this.commModule.point_list[point_id];
      if (point) {
        if (point.usage == 'emeter')
          this.pointsEnergy[point_id] = point;
        else if (point.usage == 'wmeter')
          this.pointsWater[point_id] = point;
      }
    }
  },
  watch: {
    cache() {
      var popUpName = this.cache.name;
      if (!this.cache.data) return;
      var nameInput = this.cache.data.name;
      if (!nameInput) return;
      if (popUpName == 'add_category') {
        var categoryNames = [];
        for (const id in this.reportCategories.energy) {
          categoryNames.push(this.reportCategories.energy[id].name);
        }
        if (categoryNames.includes(this.cache.data.name))
          this.$parent.toast = { text: 'set_fail', isError: true }
        else {
          var energyIds = Object.keys(this.reportCategories.energy);
          var newEnergyIds = energyIds.length > 0 ? parseInt(energyIds[energyIds.length - 1]) + 1 : 100;
          this.reportCategories.energy[newEnergyIds] = this.cache.data;
        }
      }
      else if (popUpName.includes('tenant')) {
        if (popUpName == 'tenant_settings') {
          if (Object.keys(this.tenants).includes(nameInput)) {
            this.$parent.toast = { text: 'set_fail', isError: true }
          } else this.tenants[nameInput] = { points: this.cache.data.points };
        }
        else if (popUpName == 'edit_tenant') {
          var tenantOld = this.cache.data.nameOri;
          if (!Object.keys(this.tenants).includes(nameInput)) {
            this.tenants = this.ConvertHelper.renameObjKey(this.tenants, tenantOld, nameInput);
          }
        }
      }
    },
    'commModule.save_report_setting'(res) { this.saveResponse(res) },
    'commModule.save_owner_info'(res) { this.saveResponse(res) },
    'commModule.save_price_info'(res) { this.saveResponse(res) },
    'commModule.save_holiday_calendar'(res) { this.saveResponse(res) },
    'commModule.save_tenant'(res) { this.saveResponse(res) },
  },
  methods: {
    saveResponse(res) {
      this.savedResponse.push(res);
      var complete = this.savedResponse.length == 5;
      this.$parent.isLoading = !complete;
      if (!complete) return;
      this.$parent.toast = { text: 'succeed' }
    },
    getDisplayEnergyPoints(dataPoints) {
      var assignedPoints = [];
      for (const energy_id in this.reportCategories.energy) {
        assignedPoints.push(this.reportCategories.energy[energy_id].points);
      }
      assignedPoints = [].concat(...assignedPoints);
      if (!dataPoints) return assignedPoints;
      dataPoints = dataPoints.filter(pId => this.pointsEnergy[pId]);

      this.getInitCatPoints('energy_generator').forEach(p => assignedPoints.push(p));

      for (const point_id in this.pointsEnergy) {
        this.pointsEnergy[point_id].add_edit_energy_cat = false;
      }

      dataPoints.forEach(point_id => {
        this.pointsEnergy[point_id].add_edit_energy_cat = true;
      });
      var displayDevices = [];
      for (const point_id in this.pointsEnergy) {
        var includeAssignPoints = assignedPoints.includes(point_id);
        var isDetailPoints = this.pointsEnergy[point_id].add_edit_energy_cat;
        if (!includeAssignPoints) {
          this.pointsEnergy[point_id].add_edit_energy_cat = false;
        }
        if (!includeAssignPoints || isDetailPoints)
          displayDevices.push(this.pointsEnergy[point_id]);
      }
      return displayDevices;
    },
    changeOption(newOption) {
      if (this.allMonths.includes(newOption)) {
        this.startMonth = newOption;
        this.settingsData.start_month = this.allMonths.indexOf(newOption);
        var days = this.days_from_month;
        var lastDayFromLi = days[days.length - 1];
        if (this.dateIndex + 1 > lastDayFromLi) {
          this.dateIndex = lastDayFromLi - 1;
        }
      } else {
        this.startDate = newOption;
        this.settingsData.start_day = newOption;
        this.dateIndex = newOption - 1;
      }
    },
    changeSwitch(title, switchName, onSwitch) {
      if (title == 'show_cat') {
        if (switchName == 'main') {
          this.showEnergyMeterCatSub = onSwitch;
          this.reportCategories.is_show_energy_categories = onSwitch;
        }
        else this.reportCategories.energy[switchName].is_enabled = onSwitch;
      }
      else {
        const keyMapping = {
          energy_gen: 'is_energy_generator_enabled',
          water_consumption: 'is_water_consumption_enabled',
          water_recycle: 'is_recycled_water_enabled'
        }
        if (Object.keys(keyMapping).includes(title))
          this.reportCategories[keyMapping[title]] = onSwitch;
      }
    },
    getDefaultData() {
      return { name: "", points: [], is_enabled: true };
    },
    clickEnergyCategory(category) {
      const detailName = category ? 'edit_category' : 'add_category';
      var data = category;
      if (!data) data = this.getDefaultData();
      this.NavAssistant.showPopUp({
        name: "PopUpRepSetPoints",
        details: {
          name: detailName,
          data,
          checkedString: 'add_edit_energy_cat',
          repCatEnergy: this.reportCategories.energy,
          displayDevices: this.getDisplayEnergyPoints(data.points)
        },
      });
    },
    clickedCheckBox(newPoint) {
      if (newPoint.usage == 'emeter') {
        if (newPoint[this.points_map.energy_gen.checkedString])
          this.reportCategories.energy_generator.points.push(newPoint.key_id);
        else this.ConvertHelper.removeArrayItems(this.reportCategories.energy_generator.points, newPoint.key_id);
      }
      else if (newPoint.usage == 'wmeter') {
        ['water_con', 'water_rec'].forEach(key => {
          if (newPoint[this.points_map[key].checkedString])
            this.points_map[key].primary.push(newPoint.key_id);
          else this.ConvertHelper.removeArrayItems(this.points_map[key].primary, newPoint.key_id);
        });
      }
    },
    deleteCategory(categoryId) {
      this.$parent.toast = {
        text: 'conf_del',
        pendingYesMethod: () => {
          delete this.reportCategories.energy[categoryId];
        }
      }
    },
    getDisplayPoints(type, mapKey) {
      var res = [];
      var point;
      var pointsMap = this.points_map[mapKey];
      const mainPointMap = {
        energy: this.pointsEnergy,
        water: this.pointsWater,
      }
      var mainPoint = mainPointMap[type];
      if (!pointsMap.secondary) return [];
      for (const point_id in mainPoint) {
        point = mainPoint[point_id];
        if (point) {
          if (!pointsMap.secondary.includes(point.key_id)) {
            point[pointsMap.checkedString] =
              pointsMap.primary ? pointsMap.primary.includes(point.key_id) : false;
            res.push(point);
          }
        }
      }
      return res;
    },
    getInitCatPoints(key) {
      if (!this.reportCategories[key]) this.reportCategories[key] = { points: [] };
      if (!this.reportCategories[key].points) this.reportCategories[key].points = [];
      this.reportCategories[key].points = this.reportCategories[key].points.filter(pId => this.commModule.point_list[pId])
      return this.reportCategories[key].points;
    },
    clickRightBtn() {
      this.$parent.isLoading = true;
      this.savedResponse = [];
      var price = this.commModule.load_price_info.zone;
      for (const day in price) {
        for (const zone in price[day]) {
          var newPrice = parseFloat(price[day][zone].price[0]);
          price[day][zone].price[0] = newPrice ? newPrice : 0;
        }
      }
      this.commModule.saveReportSetting(this.settingsData);
      this.commModule.saveOwnerInfo(this.commModule.load_owner_info);
      this.commModule.savePriceInfo(this.commModule.load_price_info);
      this.commModule.saveHolidayCal(this.commModule.load_holiday_calendar);
      this.commModule.saveTenant(this.tenants);
    },
    clickSettings(type, value) {
      const popUpDetailMap = {
        owner: { popUpName: 'PopUpOwnerSet', headName: 'owner_settings' },
        unit_price: { popUpName: 'PopUpUnitPriceSet', headName: 'unit_price_settings' },
        tenant: { popUpName: 'PopUpRepSetPoints', headName: value ? 'edit_tenant' : 'add_tenant' },
      }
      const popUpDetail = popUpDetailMap[type];
      var details = { name: popUpDetail.headName };
      if (type == 'tenant') {
        // details.displayDevices = this.ConvertHelper.filterObjByKey(
        //   this.commModule.point_list, 'usage', 'ac');
        var displayDevices = {};
        var ppdPoints = this.commModule.ppd_point_list;
        ppdPoints.forEach(ppdPoint => {
          var ppdPointObj = this.commModule.point_list[ppdPoint];
          if (ppdPointObj) displayDevices[ppdPoint] = ppdPointObj;
        });
        details.displayDevices = displayDevices;

        details.checkedString = 'add_edit_tenant';
        details.assignCheckString = true;
        if (value) {
          details.data = {
            name: value.tenant,
            nameOri: value.tenant,
            points: value.points,
            is_enabled: true
          }
        }
        else {
          details.data = this.getDefaultData();
        }
      }
      this.NavAssistant.showPopUp({
        name: popUpDetail.popUpName,
        details,
      });
    },
    deleteTenant(tenant) {
      this.$parent.toast = {
        text: 'conf_del',
        pendingYesMethod: () => { delete this.tenants[tenant]; }
      }
    }
  },
  computed: {
    days_from_month() {
      if (!this.startMonth) return [];
      const fixedYear = 2023;
      const monthIndex = this.allMonths.indexOf(this.startMonth);
      var numOfDays = new Date(fixedYear, monthIndex + 1, 0).getDate();
      //      console.log(monthIndex, numOfDays)
      return Array.from({ length: numOfDays }, (_, i) => i + 1);
    },
    points_map() {
      var waterConPoints = this.getInitCatPoints('water_consumption');
      var waterRecPoints = this.getInitCatPoints('recycled_water');
      return {
        energy_gen: { primary: this.getInitCatPoints('energy_generator'), secondary: this.getDisplayEnergyPoints(), checkedString: 'checked_energy_gen' },
        water_con: { primary: waterConPoints, secondary: waterRecPoints, checkedString: 'checked_water_consumption' },
        water_rec: { primary: waterRecPoints, secondary: waterConPoints, checkedString: 'checked_water_recycle' }
      }
    },
  },
  components: {
    SelectOption,
    BarRepSettings,
    NavHorizontal,
    PopUpHead,
  },
}
</script>
