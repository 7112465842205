<template>
  <div v-if="details" class="pop-up device-detail history-filter">
    <PopUpHead :details="nameDetails" :showPrevPopup="true" :leftArr="true" :hideCross="true" :wholeScreen="true"
      :isSamePageHide="true" />
    <div class="main-content can-scroll">
      <!-- {{ details }} -->
      <!-- Type -->
      <div class="dropdown">
        <div class="filter-box frame" data-bs-toggle="dropdown" data-bs-display="static" data-bs-auto-close="outside">
          <div class="vertical-center filter-type">
            <div class="left-content">
              <div>{{ stringTable.word("type") }}</div>
              <div v-if="typeSelectedList.length == 0" class="selected-list">-</div>
              <div class="selected-list" v-for="(item, index) in typeSelectedList" :key="item">{{ stringTable.word(item)
              }}{{
  typeSelectedList.length - index > 1 ? "&nbsp;|&nbsp;" : "" }}</div>
            </div>
            <img style="margin-left: auto;" :src="ReiriIcons.chevron_right" />
          </div>
        </div>
        <SelectDropDown :items="details.typeList" checkedString="typeChecked" :hideIcon="true"
          :showStringTableValue="true" :invisible="true" />
      </div>

      <!-- Action -->
      <div class="dropdown show-dropdown-up">
        <div class="filter-box frame" data-bs-toggle="dropdown" data-bs-display="static" data-bs-auto-close="outside">
          <div class="vertical-center filter-type">
            <div class="left-content">
              <div>{{ stringTable.word("action") }}</div>
              <div v-if="actionSelectedList.length == 0" class="selected-list">-</div>
              <!-- {{ actionSelectedList }} -->
              <div class="selected-list" v-for="(item, index) in actionSelectedList" :key="item">{{ stringTable.word(item)
              }}{{
  actionSelectedList.length - index > 1 ? "&nbsp;|&nbsp;" : "" }}</div>
            </div>
            <img style="margin-left: auto;" :src="ReiriIcons.chevron_right" />
          </div>
        </div>
        <SelectDropDown :items="details.actionList" checkedString="actionChecked" :hideIcon="true"
          :showStringTableValue="true" :invisible="true" />
      </div>

      <!-- Target -->
      <div class="dropdown show-dropdown-up">
        <div class="filter-box frame " data-bs-toggle="dropdown" data-bs-display="static" data-bs-auto-close="outside">
          <div class="vertical-center filter-type">
            <div class="left-content">
              <div>{{ stringTable.word("target") }}</div>
              <div v-if="targetSelectedList.length == 0" class="selected-list">-</div>
              <div class="selected-list" v-for="(item, index) in targetSelectedList" :key="item">{{
                commModule.point_list[item] ? commModule.point_list[item].name : item }}{{
    targetSelectedList.length - index > 1 ? "&nbsp;|&nbsp;" : "" }}</div>
            </div>
            <img style="margin-left: auto;" :src="ReiriIcons.chevron_right" />
          </div>
        </div>
        <SelectDropDown :items="details.targetList" checkedString="targetChecked" :hideIcon="true"
          :showStringTableValue="false" :invisible="true" isTarget="true" :commModule="commModule" />
      </div>


      <!-- Operator -->
      <div class="dropdown show-dropdown-up more">
        <div class="filter-box frame" data-bs-toggle="dropdown" data-bs-display="static" data-bs-auto-close="outside">
          <div class="vertical-center filter-type">
            <div class="left-content">
              <div>{{ stringTable.word("operator") }}</div>
              <div v-if="operatorSelectedList.length == 0" class="selected-list">-</div>
              <div class="selected-list" v-for="(item, index) in operatorSelectedList" :key="item">{{ item }}{{
                operatorSelectedList.length - index > 1 ? "&nbsp;|&nbsp;" : "" }}</div>
            </div>
            <img style="margin-left: auto;" :src="ReiriIcons.chevron_right" />
          </div>
        </div>
        <SelectDropDown :items="details.operatorList" checkedString="operatorChecked" :hideIcon="true"
          :showStringTableValue="true" :invisible="true" />
      </div>

    </div>
  </div>
</template>

<style>
.history-filter .show-dropdown-up .dropdown-menu {
  margin-top: -180px;
}

.history-filter .show-dropdown-up.more .dropdown-menu {
  margin-top: -300px;
}

.history-filter .select-dropdown-holder .dropdown-menu {
  margin-left: 550px;
  width: 400px;
}

.history-filter .select-dropdown-holder {
  margin-left: auto;
}

input[type="checkbox"]:checked {
  background-color: #25bcf5;
  border-color: #25bcf5;
}

.filter-type .selected-list {
  color: grey;
  display: inline-block;
}

.option-frame {
  margin-top: 10px;
}

.grey-bgcolor {
  background-color: var(--disable);
}

.history-filter .choose-filter-btn {
  margin-left: auto;
  width: 43px;
  padding: 5px;
  align-items: center;
}

.history-filter .reverse {
  transform: rotate(180deg);
}

.history-filter .option-box {
  padding-left: 10px;
  height: 50px;
}

.history-filter .form-check-input {
  margin-right: 5px;
  height: 20px;
  width: 20px;
}

.history-filter .bars-check-boxes .one-line {
  width: 300px;
}

.history-filter .filter-box {
  margin: 20px 30px 10px 30px;
  display: block;
  padding: 15px 20px;
}
</style>

<script>
import PopUpHead from "./PopUpHead.vue";
import SelectDropDown from './SelectDropDown.vue'

export default {
  name: "PopUpHistoryFilterDropDown",
  data() {
    return {
      nameDetails: { name: "hist_filter" },
      showOptionType: false,
      showOptionAction: false,
      showOptionTarget: false,
      showOptionOperator: false,
      typeReverse: false,
      actionReverse: false,
      targetReverse: false,
      operatorReverse: false,
      deviceNameList: [],

      typeSelectedList: [],
      actionSelectedList: [],
      targetSelectedList: [],
      operatorSelectedList: [],

      selectedFilters: { 'action': [], 'type': [], 'target': [], 'operator': [] },
      typeNameList: [],
    }
  },
  props: {
    commModule: Object,
    details: Object
  },
  created() {
  },
  watch: {
  },
  computed: {
  },
  methods: {
    clickRightBtn() {
      this.resetFilter()
      this.typeSelectedList = []
      this.actionSelectedList = []
      this.targetSelectedList = []
      this.operatorSelectedList = []
      this.selectedFilters = { 'action': [], 'type': [], 'target': [], 'operator': [] }
    },
    resetFilter() {
      for (const key in this.details) {
        if (Array.isArray(this.details[key])) {
          this.details[key].forEach(item => {
            if (Object.prototype.hasOwnProperty.call(item, 'typeChecked')) {
              item.typeChecked = false;
            } else if (Object.prototype.hasOwnProperty.call(item, 'actionChecked')) {
              item.actionChecked = false;
            } else if (Object.prototype.hasOwnProperty.call(item, 'targetChecked')) {
              item.targetChecked = false;
            } else if (Object.prototype.hasOwnProperty.call(item, 'operatorChecked')) {
              item.operatorChecked = false;
            }
          });
        }
      }
    },
    clickedCheckBox(dev, isOVerMax, checkString) {
      switch (checkString) {
        case 'typeChecked':
          this.updateTypeSelectedList()
          break;
        case 'actionChecked':
          this.updateActionSelectedList()
          break;
        case 'targetChecked':
          this.updateTargetSelectedList()
          break;
        case 'operatorChecked':
          this.updateOperatorSelectedList()
          break;
        default:
      }
    },
    updateTypeSelectedList() {
      this.typeSelectedList = []
      this.details.typeList.forEach(type => {
        if (type.typeChecked)
          this.typeSelectedList.push(type.name)
      }
      )
      this.selectedFilters.type = this.typeSelectedList
    },
    updateActionSelectedList() {
      this.actionSelectedList = []
      this.details.actionList.forEach(type => {
        if (type.actionChecked)
          this.actionSelectedList.push(type.name)
      }
      )
      this.selectedFilters.action = this.actionSelectedList
    },
    updateTargetSelectedList() {
      this.targetSelectedList = []
      this.details.targetList.forEach(type => {
        if (type.targetChecked)
          this.targetSelectedList.push(type.name)
      }
      )
      this.selectedFilters.target = this.targetSelectedList
    },
    updateOperatorSelectedList() {
      this.operatorSelectedList = []
      this.details.operatorList.forEach(type => {
        if (type.operatorChecked)
          this.operatorSelectedList.push(type.name)
      }
      )
      this.selectedFilters.operator = this.operatorSelectedList
    },
    back() {
      this.$emit('clicked', this.selectedFilters)
      this.$parent.showHistory = true
    }
  },
  components: {
    PopUpHead,
    SelectDropDown,
  }
}
</script>