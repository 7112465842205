// user account object

export class Account {
	constructor(info) {
		this.ac_class = info['class'];
		this.auth = info['auth'];
		this.available_functions = info['functions'];
		this.temp_unit = info['temp_unit'];
		this.favorite = info['favorite'];
	}
}
