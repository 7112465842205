<template>
  <div v-if="details" class="pop-up history-filter edit-action">
    <PopUpHead :details="head_detail" rightBtnTxt="done" :isSamePageHide="true" :leftArr="true" :hideCross="true"
      :wholeScreen="true" />
    <div class="action-title">
    </div>
    <div v-if="currentPoint" class="main-content can-scroll">
      {{ isNewAction ? stringTable.word("select_device") : stringTable.word("dev") }}
      <div>
        <SelectDropDown v-if="isNewAction" class="new-title" mainLabel="select_device" :items="allowAddPointList"
          :hideCheckBox="true" :isCloseOutside="true" :currentPoint="currentPoint" />
        <div v-else class="point-title frame">
          <img :src="IconsHelper.iconMain(commModule.point_list[actionDetails.pointId])" class="point-icon" />
          {{ commModule.point_list[actionDetails.pointId].name }}
        </div>
      </div>
      <DroppableControl v-for="(value, key) in loop_action_list" :key="key" :actionValue="key"
        :currentPoint="currentPoint" :detailsList="updatedSceneActionList" />
    </div>
  </div>
</template>

<style>
.edit-action .save-btn {
  right: 80px;
}

.edit-action .point-title {
  margin-top: 10px;
  margin-bottom: 35px;
  padding: 10px 14px;
}

.edit-action .new-title {
  margin-top: 10px;
  margin-bottom: 35px;
}

.edit-action .point-icon {
  margin-right: 10px;
}

.edit-action .main-content {
  padding: 10px 30px;
  height: 535px;
}

.edit-action .bar-droppable .bottom-slider {
  padding-left: 23px;
  padding-right: 23px;
}
</style>

<script>
import DroppableControl from "./DroppableControl.vue";
import SelectDropDown from './SelectDropDown.vue';
import PopUpHead from './PopUpHead.vue'

export default {
  name: "SceneEditAction",
  data() {
    return {
      nameDetails: { name: "edit_action" },
      defaultSceneActionList: {
        Switch: { stat: { value: 'off' }, off_timer: { value: 'off' } },
        Level: { av: { value: 0 } },
        LevelSw: { stat: { value: 'off' }, off_timer: { value: 'off' }, av: { value: 0 } },
        Ac: {
          stat: { value: 'off' }, off_timer: { value: 'off' }, mode: { value: 'C' },
          sp: { value: 24 }, fanstep: { value: 'L' }, sb_csp: { value: 28 }, sb_hsp: { value: 18 },
          rc_proh: {
            value: [],
            option: {
              rop_proh: { value: false, name: 'stat' }, rmode_proh: { value: false, name: 'mode' },
              rsp_proh: { value: false, name: 'sp' }, rfan_proh: { value: false, name: 'fanstep' }
            }
          }
        },
        Hrv: {
          stat: { value: 'off' }, off_timer: { value: 'off' }, vmode: { value: 'A' }, vamount: { value: 'A' }, rc_proh: {
            value: [],
            option: {
              rop_proh: { value: false, name: 'stat' }
            }
          }
        },
        Rgbw: { stat: { value: 'off' }, off_timer: { value: 'off' }, rgbw: { value: 0 } },
        Shutter: { shutter: { value: 'close' } },
        Lock: { lock: { value: 'close' } },
        Dehumi: {
          stat: { value: 'off' }, off_timer: { value: 'off' }, fanstep: { value: 'L' },
          hum_sp: { value: 50 }
        }
      },
      remoteProhibitionList: [],
      updatedSceneActionList: {},
      pointIdNameList: [],
      currentPoint: {},
      allowAddPointList: []
    }
  },
  props: {
    commModule: Object,
    details: Object,
    actionDetails: Object,
    showLast: Boolean,
    isNewAction: Boolean,
  },
  created() {
    this.clonedActionDetails = this.actionDetails
    if (!this.isNewAction) {
      this.currentPoint = this.commModule.point_list[this.actionDetails.pointId]

      if (this.currentPoint.type == 'Hrv') {
        this.remoteProhibitionList = ['rop_proh']
      } else
        this.remoteProhibitionList = ['rop_proh', 'rmode_proh', 'rsp_proh', 'rfan_proh']
    }
    this.allowAddPointList = { ...this.commModule.point_list }
    this.generateAllowAddPointList()
    this.generateSceneActionDetails()
  },
  watch: {
    currentPoint() {
      if (this.isNewAction) {
        // this.generateSceneActionDetails()
        if (this.currentPoint.type == 'Hrv') {
          this.remoteProhibitionList = ['rop_proh']
        } else
          this.remoteProhibitionList = ['rop_proh', 'rmode_proh', 'rsp_proh', 'rfan_proh']
      }
    }
  },
  computed: {
    head_detail() {
      var name = this.isNewAction ? this.stringTable.word("add_action") : this.stringTable.word(this.nameDetails.name)
      return { name }
    },
    loop_action_list() {
      var list = this.updatedSceneActionList[this.currentPoint.type];
      for (const key in list) {
        const isAdvancedKey = !['stat', 'fanstep'].includes(key);
        const isProhKey = ['mode', 'sp', 'hum_sp'].includes(key);
        if (
          (key == 'mode' && this.commModule.mode_proh) ||
          (key == 'sp' && this.commModule.sp_proh) ||
          (key == 'hum_sp' && this.commModule.sp_proh) ||
          (!isProhKey && isAdvancedKey && !this.commModule.isAdmin)
        )
          delete list[key];
      }
      return list;
    }
  },
  methods: {
    clickRightBtn() {
      this.clonedActionDetails.action = this.filterToShowProperties(this.updatedSceneActionList[this.currentPoint.type])
      this.$emit('updatedActionDetails', this.clonedActionDetails);
      this.back()
    },
    filterToShowProperties(input) {
      const filteredObject = {};
      for (const key in input) {
        if (key == 'rc_proh' && input[key].toShow) {
          this.remoteProhibitionList.forEach(key => {
            filteredObject[key] = input['rc_proh'].option[key].value
          })
        } else
          if (Object.prototype.hasOwnProperty.call(input, key) && input[key].toShow) {
            filteredObject[key] = input[key].value;
          }
      }
      return filteredObject;
    },
    generateAllowAddPointList() {
      if (this.details.action) {
        Object.keys(this.details.action).forEach(key => {
          delete this.allowAddPointList[key];
        });
      }
      this.allowAddPointList = Object.keys(this.allowAddPointList)
        .filter(key => Object.keys(this.defaultSceneActionList).includes(this.allowAddPointList[key].type))
        .map(key => this.allowAddPointList[key]);

      if (this.isNewAction) {
        this.currentPoint = this.allowAddPointList[0]
        // this.currentPoint = this.commModule.point_list["dta1:1-00002"]
        this.clonedActionDetails = { 'pointId': this.currentPoint ? this.currentPoint.key_id : '', 'action': {} }
      }
    },
    changeOption(newPoint) {
      if (newPoint) {
        this.currentPoint = newPoint;
        this.clonedActionDetails = { 'pointId': this.currentPoint.key_id, 'action': {} }
      }
      this.generateSceneActionDetails();
    },
    generatePointIdNameList() {
      this.pointIdNameList = Object.keys(this.allowAddPointList).map(key => ({
        'id': this.allowAddPointList[key]['key_id'],
        'name': this.allowAddPointList[key]['name']
      }));
    },
    generateSceneActionDetails() {
      if (!this.currentPoint) return;
      const pointType = this.currentPoint.type // switch, level, ac ...
      this.updatedSceneActionList = JSON.parse(JSON.stringify(this.defaultSceneActionList))

      for (const key in this.clonedActionDetails.action) {
        if (this.remoteProhibitionList.includes(key)) {
          if (this.clonedActionDetails.action[key]) {
            if (pointType == 'Hrv') {
              this.updatedSceneActionList['Hrv']['rc_proh'].value.push(this.updatedSceneActionList['Hrv']['rc_proh'].option[key].name)
            }
            else
              this.updatedSceneActionList['Ac']['rc_proh'].value.push(this.updatedSceneActionList['Ac']['rc_proh'].option[key].name)
          }
          this.updatedSceneActionList[pointType]['rc_proh'].toShow = true
          this.updatedSceneActionList[pointType]['rc_proh'].option[key].value = this.clonedActionDetails.action[key]
          this.updatedSceneActionList[pointType]['rc_proh'].option[key].toShow = true
        } else {
          this.updatedSceneActionList[pointType][key].value = this.clonedActionDetails.action[key]
          this.updatedSceneActionList[pointType][key].toShow = true
        }
      }
      // console.log(this.currentPoint)
      // pointType == 'Ac'
      if (!this.currentPoint.rc_proh_cap)
        delete this.updatedSceneActionList[pointType].rc_proh;
      if (!this.currentPoint.sp_cap)
        ['sp', 'sb_csp', 'sb_hsp'].forEach(key => delete this.updatedSceneActionList[pointType][key]);
      if (this.currentPoint.fanstep_cap && this.currentPoint.fanstep_cap.S == 1)
        delete this.updatedSceneActionList[pointType].fanstep;
    },
    back() {
      this.$parent.showSceneEditAction = false
    },
  },
  components: {
    DroppableControl,
    SelectDropDown,
    PopUpHead,
  }
}
</script>