<template>
  <div class="pop-up user device-setting group-setting system-setting">
    <div class="content-holder">
      <div class="left-content nav-hori-holde">
        <PopUpHead :details="details" :hideBorderBtm="true" :leftArr="true" :small="true" :plus="true" />
        <div>
          <div class="nav-list" ref="navListScroll">
            <div class="frame">
              <div v-for="key in slicedList" :key="key">
                <NavHorizontal v-if="groupObj[key]" :icon="IconsHelper.iconMain(groupObj[key])" :name="groupObj[key].name"
                  :isIconActiveColor="false" @click="groupSelected({ key, value: groupObj[key] })" />
              </div>
            </div>
          </div>
          <div class="vertical-center">
            <NavPagination direction="up" :expandHori="true" />
            <NavPagination direction="down" :expandHori="true" />
          </div>
        </div>
      </div>
      <div class="right-frame">
        <PopUpToast />
        <LoadingSpinner v-if="isLoading" :topSpacing="true" />
        <PopUpHead v-else-if="showEdit && !isLoading" :details="head_detail" :leftBtnTxt="!isRegister ? 'delete' : ''"
          :rightBtnTxt="'save'" :hideBorderBtm="true" />
        <div class="edit-section " v-show="groupDetails != '' && showEdit">
          <!-- {{ groupDetails }} -->
          <!-- {{ groupNameList }} -->
          <!-- {{ groupName }} -->
          <div class="frame main-icon-holder">
            <img class="icon" :src="IconsHelper.iconMain(groupDetails)" />
            <div class="icon-edit">
              <img :src="ReiriIcons.edit_2" @click="iconSelectionClicked" class="to-white" />
            </div>
          </div>
          <!-- name -->
          <FrameInput v-if="groupDetails.hasOwnProperty('name')" varStr="groupDetails" varStr2="name"
            placeholder="name" />
          <!-- select device title -->
          <div class="mail-addr">
            <div class="vertical-center">
              <SelectDropDown class="select-device" title="select_device" :items="clonePointList" :plusIcon=true
                checkedString="stdUserChecked" />
            </div>
          </div>
          <!-- devices -->
          <div class="info-box frame devices-box can-scroll" v-if="!emptyPointChecked">
            <div class="upper-content-points" v-for="( pointValue, pointKey ) in pointsChecked " :key="pointKey">
              <div class="left-text input-add" v-show="pointValue == true">
                <div class="icon-holder">
                  <img class="icon-point" :src="IconsHelper.iconMain(commModule.point_list[pointKey])" />
                </div>
                <div>{{ commModule.point_list[pointKey].name }}</div>
              </div>
            </div>
          </div>
          <div v-else class="info-box frame devices-box can-scroll vertical-center">
            <div class="left-center">
              {{ stringTable.word("no_device") }}
            </div>
          </div>
        </div>
        <!-- temp value target -->
        <div class="show-value" v-show="groupDetails != '' && showEdit">
          <div class="group-option">
            {{ stringTable.word("group_option") }}
          </div>
          <div class="upper-content">
            <div class="left-text vertical-center">
              <div>{{ stringTable.word("temp") }}</div>
            </div>
            <div class="right-content">
              <SelectOption v-if="groupDetails != ''" :selectList="tempPoints" :defaultSelectIndex=tempTargetIndex
                :upsideDropdown=true />
            </div>
          </div>
          <div class="upper-content">
            <div class="left-text vertical-center">
              <div>{{ stringTable.word("value_target") }}</div>
            </div>
            <div class="right-content">
              <SelectOption v-if="groupDetails != ''" :selectList="valuePoints" :defaultSelectIndex=valueTargetIndex
                :upsideDropdown=true />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.group-setting .dropdown-top {
  margin-top: -80 !important;
}

.group-setting .info-box {
  width: unset;
}

.group-setting .input-add {
  height: 30px;
}

.group-setting .upper-content-points {
  padding: 0px;
}

.left-center {
  margin-right: auto;
}

.group-setting .right-content {
  width: 400px;
}

.group-setting .devices-box {
  padding: 5px 10px;
  min-height: 40px;
  justify-content: center;
  max-height: 215px;
  margin: 7px 5px 5px 5px;
  height: unset;
}

.group-setting .name {
  margin-bottom: 5px;
}

.group-setting select {
  height: 30px !important;
  font-size: 1em;
}

.group-setting .edit-section {
  height: 430px;
}

.group-setting .group-option {
  text-align: center;
  padding-top: 5px;
  margin: 5px 0px;
  font-size: 14px;
  border-top: 2px solid var(--disable);
}

.group-setting .devices-box .icon {
  width: 36px;
  height: 36px;
  margin: 0px;
  padding: 7px;
}
</style>

<script>
import FrameInput from './FrameInput.vue'
import NavHorizontal from './NavHorizontal.vue'
import PopUpHead from './PopUpHead.vue'
import PopUpToast from './PopUpToast.vue'
import SelectDropDown from './SelectDropDown.vue'
import SelectOption from './SelectOption.vue'
import LoadingSpinner from './LoadingSpinner.vue'
import NavPagination from './NavPagination.vue'

export default {
  name: "PopUpGroupSetting",
  data() {
    return {
      details: { name: "grp_settings" },
      groupDetails: { "name": '', "mp": [], "icon_map": "group" },
      groupId: '',
      currentGroup: {},
      toast: {},
      clonedCommModule: {},
      clonePointList: {},
      stdUserChecked: false,
      pointsChecked: {},
      showEdit: false,
      isRegister: false,
      tempTarget: '',
      valueTarget: '',
      titleWord: this.stringTable.word("edit_group"),
      toastText: '',
      isLoading: false,
      isError: false,
      groupNameList: [],
      groupName: '',
      page: 1,
      itemPerPage: 14,
      slicedList: [],
      isAddNewItem: false
    }
  },
  props: {
    commModule: Object,
    cache: Object,
  },
  created() {
    this.clonedCommModule = Object.assign({}, this.commModule);
    this.clonePointList = this.commModule.point_list
    this.groupNameList = Object.keys(this.commModule.group_list).map(key => this.commModule.group_list[key].name);
  },
  mounted() {
    if (this.cache) {
      this.showEdit = true
      this.groupDetails = this.cache.content;
      this.groupId = this.cache.pointId;
      this.page = this.cache.page;
      this.pointsChecked = this.cache.pointsChecked
      this.isRegister = this.cache.isRegister
      this.groupName = this.cache.groupName
    }
  },
  computed: {
    head_detail() {
      var name = '';
      if (this.showEdit)
        name = this.isRegister ? 'add_group' : 'edit_group';
      return { name }
    },
    updatedCheckedPoints() {
      return Object.keys(this.pointsChecked).filter(key => this.pointsChecked[key] === true);
    },
    emptyPointChecked() {
      return Object.values(this.pointsChecked).every(value => value === false);
    },
    tempPoints() {
      if (this.groupDetails != {}) {
        const myList = this.groupDetails.mp.filter(point => {
          const item = this.commModule.point_list[point];
          return item.name && (item.usage === 'temp' || item.usage === 'ac');
        }).map(point => this.commModule.point_list[point].name);
        myList.splice(0, 0, this.stringTable.word("none"));
        return myList
      }
      return []
    },
    valuePoints() {
      if (this.groupDetails.mp != {}) {
        const myList = this.groupDetails.mp.filter(point => {
          const item = this.commModule.point_list[point];
          return !item.name || ((item.type === 'Value' && item.usage !== 'temp') || item.usage === 'dehumi');
        }).map(point => this.commModule.point_list[point].name);
        myList.splice(0, 0, this.stringTable.word("none"));
        return myList
      }
      return []
    },
    tempTargetIndex() {
      if (Object.prototype.hasOwnProperty.call(this.groupDetails, 'temp_targ') && this.groupDetails.temp_targ != null) {
        var point = this.commModule.point_list[this.groupDetails.temp_targ];
        return point ? this.tempPoints.indexOf(point.name) : 0;
      } else return 0;
    },
    valueTargetIndex() {
      if (Object.prototype.hasOwnProperty.call(this.groupDetails, 'value_targ') && this.groupDetails.value_targ != null) {
        var point = this.commModule.point_list[this.groupDetails.value_targ];
        return point ? this.valuePoints.indexOf(point.name) : 0;
      } else return 0;
    },
    groupObj() {
      return this.clonedCommModule.group_list;
    },
    mainList() {
      return Object.keys(this.groupObj)
    },
  },
  watch: {
    'commModule.group_list'() {
      // this.isLoading = false
      this.commModule.changeVariable("enableSendScene", true);
      this.clonedCommModule = Object.assign({}, this.commModule);
      // this.groupNameList =  Object.keys(this.clonedCommModule.groupli)
      this.isError = false
      if (this.isAddNewItem) {
        this.page = this.ConvertHelper.getMaxPage(this.mainList.length, this.itemPerPage);
        this.isAddNewItem = false;
      }
    },
    'commModule.group_modify'() {
      if (this.commModule.group_modify.result == "OK") {
        this.toast = { text: 'succeed' }
        this.showEdit = false
        this.getGroup()
        this.commModule.changeVariable("enableSendScene", false);
        this.clonedCommModule = Object.assign({}, this.commModule);
      } else { //error
        this.toast = { text: this.commModule.group_modify.result }
      }
      this.isLoading = false
    },
    'commModule.group_add'() {
      if (this.commModule.group_add.result == "NG") { //error
        this.toast = { text: this.commModule.group_add.result }
      } else {
        this.toast = { text: 'succeed' }
        this.showEdit = false
        this.getGroup()
        this.commModule.changeVariable("enableSendScene", false);
        this.clonedCommModule = Object.assign({}, this.commModule);
        this.isAddNewItem = true;
      }
      this.isLoading = false
    },
    'commModule.group_delete'() {
      if (this.commModule.group_delete.result == "OK") {
        this.toast = { text: 'succeed' }
        this.showEdit = false
        this.getGroup()
        this.commModule.changeVariable("enableSendScene", false);
        this.clonedCommModule = Object.assign({}, this.commModule);
      } else { //error
        this.toast = { text: this.commModule.group_delete.result }
      }
      this.isLoading = false
    },
  },
  methods: {
    clearPointList() {
      for (const key in (this.pointsChecked)) {
        if (Object.prototype.hasOwnProperty.call(this.pointsChecked, key)) {
          this.pointsChecked[key] = false;
        }
      }
      this.pointsChecked = []
      this.initializePointList(this.pointsChecked)
    },
    getGroup() {
      this.commModule.getGroups();

      this.isError = false
    },
    clickLeftBtn() {//delete
      this.toast = {
        text: 'conf_del',
        pendingYesMethod: () => {
          this.commModule.delGroup(this.groupId);
          this.isLoading = true
        }
      }
    },
    findKeyByName(name) {
      for (const key in this.commModule.point_list) {
        if (Object.prototype.hasOwnProperty.call(this.commModule.point_list, key) && this.commModule.point_list[key].name == name) {
          return key;
        }
      }
      return null; // Key not found
    },
    changeOption(newOption) {
      if (this.tempPoints.includes(newOption)) {
        this.tempTarget = newOption;
      } else {
        this.valueTarget = newOption;
      }
    },
    initializePointList(pointsChecked) {
      this.clonePointList = this.commModule.point_list
      for (const key in this.clonePointList) {
        if (pointsChecked.includes(key)) {
          this.clonePointList[key].stdUserChecked = true;
        } else {
          this.clonePointList[key].stdUserChecked = false;
        }
      }
      this.clickedCheckBox()

    },
    clickedCheckBox() {
      this.pointsChecked = Object.entries(this.commModule.point_list).reduce((result, [key, value]) => {
        if (value.stdUserChecked) { result[key] = true; } else { result[key] = false; }
        return result;
      }, {});
      this.groupDetails.mp = this.updatedCheckedPoints
    },
    plus() {//showRegister
      this.isRegister = true
      this.showEdit = true

      if (this.isRegister) {
        this.titleWord = this.stringTable.word("add_group")
        this.groupId = ''
        this.groupDetails = {}
        this.groupDetails.name = ''
        this.groupDetails.mp = []
        this.groupDetails.temp_targ = null
        this.groupDetails.value_targ = null
        this.groupDetails.icon = 'DefaultGrp.png'
        this.groupDetails.icon_map = "group"
        this.clearPointList()
      } else {
        this.titleWord = this.stringTable.word("edit_group")
      }
    },
    clickRightBtn() {
      if (this.ConvertHelper.isEmptyString(this.groupDetails.name)) {
        this.toast = { text: 'empty_grpname', isError: true, }
        return
      }
      if (this.isRegister) {
        if (this.groupNameList.includes(this.groupDetails.name)) {
          this.toast = { text: 'same_name', isError: true, }
          return
        }
      } else {
        if (this.groupName !== this.groupDetails.name && this.groupNameList.includes(this.groupDetails.name)) {
          this.toast = { text: 'same_name', isError: true, }
          return
        }
      }

      const editedGroup = this.groupDetails
      editedGroup.temp_targ = this.findKeyByName(this.tempTarget)
      editedGroup.value_targ = this.findKeyByName(this.valueTarget)
      editedGroup.mp = this.updatedCheckedPoints

      if (this.isRegister == true) {
        const finalGroup = editedGroup
        this.commModule.addGroup(finalGroup);
      } else {
        const finalGroup = [this.groupId, editedGroup];
        this.commModule.editGroup(finalGroup);
      }
      this.isLoading = true
    },
    iconSelectionClicked() {
      this.NavAssistant.showPopUp({
        name: 'PopUpIcons',
        details: {
          pointId: this.groupId,
          content: this.groupDetails,
          page: this.page,
          // scrollTop: this.$refs.navListScroll.scrollTop,
          iconMap: 'groupIconMap',
          pointsChecked: this.pointsChecked,
          isRegister: this.isRegister,
          groupName: this.groupName,
        },
        page: this.$parent.page
      }, true);
    },
    groupSelected(item) {
      this.isRegister = false
      this.showEdit = true
      this.currentGroup = item
      this.groupId = item.key
      this.groupName = item.value.name

      this.groupDetails = Object.assign({}, item.value);
      this.pointsChecked = this.groupDetails.mp
      this.tempTarget = this.groupDetails.temp_targ
      this.valueTarget = this.groupDetails.value_targ
      this.initializePointList(this.pointsChecked)
    },
    prev() {
      this.groupDetails = '';
      this.showEdit = false;
    }
  },
  components: {
    FrameInput,
    NavHorizontal,
    PopUpHead,
    PopUpToast,
    SelectDropDown,
    SelectOption,
    LoadingSpinner,
    NavPagination
  }
}
</script>