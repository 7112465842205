<template>
  <div class="select-date-range" :class="lyType">
    <SelectOption :selectList="dateRangeList" :defaultSelectIndex="defaultSelectIndex" />
  </div>
</template>

<style>
.select-date-range.month {
  width: 160px;
}

.select-date-range.year {
  width: 260px;
}
</style>

<script>
import SelectOption from './SelectOption.vue'

export default {
  name: "SelectDateRange",
  props: {
    startDay: Number,
    startMonth: Number,
    lyType: String,
    year: Number
  },
  data() {
    return {
      dateRangeList: [],
      dateRangeObjList: [],
      defaultSelectIndex: null,
    }
  },
  created() {
    if (!this.year) this.init();
    // this.$parent.changeDateRange(this.dateRangeObjList[this.defaultSelectIndex]);
  },
  methods: {
    init() {
      this.dateRangeList = [];
      this.dateRangeObjList = [];
      const tdyDt = new Date();
      var currDt = new Date();
      var endDt, nextDt, fromDt, toDt;
      if (this.lyType == 'month') {
        var startDates = [];
        var selYear = this.year ? this.year : tdyDt.getFullYear();
        for (let index = 0; index < 13; index++) {
          nextDt = new Date(selYear, index, this.startDay);
          if (nextDt.getMonth() != index && index != 12)
            nextDt.setDate(1);
          startDates.push(nextDt);
        }

        startDates.forEach((date, index) => {
          if (index + 1 == startDates.length) return;
          var to = new Date(startDates[index + 1]);
          to.setDate(to.getDate() - 1);
          this.dateRangeObjList.push({ from: date, to });
        });

        this.dateRangeObjList.forEach((dateRangeObj, index) => {
          if (this.defaultSelectIndex === null &&
            tdyDt >= dateRangeObj.from && tdyDt <= dateRangeObj.to)
            this.defaultSelectIndex = index;
          var serverTo = new Date(dateRangeObj.to);
          serverTo.setDate(serverTo.getDate() + 1);
          this.dateRangeObjList[index] = {
            fy: dateRangeObj.from.getFullYear(),
            fm: dateRangeObj.from.getMonth() + 1,
            fd: dateRangeObj.from.getDate(),
            ty: serverTo.getFullYear(),
            tm: serverTo.getMonth() + 1,
            td: serverTo.getDate(),
            ...dateRangeObj
          }
          fromDt = this.ConvertHelper.datePickerFormat(dateRangeObj.from, 'day_month');
          toDt = this.ConvertHelper.datePickerFormat(dateRangeObj.to, 'day_month');
          this.dateRangeList.push(`${fromDt} - ${toDt}`);
        });

        // currDt.setMonth(0);
        // currDt.setDate(this.startDay);

        // endDt = new Date(currDt);
        // endDt.setFullYear(endDt.getFullYear() + 1);
        // endDt.setDate(endDt.getDate() - 1);

        // while (currDt <= endDt) {
        //   nextDt = new Date(currDt);
        //   nextDt.setMonth(nextDt.getMonth() + 1);
        //   nextDt.setDate(nextDt.getDate() - 1);
        //   // var next = new Date(this.year, nextDt.getMonth() + 1, 0);
        //   // console.log(next)

        //   if (tdyDt >= currDt && tdyDt <= nextDt)
        //     this.defaultSelectIndex = this.dateRangeObjList.length;

        //   fromDt = this.ConvertHelper.datePickerFormat(currDt, 'day_month');
        //   toDt = this.ConvertHelper.datePickerFormat(nextDt, 'day_month');
        //   var fromDtObj = new Date(currDt);
        //   var toDtObj = new Date(nextDt);
        //   nextDt.setDate(nextDt.getDate() + 1);
        //   this.dateRangeObjList.push({
        //     fy: fromDtObj.getFullYear(),
        //     fm: fromDtObj.getMonth() + 1,
        //     fd: fromDtObj.getDate(),
        //     ty: nextDt.getFullYear(),
        //     tm: nextDt.getMonth() + 1,
        //     td: nextDt.getDate(),

        //     from: fromDtObj,
        //     to: toDtObj
        //   });
        //   this.dateRangeList.push(`${fromDt} - ${toDt}`);
        //   currDt.setMonth(currDt.getMonth() + 1);
        // }
        console.log(this.dateRangeObjList)
      }
      else if (this.lyType == 'year') {
        // var realStartMth = this.startDay > 15 ? this.startMonth - 1 : this.startMonth;
        currDt.setMonth(this.startMonth);
        currDt.setDate(this.startDay);
        currDt.setFullYear(currDt.getFullYear() - 2);

        console.log(this.startDay, currDt)
        endDt = new Date(currDt);
        endDt.setFullYear(endDt.getFullYear() + 3);
        endDt.setDate(endDt.getDate() - 1);
        while (currDt <= endDt) {
          nextDt = new Date(currDt);
          nextDt.setFullYear(nextDt.getFullYear() + 1);
          nextDt.setDate(nextDt.getDate() - 1);

          fromDt = this.ConvertHelper.datePickerFormat(currDt);
          toDt = this.ConvertHelper.datePickerFormat(nextDt);
          var fromDtObj = new Date(currDt);
          var toDtObj = new Date(nextDt);
          nextDt.setDate(nextDt.getDate() + 1);
          this.dateRangeObjList.push({
            fy: fromDtObj.getFullYear(),
            fm: fromDtObj.getMonth() + 1,
            fd: fromDtObj.getDate(),
            ty: nextDt.getFullYear(),
            tm: nextDt.getMonth() + 1,
            td: nextDt.getDate(),

            from: fromDtObj,
            to: toDtObj
          });
          this.dateRangeList.push(`${fromDt} - ${toDt}`);

          currDt.setFullYear(currDt.getFullYear() + 1);
        }
        this.dateRangeObjList = this.dateRangeObjList.reverse();
        this.dateRangeList = this.dateRangeList.reverse();
        this.dateRangeObjList.forEach((dRangeObj, ind) => {
          if (tdyDt >= dRangeObj.from && tdyDt <= dRangeObj.to)
            this.defaultSelectIndex = ind;
        });
      }
    },
    changeOption(newDateRange) {
      this.defaultSelectIndex = this.dateRangeList.indexOf(newDateRange);
      var dateSel = this.dateRangeObjList[this.defaultSelectIndex];
      this.$parent.changeDateRange(dateSel);
    }
  },
  watch: {
    lyType() {
      this.defaultSelectIndex = null;
      this.init();
    },
    year() {
      this.init();
    }
  },
  components: {
    SelectOption
  }
}
</script>
