<template>
  <div class="val-unit" :class="size">
    <div class="val font-bold">{{ val }}</div>
    <div class="unit">{{ unit }}</div>
  </div>
</template>

<style>
.val-unit {
  display: flex;
}

.val-unit.big {
  font-size: 1.25em;
}

.val-unit .val {
  line-height: 1;
  margin-right: 2px;
}

.val-unit .unit {
  font-size: .55em;
  margin-top: auto;
}
</style>

<script>
export default {
  name: "ValueUnit",
  props: {
    val: Object,
    unit: Object,
    size: String,
  },
  data() {
    return {
    }
  }
}
</script>
