<template>
  <div class="page-favorite">
    <NavTopFavorite :activeIndex="activeIndex" />
    <LoadingSpinner v-if="isLoading" />
    <component v-else :is="currentScreen" :commModule="commModule" />
  </div>
</template>

<style>
.page-favorite {
  height: 91%;
}
</style>

<script>
import PageFavDevice from './PageFavDevice.vue'
import PageFavGroup from './PageFavGroup.vue'
import PageFavScene from './PageFavScene.vue'
import NavTopFavorite from './NavTopFavorite.vue'
import LoadingSpinner from './LoadingSpinner.vue'

export default {
  name: "PageFavorite",
  props: {
    commModule: Object,
  },
  data() {
    return {
      isLoading: true,
      activeIndex: 0,
      currentScreen: 'PageFavDevice'
    }
  },
  created() {
    this.NavAssistant.push(this);
  },
  mounted() {
    this.commModule.getFavorite();
  },
  watch: {
    'commModule.get_favorite'(newFav) {
      const favKey = {
        device: 'point_list',
        group: 'group_list',
        scene: 'scene_list',
      }
      for (const key in favKey) {
        if (newFav[key]) {
          newFav[key] = newFav[key].filter(id =>
            this.commModule[favKey[key]][id]);
        }
      }
      this.isLoading = false;
    }
  },
  components: {
    PageFavDevice,
    PageFavGroup,
    PageFavScene,
    NavTopFavorite,
    LoadingSpinner
  }
}
</script>