<template>
	<div class="realtime-energy">
		<LoadingSpinner style="padding-left:450px; padding-top:180px;" v-if="this.isChartLoading" :topSpacing="true" />
		<NoList style="margin-left:450px; margin-top:180px;" class="center-item" v-else-if="this.isNoList" />
		<div class="pie-chart-frame" v-if="!this.isChartLoading && !this.isNoList">
			<div>
				<div class="piechart">
					{{ stringTable.word("this_hour") }}
					<Chart name="pieHourChart" :isPie="true" :data="hourPieData" :options="pieOptions" :plugins="piePlugins"
						:isLoading="false" :isMountedRefresh="true" :legendDisable="true" :legend="{ position: 'right' }" />
				</div>
				<div class="piechart2">
					<div style="height: 100%; width: 50%;">
						<Chart name="pieTodayChart" :isPie="true" :data="todayPieData" :options="pieOptions2" :plugins="piePlugins2"
							:isLoading="false" :isMountedRefresh="true" />
						{{ stringTable.word("today") }}
					</div>
					<div style="height: 100%; width: 50%; ">
						<Chart name="pieMonthChart" :isPie="true" :data="monthPieData" :options="pieOptions2" :plugins="piePlugins2"
							:isLoading="false" :isMountedRefresh="true" />
						{{ stringTable.word("this_month") }}
					</div>
				</div>
			</div>
		</div>
		<div class="bar-chart-frame" v-if="!this.isChartLoading && !this.isNoList">
			<div>
				{{ stringTable.word("past_24_hr") }}
				<Chart name="barHourChart" class="bar-chart" :data="hourData" :options="options" :plugins="plugins"
					:isLoading="false" :isMountedRefresh="true" />
			</div>
			<div class="data-table">
				<div class="vertical-center">
					<div>{{ }}</div>
					<div><b>{{ stringTable.word("this_hour") }} (kwh)</b></div>
					<div><b>{{ stringTable.word("today") }} (kwh)</b></div>
					<div><b>{{ stringTable.word("this_month") }} (kwh)</b></div>
				</div>

				<div v-if="totalRtData" class="vertical-center">
					<div><b>{{ stringTable.word("total") }}</b></div>
					<div><b>{{ totalRtData.hour }}</b></div>
					<div><b>{{ totalRtData.day }}</b></div>
					<div><b>{{ totalRtData.month }}</b></div>
				</div>

				<div class="can-scroll">
					<div v-for="(item, name) in isEnergyCat ? categoryRtData : pointRtData" :key=item class="vertical-center">
						<div>{{ name == 'energy_generator' ? stringTable.word('energy_gen') : name }}</div>
						<div>{{ item.hour }}</div>
						<div>{{ item.day }}</div>
						<div>{{ item.month }}</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<style>
.realtime-energy {
	display: flex;
}

.realtime-energy .pie-chart-frame {
	text-align: center;
	margin-top: 10px;
	width: 440px;
	height: 480px;
	display: block;
}

.realtime-energy .bar-chart-frame {
	margin-top: 10px;
	width: 600px;
	height: 470px;
	display: block;
}

.realtime-energy .bar-chart-frame>div:first-child {
	height: 250px;
	text-align: center;
}

.realtime-energy .bar-chart {
	/* margin-left: 10px; */
	/* margin-right: auto; */
	width: 95%;
	height: 90%
}

.realtime-energy .data-table {
	height: 200px;
	width: 100%;
	padding: 6px 20px;
	/* padding-left: 30px;
	padding-right: 10px; */
}

.realtime-energy .data-table .vertical-center:nth-child(1),
.realtime-energy .data-table .vertical-center:nth-child(2) {
	font-size: 14px;
}

.realtime-energy .data-table .vertical-center div:nth-child(1) {
	width: 31%;
}

.realtime-energy .data-table .vertical-center div:nth-child(2),
.realtime-energy .data-table .vertical-center div:nth-child(3),
.realtime-energy .data-table .vertical-center div:nth-child(4) {
	width: 23%;
}

.realtime-energy .data-table .can-scroll {
	height: 170px;
	padding-top: 6px;
}

.realtime-energy .data-table .can-scroll * {
	line-height: 1.1;
	font-size: 13px !important;
}

.realtime-energy .data-table .can-scroll .vertical-center {
	margin-bottom: 6px;
}

.realtime-energy .piechart {
	margin-left: auto;
	margin-right: auto;
	width: 410px;
	height: 235px;
}

.realtime-energy .piechart .chart-holder {
	height: 205px;
}

.realtime-energy .piechart2 {
	display: flex;
	padding: 0 8px;
	width: 100%;
	height: 45%
}

.realtime-energy .piechart2 .chart-holder {
	margin-bottom: 4px;
}
</style>



<script>

import Chart from './Chart.vue';
import LoadingSpinner from './LoadingSpinner.vue';
import NoList from './NoList.vue';
export default {
	name: "PagePower",
	props: {
		commModule: Object
	},
	data() {
		return {
			isNoList: false,
			isChartLoading: true,
			isEnergyCat: false,
			realtimeData: {},
			totalRtData: {},
			categoryRtData: {},
			pointRtData: {},
			energySettings: {},
			suffixMap: {},
			dataMap: {},
			hourData: {
				labels: [],
				datasets: [],
			},
			todayPieData: {
				labels: [],
				datasets: [],
			},
			monthPieData: {
				labels: [],
				datasets: [],
			},
			hourPieData: {
				labels: [],
				datasets: [],
			},
			piePlugins: {
				// legend: {
				// 	display: true,
				// 	position: 'right'
				// },
				datalabels: {
					formatter: (value, ctx) => {
						var datasets = ctx.chart.data.datasets;
						if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
							var sum = datasets[0].data.reduce((a, b) => a + b, 0);
							var percentage = (value / sum) * 100;
							percentage = `${Math.round(percentage * 10) / 10}%`;
							if (Math.round((value / sum) * 100) == 0 || value == 0.00001) {
								return null;
							} else {
								return percentage;
							}
						} else {
							percentage = '100%'
							return percentage;
						}
					},
					color: '#fff',
				}
			},
			piePlugins2: {
				legend: {
					display: false,
				},
				datalabels: {
					formatter: (value, ctx) => {
						var datasets = ctx.chart.data.datasets;
						if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
							var sum = datasets[0].data.reduce((a, b) => a + b, 0);
							var percentage = (value / sum) * 100;
							percentage = `${Math.round(percentage * 10) / 10}%`;
							if (Math.round((value / sum) * 100) == 0 || value == 0.00001) {
								return null;
							} else {
								return percentage;
							}
						} else {
							percentage = '100%'
							return percentage;
						}
					},

					color: '#fff',
				}
			},
			plugins: {
				legend: {
					display: false
				}
			},
			pieOptions: {
				maintainAspectRatio: false,
				// aspectRatio: 1.7,
				// animation: { duration: 0 },
				responsive: true,
			},
			pieOptions2: {
				aspectRatio: 1.05,
			},
			options: {
				scales: {
					x: {
						barThickness: 35,
						grid: { display: false },
						stacked: true,
					},
					y: {
						title: {
							display: true,
							text: '[kWh]',
						},
						grid: { drawOnChartArea: false, },
						display: true,
						stacked: true,
						alignment: 'start',
						beginAtZero: true,
						lineThickness: 1,
					},
				}
			},
		}
	},
	created() {
		this.NavAssistant.push(this);
		this.savedResponse = [];
		this.commModule.getReportSetting();

	},
	watch: {
		'commModule.load_report_setting'(res) {
			if (!res.data) {
				res.data = {
					start_month: 0,
					start_day: 1,
					categories: {
						is_show_energy_categories: false,
						is_energy_generator_enabled: false,
						is_water_consumption_enabled: false,
						is_recycled_water_enabled: false,
						energy: {},
						energy_generator: { name: "energy_generator", points: [] },
						water_consumption: { name: "water_consumption", points: [] },
						recycled_water: { name: "recycled_water", points: [] }
					}
				}
			}
			else {
				this.energySettings = res.data.categories.energy;
				if (res.data.categories.is_show_energy_categories) {
					this.isEnergyCat = true;
				}
			}
			this.commModule.getRealtimeData();
		},
		'commModule.get_data'(res) {
			this.realtimeData = JSON.parse(JSON.stringify(res));
			for (const key in this.realtimeData) {
				if (key in this.energySettings) {
					this.categoryRtData[this.energySettings[key].name + ' '] = this.realtimeData[key];
				} else if (key == 'total_energy') {
					this.totalRtData = this.realtimeData[key];
					if ('energy_generator' in this.realtimeData) {
						this.totalRtData = this.realtimeData[key];
						['day', 'hour', 'month'].forEach(lyKey => {
							this.totalRtData[lyKey] -= this.realtimeData.energy_generator[lyKey];
							this.totalRtData[lyKey] = (Math.round(this.totalRtData[lyKey] * 100) / 100).toFixed(2);
						});
					}
				}
				else if (key == 'energy_generator') {
					this.categoryRtData['energy_generator'] = this.realtimeData[key];
					['hourly', 'daily'].forEach(lyKey => {
						for (const index in this.realtimeData[key][lyKey])
							this.realtimeData[key][lyKey][index] = -this.realtimeData[key][lyKey][index];
					});
					// ['day', 'hour', 'month'].forEach(lyKey => {
					// 	this.realtimeData[key][lyKey] = -this.realtimeData[key][lyKey];
					// });
				}
				else {
					if (this.commModule.point_list[key]) {
						if (this.suffixMap[key] == null) {
							this.suffixMap[key] = 0;
						} else {
							this.suffixMap[key] = this.suffixMap[this.commModule.point_list[key]] + 1;
						}
						this.pointRtData[key] = this.realtimeData[key];
					}
				}
			}
			this.dataMap = {};
			// handle suffix for repeated point name
			if (this.pointRtData) {
				var addedPointName = [];
				for (const key in this.pointRtData) {
					var point = this.commModule.point_list[key];
					if (point && point.usage == 'emeter') {
						var pointName = point.name;
						addedPointName.push(pointName);
						var num = addedPointName.filter(pn => pn == pointName).length;
						var nameKey = num > 1 ? `${pointName}-${num}` : pointName;
						this.dataMap[nameKey] = this.pointRtData[key];
					}
				}
			}
			this.pointRtData = this.dataMap;
			this.loadChart();
		},
		'commModule.cos'(res) {
			if (res) {
				this.commModule.getRealtimeData();
			}

		},
	},
	methods: {
		loadChart() {
			var currentHour = new Date().getHours();
			var index = 0;
			var hourlyList = [];
			var hourList = [];
			var dayList = [];
			var monthList = [];
			var colorList = [];
			var pointTitleList = [];

			// for (const key in this.pointRtData) {
			// 	index++;
			// }
			var dataUsed = this.isEnergyCat ? this.categoryRtData : this.pointRtData;
			index = 0;
			for (const key in dataUsed) {
				var point = this.commModule.point_list[key];
				var label = point ? point.name : key;
				colorList.push(this.ColorHelper.get(index));
				if (key == 'energy_generator') {
					hourList.push(null);
					pointTitleList.push(this.stringTable.word('energy_gen'));
				} else {
					hourList.push(dataUsed[key].hour);
					dayList.push(dataUsed[key].day);
					monthList.push(dataUsed[key].month);
					pointTitleList.push(label);
				}
				var pointHourly = dataUsed[key].hourly;
				var pointData = [];
				var titleList = [];
				for (let i = currentHour + 1; i < pointHourly.length; i++) {
					titleList.push(i < 10 ? '0' + i.toString() : i.toString());
					pointData.push(pointHourly[i]);
				}
				for (let i = 0; i < currentHour + 1; i++) {
					titleList.push(i < 10 ? '0' + i.toString() : i.toString());
					pointData.push(pointHourly[i]);
				}
				hourlyList.push({
					label: label == 'energy_generator' ? this.stringTable.word('energy_gen') : label,
					type: 'bar',
					backgroundColor: this.ColorHelper.get(index),
					barThickness: 15,
					borderWidth: 1.5,
					data: pointData,
				});
				index++;
			}

			var sumHour = hourList.reduce((partialSum, a) => partialSum + a, 0);
			var sumDay = dayList.reduce((partialSum, a) => partialSum + a, 0);
			var sumMonth = monthList.reduce((partialSum, a) => partialSum + a, 0);

			this.hourData.labels = titleList;
			this.hourData.datasets = hourlyList;

			if (sumHour == 0) {
				pointTitleList.push('');
				hourList.push(0.00001);
				colorList[pointTitleList.length - 1] = '#a3a3a3';
				pointTitleList.pop();
			}

			this.hourPieData.labels = pointTitleList;
			this.hourPieData.datasets =
				[{
					data: hourList,
					backgroundColor: colorList,
					borderWidth: 0
				}];

			if (sumDay == 0) {
				this.todayPieData.labels = [''];
				this.todayPieData.datasets =
					[{
						data: [0.00001],
						backgroundColor: '#a3a3a3',
						borderWidth: 0
					}];
			} else {
				this.todayPieData.labels = pointTitleList;
				this.todayPieData.datasets = [{
					data: dayList,
					backgroundColor: colorList,
					borderWidth: 0
				}];
			}

			if (sumMonth == 0) {
				this.monthPieData.labels = [''];
				this.monthPieData.datasets = [{
					data: [0.00001],
					backgroundColor: '#a3a3a3',
					borderWidth: 0
				}];
			} else {
				this.monthPieData.labels = pointTitleList;
				this.monthPieData.datasets = [{
					data: monthList,
					backgroundColor: colorList,
					borderWidth: 0
				}];
			}
			this.isChartLoading = false;
		}
	},
	computed: {
	},
	components: {
		Chart,
		LoadingSpinner,
		NoList
	},
}
</script>

<!-- 
// pieOptions2: {
// 	aspectRatio: 0.5,
// 	animation: { duration: 0 },
// 	tooltips: {
// 		callbacks: {
// 			label: function (tooltipItem, data) {
// 				return data['labels'][tooltipItem['index']] + ': ' + data['datasets'][0]['data'][tooltipItem['index']] + '%';
// 			}
// 		}
// 	}
// },

// if (pointName in this.dataMap) {
// 	for (var i = 1; i < this.suffixMap[key]; i++) {
// 		if (String(this.commModule.point_list[key].name + '-' + (i + 1).toString) in this.dataMap == false) {
// 			this.dataMap[String(this.commModule.point_list[key].name + '-' + (i + 1).toString)] = this.pointRtData[key];
// 			break;
// 		}
// 	}

-->