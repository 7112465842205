<template>
  <div class="bar-two-btn bar-spacing frame" :class="{ 'vertical-center': leftRight, 'top-bottom': topBottom }">
    <div class="label">
      {{ stringTable.word(label) }}
    </div>
    <div class="btn-holder">
      <div v-for="button in buttons" :key="button" class="frame btn" role="button" @click="buttonClicked(button)">
        {{ stringTable.word(button) }}
      </div>
    </div>
  </div>
</template>

<style>
.bar-two-btn {
  padding: 6px;
}

.bar-two-btn .btn {
  margin: 0 3px;
  color: var(--active);
}

.bar-two-btn.top-bottom .label {
  margin-bottom: 4px;
}

.bar-two-btn.top-bottom .btn-holder {
  width: fit-content;
  margin: 0 auto;
}
</style>

<script>
export default {
  name: "BarTwoBtn",
  props: {
    label: String,
    buttons: Array,
    leftRight: Boolean,
    topBottom: Boolean,
  },
  methods: {
    buttonClicked(value) {
      this.$parent.barTwoBtnClicked(value, this.label)
    }
  }
}
</script>
