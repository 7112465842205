
export class ConvertHelper {
  constructor(strTable) {
    this.strTable = strTable;
    this.months = ['mon0', 'mon1', 'mon2', 'mon3', 'mon4', 'mon5',
      'mon6', 'mon7', 'mon8', 'mon9', 'mon10', 'mon11'];
    this.monthsFull = ['month1', 'month2', 'month3', 'month4', 'month5', 'month6',
      'month7', 'month8', 'month9', 'month10', 'month11', 'month12'];
    this.weeks = ['s_sun', 's_mon', 's_tue', 's_wed', 's_thu', 's_fri', 's_sat'];
    this.weeksFull = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  }

  pointGotOper(point){
    return point.comm_stat && 
      ['Switch', 'Level', 'LevelSw', 'Ac', 'Hrv', 'Rgbw', 'Shutter', 'Lock', 'Dehumi']
        .includes(point.type);
  }

  listToIdNameList(li) {
    var res = [];
    for (const id in li) res.push({
      id, name: li[id].name
    });
    return res;
  }

  formatNumber(number) {
    // Convert the number to a string and remove trailing zeroes
    var formattedNumber = number.toFixed(2).replace(/\.?0*$/, '');
    return formattedNumber;
  }

  getMaxPage(mainLen, itemPerPage) {
    return Math.ceil(mainLen / itemPerPage);
  }

  isFloat(value) {
    return (!isNaN(value) && value.toString().indexOf('.') != -1)
  }

  isNumberOrFloat(value) {
    return !isNaN(value) || this.isFloat(value);
  }

  notNumberOrIsFloat(value) {
    return isNaN(value) || this.isFloat(value);
  }

  isIntegerWithoutDecimal(number) {
    return Number.isInteger(number) && number % 1 === 0;
  }

  getMinOrMax(li, isMin) {
    if (li && li.length > 0) {
      var validLi = li.filter((item) => item != undefined);
      if (isMin) return Math.min(...validLi);
      return Math.max(...validLi);
    }
    return 0;
  }

  listKeyToStrTbl(theList) {
    var res = [];
    theList.forEach(l => res.push(this.strTable.word(l)));
    return res;
  }

  getMonthsFull(index) {
    if (index != undefined) return this.strTable.word(this.monthsFull[index]);
    return this.listKeyToStrTbl(this.monthsFull);
  }

  getWeeks() {
    return this.listKeyToStrTbl(this.weeks);
  }

  getWeeksFull(index) {
    if (index != undefined) return this.strTable.word(this.weeksFull[index]);
    return this.listKeyToStrTbl(this.weeksFull);
  }

  isInteger(str) {
    return Number.isInteger(parseInt(str));
  }

  isEmptyString(inputString) {
    // Remove leading and trailing spaces using trim() method
    var trimmedString = inputString.trim();

    // Check if the trimmed string is empty (contains only spaces)
    return trimmedString === '';
  }

  getRoundedPrecise(num) {
    var numStr = num.toString();
    var finalNum = '';
    var onlyNum;
    if (num >= 1) {
      numStr = parseInt(numStr).toString();
      onlyNum = numStr[0];
      finalNum = Number(onlyNum) + 1;
      for (let index = 0; index < numStr.length - 1; index++) {
        finalNum += '0';
      }
    } else {
      for (const key in numStr) {
        var numStrSub = numStr[key];
        if (!['0', '.'].includes(numStrSub)) {
          onlyNum = Number(numStrSub) + 1;
          break;
        } finalNum += numStrSub;
      }
      finalNum = `${finalNum}${onlyNum}`;
    }
    return Number(finalNum);
  }

  chartPrecision(max, toDivide) {
    toDivide = toDivide ? toDivide : 4;
    var precise = this.getRoundedPrecise(max) / toDivide;
    if (precise >= 1) {
      precise = Math.floor(precise);
      precise = Number(precise.toPrecision(1))
    }
    return precise;
  }

  roundIncrement(number, increment) {
    return Math.ceil((number) / increment) * increment;
  }

  intToNumOfDigits(num, numOfDigits = 2) {
    return num.toLocaleString('en-US', {
      minimumIntegerDigits: numOfDigits,
      useGrouping: false
    });
  }

  toCapitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  removeArrayItems(arr, value) {
    if (!arr) return [];
    var i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  filterObjByKey(aObj, filterKey, filterVal) {
    var res = {};
    for (const key in aObj) {
      if (aObj[key][filterKey] == filterVal)
        res[key] = aObj[key];
    }
    return res;
  }

  renameObjKey(aObj, oldKey, newKey) {
    if (oldKey == newKey) return aObj;
    var res = {};
    for (const objKey in aObj) {
      const val = aObj[objKey];
      res[objKey == oldKey ? newKey : objKey] = val;
    }
    return res;
  }

  orderObjByKey(unorderedObj) {
    return Object.keys(unorderedObj).sort().reduce(
      (obj, key) => {
        obj[key] = unorderedObj[key];
        return obj;
      },
      {}
    );
  }

  orderArrByObjVal(array, key) {
    return array.sort(function (a, b) {
      var x = a[key]; var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  secondsToHHMM(seconds) {
    var minutes_raw = seconds / 60;
    var hours_raw = minutes_raw / 60;
    var hours = Math.floor(hours_raw);
    var minutes = Math.floor((hours_raw - hours) * 60);
    return `${this.intToNumOfDigits(hours)}:${this.intToNumOfDigits(minutes)}`;
  }

  dtObjToMthName(dtObj) {
    return this.strTable.word(this.months[dtObj.getMonth()]);
  }

  dtObjToFullMthName(dtObj) {
    return this.strTable.word(this.monthsFull[dtObj.getMonth()]);
  }

  dtObjToMthStr(dtObj) {
    return this.intToNumOfDigits(dtObj.getMonth() + 1);
  }

  dtObjToDayStr(dtObj) {
    return this.intToNumOfDigits(dtObj.getDate());
  }

  dtObjToHourStr(dtObj) {
    return this.intToNumOfDigits(dtObj.getHours());
  }

  dtObjToMinsStr(dtObj) {
    return this.intToNumOfDigits(dtObj.getMinutes());
  }

  dtObjToDateStr(dtObj) {
    var dayStr = this.dtObjToDayStr(dtObj);
    var mthStr = this.dtObjToMthStr(dtObj);
    return `${mthStr}-${dayStr}`;
  }

  dtObjToDayMthYrStr(dtObj) {
    return `${this.dtObjToDateStr(dtObj)}-${dtObj.getFullYear()}`;
  }

  dtObjToTimeStr(dtObj) {
    var hourStr = this.dtObjToHourStr(dtObj);
    var minsStr = this.dtObjToMinsStr(dtObj);
    return `${hourStr}:${minsStr}`;
  }

  dtServerToDtObj(dtObj) {
    dtObj = dtObj.toString();
    var lastYear = dtObj.slice(0, 4);
    var lastMonth = parseInt(dtObj.slice(4, 6)) - 1;
    var lastDay = dtObj.slice(6, 8);
    var lastHour = dtObj.slice(8, 10);
    var lastMins = dtObj.slice(10, 12);
    return new Date(lastYear, lastMonth, lastDay, lastHour, lastMins);
  }

  dtObjToDtServer(dtObj) {
    return `${dtObj.getFullYear()}${this.dtObjToMthStr(dtObj)}${this.dtObjToDayStr(dtObj)}`;
  }

  dtObjToDtimeServer(dtObj) {
    return `${this.dtObjToDtServer(dtObj)}${this.dtObjToHourStr(dtObj)}${this.dtObjToMinsStr(dtObj)}`;
  }

  dtObjToYearMthServer(dtObj) {
    return `${dtObj.getFullYear()}${this.dtObjToMthStr(dtObj)}`;
  }

  dtServerToTimeString(dtObj) {
    return this.dtObjToTimeStr(this.dtServerToDtObj(dtObj));
  }

  dtServerToDayMthString(dtObj) {
    return this.datePickerFormat(this.dtServerToDtObj(dtObj), 'day_month');
  }

  dtObjToLblStr(dtObj, hideDate = false) {
    var result = [this.dtObjToTimeStr(dtObj)];
    if (!hideDate) result.push(this.dtObjToDateStr(dtObj));
    return result;
  }

  dtInputToDtObj(dtInput) {
    return new Date(Date.parse(dtInput));
  }

  dtObjToDtInput(dtObj) {
    dtObj = dtObj ? dtObj : new Date();
    return this.dtObjToYearMthDayStr(dtObj);//.toJSON().slice(0, 10);
  }

  dtObjToYearMthDayStr(dtObj) {
    return `${dtObj.getFullYear()}-${this.dtObjToMthStr(dtObj)}-${this.dtObjToDayStr(dtObj)}`;
  }

  hanLang() {
    var lang = localStorage.getItem('Lang');
    return ['CH', 'TW', 'HK', 'JP'].includes(lang);
  }

  dtObjToMthYear(dtObj) {
    var mthName = this.dtObjToFullMthName(dtObj);
    var year = dtObj.getFullYear();
    return this.hanLang() ? `${year} ${mthName}` : `${mthName} ${year}`;
  }

  datePickerFormat(dtObj, formatName) {
    const day = dtObj.getDate();
    const month = dtObj.getMonth() + 1;
    const year = dtObj.getFullYear();

    const isDayMonth = formatName == 'day_month';
    if (this.hanLang()) {
      if (isDayMonth) return `${month}/${day}`;
      return `${year}/${month}/${day}`;
    } else {
      if (isDayMonth) return `${day}/${month}`;
      return `${day}/${month}/${year}`;
    }
  }

  timeStrToHour(time, isToInt) {
    var sliced = time.replace(':', '').slice(0, 2);
    return isToInt ? parseInt(sliced) : sliced;
  }

  timeStrToMin(time, isToInt) {
    var sliced = time.replace(':', '').slice(2, 4);
    return isToInt ? parseInt(sliced) : sliced;
  }

  zoneTimeToSelectTime(zoneTime) {
    var selTime = zoneTime.toString();
    while (selTime.length < 4) {
      selTime = `0${selTime}`;
    } return `${this.timeStrToHour(selTime)}:${this.timeStrToMin(selTime)}`;
  }

  zoneTimeToDecimalTime(zoneTime) {
    var selTime = this.zoneTimeToSelectTime(zoneTime);
    var hour = this.timeStrToHour(selTime, true);//.slice(0, 2));
    var mins = this.timeStrToHour(selTime, true);//.slice(2, 4));
    var minsDecimal = mins / 60;
    return hour + minsDecimal;
  }

  getErrorMsg(strKey) {
    return `${this.strTable.word('error')}: ${this.strTable.word(strKey)}`;
  }

  parentMethodsGot(compo, methodName) {
    var parentMethods = compo.$parent.$options.methods;
    parentMethods = parentMethods ? parentMethods : {};
    const parentMethodsKeys = Object.keys(parentMethods);
    return parentMethodsKeys.includes(methodName);
  }
}

// dtObjToMthNameYear(dtObj) {
//   return `${this.dtObjToMthName(dtObj)} ${dtObj.getFullYear()}`;
// }

// dtObjToDayMthYearStr(dtObj) {
//   return `${this.dtObjToDayStr(dtObj)}-${this.dtObjToMthStr(dtObj)}-${dtObj.getFullYear()}`;
// }