<template>
  <div class="chart-holder">
    <LoadingSpinner v-if="isLoading" />
    <NoList v-else-if="empty_data && !isPie && name != 'barHourChart'" />
    <canvas :ref="chart_name" :class="{ 'd-none': empty_data || isLoading }"></canvas>
  </div>
</template>

<style></style>

<script>
import LoadingSpinner from './LoadingSpinner.vue'
import NoList from './NoList.vue'
import { CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend } from 'chart.js'
import Chart from 'chart.js/auto';
import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels'
Chart.register(CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend, ChartJSPluginDatalabels)

export default {
  name: 'ChartJs',
  props: {
    name: String,
    isPie: Boolean,
    isLoading: Boolean,
    data: Object,
    options: Object,
    plugins: Object,
    vertiXlabel: Boolean,
    isMountedRefresh: Boolean,
    legendDisable: Boolean,
    legend: Object,
    scaleHeightX: Number,
    showDataEmpty: Boolean,
  },
  data() {
    return {
      defaultOptions: {
        animation: false,
      },
      ticks: {
        autoSkip: false,
        minRotation: 0,
        maxRotation: 0,
      },
      chart: null
    }
  },
  created() {
    if (!this.isPie) {
      this.defaultOptions = {
        radius: 0,
        stacked: false,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            mode: 'index',
            intersect: false
          }
        },
        scaleShowValues: true,
        ...this.defaultOptions
      }
    }
    else {
      this.defaultOptions = {
        rotation: 90,
        ...this.defaultOptions
      }
    }
  },
  mounted() {
    if (this.isMountedRefresh)
      this.refresh();
  },
  watch: {
    'data.datasets'() {
      this.refresh();
    },
  },
  methods: {
    combinedOptions() {
      this.defaultOptions.plugins = { ...this.defaultOptions.plugins, ...this.plugins };
      if (!this.isPie) {
        this.defaultOptions.plugins.datalabels = { formatter: () => { return null } }
      }
      var tempOptions = {}
      if (this.legendDisable) {
        tempOptions = {
          ...this.options, ...this.defaultOptions,
          plugins: {
            ...this.defaultOptions.plugins,
            legend: { onClick: () => { }, },
            // datalabels: { formatter: () => null },
          },
        };
        if (this.legend)
          tempOptions.plugins.legend = { ...tempOptions.plugins.legend, ...this.legend };
        // tempOptions.plugins.legend = this.legend;
      } else {
        tempOptions = {
          ...this.options, ...this.defaultOptions, plugins: {
            ...this.defaultOptions.plugins,
          },
        };
      }

      if (tempOptions.scales) {
        var ticks = this.ticks;
        delete ticks.minRotation;
        if (this.vertiXlabel) {
          ticks.minRotation = 90;
          //   ticks.maxRotation = 10;
        }
        tempOptions.scales.x.ticks = ticks;
      }
      return tempOptions;
    },
    refresh() {
      //      console.log("refreshChart")
      if (this.chart) {
        this.chart.tooltip.x = undefined;
        this.chart.tooltip.y = undefined;
      }
      if (this.chart) this.chart.destroy();
      const chrt = this.$refs[this.chart_name]; //document.getElementById(this.chart_name);
      if (!chrt) return;
      this.chart = new Chart(chrt, {
        type: this.isPie ? 'pie' : 'line',
        data: this.data,
        options: this.combinedOptions(),
      });

      if (this.ConvertHelper.parentMethodsGot(this, 'clickedChartLabel')) {
        var thisCompo = this;
        chrt.onclick = function (e) {//evt
          var rect = e.target.getBoundingClientRect();
          var x = e.clientX - rect.left; //x position within the element.
          var y = e.clientY - rect.top;  //y position within the element.
          var dataIndex = thisCompo.chart.scales.x.getValueForPixel(x);
          if (dataIndex >= 0 && dataIndex < thisCompo.data.labels.length &&
            y >= (rect.height - thisCompo.scaleHeightX)) {
            console.log(dataIndex)
            thisCompo.$parent.clickedChartLabel(dataIndex);
          }
        }
      }
    }
  },
  computed: {
    chart_name() {
      return `chart-canvas-${this.name ? this.name : 'default'}`;
    },
    empty_data() {
      if (!this.data.datasets) return true;
      var isEmpty = true;
      this.data.datasets.forEach(d => {
        if (this.showDataEmpty && d.data.length == 0) {
          isEmpty = false; return;
        }
        d.data.forEach(raw => {
          if (raw !== undefined) {
            isEmpty = false; return;
          }
        });
      });
      return isEmpty;
    }
  },
  components: {
    LoadingSpinner,
    NoList
  }
}
</script>