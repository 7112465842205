<template>
  <div class="guest guest-locations">
    <PopUpHead :details="details" :hideBorderBtm="true" />
    <div class="vertical-top">
      <BarReorderable :cache="cache" :nameLi="locations" :iconRefer="settings ? settings.locations : {}" :hideIcon="true"
        :pageTrack="page" :itemPerPage="itemPerPage" />
      <img class="add-rotate add-device-btn" :src="ReiriIcons.cross" @click="addLocation" role="button" />
    </div>
  </div>
</template>

<style>
.select-dropdown-holder .dropdown-top {
  margin-left: -400px !important;
}

.guest-locations .bar-reorderable {
  width: 100%;
}

.guest-locations .add-rotate {
  position: absolute;
  right: 4px;
}
</style>

<script>
import PopUpHead from './PopUpHead.vue'
import BarReorderable from './BarReorderable.vue'

export default {
  name: "GuestLocations",
  props: {
    commModule: Object,
    details: Object,
    cache: Object,
  },
  data() {
    return {
      page: 1,
      itemPerPage: 11
    }
  },
  created() {
    if (!this.settings) return;
    if (this.cache) {
      this.page = this.cache.name == 'add_location' ?
        this.ConvertHelper.getMaxPage(this.locations.length, this.itemPerPage) :
        this.cache.page;
    }
    var resObj = new Map();
    if (this.settings.locations)
      this.settings.locations.forEach(location => {
        resObj.set(location.name, location.room_order);
      });
    var locations = [];
    for (const key of resObj.keys()) {
      locations.push({ name: key, room_order: resObj.get(key) })
    }
    this.settings.locations = locations;
  },
  watch: {
  },
  methods: {
    prev() {
      this.$parent.closeRightScreen();
    },
    barClicked(locations, location) {
      this.popUp('edit_location', location.name, this.settings.locations);
    },
    addLocation() {
      this.popUp('add_location', '', this.settings.locations);
    },
    popUp(title, locName, contents) {
      this.NavAssistant.showPopUp({
        name: 'PopUpGuestLocation',
        details: {
          name: title,
          screen: this.details.name,
          settings: this.settings,
          locName, contents, page: this.page
        }
      }, true);
    },
    reorderChanged() {
      this.commModule.saveGuests(this.settings);
    },
    reorderDeleted() {
      this.commModule.saveGuests(this.settings);
    }
  },
  computed: {
    settings() {
      if (this.cache) return this.cache.settings;
      return this.details.settings;
    },
    reorder() {
      return this.settings ? this.settings.locations : [];
    },
    locations() {
      return this.reorder.map((l) => l.name);
    },
  },
  components: {
    PopUpHead,
    BarReorderable
  },
}
</script>