<template>
  <div class="navigation navigation-btm">
    <div class="btn-holder">
      <BtnNavIconText iconDefault="tab_homepage_default" iconActive="tab_homepage_active" text="home"
        :compoForChangeScreen="$parent" compoTarget="PageHome" />
      <BtnNavIconText iconDefault="tab_group_default" iconActive="tab_group_active" text="group"
        :compoForChangeScreen="$parent" compoTarget="PageGroup" />
      <BtnNavIconText iconDefault="tab_scene_default" iconActive="tab_scene_active" text="scene"
        :compoForChangeScreen="$parent" compoTarget="PageScene" />
      <BtnNavIconText v-if="isAdmin" iconDefault="tab_report_default" iconActive="tab_report_active" text="report"
        :compoForChangeScreen="$parent" compoTarget="PageReport" />
      <BtnNavIconText iconDefault="tab_setting_default" iconActive="tab_setting_active" text="sys"
        :compoForChangeScreen="$parent" compoTarget="PageSystem" />
    </div>
  </div>
</template>

<style>
.navigation-top,
.navigation-btm {
  width: 1024px;
  box-shadow: 0 0 8px #888888;
}

.navigation-btm {
  background-color: white;
  position: fixed;
  z-index: 2;
}

.navigation .btn-holder {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  line-height: 1.2;
}

/* Inside BtnNavIconText */
.navigation img {
  display: inline-block;
  margin-bottom: 0;
}

.navigation .active-text {
  color: var(--active);
}

.navigation-btm img {
  width: 35px;
}

.navigation-btm .item {
  width: 165px;
  font-size: 19px;
  padding: 9px;
}
</style>

<script>
import BtnNavIconText from './BtnNavIconText.vue'

export default {
  name: "NavBtmMain",
  props: {
    activeIndex: Number,
    showNav: Boolean,
    isAdmin: Boolean,
  },
  data() {
    return {
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
    methodThatForcesUpdate() {
      this.$forceUpdate();  // Notice we have to use a $ here
    },
    isActive(index) {
      return index == this.activeIndex;
    },
    icon(index, icon_def, icon_act) {
      return this.isActive(index) ? this.ReiriIcons[icon_act] : this.ReiriIcons[icon_def]
    },
  },
  components: {
    BtnNavIconText
  }
}
</script>

<!-- 
      pagesBtm: ['PageHome', 'PageGroup', 'PageScene', 'PageReport', 'PageSystem'],
<div role="button" v-for="(item, index) in nav_btm_list" :key="index"
  @click="changeActive(index)">
  <img :src="index == activeIndex ? ReiriIcons[item.icon_act] : ReiriIcons[item.icon_def]" />
  <br />
  <span class="nav-text" :class="{ 'active-text': index == activeIndex }">
    {{ stringTable.word(item.name) }}
  </span>F
</div> 
// nav_btm_list: [
//   {
//     name: "home",
//     icon_def: "tab_homepage_default",
//     icon_act: "tab_homepage_active",
//   },
//   {
//     name: "group",
//     icon_def: "tab_group_default",
//     icon_act: "tab_group_active",
//   },
//   {
//     name: "scene",
//     icon_def: "tab_scene_default",
//     icon_act: "tab_scene_active",
//   },
//   {
//     name: "report",
//     icon_def: "tab_report_default",
//     icon_act: "tab_report_active",
//   },
//   {
//     name: "sys",
//     icon_def: "tab_setting_default",
//     icon_act: "tab_setting_active",
//   }
// ], -->