<template>
  <div v-if="showToast" class="pop-up toast-custom vertical-center">
    <div class="box-holder">
      <div v-if="isError" class="text error">
        {{ this.stringTable.word("error") + ': ' + this.stringTable.word(text) }}
      </div>
      <div v-else class="text"> {{ this.stringTable.word(text) }} </div>
      <div v-if="type == 'confirm'">
        <div class="frame btn cancel" @click="hideShow()">
          {{ stringTable.word('cancel') }}
        </div>
        <div class="frame btn" @click="hideShow(true)">
          {{ stringTable.word('ok') }}
        </div>
      </div>
      <div v-else-if="type == 'showReboot'" class="text"></div>
      <div v-else class="frame btn" @click="hideShow()">
        {{ stringTable.word('ok') }}
      </div>
    </div>
  </div>
</template>

<style>
.pop-up .error {
  color: red;
}

.pop-up.toast-custom {
  width: 100%;
  height: 100%;
  left: 0;
  background-color: rgba(130, 130, 130, 0.3);
}

.pop-up.toast-custom .box-holder {
  max-width: 500px;
  margin: 0 auto;
  padding: 12px 18px;
  text-align: center;
  background-color: var(--background);
  border-radius: 10px;
}

.pop-up.toast-custom .btn {
  border-radius: 30px;
  margin: 8px 6px 4px 6px;
  width: 140px;
}

.pop-up.toast-custom .btn.cancel {
  width: 140px;
}
</style>

<script>

export default {
  name: "PopUpToast",
  props: {
    // toast: Object,
    // type: String,
    // pendingYesMethod: Function,
    // isError: Boolean,
  },
  data() {
    return {
      text: '',
      isYes: false,
      showToast: false,
      isError: false,
      toShowReboot: false,
    }
  },
  watch: {
    isYes() {
      if (this.isYes) {
        this.$parent.toast.pendingYesMethod();
        this.resetParentToast();
        if (this.toShowReboot)
          this.$parent.showReboot();
      }
      this.toShowReboot = false;
      this.isYes = false;
      this.$parent.toDelete = false
    },
    '$parent.toast'(toast) {
      if (Object.keys(toast).length == 0) return;
      this.showToast = true;
      this.text = toast.text;
      this.type = toast.pendingYesMethod ? 'confirm' : toast.type;
      this.isError = toast.isError;
      this.toShowReboot = toast.toShowReboot;
    }
  },
  methods: {
    resetParentToast() {
      this.$parent.toast = {};
    },
    hideShow(isYes) {
      this.showToast = false;
      this.isYes = isYes;
      if (!isYes) this.resetParentToast();
    }
  }
}
</script>