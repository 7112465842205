<template>
  <div class="row vertical-center justify-center loading-container"
    :class="{ 'bkg': background, 'top-space': topSpacing }">
    <div class="col-auto loading-holder">
      <div class="spinner-border" role="status">
      </div>
    </div>
  </div>
</template>

<style>
.bef-loading {
  position: relative;
}

.loading-container {
  width: inherit;
  height: inherit;
  margin: 0 !important;
  z-index: 1000;
}

.loading-container.bkg {
  position: absolute;
  height: 100%;
  background-color: rgba(72, 72, 72, 0.5);
}

.loading-container.top-space {
  padding-top: 60px;
}

.loading-container .spinner-border {
  width: 8rem;
  height: 8rem;
  color: var(--active);
}
</style>

<script>
export default {
  name: "LoadingSpinner",
  props: {
    background: Boolean,
    topSpacing: Boolean
  },
}
</script>
