<template>
  <div role="button" @click="changeActive" class="item" :class="{ 'active-item': is_active }">
    <img v-if="this.iconDefault" :src="ReiriIcons[icon]" :class="{ 'to-active': isIconActiveColor }" />
    <div class="nav-text" :class="{
      'active-text': is_active && !textBlackBold, 'fw-bold': textBlackBold
    }" role="button">
      {{ stringTable.word(text) }}
    </div>
  </div>
</template>

<style></style>

<script>

export default {
  name: "BtnNavIconText",
  props: {
    iconDefault: String,
    iconActive: String,
    text: String,
    compoForChangeScreen: Object,
    compoTarget: String,
    isIconActiveColor: Boolean,
    textBlackBold: Boolean,
    changeScreenVarName: String
  },
  data() {
    return {
    }
  },
  created() {
  },
  computed: {
    icon() {
      return this.is_active && this.iconActive ? this.iconActive : this.iconDefault;
    },
    is_active() {
      return this.compoForChangeScreen[this.change_screen_var_name] == this.compo_target;
    },
    change_screen_var_name() {
      return this.changeScreenVarName ? this.changeScreenVarName : "currentScreen";
    },
    compo_target() {
      return this.compoTarget ? this.compoTarget : this.text;
    }
  },
  methods: {
    changeActive() {
      this.NavAssistant.changeCompo(this.compoForChangeScreen, this.compo_target, this.change_screen_var_name);
    },
  },
}
</script>