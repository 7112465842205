<template>
  <div class="main-screen">
    <component :is="currentScreen" :commModule="commModule" />
  </div>
  <NavBtmMain v-if="showNavBtm" :activeIndex="activeIndex" :isAdmin="commModule.isAdmin" />
</template>

<style>
.main-screen {
  width: inherit;
  height: 525px;
}
</style>

<script>
import PageHome from './PageHome.vue'
import PageGroup from './PageGroup.vue'
import PageScene from './PageScene.vue'
import PageReport from './PageReport.vue'
import PageSystem from './PageSystem.vue'
import NavBtmMain from './NavBtmMain.vue'

export default {
  name: "PageMain",
  props: {
    commModule: Object,
    err: String,
  },
  data() {
    return {
      commModuleCloned: this.commModule,
      activeIndex: 0,
      currentScreen: 'PageHome',
      showNavBtm: true
    }
  },
  created() {
    this.NavAssistant.push(this);
  },
  watch: {
  },
  methods: {
  },
  computed: {
  },
  components: {
    PageHome,
    PageGroup,
    PageScene,
    PageReport,
    PageSystem,
    NavBtmMain,
  },
}
</script>
