<template>
  <div v-if="showPage" class="system-page">
    <div class="content-holder nav-hori-holder justify-between vertical-top">
      <div class="content-left">
        <div class="frame">
          <NavHorizontal v-if="commModule.isAdmin && commModule.account.available_functions.schedule"
            :isIconActiveColor="true" :bigText="true" icon="schedule" name="schedule" @click="scheduleSetting" />
          <NavHorizontal v-if="commModule.isAdmin && commModule.account.available_functions.interlock"
            :isIconActiveColor="true" :bigText="true" icon="interlock" name="interlock" @click="interlockSetting" />
          <NavHorizontal v-if="commModule.isAdmin && commModule.account.available_functions.change_over"
            :isIconActiveColor="true" :bigText="true" icon="auto_change_over" name="change_over"
            @click="autoChangeover" />
          <NavHorizontal :isIconActiveColor="true" :bigText="true" icon="default_scene" name="scene"
            @click="sceneSetting" />
        </div>
        <div class="frame">
          <NavHorizontal :isIconActiveColor="true" :bigText="true" icon="favorite_default" name="fav_settings"
            @click="checkFavorite" />
          <NavHorizontal v-if="commModule.isAdmin && commModule.account.available_functions.group"
            :isIconActiveColor="true" :bigText="true" icon="group" name="grp_settings" @click="groupSetting" />
          <NavHorizontal v-if="commModule.isAdmin" :isIconActiveColor="true" :bigText="true" icon="point_setup"
            name="dev_settings" @click="deviceSetting" />
          <NavHorizontal v-if="commModule.isAdmin && commModule.account.available_functions.iaq_mon"
            :isIconActiveColor="true" :bigText="true" icon="iaq_setting" name="iaq_room_set" @click="iAQRoomSetting" />
          <NavHorizontal v-if="commModule.isAdmin && commModule.account.available_functions.unit_manage"
            :isIconActiveColor="true" :bigText="true" icon="bed_room" name="hotel_gr_man" @click="hotelRoom" />
          <NavHorizontal v-if="commModule.isAdmin && commModule.account.available_functions.history"
            :isIconActiveColor="true" :bigText="true" icon="history" name="history" @click="checkHistory"
            :popUpWithCache="true" />
        </div>
      </div>
      <div class="content-right">
        <div class="frame">
          <NavHorizontal v-if="commModule.isAdmin" :isIconActiveColor="true" :bigText="true" icon="user_administration"
            name="user_admin" @click="checkUser" />
          <NavHorizontal :isIconActiveColor="true" :bigText="true" icon="password" name="passwd"
            @click="changePassword" />
          <NavHorizontal :isIconActiveColor="true" :bigText="true" icon="language" name="lang" @click="changeLanguage" />
        </div>
        <div class="frame">
          <NavHorizontal v-if="commModule.isAdmin" :isIconActiveColor="true" :bigText="true" icon="qr_code"
            name="qr_code_gen" @click="showQRCode" />
          <NavHorizontal :isIconActiveColor="true" :bigText="true" icon="terms_conditions" name="term_cond"
            @click="showTermAndCond" />
          <NavHorizontal :isIconActiveColor="true" :bigText="true" icon="version_information" name="version_information"
            @click="showVersionInfo" />
        </div>
        <div class="frame">
          <NavHorizontal :isIconActiveColor="true" :bigText="true" icon="logout" name="logout" @click="logout" />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.system-page .content-holder {
  width: 880px;
  margin: 0 auto;
  padding-top: 24px;
}

.system-page .content-holder>div {
  width: 49.2%;
}

.system-page .item-text {
  width: 350px;
}

.system-page .nav-horizontal .icon {
  width: 32px;
  margin: 6px;
}

.system-page .nav-hori-holder .frame {
  margin-bottom: 12px;
}
</style>

<script>
import NavHorizontal from "./NavHorizontal.vue";

export default {
  name: "PageSystem",
  props: {
    commModule: Object,
  },
  data() {
    return {
      showPage: true,
    };
  },
  created() {
    this.NavAssistant.push(this);
  },
  watch: {
    showPage(val) {
      if (val) {
        this.$parent.showNavBtm = true
      }
    }
  },
  methods: {
    logout() {
      this.commModule.logout();
    },
    scheduleSetting() {
      this.NavAssistant.showPopUp({
        name: 'PopUpSchedule'
      });
    },
    interlockSetting() {
      this.NavAssistant.showPopUp({
        name: 'PopUpInterlock'
      });
    },
    autoChangeover() {
      this.NavAssistant.showPopUp({
        name: 'PopUpAutomaticChangeover'
      });
    },
    checkFavorite() {
      this.NavAssistant.showPopUp({
        name: 'PopUpFavorite'
      });
    },
    deviceSetting() {
      this.NavAssistant.showPopUp({
        name: 'PopUpDeviceSetting'
      });
    },
    iAQRoomSetting() {
      this.NavAssistant.showPopUp({
        name: 'PopUpIAQRoomSetting'
      });
    },
    groupSetting() {
      this.NavAssistant.showPopUp({
        name: 'PopUpGroupSetting'
      });
    },
    sceneSetting() {
      this.NavAssistant.showPopUp({
        name: 'PopUpSceneSetting'
      });
    },
    hotelRoom() {
      this.NavAssistant.showPopUp({
        name: 'PopUpGuestRoom'
      });
    },
    checkHistory() {
      this.NavAssistant.showPopUp({
        name: 'PopUpHistory'
      });
    },
    checkUser() {
      this.NavAssistant.showPopUp({
        name: 'PopUpUserAdmin'
      });
    },
    changePassword() {
      this.NavAssistant.showPopUp({
        name: 'PopUpPassword'
      });
    },
    changeLanguage() {
      this.showPage = false;
      this.$parent.showNavBtm = false
      this.NavAssistant.showPopUp({
        name: 'PopUpLanguage'
      });
    },
    showQRCode() {
      this.NavAssistant.showPopUp({
        name: 'PopUpQR'
      });
    },
    showVersionInfo() {
      this.NavAssistant.showPopUp({
        name: 'PopUpVersionInfo'
      });
    },
    showTermAndCond() {
      this.NavAssistant.showPopUp({
        name: 'PopUpTermAndCond'
      });
    }
  },
  computed: {},
  components: {
    NavHorizontal,
  },
};
</script>
