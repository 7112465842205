<template>
  <div class="frame tile-iaq" :class="{ 'd-none': !room }">
    <div v-if="room" role="button" @click="clicked(room)">
      <div class="top-content vertical-center">
        <div class="circle small" :class="room.indicator"></div>
        <div class="name one-line can-scroll">{{ room.name }}</div>
        <img :src="ReiriIcons.chevron_right" />
      </div>
      <div class="btm-content can-scroll">
        <TileIaqValUnit v-for="roomDetail in room.details" :key="roomDetail" :commModule="commModule"
          :roomDetailProp="roomDetail" />
      </div>
      <div class="btm-bar" :class="getIndicator()"></div>
    </div>
  </div>
</template>

<style></style>

<script>
import TileIaqValUnit from './TileIaqValUnit.vue'

export default {
  name: "TileIaq",
  props: {
    commModule: Object,
    roomProp: Object,
  },
  data() {
    return {
      room: this.roomProp
    }
  },
  created() {
  },
  watch: {
  },
  methods: {
    getIndicator() {
      if (!this.room) return;
      var indicators = [];
      this.room.details.forEach(roomDetail => {
        indicators.push(roomDetail.indicator);
      });
      var indicator;
      if (indicators.includes('red')) indicator = 'bkg-red';
      else if (indicators.includes('yellow')) indicator = 'bkg-yellow';
      else indicator = 'bkg-green';
      this.room.indicator = indicator;
      return indicator;
    },
    clicked() {
      this.NavAssistant.showPopUp({
        name: 'PopUpIaqChart',
        details: this.room,
      });
    }
  },
  computed: {
  },
  components: {
    TileIaqValUnit
  },
}
</script>