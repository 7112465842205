<template>
	<div class="layout">
		<PopUpToast />
		<div v-if="this.dropDownList.length > 0" class="vertical-center sel_screen">
			<div>
				{{ stringTable.word("cur_screen") + ':' }}
			</div>
			<SelectOption class="option_list" :selectList="dropDownList" :useObject="true" 
				:defaultSelectIndex="dropDownList.findIndex(dropd => dropd.id == selectId)" />
		</div>
		<!-- Object.keys(layoutBig).length === 0 -->
		<div :class="{
			'empty-holder': isEmptyData, 'max-screen-holder': !isEmptyData,
			'can-scroll can-scroll-x': !isLoading && !isEmptyData
		}">
			<LoadingSpinner v-if="isLoading" />
			<NoList v-else-if="isEmptyData" />
			<div v-if="layoutBig[selectId]" class="layout_holder" :style="resStyles('sres')">
				<img v-if="bgImage && refresher != 0" :src="bgImage" id="bg-image" :style="resStyles('ires')">
				<!-- <div class="zoom-icon-holder">
				<div class="zoom-icon frame vertical-center justify-center" role="button" @click="zoomIn">
					<img :src="ReiriIcons.zoom_in" />
				</div>
				<div class="zoom-icon frame vertical-center justify-center" role="button" @click="zoomOut">
					<img :src="ReiriIcons.zoom_out" />
				</div>
			</div> -->
				<!-- && Object.keys(layoutBig).length !== 0 -->
				<div v-if="!isLoading && !isEmptyData">
					<div v-for="typeObj, typeName in main_target " :key="typeObj">
						<div v-for="(itemObj, typeId) in typeObj" :key="typeId">

							<div v-if="notGroupOrGroupGotPoints(typeName, typeId)" role="button"
								@click="targetClicked(typeId, typeName)">
								<!-- <div v-if="target.type.includes('Av') && commModule.point_list[typeId].type == 'Dehumi' && isCommStatOn(target)"> -->
								<div class="humidity-holder" v-if="itemObjGot(itemObj, 'humi') && commModule.point_list[typeId] && 
									commModule.point_list[typeId].type == 'Dehumi' && isCommStatOn(typeId)"
									:style="itemStyles(itemObj.humi.pos[0], itemObj.humi.pos[1], 'humi', itemObj.humi.size)">
									<div class="status-text only-text bg-grey">
										{{ commModule.point_list[typeId].av + commModule.point_list[typeId].unit }}
									</div>
									<div class="status-text icon-text cool">
										<img :src="ReiriIcons.humid" class="to-white" />
										{{ commModule.point_list[typeId].hum_sp + commModule.point_list[typeId].unit }}
									</div>
								</div>

								<div class="vertical-center justify-center">
									<div v-if="itemObjGot(itemObj, 'label')" class="item-label one-line"
										:style="itemStylesByItemObj(itemObj, 'label')">
										<div v-if="typeName == 'layouts' && layoutBig[typeId]"> {{ layoutBig[typeId].name }} </div>
										<!-- -if="target.type.includes('Label')" -->
										<div v-else-if="getNameByKeyId(typeId)"> {{ getNameByKeyId(typeId) }} </div>
									</div>
									<div v-if="typeName != 'points' || isCommStatOn(typeId)">
										<!-- target.type.includes('Av') -->
										<div v-if="itemObjGot(itemObj, 'av')" class="status-text only-text bg-grey"
											:style="itemStylesByItemObj(itemObj, 'av')">
											{{ showAv(typeId) }}
										</div>
										<!-- target.type.includes('Mode') -->
										<div v-if="itemObjGot(itemObj, 'mode') && commModule.point_list[typeId]" class="only-icon item-mode"
											:class="getClasses(typeId, 'mode')" :style="itemStylesByItemObj(itemObj, 'mode')">
											<img v-if="commModule.point_list[typeId].mode == 'F'" :src="ReiriIcons.fan_1" />
											<img v-else :src="ReiriIcons.iconList.mode[commModule.point_list[typeId].mode]" />
										</div>
										<!-- target.type.includes('Sp') && -->
										<div v-if="itemObjGot(itemObj, 'sp') && commModule.point_list[typeId] && 
											!['D', 'F'].includes(commModule.point_list[typeId].mode) && commModule.point_list[typeId].sp_cap" 
											class="status-text only-text" :class="getClasses(typeId, 'sp')"
											:style="itemStylesByItemObj(itemObj, 'sp')">
											{{ pointSp(typeId) }}
										</div>
									</div>

									<div v-if="itemObjGot(itemObj, 'option') && commModule.group_list[typeId]" class="status-text"
										:style="itemStylesByItemObj(itemObj, 'option')">
										<div v-if="groupTargetPoint(typeId, 'temp') && showAvTemp(typeId)"
											class="group-option icon-text one-line">
											<!-- :style="iconTextIconStyle(itemObj.option.size)" -->
											<img :src="ReiriIcons.thermometer" class="thermo_icon" />
											{{ showAvTemp(typeId) }}
										</div>
										<div v-if="groupTargetPoint(typeId, 'value')" class="group-option value-unit one-line">
											{{ groupTargetPoint(typeId, 'value').av + groupTargetPoint(typeId, 'value').unit }}
										</div>
									</div>

									<div v-if="itemObjGot(itemObj, 'temp') && showTemp(typeId)" class="status-text icon-text bg-grey"
										:style="itemStylesByItemObj(itemObj, 'temp')">
										<img :src="ReiriIcons.thermometer" class="thermo_icon" />
										{{ showTemp(typeId) }}
									</div>

									<div v-if="itemObjGot(itemObj, 'meter') && commModule.point_list[typeId]" class="meter-type"
										:style="itemStylesByItemObj(itemObj, 'meter')">
										<div v-if="commModule.point_list[typeId].meter != undefined" class="status-text only-text">
											{{ commModule.point_list[typeId].meter + commModule.point_list[typeId].unit }}
										</div>
										<div v-if="commModule.point_list[typeId].value != undefined" class="status-text only-text">
											{{ commModule.point_list[typeId].value + commModule.point_list[typeId].sub_unit }}
										</div>
									</div>

									<div v-if="itemObjGot(itemObj, 'icon')" class="item-display item-icon"
										:style="itemStylesByItemObj(itemObj, 'icon')">
										<div v-if="typeName == 'points' && commModule.point_list[typeId] && !isCommStatOn(typeId)" class="white-transparent"></div>
										<div v-if="typeName == 'groups' && !anyDevicesOn(typeId)" class="white-transparent"></div>
										<div v-if="typeName == 'layouts' || isOtherTypeName(typeName, typeId)" class="icon-bg"
											:class="{ 'stat_on': getStatById(typeId), 'bg-grey': !getStatById(typeId) || typeName == 'layouts' }">
										</div>

										<div class="ch-master" v-if="typeName == 'points' && isCommStatOn(typeId) &&
											commModule.point_list[typeId] && commModule.point_list[typeId].ch_master == true">
											<img :src="ReiriIcons.ch_master" />
										</div>

										<div class="info-icon" v-if="typeName == 'groups'">
											<div v-if="showGroupError('error_red', typeId)" class="only-icon error-holder">
												<!-- :style="getErrorStyles(itemObj)" -->
												<img :src="ReiriIcons.warning" class="to-error" />
											</div>
											<div v-if="showGroupError('error_alert', typeId)" class="only-icon alert-holder">
												<img :src="ReiriIcons.warning" class="to-alert" />
											</div>
											<div v-if="showGroupError('error_blue', typeId)" class="only-icon error-blue-holder">
												<img :src="ReiriIcons.warning" class="to-blue" />
											</div>
										</div>

										<img v-if="typeName == 'layouts' && layoutBig[typeId]"
											:src="findIconByName(layoutBig[typeId].icon)" />
										<img v-else-if="isOtherTypeName(typeName, typeId)"
											:src="IconsHelper.iconMain(getObjByKeyId(typeId))" />
										<!-- <img v-else-if="typeName == 'scenes' && getNameByKeyId(typeId)"
											:src="IconsHelper.iconMain(getObjByKeyId(typeId))" />
										<img v-else-if="typeName == 'points' && getNameByKeyId(typeId)"
											:src="IconsHelper.iconMain(getObjByKeyId(typeId))" />
										<img v-else-if="typeName == 'groups' && getNameByKeyId(typeId)"
											:src="IconsHelper.iconMain(getObjByKeyId(typeId))" /> -->
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style>
.layout .cool {
	background-color: var(--cool);
	color: white;
}

.layout .red {
	background-color: var(--alert);
	color: white;
}

.layout .black {
	background-color: clear;
	color: black;
}

.layout .green {
	background-color: var(--fan);
	color: white;
}

.layout .group-option {
	background-color: var(--inputArea);
	width: fit-content;
	height: auto;
	border-radius: 12px;
	margin-bottom: 5px;
	padding: 3px 6px 3px 3px;
}

.layout .item-icon {
	width: 36px;
	height: 36px;
}

.layout .icon-bg {
	z-index: 30;
	width: 100%;
	height: 100%;
	position: absolute;
}

.layout .info-icon,
.layout .ch-master {
	position: absolute;
	z-index: 40 !important;
	text-align: center;
}

.layout .info-icon {
	top: -3px;
	left: -3px;
}

.layout .humidity-holder .status-text {
	margin-bottom: 3px;
}

.layout .ch-master {
	top: -11.5px;
	right: -3px;
	width: 10px !important;
	height: 10px !important;
}

/* .layout .ch-master img, */
.layout .info-icon img {
	width: 9px !important;
	height: 9px !important;
}

.layout .info-icon .only-icon {
	border-radius: 50%;
	padding: 1.5pt;
}

.layout .item-label {
	position: relative;
	z-index: 25 !important;
}

.layout .item-icon img {
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 30;
}

.layout .white-transparent {
	z-index: 40;
}

.layout .item-mode {
	z-index: 41;
}

.layout .item-display>img {
	width: 100%;
	height: 100%;
	padding: 2px 2.5px 2.5px 2px;
}

.layout .stat_on {
	background-color: var(--statOn);
}

.layout .bg-grey {
	background-color: var(--inputArea);
}

.layout .stat_on,
.layout .bg-grey,
.layout .white-transparent {
	border-radius: 10px;
}

.layout .status-text {
	font-size: 0.7em;
	margin-right: auto;
	border-radius: 12px;
	width: fit-content;
}

.layout .icon-text img {
	margin-right: 0;
	width: 18px;
}

.layout .only-icon img {
	width: 100%;
}

.layout .status-text.icon-text {
	padding: 3px 6px 3px 3px;
}

.layout .status-text.only-text,
.layout .group-option.value-unit {
	padding: 2px 6px;
}

.layout .status-text.only-icon {
	padding: 3px;
}

.layout .meter-type .status-text {
	background-color: var(--inputArea);
	margin-bottom: 5px;
}

.layout .info-icon>div {
	display: flex;
	align-items: center;
}

.layout .item-display {
	position: absolute;
	/* overflow: hidden; */
}

.layout .zoom-icon-holder {
	position: fixed;
	/* Position the icon absolutely within the parent container */
	top: 102px;
	right: 18px;
	z-index: 5;
}

.layout .zoom-icon {
	margin: 8px;
	padding: 0;
	width: 42px;
	height: 42px;
	position: relative;

}

.layout .max-screen-holder,
.layout .empty-holder {
	margin: 0 auto;
	width: 1010px;
}

.layout .max-screen-holder {
	background-color: white;
	height: 415px;
}

.layout .empty-holder {
	height: 480px;
}

.layout .layout_holder {
	background-color: white;
	position: relative;
}

.layout .sel_screen {
	justify-content: center;
	display: flex;
}

.layout .option_list {
	margin: 10px;
	width: 300px;
	justify-content: center;
	display: flex;
}
</style>

<script>
import SelectOption from './SelectOption.vue'
import NoList from './NoList.vue'
import LoadingSpinner from './LoadingSpinner.vue'
import PopUpToast from './PopUpToast.vue'

export default {
	name: "PageLayout",
	props: {
		commModule: Object,
	},
	data() {
		return {
			toast: {},
			// selectedScreen: "",
			dropDownList: [],
			layoutBig: {},
			bgImage: "",
			zoomLevel: 0, // Initial zoom level (50%)
			maxZoomLevel: 100, // Adjust this value as needed
			minZoomLevel: 50,  // Adjust this value as needed
			layout: {
				x: 603, // x-coordinate
				y: 191, // y-coordinate
				resolution: {
					x: 700, // Width of the layout in pixels
					y: 390,  // Height of the layout in pixels
				},
			},
			targetLi: [],
			isEmptyData: true,
			layoutIcon: '',
			isLoading: false,
			bgImageCache: {},
			targetkeyIds: [],
			selectId: 0,

			percentage: 0,
			zoomPercentage: 0,

			adtl: 'additional'
		}
	},
	created() {
		this.NavAssistant.push(this);
		this.commModule.getLayout();
		this.isLoading = true
		this.targetLi = this.MixList.get(['point', 'group', 'scene']);
		this.targetkeyIds = this.targetLi.map(item => item.key_id);
		// console.log(this.targetkeyIds)

		// TESTING
		// var point = this.commModule.point_list["dss3-dcpa1:1-00002"];
		// point.comm_stat = false;
		// TESTING
	},
	watch: {
		'commModule.layout_list'(layout) {
			this.isEmptyData = Object.keys(layout).length === 0 ? true : false;
			layout = this.legacyToNewFormat(layout);
			console.log(layout);
			// Alert
			// var cleanedGroupIds = this.commModule.getGroupsClean();

			// for (const layoutKey in layout) {
			// var eachLayout = layout[layoutKey];
			// eachLayout.target = eachLayout.target.filter(target =>
			// 	!target.type.includes('group') || cleanedGroupIds.includes(typeId));
			// }
			this.layoutBig = layout;
			console.log(this.layoutBig)
			// this.filterTargets();
			if (!this.isEmptyData) {
				this.isLoading = true;
				this.selectId = Object.keys(this.layoutBig)[0]; //this.layoutBig[Object.keys(this.layoutBig)[0]].name
				this.loadBgImage()
			}
			this.isLoading = false
			this.init()
		},
		'commModule.load_image'(img) {
			this.bgImage = img.data
			this.bgImageCache[this.selectId] = img.data
			this.isLoading = false;
			// this.updateBgDimension()
		},
		'commModule.scene_exec'(exec) {
			if (exec.result == 'OK')
				this.toast = { text: 'scene_success' }
		},
	},
	methods: {
		info(e) {
			// Data type is all over the place!
			let x = parseInt(e['x']);
			let y = parseInt(e['y']);
			// if (_lx < x) _lx = x;
			// if (_ly < y) _ly = y;

			let ret = {
				'pos': [x, y],
				'size': e['size'],
				'visible': true,
			};

			if (this.adtl in e) {
				ret[this.adtl] = e[this.adtl];
			}

			return ret;
		},
		defaultHiddenValues() {
			return {
				'pos': [0, 0],
				'size': 3,
				'visible': false
			};
		},
		legacyToNewFormat(layout) {
			let formattedData = {};
			console.log('layout = ', layout);

			Object.keys(layout).forEach((k) => {
				if (!Array.isArray(layout[k]['target'])) {
					formattedData[k] = layout[k];
					return;
				}
				let points = {};
				let groups = {};
				let scenes = {};
				let layouts = {};

				layout[k]['target'].forEach((e) => {
					if (e['x'] >= 0 && e['y'] >= 0) {
						// Points
						if (e['type'].includes('mpoints')) {
							let dat = this.commModule.point_list[e['id']];

							if (!points[e['id']]) points[e['id']] = {};
							let path = points[e['id']];

							if (dat && Object.keys(dat).length !== 0) {
								// Universal
								if (e['type'].includes('Label')) path['label'] = this.info(e);
								else if (!path['label']) path['label'] = this.defaultHiddenValues();
								path['label'][this.adtl] = dat['name'];

								if (e['type'].includes('Icon')) path['icon'] = this.info(e);
								else if (!path['icon']) path['icon'] = this.defaultHiddenValues();
								path['icon'][this.adtl] = dat['icon'];

								// Sort by Type instead of just spawning all the things blindly
								if (dat['type'] === 'Ac') {
									// AC needs Label, Icon, Mode, Sp, Temp
									if (e['type'].includes('Mode')) path['mode'] = this.info(e);
									else if (!path['mode']) path['mode'] = this.defaultHiddenValues();

									if (e['type'].includes('Sp')) path['sp'] = this.info(e);
									else if (!path['sp']) path['sp'] = this.defaultHiddenValues();

									if (e['type'].includes('Temp')) path['temp'] = this.info(e);
									else if (!path['temp']) path['temp'] = this.defaultHiddenValues();
								} else if (dat['type'] === 'Dehumi' || dat['type'] === 'Value' || dat['type'] === 'Level' || dat['type'] === 'LevelSw') {
									// Label, Icon, Av
									if (e['type'].includes('Av')) path['av'] = this.info(e);
									else if (!path['av']) path['av'] = this.defaultHiddenValues();
								} else if (dat['type'] === 'SMeter') {
									// Label, Icon, Meter
									if (e['type'].includes('Meter')) path['meter'] = this.info(e);
									else if (!path['meter']) path['meter'] = this.defaultHiddenValues();
								}
							}
						}
						// Groups
						if (e['type'].includes('group')) {
							let dat = this.commModule.group_list[e['id']];

							if (!groups[e['id']]) groups[e['id']] = {};
							let path = groups[e['id']];

							if (dat && Object.keys(dat).length !== 0) {
								if (e['type'].includes('Label')) path['label'] = this.info(e);
								else if (!path['label']) path['label'] = this.defaultHiddenValues();
								path['label'][this.adtl] = dat['name'];

								if (e['type'].includes('Icon')) path['icon'] = this.info(e);
								else if (!path['icon']) path['icon'] = this.defaultHiddenValues();
								path['icon'][this.adtl] = dat['icon'];

								if (dat['temp_targ'] || dat['value_targ']) {
									if (e['type'].includes('Option')) path['option'] = this.info(e);
									else if (!path['option']) path['option'] = this.defaultHiddenValues();
								}
							}
						}
						// Scene
						if (e['type'].includes('scene')) {
							let dat = this.commModule.scene_list[e['id']];

							if (!scenes[e['id']]) scenes[e['id']] = {};
							let path = scenes[e['id']];

							if (dat && Object.keys(dat).length !== 0) {
								if (e['type'].includes('Label')) path['label'] = this.info(e);
								else if (!path['label']) path['label'] = this.defaultHiddenValues();
								path['label'][this.adtl] = dat['name'];

								if (e['type'].includes('Icon')) path['icon'] = this.info(e);
								else if (!path['icon']) path['icon'] = this.defaultHiddenValues();
								path['icon'][this.adtl] = dat['icon'];
							}
						}
						// Layouts
						if (e['type'].includes('layout')) {
							// let dat = layout;

							if (!layouts[e['id']]) layouts[e['id']] = {};
							let path = layouts[e['id']];

							if (layout[e['id']] !== null) {
								if (e['type'].includes('Label')) path['label'] = this.info(e);
								else if (!path['label']) path['label'] = this.defaultHiddenValues();
								path['label']['additional'] = layout[e['id']]['name'];

								// Extra data needs to get from outside
								if (e['type'].includes('Icon')) path['icon'] = this.info(e);
								else if (!path['icon']) path['icon'] = this.defaultHiddenValues();
								path['icon']['additional'] = layout[e['id']]['icon'];
							}
						}
					}
				});

				// sres and ires are added later
				formattedData[k] = {
					'name': layout[k]['name'],
					'icon': layout[k]['icon'],
					'bgImage': layout[k]['bgImage'],
					'sres': layout[k]['sres'],
					'ires': layout[k]['ires'],
					'target': {
						'points': points,
						'groups': groups,
						'scenes': scenes,
						'layouts': layouts,
					},
					'timestamp': 0,
				};
			});

			console.log('Legacy To New formattedData = ', formattedData);
			return formattedData;
		},
		async updateBgDimension() {
			let image = new Image()
			image.src = this.bgImage
			await image.decode();
			this.layout.resolution.x = image.width
			this.layout.resolution.y = image.height
		},
		showAv(id) {
			var point = this.commModule.point_list[id];
			if (!point) return '';
			if (point.av != null) {
				if (point.unit == 'DEG') {
					return this.tempConvert.tempConvert(point.av).toFixed(1) + this.stringTable.word('DEG');
				} else {
					return point.av + point.unit;
				}
			} else return '';
		},
		showTemp(id) {
			var point = this.commModule.point_list[id];
			if (!point) return '';
			if (!point.comm_stat) return '';
			if (Object.prototype.hasOwnProperty.call(point, 'temp') && point.temp != null) {
				return this.tempConvert.tempConvert(point.temp).toFixed(1) + this.stringTable.word('DEG');
			} else return '';
		},
		showAvTemp(id) {
			var point = this.groupTargetPoint(id, 'temp');
			if (!point) return;
			if (Object.prototype.hasOwnProperty.call(point, 'av') && point.av != null) {
				return this.tempConvert.tempConvert(point.av).toFixed(1) +
					this.stringTable.word('DEG');
			} else if (Object.prototype.hasOwnProperty.call(point, 'temp') && point.temp != null) {
				return this.tempConvert.tempConvert(point.temp).toFixed(1) +
					this.stringTable.word('DEG');
			} else return;
		},
		filterTargets() {
			this.targetkeyIds = this.targetkeyIds.concat(Object.keys(this.layoutBig))
			for (const layoutKey in this.layoutBig) {
				if (Object.prototype.hasOwnProperty.call(this.layoutBig, layoutKey)) {
					const layout = this.layoutBig[layoutKey];
					if (Object.prototype.hasOwnProperty.call(layout, "target")) {
						layout.target = layout.target.filter(target => this.targetkeyIds.includes(target.id));
					}
				}
			}
		},
		findIconByName(iconName) {
			if (Object.prototype.hasOwnProperty.call(this.ReiriIcons.pointIconMap, iconName))
				return this.ReiriIcons.pointIconMap[iconName];
			if (Object.prototype.hasOwnProperty.call(this.ReiriIcons.groupIconMap, iconName))
				return this.ReiriIcons.groupIconMap[iconName];
			if (Object.prototype.hasOwnProperty.call(this.ReiriIcons.sceneIconMap, iconName))
				return this.ReiriIcons.sceneIconMap[iconName];
			return null;
		},
		targetClicked(id, type) {
			//layout['layoutLabel', 'layoutIcon'].includes(type)
			if (type == 'layouts') {
				this.selectId = id;// this.layoutBig[id].name
				return
			}
			const targetType = this.getTypeByKeyId(id)
			//point
			switch (targetType) {
				case 'point':
					var point = this.commModule.point_list[id];
					if (this.ConvertHelper.pointGotOper(point))
						this.NavAssistant.showPopUp({
							name: 'PopUpOperDev',
							details: point,
							commModule: this.commModule,
						}, this.popUpWithCache);
					break;
				case 'group':
					this.NavAssistant.showPopUp({
						name: 'PopUpDevices',
						details: this.commModule.group_list[id],
					});
					break;
				case 'scene':
					this.commModule.execScene(id);
					break;
			}
		},
		gotTrue(obj) {
			return obj ? Object.values(obj).includes(true) : false;
		},
		showGroupError(type, groupKey) {
			const group = this.commModule.group_list[groupKey]
			var canShow = false;
			if (!group) return false;
			group.mp.forEach(mp => {
				var point = this.commModule.point_list[mp];
				if (point &&
					((type == 'error_red' && point.comm_stat && this.gotTrue(point.error)) ||
						(type == 'error_alert' && point.comm_stat && this.gotTrue(point.alert)) ||
						(type == 'error_blue' && (!point.comm_stat)))
				) {
					canShow = true;
				}
			});
			return canShow;
		},
		init() {
			if (Object.keys(this.layoutBig).length != 0) {
				// this.dropDownList = [{ id: '', name: this.stringTable.word('nodata') }];
				// } else {
				this.dropDownList = [];
				for (const key in this.layoutBig) {
					var layout = this.layoutBig[key];
					console.log(layout)
					this.dropDownList.push({ id: key, name: layout.name })
				}
			}
			console.log(this.dropDownList)
		},
		isCommStatOn(pointId) {
			var point = this.commModule.point_list[pointId];
			return point ? point.comm_stat : false;
			// const targetType = this.getTypeByKeyId(typeId)
			// if (targetType == 'point') {
			// 	var point = this.commModule.point_list[typeId];
			// 	return point ? !point.comm_stat : false;
			// }
			// return false;
		},
		getTypeByKeyId(keyId) {
			const item = this.targetLi.find(item => item.key_id === keyId);
			return item ? item.icon_map : false;
		},
		getObjByKeyId(keyId) {
			const item = this.targetLi.find(item => item.key_id === keyId);
			return item ? item : {};
		},
		getNameByKeyId(keyId) {
			const item = this.targetLi.find(item => item.key_id === keyId);
			return item ? item.name : false;
		},
		pointOn(pId) {
			var point = this.commModule.point_list[pId];
			return point && (point.stat == 'on' && point.comm_stat);
		},
		getStatById(keyId) {
			const type = this.getTypeByKeyId(keyId)
			if (type == 'point') {
				return this.pointOn(keyId);
			} else if (type == 'group') {
				var group = this.commModule.group_list[keyId];
				var isOn = false;
				group.mp.forEach(p => {
					var pointOn = this.pointOn(p);
					if (pointOn) isOn = true;
				})
				return isOn;
			} else if (type == 'scene') {
				return this.commModule.scene_list[keyId].stat == 'on';
			} else
				return false
		},
		// zoomIn() {
		// 	if (this.zoomLevel < this.maxZoomLevel) {
		// 		this.zoomLevel += 10
		// 		document.getElementById('bg-image').height += this.zoomLevel
		// 		console.log(document.getElementById('bg-image').height)
		// 		console.log(document.getElementById('bg-image').width)
		// 		this.zoomPercentage = 1.028
		// 	}
		// },
		// zoomOut() {
		// 	if (this.zoomLevel > this.minZoomLevel) {
		// 		this.zoomLevel -= 10
		// 		document.getElementById('bg-image').height -= this.zoomLevel
		// 		this.zoomPercentage = 1.028
		// 	}
		// },
		// findKeyByName(targetName) {
		// 	for (const key in this.layoutBig) {
		// 		if (Object.prototype.hasOwnProperty.call(this.layoutBig, key) && this.layoutBig[key].name === targetName) {
		// 			return key;
		// 		}
		// 	}
		// 	return null; // Return null if not found
		// },
		changeOption(selectId) {
			this.selectId = selectId;
			this.loadBgImage()
		},
		loadBgImage() {
			this.bgImage = null
			if (Object.prototype.hasOwnProperty.call(this.bgImageCache, this.selectId)) {
				this.bgImage = this.bgImageCache[this.selectId]
				this.isLoading = false
				// this.updateBgDimension()
			} else {
				console.log(this.selectId)
				if (this.selectId) {//&& this.selectedScreen != this.stringTable.word('nodata')) {
					this.isLoading = true
					const bgImageName = this.layoutBig[this.selectId].bgImage//this.findKeyByName(this.selectedScreen)
					this.commModule.loadImage(bgImageName)
				}
			}
		},
		getClasses(targetId, type) {
			var point = this.commModule.point_list[targetId];
			var mode = point.mode;
			var stat = point.stat;
			if (stat == 'off') {
				return type == 'sp' ? 'black bg-grey' : 'black';
			}
			if (mode == 'H')
				return type == 'sp' ? "red" : "to-alert"
			else if (['C', 'D'].includes(mode))
				return type == 'sp' ? "cool" : "to-active"
			else if (mode == 'F')
				return type == 'sp' ? "green" : "to-green"
			// add A here 
			else if (mode == 'A' && type == 'sp') {
				if (point.actual_mode == 'C') return "cool"
				else if (point.actual_mode == 'H') return "red"
				else return 'black'
			} else return 'black'

		},
		resStyles(key) {
			var currLayout = this.layoutBig[this.selectId];
			var pos = currLayout[key];
			if (pos && key == 'ires') {
				var ires = currLayout.ires;
				var sres = currLayout.sres;
				if (ires == null || parseInt(ires[0]) > parseInt(sres[0])) pos[0] = sres ? sres[0] : 1280;
				if (ires == null || parseInt(ires[1]) > parseInt(sres[1])) pos[1] = sres ? sres[1] : 720;
			}
			console.log(pos)
			return { width: pos ? `${pos[0]}px` : '1280px', height: pos ? `${pos[1]}px` : '720px' };
		},
		itemObjGot(itemObj, accessKey) {
			return accessKey in itemObj && itemObj[accessKey].visible;
		},
		itemStylesByItemObj(itemObj, accessKey) {
			var itemDetails = itemObj[accessKey];
			if (itemDetails)
				return this.itemStyles(itemDetails.pos[0], itemDetails.pos[1], accessKey, itemDetails.size, false);
		},
		itemStyles(x, y, accessKey, size, widthNheightOnly) {
			// Calculate the position of the icon based on coordinates and zoom level
			// const zoomFactor =  / 100;
			// const iconX = (x * zoomFactor / this.layout.resolution.x) * 100;
			// const iconY = (y * zoomFactor / this.layout.resolution.y) * 250;
			// const iconX = x + (size * this.zoomPercentage);
			// const iconY = y + (size * this.zoomPercentage);
			const isIcon = accessKey.includes('icon');
			const iconX = x;// accessKey == 'av' ? x - (2 * size) : x;
			const iconY = y;
			// const iconSize = 50 * zoomFactor * size; // Adjust as needed
			var iconSize;
			if (accessKey == 'top_icon') iconSize = 15 + (size * 2);
			// else iconSize = 27 + (size * 9); // Adjust as needed
			// var rect = document.body.getBoundingClientRect();
			// console.log(rect.top, rect.right, rect.bottom, rect.left);
			var res = {
				left: `${iconX}px`,
				top: `${iconY}px`,
				width: isIcon ? `${iconSize}px` : 'auto',
				height: isIcon ? `${iconSize}px` : 'auto',
			}
			// if (accessKey != 'icon') res.fontSize = `${0.45 + size * 7 / 100}em`;
			if (['av', 'humi', 'sp', 'temp', 'meter', 'option'].includes(accessKey))
				res.transform = `scale(${0.4 + size * 18 / 100})`;
			else if (accessKey == 'label')
				res.transform = `scale(${0.52 + size * 92 / 1000})`;
			else if (accessKey == 'icon')
				res.transform = `scale(${0.65 + size * 33 / 100})`;
			else if (accessKey == 'mode') {
				res.width = `${15 + size * 5}px`;
				res.transform = `scale(${0.85 + size * 5 / 100})`;
			}
			if (['icon', 'mode', 'label', 'av', 'humi', 'sp', 'temp', 'meter', 'option'].includes(accessKey)) {
				res['transform-origin'] = 'top left';
				res.position = 'absolute';
			}
			if (widthNheightOnly) {
				delete res.left;
				delete res.top;
			}
			return res;
		},
		// getErrorStyles(itemObj) {
		// 	return this.itemStyles(itemObj.icon.pos[0] - (4 + itemObj.icon.size), itemObj.icon.pos[1] - (2 + itemObj.icon.size * 2),
		// 		'top_icon', itemObj.icon.size, true);
		// },
		iconTextIconStyle(size) {
			return { width: `${7 + size * 2}px` }
		},
		// notPointOrOnline(target) {
		// 	return this.getTypeByKeyId(target.id) != 'point' ||
		// 		this.isCommStatOn(target);
		// },
		groupTargetPoint(id, name) {
			var point = this.commModule.point_list[this.commModule.group_list[id][`${name}_targ`]];
			if (point && !point.comm_stat) return false;
			return point;
		},
		pointSp(id) {
			var point = this.commModule.point_list[id];
			var sp;
			var mode = point.mode;
			if (mode == 'A') {
				if (point.actual_mode == 'C') mode = 'C';
				else if (point.actual_mode == 'H') mode = 'H';
			}
			if (mode == 'C') sp = point.csp;
			else if (mode == 'H') sp = point.hsp;
			if (sp == undefined) return '';
			return `${this.tempConvert.spConvert(sp).toFixed(1)}${this.stringTable.word('DEG')}`
		},
		groupPoints(groupId) {
			var group = this.commModule.group_list[groupId];
			if (!group) return [];
			var points = [];
			group.mp.forEach(point_key => {
				var point = this.commModule.point_list[point_key];
				if (point) points.push(point);
			});
			return points;
		},
		anyDevicesOn(groupId) {
			return this.groupPoints(groupId).filter(point => point && point.comm_stat).length > 0;
		},
		notGroupOrGroupGotPoints(typeName, typeId) {
			if (typeName != 'groups') return true;
			return this.groupPoints(typeId).length > 0;
		},
		isOtherTypeName(typeName, typeId) {
			return ['points', 'groups', 'scenes'].includes(typeName) && this.getNameByKeyId(typeId);
		}
	},
	computed: {
		// selectId() {
		// 	return this.selectId;// this.findKeyByName(this.selectedScreen);
		// },
		main_target() {
			if (!this.selectId) return;
			return this.layoutBig[this.selectId] ? this.layoutBig[this.selectId].target : {};
		},
	},
	components: {
		PopUpToast,
		SelectOption,
		NoList,
		LoadingSpinner,
	},
}
</script>


<!-- <img :src="ReiriIcons.thermometer" class="thermo_icon " :class="{ 'to-white': pointOn(typeId) }"
	:style="iconTextIconStyle(target.size)" /> -->
<!-- {{ commModule.point_list[typeId] }} -->
<!-- <div v-if="notPointOrOnline(target) && target.type == 'mpointsIcon'"> -->
<!-- <div v-if="itemObjGot(itemObj, 'icon') && typeName == 'points' && isCommStatOn(typeId)">
	<div class="item-display ch-master" v-if="commModule.point_list[typeId].ch_master == true"
		:style="itemStyles(itemObj.icon.pos[0] + (itemObj.icon.size * 7) + 19, itemObj.icon.pos[1] - 4, 'top_icon', itemObj.icon.size)">
		<img :src="ReiriIcons.ch_master" />
	</div>
</div> -->
<!-- target.type == 'groupIcon' -->
<!-- <div v-if="itemObjGot(itemObj, 'icon') && typeName == 'groups'" class="item-display info-icon"
	:style="itemStyles(itemObj.icon.pos[0] - (4 + itemObj.icon.size), itemObj.icon.pos[1] - (itemObj.icon.size * 1), '', itemObj.icon.size)">
	<div v-if="showGroupError('error_red', typeId)" class="detail-frame only-icon error-holder"
		:style="getErrorStyles(itemObj)">
		<img :src="ReiriIcons.warning" class="to-error" />
	</div>
	<div v-if="showGroupError('error_alert', typeId)" class="detail-frame only-icon alert-holder"
		:style="getErrorStyles(itemObj)">
		<img :src="ReiriIcons.warning" class="to-alert" />
	</div>
	<div v-if="showGroupError('error_blue', typeId)" class="detail-frame only-icon error-blue-holder"
		:style="getErrorStyles(itemObj)">
		<img :src="ReiriIcons.warning" class="to-blue" />
	</div>
</div> -->
<!-- ---------------------------------------<br> -->
<!--<div class=" item-display vertical-center justify-center"
:style="itemStyles(itemObj.icon.pos[0], itemObj.icon.pos[1], target.type, target.size)"
:class="{ 'bg-grey': !target.type.includes('Label') && !target.type.includes('Meter') && (['sceneIcon', 'mpointsIcon', 'groupIcon'].includes(target.type) && getNameByKeyId(typeId)) || target.type == 'layoutIcon' || target.type.includes('Av') || target.type.includes('Sp') || target.type.includes('Temp') }">
<div v-if="target.type == 'mpointsIcon' && isCommStatOff(target)" class="white-transparent"></div>
<div v-if="['mpointsLabel', 'mpointsIcon'].includes(target.type) || notPointOrOnline(target)"> ## included
</div>
</div>-->