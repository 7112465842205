<template>
  <div class="bar-rep-settings frame">
    <div class="vertical-center">
      <div class="title" @click="toggleBottom()" role="button">
        {{ stringTable.word(title) }}
      </div>
      <BtnSwitch name="main" :default="mainSwitch" />
    </div>
    <div v-if="showBottom" class="bottom-holder can-scroll">
      <div class="bar-plus-holder">
        <div class="bar-row-holder">
          <div v-for="(category, category_id) in categorys" :key="category" class="bar-row">
            <div class="frame vertical-center">
              <BtnSwitch :name="category_id" :default="category.is_enabled" />
              <div class="naming" @click="$parent.clickEnergyCategory(category)" role="button">
                {{ category.name }}
              </div>
              <img :src="ReiriIcons.delete" class="btn-delete to-error" @click="$parent.deleteCategory(category_id)"
                role="button" />
            </div>
          </div>
        </div>
        <div v-if="categorys" class="plus-icon to-active" @click="$parent.clickEnergyCategory()" role="button">+</div>
      </div>
      <BarCheckBoxes :items="points" :checkedString="`checked_${title}`" />
    </div>
  </div>
</template>

<style>
.bar-rep-settings .title {
  width: 100%;
  margin-bottom: 4px;
}

.bar-rep-settings .bottom-holder {
  max-height: 120px;
  overflow-y: scroll;
  padding: 6px 0 0 0;
}

.bar-rep-settings .bottom-holder .bar-plus-holder {
  display: flex;
  align-items: top;
}

.bar-rep-settings .bottom-holder .bar-row-holder {
  width: 650px;
}

.bar-rep-settings .bottom-holder .bar-row {
  width: 640px;
  margin-top: 6px;
  padding: 0 8px;
}

.bar-rep-settings .bottom-holder .frame {
  padding: 4px 10px !important;
}

.bar-rep-settings .bottom-holder .btn-delete {
  margin-left: auto;
}

/* .bar-rep-settings .form-check-input {
  margin-left: auto;
} */
</style>

<script>
import BtnSwitch from './BtnSwitch.vue'
import BarCheckBoxes from './BarCheckBoxes.vue'

export default {
  props: {
    title: String,
    mainSwitch: Boolean,
    categorys: Array,
    points: Array,
    checkedString: String
  },
  data() {
    return {
      onSwitch: false,
      showBottom: false,
      firstLoad: true,
    }
  },
  methods: {
    changeSwitch(switchName, onSwitch) {
      if (switchName == 'main') {
        if (!this.firstLoad)
          this.showBottom = onSwitch;
        this.onSwitch = onSwitch;
        this.firstLoad = false;
      }
      this.$parent.changeSwitch(this.title, switchName, onSwitch);
    },
    toggleBottom() {
      if (!this.onSwitch) this.showBottom = false;
      else this.showBottom = !this.showBottom;
    },
    clickedCheckBox(newPoint) {
      this.$parent.clickedCheckBox(newPoint);
    },
  },
  components: {
    BtnSwitch,
    BarCheckBoxes
  },
}
</script>
