<template>
  <div class="bars-check-boxes">
    <div v-for="item, index in items" :key="item" class="form-check vertical-center">
      <input class="form-check-input" type="checkbox" :id="item_id(item, index)" v-model="item[checkedString]"
        @change="clickedCheckBox(item)">
      <label class="form-check-label vertical-center" :for="item_id(item, index)" role="button">
        <div v-if="!hideIcon" class="icon-holder"><img :src="IconsHelper.iconMain(item)" /></div>
        <div class="can-scroll-x one-line">
          <div v-if="isTarget" :class="{ 'label-spacing': hideIcon }">
            {{ getNameByKeyId(item.name) ? getNameByKeyId(item.name) : stringTable.word(item.name) }}
          </div>
          <div v-else :class="{ 'label-spacing': hideIcon }">
            {{ showStringTableValue ? stringTable.word(item.name) : item.name }}
          </div>
        </div>
      </label>
    </div>
  </div>
</template>

<style>
.form-check {
  padding-left: 0;
}

.bars-check-boxes {
  padding: 0 6px 3px 32px;
  width: 100%;
}

.bars-check-boxes .form-check {
  padding-top: 3px;
  padding-right: 0;
}

.bars-check-boxes .form-check,
.bars-check-boxes .form-check-label {
  width: inherit;
}

.bars-check-boxes .icon-holder {
  margin: 0 6px 0 8px;
}

.bars-check-boxes .label-spacing {
  margin: 0 6px;
}
</style>

<script>
export default {
  name: "BarCheckBoxes",
  props: {
    items: Array,
    checkedString: String,
    hideIcon: Boolean,
    showStringTableValue: Boolean,
    isTarget: Boolean,
    commModule: Object,
  },
  methods: {
    getNameByKeyId(keyId) {
      const targetLi = this.MixList.get(['point']);
      const item = targetLi.find(item => item.key_id === keyId);
      return item ? item.name : false;
    },
    clickedCheckBox(item) {
      this.$parent.clickedCheckBox(item);
    },
    item_id(item, index) {
      var id = item.key_id;
      if (!id) id = index;
      return `${id}_${this.checkedString}`;
    }
  },
  computed: {
  }
}

</script>
