<template>
  <div class="bar-droppable bar-spacing frame ac" :class="{ 'got-slider': showSliderAndValue }">
    <div class="upper-content vertical-center" @click="toggleSlider">
      <div class="label">
        <img v-if="icon" :src="icon" />
        {{ label }}
        <div v-if="Array.isArray(sliderValue)" class="ac-value" v-show="showACValue && showSliderAndValue">{{
          sliderValue[0].toFixed(1) }}{{ stringTable.word('DEG') }} -
          {{ sliderValue[1].toFixed(1) }}{{ stringTable.word('DEG') }} </div>
        <div v-else class="ac-value" v-show="showACValue && showSliderAndValue">
          {{ sliderValue.toFixed(1) }}{{ stringTable.word('DEG') }}
        </div>
      </div>
      <BtnSwitch v-if="showSwitch" :default="switchVal" class="switch" :name="type" />
      <div v-if="topRightBtn" class="frame btn top-right-btn" @click="topRightBtnClicked">
        {{ stringTable.word(topRightBtn) }}
      </div>
    </div>
    <div class="bottom-content" v-show="showSlider && showSliderAndValue">
      <vue-slider v-model="sliderValue" :tooltip="'always'" :min="sliderMin" :max="sliderMax" :marks="marks"
        :interval="interval ? interval : 0.5" :min-range="1"></vue-slider>
    </div>
  </div>
</template>
<style>
.bar-spacing {
  margin-bottom: 10px;
}

.bar-droppable.ac {
  padding: 6px;
}

.bar-droppable .ac-value {
  padding-left: 8px;
  color: #25bcf5;
}

.bar-droppable .bottom-content {
  padding: 38px 15px 18px 16px;
}

.bar-droppable .label>img {
  margin-right: 14px;
}

.bar-droppable .switch,
.bar-droppable .top-right-btn {
  margin-left: auto;
}

.bar-droppable .top-right-btn {
  color: var(--active);
}

.vue-slider-ltr .vue-slider-mark-label,
.vue-slider-rtl .vue-slider-mark-label {
  margin-top: 4px;
}
</style>

<script>
import BtnSwitch from './BtnSwitch.vue'
import { reactive, toRefs } from 'vue'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  setup() {
    const data = reactive({ value: 0 })
    return toRefs(data)
  },
  name: "DroppableAc",
  props: {
    icon: String,
    label: String,
    topRightBtn: String,
    showSwitch: Boolean,
    switchVal: Boolean,
    showSliderAndValue: Boolean,
    openSlider: Boolean,
    firstVal: Number,
    secondVal: Number,
    type: String,
    range: Array,
    interval: Number,
    isOperGroup: Boolean,
    isAv: Boolean,
  },
  data() {
    return {
      showSlider: false,
      sliderValue: 0,
      marks: [],
      showACValue: false,
      timer: null,
      firstLoad: true,
      sliderMin: 0,
      sliderMax: 0,
    }
  },
  created() {
    (this.range) ? this.marks = this.range : this.marks = [16, 32]
    this.sliderMin = JSON.parse(JSON.stringify(this.marks[0]))
    this.sliderMax = JSON.parse(JSON.stringify(this.marks[1]))
    this.marks = this.marks.reduce((result, value) => {
      result[value.toString()] = `${value.toFixed(1)}${this.isAv ? '%' : this.stringTable.word('DEG')}`;
      return result;
    }, {});
    if (this.firstVal && this.secondVal)
      this.sliderValue = [this.firstVal, this.secondVal];
    else if (this.firstVal) {
      this.sliderValue = JSON.parse(JSON.stringify(this.firstVal));
    }
    if (this.openSlider) this.showSlider = true;
  },
  watch: {
    sliderValue() {
      if (!this.firstLoad) {
        this.startTimer()
      }
      this.firstLoad = false;
    }
  },
  methods: {
    topRightBtnClicked() {
      this.$parent.topRightBtnClicked(this.type)
    },
    startTimer() {
      // Check if there's an existing timer, and if so, clear it
      if (this.timer)
        clearTimeout(this.timer);
      // Set a new timer to perform your function after 5 seconds
      this.timer = setTimeout(() => {
        this.$parent.changeSliderValue(this.sliderValue, this.type);
      }, 1000);
    },
    toggleSlider() {
      if (this.openSlider) return;
      this.showSlider = !this.showSlider;
    },
    changeSwitch(name, newSwitch) {
      this.firstLoad = false;
      this.showACValue = newSwitch;
      this.$parent.changeRightSwitch(newSwitch, this.type, this.sliderValue);
    }
  },
  components: {
    BtnSwitch,
    VueSlider
  }
}
</script>
