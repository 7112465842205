<template>
  <div v-if="!showSceneEditAction" class="pop-up user device-setting group-setting scene-setting system-setting">
    <div class="content-holder">
      <div class="left-content ">
        <PopUpHead :details="details" :hideBorderBtm="true" :leftArr="true" :small="true" :plus="true" />
        <LoadingSpinner v-if="isLoading" :topSpacing="true" />
        <div v-else>
          <div class="nav-list">
            <div v-for="key in slicedList" :key="key">
              <NavHorizontal v-if="sceneObj[key]" class="frame" :icon="IconsHelper.iconMain(sceneObj[key])"
                :name="sceneObj[key].name" hideKeyValue="true" showDescription="false"
                @click="sceneSelected({ key, value: sceneObj[key] })" />
            </div>
          </div>
          <div class="vertical-center">
            <NavPagination direction="up" :expandHori="true" />
            <NavPagination direction="down" :expandHori="true" />
          </div>
        </div>
      </div>
      <div class="right-frame">
        <PopUpToast />
        <PopUpHead v-if="showEdit" :details="head_detail" :leftBtnTxt="!isRegister ? 'delete' : ''" rightBtnTxt="save"
          :hideBorderBtm="true" />
        <div class="edit-section can-scroll" v-show="sceneDetails != '' && showEdit">
          <div class="frame main-icon-holder">
            <img class="icon" :src="IconsHelper.iconMain(sceneDetails)" />
            <div class="icon-edit">
              <img :src="ReiriIcons.edit_2" @click="iconSelectionClicked" class="to-white" />
            </div>
          </div>
          <!-- {{ sceneDetails }} -->
          <!-- name -->
          <FrameInput v-if="sceneDetails.hasOwnProperty('name')" varStr="sceneDetails" varStr2="name"
            placeholder="name" />
          <!-- select device title -->
          <div class="mail-addr">
            <div class="nomargin-content vertical-center ">
              <div class="left-text">
                <div>{{ stringTable.word("scene_action") }}</div>
              </div>
              <div class="right-content">
                <img class="add-rotate add-device-btn" :src="ReiriIcons.cross" @click="addNewDevice" />
              </div>
            </div>
          </div>
          <div class="scene-action can-scroll">
            <div v-for="(value, key) in sceneDetails.action" :key="key">
              <div v-if="clonedCommModule.point_list[key]">
                <FrameAction :label="clonedCommModule.point_list[key].name"
                  :pointKey="clonedCommModule.point_list[key].key_id" :actionDesc="value" :alignDescValRight="true"
                  @click="sceneActionClicked({ 'pointId': key, 'action': value })" :commModule="commModule" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <SceneEditAction v-if="showSceneEditAction" :isNewAction="isNewAction" :commModule="clonedCommModule"
    :details="sceneDetails" :actionDetails="pointAction" :showLast="true" @updated-action-details="updateAction">
  </SceneEditAction>
</template>

<style>
.scene-setting .item-text,
.group-setting .item-text,
.schedule-setting .item-text,
.interlock .item-text,
.auto-changeover .item-text,
.device-page .item-text,
.user-page .item-text {
  width: 210px;
}

.scene-setting .nav-list .frame {
  margin-bottom: 5.8px;
}

.auto-changeover .nav-list .frame,
.schedule-setting .program-list .frame {
  margin-bottom: 5.2px;
}

.scene-setting .nav-list .frame {
  padding: 2.7px 4px;
}

.scene-setting .edit-section {
  height: 550px !important;
  overflow-y: unset;
}

.scene-setting .scene-action {
  margin-top: 6px;
  overflow-y: scroll;
  max-height: 320px;
  padding: 4px;
}
</style>

<script>
import PopUpHead from './PopUpHead.vue'
import NavHorizontal from './NavHorizontal.vue'
import FrameInput from './FrameInput.vue'
import FrameAction from './FrameAction.vue'
import SceneEditAction from './SceneEditAction.vue'
import PopUpToast from './PopUpToast.vue'
import LoadingSpinner from './LoadingSpinner.vue'
import NavPagination from './NavPagination.vue'

export default {
  name: "PopUpSceneSetting",
  data() {
    return {
      details: { name: "scene" },
      clonedCommModule: {},
      clonedSceneList: {},
      clonedPointList: {},
      showEdit: false,
      sceneDetails: {
        "name": '', "icon": 'DefaultScene.png', "action": {}, "icon_map": "scene"
      },
      sceneId: '',
      isRegister: false,
      pointAction: { 'pointId': '', 'action': {} },
      showSceneEditAction: false,
      isNewAction: false,
      toast: {},
      isLoading: false,
      isError: false,
      page: 1,
      itemPerPage: 11,
      slicedList: [],
      isAddNewItem: false
    }
  },
  props: {
    commModule: Object,
    cache: Object,
  },
  created() {
    this.clonedCommModule = Object.assign({}, this.commModule);
    this.clonedSceneList = this.commModule.scene_list
    this.clonedPointList = this.commModule.point_list
  },
  mounted() {
    if (this.cache) {
      this.showEdit = true
      this.sceneDetails = this.cache.content;
      this.sceneId = this.cache.pointId;
      this.isRegister = this.cache.isRegister
      this.page = this.cache.page
    } else {
      this.getScenes()
      this.isLoading = true;
    }
  },
  computed: {
    head_detail() {
      var name = '';
      if (this.showEdit)
        name = this.isRegister ? 'add_scene' : 'edit_scene';
      return { name }
    },
    sceneObj() {
      return this.clonedSceneList;
    },
    mainList() {
      return Object.keys(this.sceneObj)
    },
  },
  watch: {
    'commModule.scene_list'() {
      this.commModule.changeVariable("enableSendScene", true);
      this.clonedCommModule = Object.assign({}, this.commModule);
      this.clonedSceneList = this.clonedCommModule.scene_list
      this.isLoading = false;
      this.isError = false
      if (this.isAddNewItem) {
        this.page = this.ConvertHelper.getMaxPage(this.mainList.length, this.itemPerPage);
        this.isAddNewItem = false;
      }
    },
    'commModule.scene_modify_scene'() {
      if (this.commModule.scene_modify_scene.result == "OK") {
        this.toast = { text: 'succeed' }
        this.showEdit = false
        this.getScenes()
        this.commModule.changeVariable("enableSendScene", true);
        this.clonedCommModule = Object.assign({}, this.commModule);
      } else { //error
        this.toast = { text: this.commModule.scene_modify_scene.result }
      }
    },
    'commModule.scene_add_scene'() {
      if (this.commModule.scene_add_scene.result != "NG") {
        this.toast = { text: 'succeed' }
        this.showEdit = false
        this.sceneId = Object.keys(this.commModule.scene_add_scene)[0]
        this.sceneDetails = this.commModule.scene_add_scene[this.sceneId]
        this.getScenes()
        this.commModule.changeVariable("enableSendScene", true);
        this.clonedCommModule = Object.assign({}, this.commModule);
        this.isAddNewItem = true;
      } else { //error
        this.toast = { text: this.commModule.scene_add_scene.result }
      }
    },
    'commModule.scene_delete_scene'() {
      if (this.commModule.scene_delete_scene.result == "OK") {
        this.toast = { text: 'succeed' }
        this.showEdit = false
        this.getScenes()
        this.commModule.changeVariable("enableSendScene", true);
        this.clonedCommModule = Object.assign({}, this.commModule);
      } else { //error
        this.toast = { text: this.commModule.scene_delete_scene.result }
      }
    },
  },
  methods: {
    clickLeftBtn() {
      this.toast = {
        text: 'conf_del',
        pendingYesMethod: () => {
          this.commModule.delScene(this.sceneId);
        }
      }
    },
    plus() {//addScene
      this.sceneDetails = { "name": '', "icon": 'DefaultScene.png', "action": {}, "icon_map": "scene" };
      this.isRegister = true;
      this.showEdit = true;
    },
    prev() {
      this.showEdit = false;
      this.sceneDetails = '';
    },
    clickRightBtn() {
      if (this.ConvertHelper.isEmptyString(this.sceneDetails.name)) {
        this.toast = { text: 'no_name', isError: true, }
        return
      }
      if (Object.keys(this.sceneDetails.action).length === 0) {
        this.toast = { text: 'no_action', isError: true, }
        return
      }
      if (this.isRegister) {
        this.commModule.addScene(this.sceneDetails);
      } else {
        const finalScene = {}
        finalScene[this.sceneId] = this.sceneDetails
        this.commModule.editScene(finalScene);
      }
    },
    getScenes() {
      this.commModule.getScenes();
      this.isError = false
    },
    deleteAction(pointKey) {
      this.toast = {
        text: 'conf_del',
        pendingYesMethod: () => {
          delete this.sceneDetails.action[pointKey]
        }
      }
    },
    updateAction(newAction) {
      const pointKey = newAction.pointId
      const action = newAction.action
      this.sceneDetails.action[pointKey] = action
    },
    addNewDevice() {
      this.pointAction = { 'pointId': '', 'action': {} }
      this.NavAssistant.openPopUp(this);
      this.showSceneEditAction = true
      this.isNewAction = true
    },
    changeOption() {
    },
    sceneActionClicked(item) {
      this.pointAction = item
      this.NavAssistant.openPopUp(this);
      this.showSceneEditAction = true
      this.isNewAction = false
    },
    sceneSelected(item) {
      this.getScenes()
      this.isRegister = false
      this.showEdit = true
      this.sceneDetails = Object.assign({}, item.value);
      this.sceneId = item.key
    },
    iconSelectionClicked() {
      this.NavAssistant.showPopUp({
        name: 'PopUpIcons',
        details: {
          pointId: this.sceneId,
          content: this.sceneDetails,
          iconMap: 'sceneIconMap',
          isRegister: this.isRegister,
          page: this.page
        },
        page: this.$parent.page
      }, true);
    },
  },
  components: {
    PopUpHead,
    NavHorizontal,
    FrameInput,
    FrameAction,
    SceneEditAction,
    PopUpToast,
    LoadingSpinner,
    NavPagination
  }
}
</script>