
export class IconsHelper {
  constructor(ReiriIcons) {
    this.ReiriIcons = ReiriIcons;
    this.typeMapName = {
      point: 'pointIconMap',
      group: 'groupIconMap',
      scene: 'sceneIconMap',
    }
  }

  iconMain(details) {
    var mapObj = this.ReiriIcons[this.typeMapName[details.icon_map]];
    var srcStr = mapObj ? mapObj[details.icon] : null;
    if (!srcStr)
      if (this.ReiriIcons[details.icon]) return this.ReiriIcons[details.icon];
      else return this.ReiriIcons.default_group;
    return srcStr;
  }
}