<template>
  <div class="list-auto-change info-box vertical-top">
    <div class="upper-content-points-holder">
      <div class="upper-content-points" v-for="pointKey in slicedList" :key="pointKey">
        <div v-if="commModule.point_list[pointKey]" class="left-text input-add point-tile frame">
          <!-- v-show="points[pointKey]" -->
          <div class="icon-holder">
            <img class="icon-point" :src="IconsHelper.iconMain(commModule.point_list[pointKey])" />
          </div>
          <div>
            <div>{{ commModule.point_list[pointKey].name }}</div>
            <div v-if="isVote" class="vertical-center weight">
              <div> {{ stringTable.word("weight") + ': ' }}</div>
              <!-- <input class="input-unit" :type="'text'" v-model="groupDetails.weight[pointKey]" /> -->
              <SelectOption class="weight-input" name="weightChangeover" :selectList="weightOptions"
                :defaultSelectIndex="defaultWeightIndex(pointKey)" :hideArrow="true"
                v-model="$parent.groupDetails.weight[pointKey]" />
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="pagin-holder">
      <NavPagination direction="up" :noNull="true" />
      <NavPagination direction="down" :noNull="true" />
    </div>
  </div>
</template>

<style>
.list-auto-change .upper-content-points-holder {
  flex: 1;
  margin-right: 12px;
}
</style>

<script>
import NavPagination from './NavPagination.vue'
import SelectOption from './SelectOption.vue'

export default {
  name: "ListAutoChangeover",
  props: {
    commModule: Object,
    points: Object,
    isVote: Boolean,
    groupDetails: Object,
  },
  data() {
    return {
      weightOptions: [0, 1, 2, 3],
      page: 1,
      // itemPerPage: 4,
      slicedList: [],
    }
  },
  created() {
  },
  watch: {
    points() {
      this.page = 1;
    },
  },
  methods: {
    defaultWeightIndex(key) {
      if (this.isRegister || this.groupDetails.weight[key] == null) {
        return 1
      } else {
        return this.weightOptions.indexOf(this.groupDetails.weight[key]);
      }
    },
    changeOption() {
    },
  },
  computed: {
    mainList() {
      var res = [];
      if (!this.points) return [];
      for (const key in this.points) {
        if (this.points[key])
          res.push(key);
      }
      return res;
    },
    itemPerPage() {
      return this.isVote ? 4 : 6;
    }
  },
  components: {
    NavPagination,
    SelectOption
  }
}
</script>
