<template>
  <div class="pop-up device-detail lang">
    <PopUpHead :details="details" :showIcon="false" :showLast="true" :hideBorderBtm="true" :leftArr="true"
      :hideCross="true" />
    <div class="lang-holder frame">
      <div v-for="selected, l in allLangs" :key="l" class="lang-bar" @click="clickLang(l)" role="button"
        :class="{ 'reverse-frame': selected }">
        {{ stringTable.word(l) }}
      </div>
    </div>
  </div>
</template>

<style>
.pop-up.lang .lang-holder {
  width: 500px;
  margin: 8px auto;
  font-size: 1.2em;
}

.pop-up.lang .lang-bar {
  padding: 10px 16px;
}

.pop-up.lang .pop-up-head {
  height: 60px;
  padding: auto;
}

.pop-up.lang .icon-text-label {
  font-size: 25px;
}
</style>

<script>
import PopUpHead from "./PopUpHead.vue";

export default {
  name: "PopUpLanguage",
  data() {
    return {
      allLangs: {
        EN: false, BR: false, ES: false,
        VN: false, THAI: false, BAH: false,
        CH: false, TW: false, HK: false, JP: false
      },
      lang: "EN",
      details: { name: "sel_lang" },
      oriLang: "EN",
    }
  },
  props: {
  },
  created() {
    this.oriLang = localStorage.getItem('Lang')
    this.lang = localStorage.getItem('Lang')
    if (this.lang == undefined) this.lang = 'EN'
    if (this.oriLang == undefined) this.lang = 'EN'

    for (const key in this.allLangs)
      this.allLangs[key] = this.lang == key;
  },
  watch: {
  },
  methods: {
    clickLang(l) {
      for (const key in this.allLangs)
        this.allLangs[key] = false;
      this.allLangs[l] = true;
      this.lang = l;
      if (this.lang != null) { // && this.lang != this.oriLang
        localStorage.setItem('Lang', this.lang);
        this.stringTable.setLang(this.lang);
      }
      this.details = { name: "sel_lang" }
    }
  },
  components: {
    PopUpHead
  },
}
</script>
<!-- <div class="input-holder frame">
  <select v-model="lang">
    <option value='EN'>{{ stringTable.word('EN') }}</option>
    <option value='ES'>{{ stringTable.word('ES') }}</option>
    <option value='BR'>{{ stringTable.word('BR') }}</option>
    <option value='VN'>{{ stringTable.word('VN') }}</option>
    <option value='THAI'>{{ stringTable.word('THAI') }}</option>
    <option value='BAH'>{{ stringTable.word('BAH') }}</option>
    <option value='CH'>{{ stringTable.word('CH') }}</option>
    <option value='TW'>{{ stringTable.word('TW') }}</option>
    <option value='HK'>{{ stringTable.word('HK') }}</option>
    <option value='JP'>{{ stringTable.word('JP') }}</option>
  </select>
</div> -->