import { createApp } from 'vue'
// import { LoadingPlugin } from 'vue-loading-overlay';
// import 'vue-loading-overlay/dist/css/index.css';
import App from './App.vue'

import { StringTable } from './components/StringTable.js'
import { ReiriIcons } from "./components/ReiriIcons.js"
import { IconsHelper } from "./components/IconsHelper.js"
import { ConvertHelper } from "./components/ConvertHelper.js"
import { MixList } from "./components/MixList.js"
import { NavAssistant } from './components/NavAssistant.js'
import { ColorHelper } from './components/ColorHelper.js'
import { TempConversion } from './components/TempConversion.js'

const app = createApp(App);
// app.use(LoadingPlugin)
const strTable = new StringTable();
const convHlper = new ConvertHelper(strTable);
const tempConvert = new TempConversion();

app.config.globalProperties.stringTable = strTable;
app.config.globalProperties.ReiriIcons = ReiriIcons;
app.config.globalProperties.IconsHelper = new IconsHelper(ReiriIcons);
app.config.globalProperties.ConvertHelper = convHlper;
app.config.globalProperties.NavAssistant = new NavAssistant();
app.config.globalProperties.MixList = new MixList(strTable, convHlper);
app.config.globalProperties.ColorHelper = new ColorHelper();
app.config.globalProperties.tempConvert = tempConvert;

app.mount('#app')
