
export class NavAssistant {
  constructor() {
    this.histories = [];
    this.popups = [];
    this.popupCompo = [];
  }
  push(compo) {
    var tempHistory = [];
    this.histories.forEach(h => {
      if (h.$options.name != compo.$options.name)
        tempHistory.push(h);
    });
    tempHistory.push(compo);
    this.histories = tempHistory;
    this.view();
  }
  last() {
    return this.histories[this.histories.length - 1];
  }
  changeCompo(compoForChangeScreen, compoTarget, changeScreenVarName) {
    if (this.last().$options.name == compoTarget) return;
    var indexForChangeScreen;
    this.histories.forEach((h, i) => {
      if (h.$options.name == compoForChangeScreen.$options.name) {
        indexForChangeScreen = i;
      }
    });
    if (indexForChangeScreen)
      this.histories = this.histories.slice(0, indexForChangeScreen + 1);
    if (compoForChangeScreen)
      compoForChangeScreen[changeScreenVarName] = compoTarget;
  }
  findCompo(compoName) {
    return this.histories.find((h) =>
      h.$options.name == compoName);
  }
  view() {
    var nameLi = [];
    this.histories.forEach((h) => {
      nameLi.push(h.$options.name);
    });
//    console.log(nameLi);
  }
  showLast() {
//    console.log(this.last())
    if (this.last()) {
      this.last().showPage = true;
//      console.log(this.histories[1]);
    }
    // this.histories[1].showNavBtm = true;
  }

  setApp(app) {
    this.app = app;
  }

  showPopUp(popUp, isCache) {
//    console.log(popUp)
    this.app.popUp.page = popUp.page;
    this.app.popUp.cache = popUp.details;
    if (isCache)
      this.popups.push(this.app.popUp);
    this.app.popUp = popUp;
    if (!isCache)
      this.app.popUp.cache = popUp.details;
//    console.log(this.app.popUp)
  }

  openPopUp(compo) {
    this.popupCompo.push(compo);
  }

  hidePopUp() {
    var last = this.popups.pop();
    if (last) {
      this.app.popUp = last;
//      console.log(last)
    }
    else {
      this.app.popUp = {
        cache: this.app.popUp.cache
      };
//      console.log(this.app.popUp)
      if (this.last()) {
        this.last().$data.cache = this.app.popUp.cache;
//        console.log(this.last())
      }
//      console.log(this.app.popUp)
    }
  }

  closePopUp() {
    var lastCompo = this.popupCompo.pop();
//    console.log(lastCompo);
    lastCompo.showHistory = true;
    lastCompo.showSceneSetting = true;
  }
}