<template>
  <div class="password-input frame">
    <input class="input-bar" :type="passwordVisible ? 'text' : 'password'" :placeholder="stringTable.word(placeholder)"
      v-model="$parent[varStr]" maxlength=30 />
    <span class="toggle-icon vertical-center" @click="toggleVisibility">
      <img class="to-active" :src="passwordVisible ? ReiriIcons.eyes_visible : ReiriIcons.eyes_hidden"
        alt="Toggle Password Visibility" />
    </span>
  </div>
</template>

<style>
.password-input input[type=password] {
  box-shadow: none;
}

.password-input {
  display: flex;
  margin-top: 4px;
}

.password-input input {
  display: inline-block;
  width: 100%;
  box-shadow: none !important;
}

.toggle-icon {
  cursor: pointer;
  margin: 0 8px;
  align-items: center;
  display: flex;
}

.toggle-icon i {
  font-size: 16px;
  color: #999;
}

.toggle-icon i:hover {
  color: #333;
}
</style>

<script>
export default {
  name: "FrameInputPwd",
  props: {
    varStr: String,
    value: String,
    placeholder: String,
    toggleToInvisible: Boolean
  },
  data() {
    return {
      details: { name: "passwd" },
      passwordVisible: false,
      passwd: '',
    }
  },
  created() {
    this.passwd = this.value
  },
  mounted() {
  },
  watch: {
    toggleToInvisible() {
      this.passwordVisible = false;
    }
  },
  methods: {
    toggleVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    // handleInput(event) {
    //   if (this.isConfirmPw) {
    //     this.updateConfirmPassword(event);
    //   } else {
    //     this.updateNewPassword(event);
    //   }
    // },
    // updateNewPassword(event) {
    //   this.$emit('updateNewpw', event.target.value);
    // },
    // updateConfirmPassword(event) {
    //   this.$emit('updateConfirmpw', event.target.value);
    // },
  },
  components: {
  },
}
</script>