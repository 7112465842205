<template>
  <div class="report ppd-report">
    <LoadingSpinner v-if="isLoading" />
    <div class="consolidated" :class="{ 'd-none': showSpecific }">
      <div class="top-content">
        <div class="navigation btm-line expand">
          <div class="btn-holder">
            <BtnNavIconText text="day" :compoForChangeScreen="this" />
            <BtnNavIconText text="month" :compoForChangeScreen="this" />
          </div>
        </div>
        <BarDateTime v-if="currentScreen == 'day'" :hideDatePicker="is_month" :showSelectMonth="is_month" />
        <BarDateRangeYear v-if="currentScreen == 'month'" :dateRangeData="commModule.load_report_setting.data"
          :lyType="currentScreen" :firstLoad="firstLoad" />
      </div>
      <div class="chart-with-arrow vertical-center">
        <NavPagination direction="left" :noNull="true" />
        <Chart :data="data" :options="options" :isLoading="isChartLoading" :vertiXlabel="true" :scaleHeightX="175"
          :legendDisable="true" />
        <NavPagination direction="right" :noNull="true" />
      </div>
    </div>
    <div class="specific" :class="{ 'd-none': !showSpecific }">
      <PopUpHead :details="{ name: pointName }" :hideBorderBtm="true" :small="true" />
      <Chart name="specific" :data="dataSpecific" :vertiXlabel="this.currentScreen == 'month'" :options="optionsSpecific"
        :legendDisable="true" />
    </div>
  </div>
</template>

<style>
.ppd-report {
  padding-left: 0;
  padding-right: 0;
  height: 515px;
}

.ppd-report .top-content {
  margin: 0 10px;
}

.ppd-report .navigation {
  margin-bottom: 6px;
}

.ppd-report .chart-with-arrow {
  padding: 0 2px;
}

.ppd-report .chart-with-arrow .nav-pagin.left {
  margin-left: auto;
}

.ppd-report .chart-with-arrow .nav-pagin.right {
  margin-right: auto;
}

.ppd-report .consolidated .chart-holder {
  width: 653px !important;
  height: 430px;
}

.ppd-report .specific {
  padding-top: 4px;
}

.ppd-report .specific .icon-text-label {
  max-width: fit-content !important;
}

.ppd-report .specific .chart-holder {
  width: 720px !important;
  height: 475px;
}
</style>

<script>
import LoadingSpinner from './LoadingSpinner.vue'
import PopUpHead from './PopUpHead.vue'
import BarDateTime from './BarDateTime.vue'
import BarDateRangeYear from './BarDateRangeYear.vue'
import BtnNavIconText from './BtnNavIconText.vue'
import Chart from './Chart.vue'
import NavPagination from './NavPagination.vue'

export default {
  name: "ReportPpdData",
  props: {
    commModule: Object,
  },
  data() {
    return {
      currentScreen: 'day',
      startDtInput: new Date(),
      startMthInput: new Date(),
      isLoading: false,
      isChartLoading: false,
      firstLoad: true,
      dateRange: {},
      data: {},
      showSpecific: false,
      dataSpecific: {},
      options: {
        // responsive: true,
        // maintainAspectRatio: false,
        scales: {
          x: {
            grid: { display: false },
            stacked: true,
            afterFit: (scale) => {
              scale.height = 175;
            },
            ticks: { align: 'start', },
          },
          y: {
            title: {
              display: true,
              text: '[kWh]'
            },
            ticks: { stepSize: 0.5 },
            type: 'linear',
            lineThickness: 1,
            stacked: true,
            display: true,
            position: 'left',
          },
        }
      },
      optionsSpecific: {
      },
      pointName: '',
      page: 1,
      itemPerPage: 30,
      slicedList: [],
      savedDatasets: [],
    }
  },
  created() {
    this.isChartLoading = true;
    this.commModule.getPpdPoints();
    this.optionsSpecific = JSON.parse(JSON.stringify(this.options));
    delete this.optionsSpecific.scales.x.afterFit;
    delete this.optionsSpecific.scales.y.ticks;

  },
  watch: {
    currentScreen() {
      this.firstLoad = true;
      this.startDtInput = new Date();
      this.startMthInput = new Date();
      this.dateRange = {};
      this.loadChart();
    },
    startDtInput() {
      if (!this.firstLoad)
        this.loadChart();
    },
    'commModule.ppd_point_list'() {
      this.loadChart();
    },
    'commModule.get_ppd'(newPpd) {//newPpd
      this.firstLoad = false;
      // DUMMY
      // var keysToClone = Object.keys(newPpd.data);
      // for (let index = 0; index < 100; index++) {
      //   keysToClone.forEach(key => {
      //     newPpd.data[`${key}_${index}`] = newPpd.data[key]
      //   });
      // }
      if (!newPpd || !newPpd.data) return;
      var cleanedObj = {};
      for (const point_id in newPpd.data) {
        var tempObj = this.getEmptyPpd();
        if (newPpd.data[point_id])
          newPpd.data[point_id].forEach(raw => {
            tempObj = this.calPpd(tempObj, raw);
          });
        cleanedObj[point_id] = tempObj;
      }

      this.savedDatasets = this.prepareDatasets(cleanedObj);
      var numLabels = Object.keys(newPpd.data).length;
      var stackedList = new Array(numLabels).fill(0);
      this.savedDatasets.forEach(datasets => {
        stackedList.forEach((stackEle, index) => {
          stackedList[index] += datasets.data[index];
        })
      });
      var maxData = Math.max(...stackedList);
      var stepMax = this.ConvertHelper.chartPrecision(maxData, 10);
      this.options.scales.y.max = this.ConvertHelper.roundIncrement(maxData, stepMax);
      this.options.scales.y.ticks.stepSize = stepMax;
      this.isChartLoading = false;
      // var numLabels = Object.keys(newPpd.data).length;
      // var stackedList = new Array(numLabels).fill(0);
      // this.savedDatasets = [];
      // this.ppdMode.forEach((legend, index) => {
      //   var data = [];
      //   for (const point_id in cleanedObj) {
      //     data.push(cleanedObj[point_id][legend]);
      //   }

      //   stackedList.forEach((stackEle, index) => {
      //     stackedList[index] += data[index];
      //   })
      //   var aColor = this.ColorHelper.get(index);
      //   this.savedDatasets.push({
      //     type: 'bar',
      //     label: this.stringTable.word(legend),
      //     data,
      //     borderColor: aColor,
      //     backgroundColor: aColor,
      //     yAxisID: 'y',
      //     borderWidth: 1.5,
      //     barThickness: 10
      //   });
      // });
    },
    slicedList(dataIndexes) {
      this.data.labels = [];
      var newPpd = this.commModule.get_ppd;
      if (!newPpd || !newPpd.data) return;
      var ppdKeys = Object.keys(newPpd.data);
      dataIndexes.forEach(dataIndex => {
        // for (const point_id in newPpd.data) {
        var pointId = ppdKeys[dataIndex];
        this.data.labels.push(this.pointLabel(pointId));
      });
      var slicedDatasets = JSON.parse(JSON.stringify(this.savedDatasets));
      slicedDatasets.forEach((ds) => {
        if (!ds.data) return;
        ds.data = ds.data.slice(dataIndexes[0], dataIndexes[dataIndexes.length - 1] + 1);
      });
      this.data.datasets = slicedDatasets;
    },
  },
  methods: {
    prev() {
      this.showSpecific = false;
    },
    pointLabel(pointId) {
      var point = this.commModule.point_list[pointId];
      return point ? point.name : pointId;
    },
    getEmptyPpd() {
      return { cool: 0, heat: 0, standby: 0, fan: 0, heater: 0, }
    },
    calPpd(tempObj, raw) {
      if (!raw) return tempObj;
      tempObj.cool += raw[1];
      tempObj.heat += raw[2];
      tempObj.standby += raw[3];
      tempObj.fan += raw[4];
      tempObj.heater += raw[5];
      return tempObj;
    },
    loadChart() {
      var fromDate, toDate;
      if (this.currentScreen == 'day') {
        fromDate = this.start_dt;
        toDate = new Date(fromDate);
        toDate.setDate(toDate.getDate() + 1);
        this.dateRange = {
          ...this.dateRange,
          ty: toDate.getFullYear(),
          tm: toDate.getMonth() + 1,
          td: toDate.getDate(),
        }
      }
      else if (this.currentScreen == 'month') {
        if (Object.keys(this.dateRange).length == 0) return;
        fromDate = this.dateRange.from;
        toDate = this.dateRange.to;
      }
      var inputPrep = {
        fy: fromDate.getFullYear(),
        fm: fromDate.getMonth() + 1,
        fd: fromDate.getDate(),

        ty: this.dateRange.ty,
        tm: this.dateRange.tm,
        td: this.dateRange.td,
        points: this.commModule.ppd_point_list
      }
      this.isChartLoading = true;
      this.commModule.getPpd(inputPrep);
    },
    changeDateRange(newDateRange) {
      this.dateRange = newDateRange;
      console.log(newDateRange)
      this.loadChart();
    },
    getLyLabels() {
      var dateObjs = [];
      var currDt, toDt;
      if (this.currentScreen == 'day') {// hourly
        currDt = this.start_dt;
        for (let hour = 0; hour < 24; hour++) {
          dateObjs.push(new Date(
            currDt.getFullYear(),
            currDt.getMonth(),
            currDt.getDate(),
            hour, 0
          ));
        }
      }
      else if (this.currentScreen == 'month') {// daily
        currDt = new Date(this.dateRange.from);
        toDt = new Date(this.dateRange.to);
        while (currDt <= toDt) {
          dateObjs.push(new Date(currDt));
          currDt.setDate(currDt.getDate() + 1)
        }
      }
      return dateObjs;
    },
    prepareDatasets(dataObj) {
      var datasets = [];
      ['cool', 'heat', 'standby', 'fan', 'heater'].forEach((legend, index) => {
        var data = [];
        for (const key in dataObj) {
          data.push(dataObj[key][legend]);
        }

        var aColor = this.ColorHelper.get(index);
        datasets.push({
          type: 'bar',
          label: this.stringTable.word(legend),
          data,
          borderColor: aColor,
          backgroundColor: aColor,
          yAxisID: 'y',
          borderWidth: 1.5,
          barThickness: 10
        });
      });
      return datasets;
    },
    clickedChartLabel(index) {
      this.isLoading = true;
      setTimeout(() => {
        this.showSpecific = true;
        var selIndex = this.slicedList[index];
        var ppdKeys = Object.keys(this.ppd_data);
        var pointId = ppdKeys[selIndex];
        this.pointName = this.pointLabel(pointId);
        var labels = this.getLyLabels();
        var cleanedObj = {};
        labels.forEach(label => {
          var dtServer = this.ConvertHelper.dtObjToDtimeServer(label);
          cleanedObj[dtServer] = this.getEmptyPpd();
        })

        var data = this.ppd_data[pointId];
        data.forEach(raw => {
          var dtServer = raw[0];
          var dtObj = this.ConvertHelper.dtServerToDtObj(dtServer);
          dtObj.setMinutes(0);
          if (this.currentScreen == 'month') dtObj.setHours(0);
          dtServer = this.ConvertHelper.dtObjToDtimeServer(dtObj);
          cleanedObj[dtServer] = this.calPpd(cleanedObj[dtServer], raw);
        });
        this.dataSpecific.labels = [];
        for (const dtServer in cleanedObj) {
          var label;
          if (this.currentScreen == 'day') {
            var from = this.ConvertHelper.dtServerToTimeString(dtServer);
            label = `${from.slice(0, 2)}`;
          }
          else if (this.currentScreen == 'month') {
            label = this.ConvertHelper.dtServerToDayMthString(dtServer);
          }
          this.dataSpecific.labels.push(label)
        }
        this.dataSpecific.datasets = this.prepareDatasets(cleanedObj);
        setTimeout(() => {
          this.isLoading = false;
        }, 1);
      }, 1);
    }
  },
  computed: {
    start_dt() {
      return this.ConvertHelper.dtInputToDtObj(this.startDtInput);
    },
    is_month() {
      return this.currentScreen == 'month';
    },
    mainList() {
      return this.isChartLoading ? [] :
        Array.from(Object.keys(this.ppd_data).keys());
    },
    ppd_data() {
      return this.commModule.get_ppd ? this.commModule.get_ppd.data : {};
    },
  },
  components: {
    LoadingSpinner,
    PopUpHead,
    BarDateTime,
    BarDateRangeYear,
    BtnNavIconText,
    Chart,
    NavPagination
  },
}
</script>
