<template>
  <div class="pop-up guest-location">
    <PopUpToast />
    <PopUpHead :details="detailsCloned" rightBtnTxt="save" :leftArr="true" :hideCross="true" :wholeScreen="true" />
    <FrameInput varStr="locName" placeholder="loc_name" />
    <div class="guest-rooms-list">
      <SelectDropDown title="guest_rooms" :items="roomSelections" :plusIcon="true" checkedString="guest_room"
        :borderBtm="true" :manualResetPage="true" />
    </div>
    <BarReorderable :cache="cache" :nameLi="reorder" :iconRefer="settings ? settings.rooms : {}" :hideDelete="true"
      :noClick="true" :removeHighlight="removeHighlight" :itemPerPage="9" />
  </div>
</template>

<style>
.pop-up.guest-location .frame-input {
  margin: 8px 36px;
}

.pop-up.guest-location .guest-rooms-list {
  margin: 0 18px 4px 18px;
}

.pop-up.guest-location .bar-reorderable {
  margin: 0 60px;
}
</style>

<script>
import PopUpToast from './PopUpToast.vue'
import PopUpHead from './PopUpHead.vue'
import FrameInput from './FrameInput.vue'
import SelectDropDown from './SelectDropDown.vue'
import BarReorderable from './BarReorderable.vue'

export default {
  name: "PopUpGuestLocation",
  props: {
    commModule: Object,
    details: Object,
  },
  data() {
    return {
      toast: {},
      settings: this.details.settings,
      settingsCopy: JSON.parse(JSON.stringify(this.details.settings)),
      detailsCloned: this.details,
      locName: '',
      removeHighlight: false,
      newRoom: { room_order: [] },
      roomSelections: [],
      page: 1,
    }
  },
  created() {
    this.locName = this.detailsCloned.locName;
    this.roomSelections = [];
    var allRooms = this.settings.roomOrder;
    var roomsVacanted = [];
    if (this.detailsCloned.contents) {
      this.detailsCloned.contents.forEach(loc => {
        if (loc.name != this.detailsCloned.locName)
          loc.room_order.forEach(room => roomsVacanted.push(room));
      })
    }
    if (allRooms) {
      allRooms.forEach(room => {
        if (!roomsVacanted.includes(room))
          this.roomSelections.push({ name: room, icon: 'admin', guest_room: this.reorder.includes(room) })
      });
    }
  },
  watch: {
  },
  methods: {
    prev() {
      this.detailsCloned.settings = this.settingsCopy;
    },
    findLocation() {
      return this.settings.locations.find(l =>
        l.name == this.detailsCloned.locName);
    },
    clickRightBtn() {
      var isAdd = this.detailsCloned.name == 'add_location';
      var isEdit = this.detailsCloned.name == 'edit_location';
      // var noName = this.ConvertHelper.isEmptyString(this.locName);
      var usedLocNames = this.settings.locations.map(l => l.name);
      var duplicateLocName = isAdd && usedLocNames.includes(this.locName);
      if (duplicateLocName) {//noName ||
        var text;
        // if (noName) text = 'no_name';
        // else if (duplicateLocName) 
        text = 'same_name';
        this.toast = { text, isError: true, };
        return;
      }
      if (isAdd) this.settings.locations.push({
        name: this.locName, room_order: this.reorder
      });
      else if (isEdit) {
        var foundLocation = this.findLocation();
        foundLocation.name = this.locName;
      }
      this.commModule.saveGuests(this.settings);
      this.NavAssistant.hidePopUp();
    },
    clickedCheckBox(location) {
      this.removeHighlight = !this.removeHighlight;
      if (location.guest_room) this.reorder.push(location.name);
      else {
        var index = this.reorder.indexOf(location.name);
        if (index !== -1) this.reorder.splice(index, 1);
      }
    }
  },
  computed: {
    reorder() {
      var foundLocation = this.findLocation();
      if (foundLocation) {
        if (!this.detailsCloned) foundLocation.room_order = [];
        return foundLocation.room_order;
      } else return this.newRoom.room_order;
    },
  },
  components: {
    PopUpToast,
    PopUpHead,
    FrameInput,
    SelectDropDown,
    BarReorderable
  },
}
</script>

<!-- 
// rooms() {
//   var rooms = [];
//   if (!this.reorder) return;
//   this.reorder.forEach(room => {
//     var icon = this.defaultIcon;
//     if (this.cache && this.cache.content.name == room)
//       icon = this.cache.content.icon;
//     else if (this.settings.rooms[room])
//       icon = this.settings.rooms[room].icon;
//     var iconDisplay = this.ReiriIcons.groupIconMap[icon];
//     rooms.push({ name: room, iconDisplay, icon });
//   });
//   return rooms;
// } -->