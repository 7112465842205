<template>
  <!-- {{ details.value }} -->
  <div v-if="details_copy.icons.length > 0" class="ac button frame " @click="changeClick()"
    :class="{ 'active': isBkgActive }" role="button">
    <div class="center-content">
      <div>
        <img :src="icon" :class="{ 'to-white': isBkgActive }" /><br />
      </div>
      <div>
        {{ stringTable.word(text) }}
        {{ details.stat }}
      </div>
    </div>
  </div>
</template>

<style>
.ac .center-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  /* You may want to specify a fixed height for the container */
}

.ac.button {
  display: inline-block;
  width: 90px;
  padding: 8px 0;
  margin: 0 5px;
  font-size: 14px;
  height: 90px;
}

.ac.button.active {
  background-color: var(--active);
  color: white;
}

.ac.button img {
  width: 40px;
}
</style>

<script>
export default {
  name: "BtnOperation",
  props: {
    details: Object,
    iconIndex: Number,
    type: String,
    // stat: Boolean,
  },
  data() {
    return {
      text: '',
      icon: '',
      isBkgActive: false,
      isFreshUpSelected: false,
      statTexts: ['off', 'on']
    }
  },
  created() {
    this.init()
  },
  watch: {
    'details.value'() {
      this.updateStat()
    }
  },
  methods: {
    updateStat() {
      this.isBkgActive = this.details.value;
      this.text = this.statTexts[this.details.value ? 1 : 0];
    },
    init() {
      if (this.type == 'stat') {
        this.icon = this.details_copy.icons[0];
        this.updateStat()
      } else if (this.type == 'freshup') {
        this.isFreshUpSelected = this.details_copy.value;
        this.icon = this.isFreshUpSelected ? this.details_copy.icons[1] : this.details_copy.icons[0]
        this.text = this.type;
      } else {
        this.text = this.type;
        this.icon = this.details_copy.value;
      }
    },
    toggleText() {
      // if (texts.includes(this.text)) {
      var textIndex = this.statTexts.indexOf(this.text);
      this.text = this.statTexts[textIndex ? 0 : 1];
      // }
    },
    changeClick() {
      if (this.type == 'stat') {
        this.isBkgActive = !this.isBkgActive;
        this.toggleText();
        this.$parent.changeBtnOperation(this.isBkgActive, this.type)
      } else if (this.type == 'freshup') {
        this.isFreshUpSelected = !this.isFreshUpSelected
        this.icon = this.isFreshUpSelected ? this.details_copy.icons[1] : this.details_copy.icons[0]
        this.$parent.changeBtnOperation(this.isFreshUpSelected, this.type)
      } else {
        var currentIndex = this.details_copy.icons.findIndex(icon => {
          return icon == this.icon;
        });
        var nextIndex = currentIndex + 1;
        if (nextIndex >= this.details_copy.icons.length) {
          nextIndex = 0;
        }
        this.icon = this.details_copy.icons[nextIndex];
        this.$parent.changeBtnOperation(nextIndex, this.type)
      }
    }
  },
  computed: {
    details_copy() {
      if (Object.keys(this.details).length == 0)
        return { icons: [] }
      return this.details
    }

  }
}
</script>
