import convTable from './tempConversion.json';

export class TempConversion {
	constructor() {
		this.tempUnit = 'C';
		this.tableToC = convTable['toC'];
		this.tableToF = convTable['toF'];
	}

	setTempUnit(unit) {
		if (unit != null) this.tempUnit = unit;
	}

	spGap() {
		return this.tempUnit == 'F' ? 1 : 0.5;
	}

	tempConvert(val) {
		if (this.tempUnit == 'F') return parseFloat((val * 1.8 + 32).toFixed(1));
		else return val;
	}

	tempConvertRound(val) {
		if (this.tempUnit == 'F') return Math.round((val * 1.8 + 32).toFixed(1));
		else return Math.round(val);
	}

	tempRevConvert(val) {
		if (this.tempUnit == 'F') return parseFloat(((val - 32) / 1.8).toFixed(2));
		return val;
	}

	tempRevConvertRound(val) {
		if (this.tempUnit == 'F') return parseFloat(((val - 32) / 1.8).toFixed(2));
		return Math.round(val);
	}

	tempDiffConvert(val) {
		if (this.tempUnit == 'F') return parseFloat((val * 1.8).toFixed(1));
		else return val;
	}

	tempDiffRevConvert(val) {
		if (this.tempUnit == 'F') return parseFloat((val / 1.8).toFixed(2));
		return val;
	}

	spConvert(val) {
		if (!val || val == '--') return val;
		if (this.tempUnit == 'F') {
			if (val < 15 || val > 35) return this.tempConvert(val);
			else return this.tableToF[val.toFixed(1)];
		} else return val;
	}
	spConvertDoubleItemArr(arr) {
		return [this.spConvert(arr[0]), this.spConvert(arr[1])];
	}

	spRevConvert(val) {
		if (!val || val == '--') return val;
		if (this.tempUnit == 'F') {
			if (val < 59 || val > 95) return this.tempRevConvert(val);
			else return this.tableToC[val.toFixed(1)];
		} else return val;
	}
	spRevConvertDoubleItemArr(arr) {
		return [this.spRevConvert(arr[0]), this.spRevConvert(arr[1])];
	}
}