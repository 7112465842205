
<template>
  <div class="frame frame-input vertical-center">
    <div class="input-holder vertical-center">
      <input v-if="varStr2" type="text" :placeholder="stringTable.word(placeholder)" :maxlength="max_len"
        v-model="this.$parent[this.varStr][this.varStr2]" />
      <input v-else type="text" :placeholder="stringTable.word(placeholder)" :maxlength="max_len"
        v-model="this.$parent[this.varStr]" />
    </div>
    <div class="label-holder">
      <span class="text-length"> {{ len }} </span>/{{ max_len }}
    </div>
  </div>
</template>

<style>
.frame-input {
  margin: 0 4px;
  padding-right: 6px;
}

.frame-input .input-holder {
  width: 100%;
  margin-right: 6px;
}

.frame-input .label-holder {
  width: 60px;
  text-align: right;
  margin-left: auto;
}

.frame-input .text-length {
  color: var(--active);
}
</style>

<script>
// import PopUpHead from './PopUpHead.vue'

export default {
  name: "FrameInput",
  props: {
    placeholder: String,
    varStr: String,
    varStr2: String,
    max: Number
  },
  data() {
    return {
    }
  },
  watch: {
  },
  computed: {
    len() {
      var targetStr = this.varStr2 ?
        this.$parent[this.varStr][this.varStr2] :
        this.$parent[this.varStr];
      return targetStr ? targetStr.length : 0;
    },
    max_len() {
      return this.max ? this.max : 30;
    },
  }
}
</script>