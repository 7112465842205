<template>
  <div class="pop-up device-detail history" :class="{ 'd-none': !showHistory }">
    <PopUpHead :details="head_detail" rightBtnTxt="hist_filter" :hideBorderBtm="true" :showOrderBtn="true"
      :reverseBtndown="reverseBtndown" :leftArr="true" :hideCross="true" :wholeScreen="true" />
    <BarDateTime />
    <div style="height: 440px">
      <LoadingSpinner v-if="isLoading" />
      <NoList v-if="mainList && mainList.length == 0" :class="{ 'hidden': isLoading }" />
      <div v-else-if="!isLoading" class="main-content">
        <div class="history-block">
          <div class="tile-section">
            <TileHistory v-for="item in slicedList" :key="item" :details="item" :commModule="commModule" />
          </div>
        </div>
        <div class="nav-btn vertical-center">
          <div class="nav-holder">
            <NavPagination direction="double-up" />
            <NavPagination direction="up" />
            <NavPagination direction="down" />
            <NavPagination direction="double-down" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <PopUpHistoryFilterDropDown :commModule="commModule" :details="filterDetails" :class="{ 'd-none': showHistory }"
    @clicked="onClickChild">
  </PopUpHistoryFilterDropDown>
</template>

<style>
.history .tile-section {
  display: grid;
  grid-template-columns: 19.6% 19.6% 19.6% 19.6% 19.6%;
  gap: 5.3px 0.5%;
}

.history .reverse {
  transform: rotate(180deg);
}

.history .history-block {
  width: 100%;
  margin-right: 6px;
}

.history .main-content {
  display: flex;
  margin: 0 20px;
}

.pop-up.history .tile-section {
  grid-template-columns: 100%;
  width: 100%;
}

.history .nav-btn {
  margin-right: 0px;
  margin-left: auto;
}

.history .date-time-holder {
  width: auto;
  margin: 0px 15px 5px 15px;
}
</style>

<script>
import TileHistory from './TileHistory.vue'
import NoList from './NoList.vue'
import NavPagination from './NavPagination.vue'
import BarDateTime from './BarDateTime.vue'
import PopUpHistoryFilterDropDown from './PopUpHistoryFilterDropDown.vue'
import LoadingSpinner from './LoadingSpinner.vue'
import PopUpHead from './PopUpHead.vue'
import { ref } from 'vue';

export default {
  name: "PopUpHistory",
  data() {
    return {
      nameDetails: { name: "history" },
      filterDetails: {},
      page: 1,
      itemPerPage: 7,
      slicedList: [],
      showNoList: false,
      historyList: [],
      startDtInput: new Date(),
      startDtInput1: ref(new Date()),

      showNavBtn: false,
      reverseBtndown: false,
      typeList: [],
      actionList: [],
      targetList: [],
      operatorList: [],
      filteredList: [],
      showHistory: true,
      isLoading: false,
      originalHistoryList: [],
      actionSelectedList: { 'action': [], 'type': [], 'target': [], 'operator': [] },
    }
  },
  props: {
    commModule: Object,
    popUpWithCache: Boolean
  },
  created() {
  },
  methods: {
    onClickChild(options) {
      this.actionSelectedList = options
      this.filteredList = [];

      if (this.actionSelectedList.action.length == 0 && this.actionSelectedList.type.length == 0 && this.actionSelectedList.target.length == 0 && this.actionSelectedList.operator.length == 0) {
        this.historyList = this.originalHistoryList
        return
      }
      // Use the filter method to find matching items
      this.filteredList = this.originalHistoryList.filter(item => {
        const [, type, action, target, , operator] = item;
        // Check if each field in actionSelectedList matches, or if the field in actionSelectedList is empty
        const actionMatch = this.actionSelectedList.action.length === 0 || this.actionSelectedList.action.includes(action);
        const typeMatch = this.actionSelectedList.type.length === 0 || this.actionSelectedList.type.includes(type);
        const targetMatch = this.actionSelectedList.target.length === 0 || this.actionSelectedList.target.includes(target);
        const operatorMatch = this.actionSelectedList.operator.length === 0 || this.actionSelectedList.operator.includes(operator);
        // Return true if all conditions are met
        return actionMatch && typeMatch && targetMatch && operatorMatch;
      });

      if (this.filteredList.length == 0) {
        if (this.actionSelectedList.action.length == 0 && this.actionSelectedList.type.length == 0 && this.actionSelectedList.target.length == 0 && this.actionSelectedList.operator.length == 0)
          this.historyList = this.originalHistoryList
        else
          this.historyList = []
      } else {
        this.historyList = this.filteredList;
      }
      this.page = 1;
    },
    getHistories() {
      this.page = 1;
      this.isLoading = true;
      var dtObj = this.ConvertHelper.dtInputToDtObj(this.startDtInput);
      this.commModule.getHistory({
        year: dtObj.getFullYear(),
        month: dtObj.getMonth() + 1,
        day: dtObj.getDate()
      });
    },
    clickRightBtn() {
      // this.NavAssistant.showPopUp(this, true);
      this.showHistory = false;
    },
    clickOrderBtn() {
      this.historyList.reverse();
      this.reverseBtndown = !this.reverseBtndown;
    },
    back() {
      this.NavAssistant.hidePopUp();
      if (this.showLast) {
        this.NavAssistant.showLast();
      }
    },
  },
  mounted() {
    // this.getHistories();
    this.isLoading = true;
  },
  watch: {
    'commModule.get_history'() {
      this.historyList = this.commModule.get_history;
      this.originalHistoryList = this.commModule.get_history;
      this.isLoading = false;
      this.historyList.reverse()
      // loop through lsit top generate 4 filter list
      // type, action, target, operator
      this.typeList = []
      this.actionList = []
      this.targetList = []
      this.operatorList = []

      this.historyList.forEach(history => {
        if(history[2] == 'changeover') history[2] = 'change_over';
        // Type
        if (!this.typeList.includes(history[1]) && history[1] != '') {
          this.typeList.push(history[1]);
        }
        // Action and Target
        // if (history[2] != '') {
        if (!this.actionList.includes(history[2]) && history[2] != '') {
          this.actionList.push(history[2]);
        }
        // } else {
        if (!this.targetList.includes(history[3]) && history[3] != '') {
          this.targetList.push(history[3]);
        }
        // }
        // Operator
        if (!this.operatorList.includes(history[5]) && history[5] != '') {
          this.operatorList.push(history[5]);
        }
      });
      this.typeList = this.typeList.map((str) => ({ name: str, typeChecked: this.actionSelectedList.type.includes(str) ? true : false }));
      this.actionList = this.actionList.map((str) => ({ name: str, actionChecked: this.actionSelectedList.action.includes(str) ? true : false }));
      this.targetList = this.targetList.map((str) => ({ name: str, targetChecked: this.actionSelectedList.target.includes(str) ? true : false }));
      this.operatorList = this.operatorList.map((str) => ({ name: str, operatorChecked: this.actionSelectedList.operator.includes(str) ? true : false }));

      this.filterDetails = {
        typeList: this.typeList,
        actionList: this.actionList,
        targetList: this.targetList,
        operatorList: this.operatorList,
      }
      if (this.actionSelectedList.length != 0)
        this.onClickChild(this.actionSelectedList)
    },
    startDtInput() {
      this.getHistories();
    }
  },
  computed: {
    head_detail() {
      var name = 'history';
      return { name }
    },
    mainList() {
      return this.historyList;
    }
  },
  components: {
    NoList,
    TileHistory,
    NavPagination,
    BarDateTime,
    PopUpHistoryFilterDropDown,
    LoadingSpinner,
    PopUpHead,
  },
}
</script>