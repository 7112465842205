
export class MixList {
  constructor(stringTable, ConvertHelper) {
    this.stringTable = stringTable;
    this.ConvertHelper = ConvertHelper;
  }

  setCommModule(commModule) {
    this.commModule = commModule;
  }

  get(typeArr) {
    const typeListMap = {
      point: this.commModule.point_list,
      group: this.commModule.group_list,
      scene: this.commModule.scene_list,
      interlock: this.commModule.interlock_get_program ? this.commModule.interlock_get_program.program : {},
      calendar: this.commModule.get_schedule ? this.commModule.get_schedule.calendar : {},
      schedule: this.commModule.get_schedule ? this.commModule.get_schedule.program : {},
    }
    var res = [];
    typeArr.forEach(type => {
      const getList = this.createLi(type, typeListMap[type])
      res = res.concat(getList);
    });
    return res;
  }

  createLi(icon_map, loopLi) {
    var res = [];
    for (const key in loopLi) {
      const item = loopLi[key];
      var icon = item.icon;
      if (icon_map == 'calendar') icon = 'holiday_calender';
      if (icon_map == 'schedule') icon = 'schedule';
      if (icon_map == 'interlock') icon = 'interlock';
      res.push({
        key_id: key,
        icon_map,
        name: icon_map == 'group' ? this.stringTable.word(item.name) : item.name,
        icon,
        type: item.type,
        unit: Object.prototype.hasOwnProperty.call(item, 'unit') ? item.unit : '',
      });
    }
    if (icon_map != 'calendar')
      res = this.ConvertHelper.orderArrByObjVal(res, 'name');
    return res;
  }
}