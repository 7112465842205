<template>
  <div class="guest guest-rooms">
    <PopUpToast />
    <PopUpHead :details="details" rightBtnTxt="save" :hideBorderBtm="true" />
    <BarReorderable :cache="cache" :nameLi="reorder" :iconRefer="settings ? settings.rooms : {}" :pageTrack="page"
      :itemPerPage="11" />
  </div>
</template>

<style>
.guest .pop-up-head {
  margin-bottom: 6px;
}
</style>

<script>
import PopUpToast from './PopUpToast.vue'
import PopUpHead from './PopUpHead.vue'
import BarReorderable from './BarReorderable.vue'

export default {
  name: "GuestRooms",
  props: {
    commModule: Object,
    details: Object,
    cache: Object,
  },
  data() {
    return {
      toast: {},
      page: 1,
      settings: {},
      deletedGuestRooms: []
    }
  },
  created() {
    this.settings = this.cache ? this.cache.settings : this.details.settings;
    if (this.cache) {
      this.settings.rooms = {};
      this.settings.roomOrder = [];
      this.cache.contents.forEach(room => {
        this.settings.roomOrder.push(room.name);
        if (room.icon != 'Single.png')
          this.settings.rooms[room.name] = { icon: room.icon }
      })
      this.page = this.cache.page;
    }
  },
  watch: {
    'commModule.guest_room_settings_save'() {
      this.$parent.closeRightScreen(true);
    },
  },
  methods: {
    clickRightBtn() {
      this.toast = {
        text: 'conf_save',
        pendingYesMethod: () => {
          this.deletedGuestRooms.forEach(delRoom => {
            this.commModule.delGuests(delRoom);
          })
          this.commModule.saveGuests(this.settings);
        }
      }
    },
    prev() {
      this.$parent.closeRightScreen();
    },
    barClicked(rooms, room) {
      var pngs = ['Single.png', 'Twin.png', 'Double.png', 'Suite.png', 'Resort.png'];
      var iconObjs = {};
      pngs.forEach(png => { iconObjs[png] = this.ReiriIcons.groupIconMap[png] });
      this.NavAssistant.showPopUp({
        name: 'PopUpIcons',
        details: {
          name: room.name,
          screen: this.details.name,
          settings: this.settings,
          content: room,
          contents: rooms,
          iconObjs, page: this.page
        }
      }, true);
    },
    reorderDeleted(deletedItem) {
      this.settings.roomOrder = this.settings.roomOrder.filter(room => room != deletedItem);
      this.deletedGuestRooms.push(deletedItem);
      this.settings.locations.forEach(location => {
        location.room_order = location.room_order.filter(room => room != deletedItem);
      });
    }
  },
  computed: {
    reorder() {
      return this.settings ? this.settings.roomOrder : [];
    },
  },
  components: {
    PopUpToast,
    PopUpHead,
    BarReorderable
  },
}
</script>

<!-- // rooms() {
//   var rooms = [];
//   if (!this.reorder) return;
//   this.reorder.forEach(room => {
//     var icon = this.defaultIcon;
//     if (this.cache && this.cache.content.name == room)
//       icon = this.cache.content.icon;
//     else if (this.settings.rooms[room])
//       icon = this.settings.rooms[room].icon;
//     var iconDisplay = this.ReiriIcons.groupIconMap[icon];
//     rooms.push({ name: room, iconDisplay, icon });
//   });
//   return rooms;
// } -->