<template>
  <div v-if="!showEditAutoChangeover" class="pop-up user system-setting auto-changeover device-setting edit-iaq">
    <div class="content-holder">
      <div class="left-content  ">
        <PopUpHead :details="details" :hideBorderBtm="true" :leftArr="true" :small="true" :plus="true" />
        <LoadingSpinner v-if="isLoading" :topSpacing="true" />
        <div class="nav-list">
          <!-- {{ slicedList }} -->
          <div v-for=" key in slicedList" :key="key">
            <div v-if="clonedGroupList[key]" class="frame">
              <NavHorizontal :name="clonedGroupList[key].name" :value="clonedGroupList[key]" hideKeyValue="true"
                showDescription="false" :showSwitch="true" :id="key" :switchValue="clonedGroupList[key].enable" />
            </div>
          </div>
        </div>
        <div class="vertical-center">
          <NavPagination direction="up" :expandHori="true" />
          <NavPagination direction="down" :expandHori="true" />
        </div>
      </div>
      <div class="right-frame">
        <PopUpToast />
        <PopUpHead v-if="showEdit" :details="head_detail" :leftBtnTxt="!this.isRegister ? 'delete' : ''"
          rightBtnTxt="save" :hideBorderBtm="true" />

        <div class="edit-section" v-show="showEdit">
          <!-- name -->
          <!-- {{ clonedGroupList }} -->
          <FrameInput v-if="groupDetails.hasOwnProperty('name')" varStr="groupDetails" varStr2="name"
            placeholder="name" />
          <!-- {{ groupDetails }} -->
          <div class="frame setting">
            <div class="upper-content">
              <div class="left-text vertical-center">
                <div>{{ stringTable.word("mode") }}</div>
              </div>
              <div class="right-content">
                <SelectOption name="modeChangeover" :selectList="modeOptions" :defaultSelectIndex="defaultModeIndex"
                  :upsideDropdown=true :hideArrow="true" :showStringTable="true" />
              </div>
            </div>
            <div class="upper-content">
              <div class="left-text vertical-center">
                <div v-if="isVote">{{ stringTable.word("heat_override") }}</div>
                <div v-else>{{ stringTable.word("target") }}</div>

              </div>
              <div class="right-content">
                <label v-if="isVote" class="switch">
                  <input type="checkbox" checked v-model="groupDetails.heat_override">
                  <span class="slider round"></span>
                </label>
                <SelectOption name="targetChangeover" v-else :selectList="updatedDeviceList"
                  :defaultSelectIndex="defaultTargetIndex" :upsideDropdown=true :hideArrow="true" :showPointName="true"
                  :commModule="commModule" />
              </div>
            </div>
          </div>
          <!-- select device title -->
          <div class="mail-addr">
            <div class="nomargin-content vertical-center ">
              <div class="left-text">
                <div>{{ stringTable.word("select_devices") }}</div>
              </div>
              <div class="right-content">
                <SelectDropDown class="select-device" mainLabel="select_device" :items="clonedPointList" :plusIcon=true
                  checkedString="stdUserChecked" />
              </div>
            </div>
          </div>

          <!-- <div class="info-box   can-scroll" v-if="!emptyPointChecked">
            <div class="upper-content-points" v-for="(pointValue, pointKey) in pointsChecked" :key="pointKey">
              <div v-if="commModule.point_list[pointKey]" class="left-text input-add point-tile frame"
                v-show="pointValue == true">
                <div class="icon-holder">
                  <img class="icon-point" :src="IconsHelper.iconMain(commModule.point_list[pointKey])" />
                </div>
                <div>
                  <div>{{ commModule.point_list[pointKey].name }}</div>
                  <div v-if="isVote" class="vertical-center weight">
                    <div> {{ stringTable.word("weight") + ': ' }}</div>
                    <SelectOption class="weight-input" name="weightChangeover" :selectList="weightOptions"
                      :defaultSelectIndex="defaultWeightIndex(pointKey)" :hideArrow="true"
                      v-model="groupDetails.weight[pointKey]" />
                  </div>
                </div>

              </div>
            </div>
          </div> -->
          <ListAutoChangeover v-if="!emptyPointChecked" :commModule="commModule" :points="pointsChecked" :isVote="isVote"
            :groupDetails="groupDetails" />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.auto-changeover .nav-list,
.schedule-setting .nav-list,
.user-page .nav-list {
  padding: 3px;
}

.auto-changeover .nav-list,
.user-page .nav-list {
  margin-top: 3px;
}

.auto-changeover .nav-list .frame {
  padding: 4px;
}

.auto-changeover .upper-content-points {
  padding: 0px;
}

.auto-changeover .edit-section {
  margin-top: 10px;
  max-height: 500px;
  width: 100%;
}

.auto-changeover .weight {
  font-size: 14px;
}

.auto-changeover .weight-input {
  width: 100px;
  margin-left: 10px;
}

.auto-changeover .input-add {
  height: unset;
}

.auto-changeover .devices-box {
  height: 350px;
}

.auto-changeover .point-tile {
  padding: 10px;
  margin-bottom: 10px;
}

.auto-changeover .mail-addr {
  margin-bottom: 10px;
}

.auto-changeover .right-content {
  width: 250px;
  margin-left: auto;
  margin-right: 0;
  text-align: right;
}

.auto-changeover .select-option {
  height: 30px !important;
  text-align: center;
}

.auto-changeover .setting {
  margin: 15px 0px;
  /* height: 90px; */
}

.auto-changeover .info-box {
  height: 345px;
}
</style>

<script>
import PopUpHead from './PopUpHead.vue'
import FrameInput from "./FrameInput.vue";
import SelectDropDown from './SelectDropDown.vue'
import NavHorizontal from './NavHorizontal.vue'
import SelectOption from './SelectOption.vue'
import LoadingSpinner from './LoadingSpinner.vue';
import PopUpToast from './PopUpToast.vue';
import NavPagination from './NavPagination.vue'
import ListAutoChangeover from './ListAutoChangeover.vue'

export default {
  name: "PopUpAutomaticChangeover",
  data() {
    return {
      isLoading: true,
      details: { name: "change_over" },
      clonedCommModule: {},
      showEdit: false,
      isNewIAQValue: false,
      showEditIAQValue: false,
      clonedPointList: {},
      clonedGroupList: {},
      isRegister: false,
      groupId: '',
      groupName: '',
      groupDetails: { name: '', mode: 'fix', points: [], weight: {}, target: '' },
      modeOptions: ['fix', 'vote'],
      pointsChecked: {},
      groupKeyList: [],
      toast: {},
      isError: false,
      changeSwitch: false,
      // isVote: false,
      page: 1,
      itemPerPage: 13,
      slicedList: [],
      isAddNewItem: false
    }
  },
  props: {
    commModule: Object,
    cache: Object,
  },
  created() {
    this.clonedCommModule = Object.assign({}, this.commModule);
    this.clonedPointList = this.clonedCommModule.point_list
    this.getGroup()
  },
  mounted() {
  },
  computed: {
    updatedDeviceList() {
      const filteredKeys = Object.keys(this.pointsChecked).filter(key => this.commModule.point_list[key] && this.pointsChecked[key]);
      const resultArray = filteredKeys.map(key => key);
      resultArray.splice(0, 0, "no_device");
      return resultArray
    },
    updatedPointList() {
      const filteredKeys = Object.keys(this.pointsChecked).filter(key => this.pointsChecked[key]);
      const resultArray = filteredKeys.map(key => key);
      return resultArray
    },
    isVote() {
      if (this.groupDetails.mode == 'vote') {
        return true
      } else {
        return false
      }
    },
    emptyPointChecked() {
      return Object.values(this.pointsChecked).every(value => value === false);
    },
    defaultModeIndex() {
      // if (this.isRegister) {
      //   return 0
      // } else {
      return this.modeOptions.indexOf(this.groupDetails.mode);
      // }
    },
    defaultTargetIndex() {
      if (this.isRegister) {
        return 0
      } else {
        return this.targetPointList.indexOf(this.groupDetails.target);
      }
    },
    targetPointList() {
      // this
      const points = JSON.parse(JSON.stringify(this.groupDetails.points))
      points.splice(0, 0, "no_device");
      return points
    },
    head_detail() {
      var name = '';
      if (this.showEdit)
        name = this.isRegister ? 'add_group' : 'edit_group';
      return { name }
    },
    mainList() {
      return Object.keys(this.clonedGroupList);
    },
  },
  watch: {
    'commModule.changeover_get_groups'(res) {
      this.isLoading = false
      if (res.result == 'NG') return;
      this.clonedGroupList = this.commModule.changeover_get_groups
      // for (let index = 0; index < 34; index++) {
      //   this.clonedGroupList[`test_${index}`] =
      //     JSON.parse(JSON.stringify(this.clonedGroupList[Object.keys(this.clonedGroupList)[0]]));
      // }
      this.groupKeyList = Object.values(this.clonedGroupList).map(item => item.name);
      this.initializePointChecked()
      this.initializePointList()
      this.isError = false
      if (this.isAddNewItem) {
        this.page = this.ConvertHelper.getMaxPage(this.mainList.length, this.itemPerPage);
        this.isAddNewItem = false;
      }
    },
    'commModule.changeover_add'() {
      var isNg = this.commModule.changeover_add.result == "NG";
      this.toast = { text: isNg ? this.commModule.changeover_add.result : 'succeed' };
      this.showEdit = false
      this.getGroup()
      this.isAddNewItem = true;
    },
    'commModule.changeover_delete'() {
      var text;
      if (this.commModule.changeover_delete.result == "OK") {
        text = 'succeed';
      } else {
        text = this.commModule.changeover_delete.result;
      }
      this.toast = { text }
      this.showEdit = false
      this.getGroup()
    },
    'commModule.changeover_modify'() {
      var isNg = this.commModule.changeover_modify.result == "NG";
      if (!this.changeSwitch) {
        this.toast = { text: isNg ? this.commModule.changeover_modify.result : 'succeed' }
        this.showEdit = false
      }
      this.getGroup()
    },
  },
  methods: {
    clickedCheckBox() {
      this.pointsChecked = Object.entries(this.clonedPointList).reduce((result, [key, value]) => {
        if (value.stdUserChecked) {
          result[key] = true;
        } else {
          result[key] = false;
        }
        return result;
      }, {});
      this.groupDetails.points = this.updatedPointList

      for (var i = 0; i < this.groupDetails.points.length; i++) {
        var item = this.groupDetails.points[i];
        if (!(item in this.groupDetails.weight)) {
          this.groupDetails.weight[item] = 1; // set a default weight:1 to new point
        }
      }
    },
    initializePointList() {
      this.clonedPointList = this.commModule.point_list

      // Filter out objects with type "Ac"
      this.clonedPointList = Object.keys(this.clonedPointList).reduce((result, key) => {
        if (this.clonedPointList[key].type === "Ac") {
          result[key] = this.clonedPointList[key];
        }
        return result;
      }, {});

      //filter registered device
      const otherGroupList = JSON.parse(JSON.stringify(this.clonedGroupList))
      delete otherGroupList[this.groupId]
      var registeredPoints = [...new Set(Object.values(otherGroupList).flatMap(item => item.points))];

      const filteredPoints = {};
      for (const key in this.clonedPointList) {
        if (Object.prototype.hasOwnProperty.call(this.clonedPointList, key)
          && !registeredPoints.includes(key)) {
          filteredPoints[key] = this.clonedPointList[key];
        }
      }
      this.clonedPointList = filteredPoints
      for (const key in this.clonedPointList) {
        this.clonedPointList[key].stdUserChecked = this.pointsChecked[key] ? this.pointsChecked[key] : false
      }
    },
    initializePointChecked() {
      this.pointsChecked = {}
      this.groupDetails.points.forEach(element => {
        this.pointsChecked[element] = true
      });
    },
    changeOption(value, name,) {
      if (name == 'modeChangeover') {
        this.groupDetails.mode = value;
        if (!this.groupDetails.points.includes(this.groupDetails.target) && value == 'fix') {
          if (this.groupDetails.points.length == 0) {
            this.groupDetails.target = "no_device"
          } else {
            this.groupDetails.target = this.groupDetails.points[0]
          }
        }
      } else if (name == 'targetChangeover') {
        this.groupDetails.target = value;
        this.groupDetails.points = this.updatedPointList
      }
    },
    switchClicked(groupKey) {
      this.clonedGroupList[groupKey].enable = !this.clonedGroupList[groupKey].enable
      this.changeSwitch = true
      this.commModule.editChangeover(this.clonedGroupList);
    },
    frameSelected(item) {
      this.isRegister = false
      this.showEdit = true
      this.groupDetails = Object.assign({}, item.value);
      this.groupId = item.key;
      this.groupName = JSON.parse(JSON.stringify(this.groupDetails.name))
      this.initializePointChecked()
      this.initializePointList()
    },
    getGroup() {
      this.changeSwitch = false
      this.isError = false
      this.commModule.getChangeover()
    },
    prev() {
      this.showEdit = false
    },
    addNewIAQValue() {
      this.NavAssistant.openPopUp(this);
      this.showEditIAQValue = true
      this.isNewIAQValue = true
    },
    plus() {
      this.isRegister = true
      this.groupId = ''
      this.groupDetails = { "name": '', "mode": 'fix', "points": [], "weight": {}, "target": "", "heat_override": false }
      this.showEdit = true
      this.pointsChecked = {}
      this.targetPointList = ['no_device']
      this.initializePointChecked()
      this.initializePointList()
    },
    clickLeftBtn() {
      this.toast = {
        text: 'conf_del',
        pendingYesMethod: () => {
          this.commModule.delChangeover(this.groupId);
        }
      }
    },
    clickRightBtn() {
      if (this.ConvertHelper.isEmptyString(this.groupDetails.name) || Object.keys(this.groupDetails).length === 0) {
        this.toast = { text: 'no_name', isError: true, }
        return
      } else if (this.groupDetails.points.length == 0) {
        this.toast = { text: 'no_device', isError: true, } // duplicate room name
        return
      } else if ((this.groupDetails.target == "no_device" || this.groupDetails.target == "") && this.groupDetails.mode == "fix") {
        this.toast = { text: 'no_target', isError: true, } // duplicate room name
        return
      }
      if (this.isRegister) { // register 
        if (this.groupKeyList.includes(this.groupDetails.name)) {
          this.toast = { text: 'same_name', isError: true, } // duplicate room name           
          return
        }
        this.commModule.addChangeover(this.groupDetails);
      } else { // edit
        if (this.groupName !== this.groupDetails.name && this.groupKeyList.includes(this.groupDetails.name)) {
          this.toast = { text: 'same_name', isError: true, } // duplicate room name  
          return
        }
        this.groupDetails.enable = this.clonedGroupList[this.groupId].enable
        this.clonedGroupList[this.groupId] = this.groupDetails
        this.commModule.editChangeover(this.clonedGroupList);
      }
    },
    back() {
      this.NavAssistant.hidePopUp();
      if (this.showLast) {
        this.NavAssistant.showLast();
      }
    },
  },
  components: {
    PopUpHead,
    FrameInput,
    SelectDropDown,
    NavHorizontal,
    SelectOption,
    PopUpToast,
    LoadingSpinner,
    NavPagination,
    ListAutoChangeover
  }
}
</script>