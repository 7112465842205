<template>
  <div class="bar-droppable bar-spacing frame control" :class="{ 'with-input': showInput }">
    <div class="upper-content vertical-center" @click="toggleBottom()" role="button">
      <div class="label bold vertical-center">
        <img v-if="icon" :src="icon" /> {{ title_display }}
        <input v-if="showInput" type="text" maxlength="5" v-model="sub_list.value" />
      </div>
      <BtnSwitch class="switch" :default="sub_list.toShow != undefined ? sub_list.toShow : false" :name="actionValue" />
      <div v-if="topRightBtn" class="frame btn top-right-btn">
        {{ stringTable.word(topRightBtn) }}
      </div>
    </div>
    <div class="action-value" v-if="sub_list.toShow && actionValue != '' && !showInput">
      <!-- {{ sub_list.value }} -->
      {{ displayValue(sub_list.value) }}
    </div>
    <div v-if="showBottom">
      <div class="bottom-center" v-if="actionValue == 'lock'">
        <div class="frame option-button" :class="{ 'activebg': sub_list.value == 'open' }" @click="twoOptionClicked()"
          role="button">
          <img :src="ReiriIcons.lock_2" class="onoff-icon" :class="{ 'to-white': sub_list.value == 'open' }" />
        </div>
        <div class="frame option-button" :class="{ 'activebg': sub_list.value == 'close' }" @click="twoOptionClicked()"
          role="button">
          <img :src="ReiriIcons.lock_1" class="onoff-icon" :class="{ 'to-white': sub_list.value == 'close' }" />
        </div>
      </div>

      <div class="bottom-center"
        v-if="['stat', 'off_timer', 'notify', 'rop_proh', 'rsp_proh', 'rmode_proh', 'rfan_proh', 'interlock'].includes(actionValue)">
        <div class="frame option-button with-txt" :class="{ 'activebg': sub_list.value == keyMap[actionValue].active }"
          @click="oneOptionClicked()" role="button">
          <img :src="ReiriIcons[keyMap[actionValue].icon]" class="onoff-icon"
            :class="{ 'to-white ': sub_list.value == keyMap[actionValue].active }" />
          <div class="text">
            <!-- {{ sub_list.value }} -->
            <div v-if="actionValue == 'off_timer'">
              {{ stringTable.word(offTimerMap[sub_list.value]) }}
            </div>
            <div v-else-if="actionValue == 'notify'">
              {{ stringTable.word(notifyMap[sub_list.value]) }}
            </div>
            <div v-else>{{ stringTable.word(sub_list.value) }}</div>
          </div>
        </div>
      </div>

      <div class="bottom-center" v-if="['mode', 'fanstep', 'vmode', 'vamount', 'shutter'].includes(actionValue)">
        <!-- ['mode', 'fanstep'].includes(actionValue)"> -->
        <div v-for="(value, key) in keyMap[actionValue].option " :key="key">
          <div v-if="isGroupAction">
            <div class="frame option-button" :class="{ 'activebg': sub_list.value == key }" @click="generalClicked(key)"
              role="button">
              <img :src="value" class="onoff-icon" :class="{
                'to-white': sub_list.value == key,
                // 'to-black': sub_list.value != key && actionValue == 'vmode'
              }" />
            </div>
          </div>
          <div v-else>
            <div v-if="!keyMap[actionValue].cap || (!currentPoint || currentPoint[keyMap[actionValue].cap][key])"
              class="frame option-button" :class="{ 'activebg': sub_list.value == key }" @click="generalClicked(key)"
              role="button">
              <img :src="value" class="onoff-icon" :class="{
                'to-white': sub_list.value == key,
                // 'to-black': sub_list.value != key && actionValue == 'vmode'
              }" />
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-slider vertical-center" v-if="['sp', 'sb_csp', 'sb_hsp', 'hum_sp'].includes(actionValue)">
        <input v-if="gotCheckBox" class="form-check-input" type="checkbox" v-model="dashDash" @change="changeDashDash()">
        <vue-slider v-model="spValue" tooltip="always" :min="keyMap[actionValue].min" :max="keyMap[actionValue].max"
          :marks="keyMap[actionValue].marks" :interval="keyMap[actionValue].interval" :min-range="1">
        </vue-slider>
      </div>
      <div class="bottom-normal" v-if="actionValue == 'rc_proh'">
        <div class="form-check vertical-center"
          v-for="prob in (currentPoint.type == 'Hrv' || isHrvGroup ? ['rop_proh'] : remoteProhibitionList)" :key="prob">
          <label class="form-check-label vertical-center">
            <input class="form-check-input" type="checkbox" v-model="sub_list.option[prob].value"
              @change="updateRemoteList">
            <div>{{ stringTable.word(prob) }}</div>
          </label>
        </div>
      </div>

      <div class="bottom-center" v-if="actionValue == 'av'">
        <input class="av-input" type="number" v-model="sub_list.value" />%
      </div>

      <div class="bottom-center" v-if="actionValue == 'rgbw'">
        <div class="frame option-button">
          <input class="onoff-icon" type="color" id="favcolor" name="favcolor" :value="colorValue"
            @input="updateColorValue">
          <div class="small-description">{{ stringTable.word("color") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.av-input {
  width: 100px;
  margin-right: 5px;
}

.bar-droppable.control {
  padding: 10px 0;
}

.bar-droppable.control .upper-content {
  padding: 0 13px;
}

.bar-droppable .action-value {
  color: var(--active);
  font-size: 14px;
  padding: 0 15px 0 15px;
}

.bar-droppable.control img {
  margin-right: 0;
}

.bar-droppable.control input[type=text],
.bar-droppable.control input[type=number] {
  background-color: var(--inputArea);
  margin: 0 12px;
  width: 80px;
}

.bar-droppable .small-description {
  font-size: 12px;
}

.bar-droppable .form-check-input {
  margin-left: 0 !important;
  margin-right: 10px !important;
}

.bar-droppable .option-button {
  padding: 5px;
  margin: 8px 3px 0 3px;
  text-align: center;
}

.bar-droppable .option-button img {
  width: 28px;
  height: 28px;
}

.bar-droppable .option-button.with-txt img {
  width: 50px;
}

.bar-droppable .option-button .text {
  font-size: .8em;
}

.bar-droppable .activebg,
.interlock-action .activebg {
  color: white;
  background-color: var(--active);
}

.bar-droppable .bottom-slider {
  padding: 32px 8px 10px 8px;
}

.bar-droppable .bottom-normal {
  padding: 10px 15px 8px 16px;
}

.bar-droppable .bottom-center {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 15px !important;
}

.bar-droppable .onoff-icon {
  width: 35px;
  height: 35px;
}

.bar-droppable .label img {
  margin-right: 6px;
}

.bar-droppable .vue-slider {
  flex: 1;
}

.vue-slider-ltr .vue-slider-mark:first-child .vue-slider-mark-label {
  margin-right: -15px;
  transform: none;
}

.vue-slider-ltr .vue-slider-mark:last-child .vue-slider-mark-label {
  margin-left: -20px;
  left: 80%;
}
</style>

<script>
import BtnSwitch from './BtnSwitch.vue'
import { reactive, toRefs } from 'vue'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  setup() {
    const data = reactive({ value: 0 })
    return toRefs(data)
  },
  name: "DroppableControl",
  props: {
    icon: String,
    topRightBtn: String,
    openBottom: Boolean,
    firstVal: Number,
    secondVal: Number,
    detailsList: Object,
    currentPoint: Object,
    actionValue: String,
    showInput: Boolean,
    isGroupAction: Boolean,
    isHrvGroup: Boolean,
  },
  data() {
    return {
      detailsListCopy: {},
      showBottom: false,
      keyMap: {
        stat: { icon: 'power', active: 'on' },
        off_timer: { icon: 'timer', active: 'on' },
        notify: { icon: 'sensor', active: true },
        rop_proh: { icon: 'circle', active: 'enable' },
        rsp_proh: { icon: 'circle', active: 'enable' },
        rmode_proh: { icon: 'circle', active: 'enable' },
        rfan_proh: { icon: 'circle', active: 'enable' },
        interlock: { icon: 'circle', active: 'enable' },
        mode: {
          option: {
            'C': this.ReiriIcons.cooling,
            'H': this.ReiriIcons.sun,
            'F': this.ReiriIcons.fan,
            'D': this.ReiriIcons.dry,
            'A': this.ReiriIcons.auto_default
          },
          cap: 'mode_cap'
        },
        fanstep: { option: this.fan_step_option(), },
        vmode: {
          option: {
            A: this.ReiriIcons.HRV_auto,
            HX: this.ReiriIcons.HRV_ex,
            S: this.ReiriIcons.HRV_bypass
          },
          cap: 'vmode_cap'
        },
        vamount: {
          option: {
            A: this.ReiriIcons.fan2_auto,
            L: this.ReiriIcons.fan2_1,
            H: this.ReiriIcons.fan2_2
          },
          cap: 'vamount_cap'
        },
        shutter: {
          option: {
            open: this.ReiriIcons.shutter_up,
            close: this.ReiriIcons.shutter_up
          }
        },
        sp: { min: this.tempConvert.spConvert(16), max: this.tempConvert.spConvert(32), marks: this.displayVueSliderMarks([16, 32], 'sp'), interval: this.tempConvert.spGap() },
        sb_csp: { min: this.tempConvert.spConvert(24), max: this.tempConvert.spConvert(35), marks: this.displayVueSliderMarks([24, 35], 'sb_csp'), interval: 1 },
        sb_hsp: { min: Math.round(this.tempConvert.spConvert(12)), max: this.tempConvert.spConvert(20), marks: this.displayVueSliderMarks([12, 20], 'sb_hsp'), interval: 1 },
        hum_sp: { min: 30, max: 80, marks: this.displayVueSliderMarks([30, 80], 'hum_sp'), interval: 1 },
      },
      offTimerMap: { off: 'disable', on: 'enable' },
      notifyMap: { false: 'disable', true: 'enable' },
      vmodeMap: { A: 'vA', HX: 'vHX', S: 'vS' },
      sliderValue: 0,
      marks: [16, 32],
      remoteProhibitionList: ['rop_proh', 'rmode_proh', 'rsp_proh', 'rfan_proh'],
      dashDash: true,
      dashDashReal: '',
      gotCheckBox: false
    }
  },
  created() {
    this.detailsListCopy = this.detailsList
    if (this.firstVal && this.secondVal)
      this.sliderValue = [this.firstVal, this.secondVal];
    else if (this.firstVal)
      this.sliderValue = this.firstVal;
    if (this.openBottom) this.showBottom = true;
    // if (this.currentPoint) {
    const validData = this.currentPoint && this.currentPoint.hum_range;
    var range = validData ? this.currentPoint.hum_range : [30, 80];
    this.keyMap.hum_sp = {
      min: range[0],
      max: range[1],
      marks: this.displayVueSliderMarks(range, 'hum_sp'),
      interval: 1
    }
    this.gotCheckBox = ['sb_csp', 'sb_hsp'].includes(this.actionValue);
    if (this.gotCheckBox) {
      var defSb = { sb_csp: 28, sb_hsp: 18 }
      this.dashDashReal = defSb[this.actionValue];
      this.dashDashReal = this.tempConvert.spConvert(this.dashDashReal);
      this.dashDash = this.sub_list.value != '--';
    }
  },
  computed: {
    spValue: {
      get() {
        if (this.actionValue == 'hum_sp') return this.sub_list.value;
        return this.sub_list.value == '--' ? '--' : this.tempConvert.spConvert(this.sub_list.value)
      },
      set(value) {
        if (this.actionValue == 'hum_sp') this.sub_list.value = value;
        else this.sub_list.value = value == '--' ? value : this.tempConvert.spRevConvert(value);
      }
    },
    colorValue: {
      get() {
        // Convert the hexadecimal number to a color string
        const hexValue = this.sub_list.value.toString(16);
        return '#' + hexValue.slice(0, -2);
      },
    },
    target_list() {
      if (this.currentPoint)
        return this.detailsListCopy[this.currentPoint[
          this.currentPoint.icon_map == 'group' ? 'icon_map' : 'type']];
      return this.detailsListCopy;
    },
    sub_list() {
      var subList = this.target_list[this.actionValue];
      // if (this.actionValue == 'off_timer' &&
      //   Object.keys(this.offTimerMap).includes(subList.value)) {
      //   subList.value = this.offTimerMap[subList.value];
      // subList.value = subList.value;
      // } else if (this.actionValue == 'notify') {
      // subList.value = subList.value ? 'enable' : 'disable';
      // subList.value = subList.value;
      // }
      return subList;
    },
    title_display() {
      var title = this.stringTable.word(this.actionValue);
      if (this.actionValue == 'interlock')
        title += `/${this.stringTable.word('schedule')}`;
      return title;
    }
  },
  watch: {
    detailsList() {
      this.showBottom = false
      this.detailsListCopy = this.detailsList
    }
  },
  methods: {
    displayVueSliderMarks(marks, type) {
      return marks.reduce((result, value) => {
        if (type == 'hum_sp') result[value] = `${value}%`;
        else result[this.tempConvert.spConvert(value)] =
          `${this.tempConvert.spConvert(value).toFixed(1)}${this.stringTable.word('DEG')}`;
        return result;
      }, {});
    },
    fan_step_option() {
      var fanStepOption = {
        2: { 'L': this.ReiriIcons.fan2_1, 'H': this.ReiriIcons.fan2_2, 'A': this.ReiriIcons.fan2_auto },
        3: { 'L': this.ReiriIcons.fan3_1, 'M': this.ReiriIcons.fan3_2, 'H': this.ReiriIcons.fan3_3, 'A': this.ReiriIcons.fan3_auto },
        5: {
          'L': this.ReiriIcons.fan5_1, 'LM': this.ReiriIcons.fan5_2, 'M': this.ReiriIcons.fan5_3, 'MH': this.ReiriIcons.fan5_4, 'H': this.ReiriIcons.fan5_4, 'A': this.ReiriIcons.fan5_auto
        },
      }
      if (this.isGroupAction)
        return fanStepOption[3]
      else
        return this.currentPoint && this.currentPoint.fanstep_cap ? fanStepOption[this.currentPoint.fanstep_cap['S']] : fanStepOption[3];
    },
    displayValue(value) {
      if (['sp', 'sb_csp', 'sb_hsp'].includes(this.actionValue)) {
        return value == '--' ? value : `${this.tempConvert.spConvert(value).toFixed(1)}${this.stringTable.word('DEG')}`;
      } else if (['hum_sp', 'av'].includes(this.actionValue)) {
        return `${value}%`;
      } else if (this.actionValue == 'rgbw') {
        return '0x' + value.toString(16).slice(0, -2).toUpperCase()
      } else if (this.actionValue == 'rc_proh') {
        if (value.length == 0) return ''; // Return an empty string if the list is empty  
        return value.map(v => this.stringTable.word(v)).join(' · ');// Join the list items with ' · ' separator
      } else if (['fanstep', 'vamount'].includes(this.actionValue)) {
        if (value == 'A') return this.stringTable.word(value);
        return value;
      } else if (['vmode'].includes(this.actionValue)) {
        return this.stringTable.word(this.vmodeMap[value])
      } else if (['off_timer'].includes(this.actionValue)) {
        return this.stringTable.word(value == 'on' ? 'enable' : 'disable')
      } else if (['notify'].includes(this.actionValue)) {
        return this.stringTable.word(value == true ? 'enable' : 'disable')
      } else return this.stringTable.word(value);
    },
    updateRemoteList() {
      const category = this.isGroupAction ? 'group' : (this.currentPoint.type == 'Hrv' ? 'Hrv' : 'Ac');
      const options = this.detailsListCopy[category]['rc_proh'].option;

      const selectedOptions = Object.values(options)
        .filter(option => option.value)
        .map(option => option.name);

      this.detailsListCopy[category]['rc_proh'].value = selectedOptions;
    },
    hexToDecimal(hex) {
      //   Convert the color string to a hexadecimal number and update the data
      const decimalValue = parseInt(hex.slice(1) + 'FF', 16)
      return decimalValue;
    },
    updateColorValue(event) {
      // Update the computed property when the input value changes
      const decimalColor = this.hexToDecimal(event.target.value)
      this.target_list['rgbw'].value = decimalColor;
    },
    oneOptionClicked() {
      if (this.actionValue == 'notify') {
        this.sub_list.value = this.sub_list.value ? false : true;
        // this.sub_list.value = this.sub_list.value == 'on' ? 'off' : 'on';
      } else {
        if (['on', 'off'].includes(this.sub_list.value))
          this.sub_list.value = this.sub_list.value == 'on' ? 'off' : 'on';
        else if (['enable', 'disable'].includes(this.sub_list.value)) {
          this.sub_list.value = this.sub_list.value == 'enable' ? 'disable' : 'enable';
        }
      }
    },
    twoOptionClicked() {
      this.sub_list.value = this.sub_list.value == 'open' ? 'close' : 'open';
    },
    generalClicked(value) {
      this.sub_list.value = value;
    },
    toggleBottom() {
      if (this.openBottom) return;
      this.showBottom = !this.showBottom;
    },
    changeSwitch(name, onSwitch) {
      if (onSwitch) {
        this.target_list[name].toShow = true
      } else {
        //        console.log(this.target_list[name])
        this.target_list[name].toShow = false
      }
    },
    changeDashDash() {
      if (this.dashDash) {
        this.spValue = this.dashDashReal;
      } else {
        if (!this.dashDashReal)
          this.dashDashReal = this.spValue;
        this.spValue = '--';
      }
    }
  },
  components: {
    BtnSwitch,
    VueSlider
  }
}
</script>

<!-- off timer -->
<!-- <div class=" bottom-center" v-if="showBottom && actionValue == 'off_timer'">
  <div class="frame option-button" :class="{ 'activebg': sub_list.value == 'on' }">
    <img :src="ReiriIcons.timer" class="onoff-icon" @click="oneOptionClicked"
      :class="{ 'to-white ': sub_list.value == 'on' }" />
  </div>
</div> -->
<!-- mode -->
<!-- vmode  -->
<!-- <div class=" bottom-center" v-if="showBottom && actionValue == 'vmode' && currentPoint">
  <div v-for="( value, key ) in  vmodeOption " :key="key">
    {{ currentPoint.vmode_cap[key] }}
    <div v-if="currentPoint.vmode_cap[key]" class="frame option-button"
      :class="{ 'activebg': sub_list.value == key }">
      <img :src="value" class="onoff-icon" :class="{ 'to-white': sub_list.value == key }"
        @click="generalClicked(key)" />
    </div>
  </div>
</div> -->
<!-- vamount  -->
<!-- <div class=" bottom-center" v-if="showBottom && actionValue == 'vamount' && currentPoint">
  <div v-for="( value, key ) in  vamountOption " :key="key">
    {{ currentPoint.vamount_cap[key] }}
    <div v-if="currentPoint.vamount_cap[key]" class="frame option-button"
      :class="{ 'activebg': sub_list.value == key }">
      <img :src="value" class="onoff-icon" :class="{ 'to-white': sub_list.value == key }"
        @click="generalClicked(key)" />
    </div>
  </div>
</div> -->
<!-- fan speed -->
<!-- <div class=" bottom-center" v-if="showBottom && actionValue == 'fanstep' && currentPoint">
  <div v-for="( value, key ) in  fan_step_option " :key="key">
    <div class="frame option-button" :class="{ 'activebg': sub_list.value == key }">
      <img :src="value" class="onoff-icon" :class="{ 'to-white': sub_list.value == key }"
        @click="generalClicked(key)" />
    </div>
  </div>
</div> -->
<!-- setpoint -->
<!-- cool setback -->
<!-- <div class="bottom-slider" v-if="showBottom && actionValue == 'sb_csp'">
  <vue-slider v-model="sub_list.value" :tooltip="'always'" :min="16" :max="32" :marks="marks" :interval=0.5>
  </vue-slider>
</div> -->
<!-- heat setback -->
<!-- <div class="bottom-slider" v-if="showBottom && actionValue == 'sb_hsp'">
  <vue-slider v-model="sub_list.value" :tooltip="'always'" :min="16" :max="32" :marks="marks" :interval=0.5>
  </vue-slider>
</div> -->
<!-- hum_sp humidity -->
<!-- <div class="bottom-slider" v-if="showBottom && actionValue == 'hum_sp'">
  <vue-slider v-model="sub_list.value" :tooltip="'always'" :min="currentPoint.hum_range[0]"
    :max="currentPoint.hum_range[1]" :marks="[currentPoint.hum_range[0], currentPoint.hum_range[1]]" :interval=1>
  </vue-slider>
</div> -->
<!-- remote controller prohibition -->
<!-- analog value -->
<!-- shutter -->
<!-- <div class="bottom-center" v-if="showBottom && actionValue == 'shutter' && currentPoint">
  <div v-for="( value, key ) in  shutterOption " :key="key">
    <div class="frame option-button" :class="{ 'activebg': sub_list.value == key }">
      <img :src="value" class="onoff-icon" :class="{ 'to-white': sub_list.value == key }"
        @click="generalClicked(key)" />
    </div>
  </div>
</div> -->
<!-- rgbw -->
<!-- interlock scene, schedule, interlock -->
<!-- <div class="bottom-center" v-if="['enableDisable'].includes(actionValue)">
  <div class="frame option-button with-txt"
    :class="{ 'activebg': sub_list.value == keyMap[actionValue].active }" @click="oneOptionClicked()"
    role="button">
    <img :src="ReiriIcons[keyMap[actionValue].icon]" class="onoff-icon"
      :class="{ 'to-white ': sub_list.value == keyMap[actionValue].active }" />
    <div class="text">
      {{ stringTable.word(sub_list.value) }}
    </div>
  </div>
</div> -->