<template>
  <div class="navigation navigation-favorite btm-line expand">
    <div class="btn-holder">
      <BtnNavIconText text="dev" :compoForChangeScreen="$parent" compoTarget="PageFavDevice" />
      <BtnNavIconText text="group" :compoForChangeScreen="$parent" compoTarget="PageFavGroup" />
      <BtnNavIconText text="scene" :compoForChangeScreen="$parent" compoTarget="PageFavScene" />
    </div>
  </div>
</template>

<style>
.navigation-favorite {
  margin-bottom: 6px;
}

.navigation-favorite .btn-holder {
  background-color: var(--navFav);
  box-shadow: 0 0 8px #bababa;
  padding-top: 6px;
}

.navigation.btm-line .item {
  padding: 4px;
  border-bottom: 3px solid var(--transparent);
}

.navigation.expand .item {
  width: 100%;
}

.navigation.btm-line .active-item {
  border-color: var(--active);
}
</style>


<script>
import BtnNavIconText from './BtnNavIconText.vue'

export default {
  name: "NavTopFavorite",
  props: {
    activeIndex: Number,
  },
  data() {
    return {
    }
  },
  components: {
    BtnNavIconText
  }
}
</script>

<!-- 
name_keys: ['dev', 'group', 'scene'],
pages: ['PageFavDevice', 'PageFavGroup', 'PageFavScene'],

<div class="grid-item" :class="{ 'active-item': index == activeIndex }" role="button"
v-for="(item, index) in name_keys" :key="index" @click="changeActiveNav(index)">
<span class="nav-text" :class="{ 'active-text': index == activeIndex }">
  {{ stringTable.word(item) }}
</span>
</div> -->

<!-- 
  // methods: {
  //   changeActiveNav(activeIndex) {
  //     this.$parent.activeIndex = activeIndex;
  //     this.$parent.currentScreen = this.pages[activeIndex];
  //   }
  // }
 -->