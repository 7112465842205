<template>
	<NavTopHome :accFunctions="commModule.account.available_functions" :activeIndex="activeIndex"
		:isAdmin="commModule.isAdmin" />
	<component :is="currentScreen" :commModule="commModule" />
</template>

<style></style>

<script>
import PageFavorite from './PageFavorite.vue'
import PageIaqMoni from './PageIaqMoni.vue'
import PageLayout from './PageLayout.vue'
import PageGuestRoom from './PageGuestRoom.vue'
import PagePower from './PagePower.vue'
import NavTopHome from './NavTopHome.vue'

export default {
	name: "PageHome",
	props: {
		commModule: Object,
	},
	data() {
		return {
			activeIndex: 0,
			currentScreen: 'PageFavorite'
		}
	},
	created() {
		this.NavAssistant.push(this);
	},
	watch: {
	},
	methods: {
		changeActiveNavFav(activeIndex) {
			this.activeIndex = activeIndex;
			this.currentScreen = this.pagesFav[activeIndex];
		}
	},
	computed: {
	},
	components: {
		PageFavorite,
		PageIaqMoni,
		PageLayout,
		PageGuestRoom,
		PagePower,
		NavTopHome
	},
}
</script>