<template>
  <div ref="test">

  </div>
  <div class="page-guest-room">
    <LoadingSpinner v-if="unitStatIsLoading && roomSettIsLoading" />
    <div v-show="!unitStatIsLoading && !roomSettIsLoading">
      <div v-show="!hasLocation" class="bef-tile-section">
        <NavPagination direction="left" />
        <div class="tile-section">
          <TileGuestRoom v-for="index in slicedList" :key="index" :roomKey="index" :roomData=guestRoomData[index]
            :commModule="commModule" />
        </div>
        <NavPagination direction="right" />
      </div>
      <div v-show="hasLocation" class="vertical-center">
        <div v-show="hasLocation" class="location-container" id="location-container" ref="location1">
          <div v-for="key in guestRoomLocationMap" :key="key">
            {{ key.name }}
            <div v-for="item in  guestRoomLocationMap" :key="item">
              <div v-if="item.name == key.name" class="location-room-tile-section">
                <TileGuestRoom v-for="room in item.room_order" :key="room" :roomKey="room" :roomData=guestRoomData[room]
                  :commModule="commModule" />
              </div>
            </div>
          </div>
        </div>
        <div class="vertical-cetner scroll-button-holder">
          <div class="button">
            <div class="scroll-btn vertical-center" :class="{ 'invisible': !(!isTop && needsScroll) }">
              <div class="button-holder vertical-center">
                <div class="frame button vertical-center" v-on:click="toTop()" role="button">
                  <img class="up-down-btn" :src="ReiriIcons.page_up" />
                </div>
              </div>
            </div>
            <div class="scroll-btn vertical-center" :class="{ 'invisible': !(!isTop && needsScroll) }">
              <div class="button-holder vertical-center">
                <div class="frame button vertical-center" v-on:click="scrollUp()" role="button">
                  <img class="up-down-btn" :src="ReiriIcons.up" />
                </div>
              </div>
            </div>
            <div class="scroll-btn vertical-center" :class="{ 'invisible': !(!isBottom && needsScroll) }">
              <div class="button-holder vertical-center">
                <div class="frame button vertical-center" v-on:click="scrollDown()" role="button">
                  <img class="up-down-btn" :src="ReiriIcons.up" style="transform: rotate(180deg)" />
                </div>
              </div>
            </div>
            <div class="scroll-btn vertical-center" :class="{ 'invisible': !(!isBottom && needsScroll) }">
              <div class="button-holder vertical-center">
                <div class="frame button vertical-center" v-on:click="toBottom()" role="button">
                  <img class="up-down-btn" :src="ReiriIcons.page_up" style="transform: rotate(180deg)" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.page-guest-room {
  height: 485px;
  width: 100%;
}

.scroll-btn .button-holder {
  margin: 3px auto;
}

.scroll-btn .button-holder .button {
  border-radius: 20px;
}

.scroll-btn .up-down-btn {
  text-align: center;
  border-radius: 30px;
  height: 105px;
  margin: 3px;
  width: 30px;
  padding: 0;
}

.scroll-btn .up-down-btn img {
  width: 100%;
}

.scroll-button-holder {
  margin: 5px;
}

.scroll-button {
  margin: 5px;
  display: flex;
  justify-content: center;
}

.location-container {
  padding: 5px;
  overflow: hidden;
  height: 485px;
  flex: 1;
}

.location-room-tile-section {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 7px;
  padding-bottom: 20px;
  display: grid;
  grid-template-columns: 19.6% 19.6% 19.6% 19.6% 19.6%;
  gap: 5.3px 0.5%;
}
</style>

<script>
import LoadingSpinner from './LoadingSpinner.vue'
import TileGuestRoom from './TileGuestRoom.vue'
import NavPagination from './NavPagination.vue'
export default {
  name: "PageGuestRoom",
  props: {
    commModule: Object,
  },
  data() {
    return {
      unitStatIsLoading: true,
      roomSettIsLoading: true,
      hasLocation: false,
      isTop: true,
      isBottom: false,
      needsScroll: false,

      guestRoomData: {},
      unitStatMap: {},
      guestRoomSettingsMap: {},
      guestRoomLocationMap: {},
      guestRoomIconMap: {},
      reorderedMap: {},

      guestRoomOrder: [],

      //for nav buttons
      page: 1,
      itemPerPage: 30,
      mainList: [],
      slicedList: [],

    }
  },
  created() {
    this.commModule.getGuestRooms();
    this.NavAssistant.push(this);
  },
  mounted() {
  },
  watch: {

    "commModule.get_unit_stat"(room) {
      this.getInit(room);
    },

    "commModule.guest_room_settings"(room) {
      for (const key in JSON.parse(JSON.stringify(room))) {
        this.guestRoomSettingsMap[key] = JSON.parse(JSON.stringify(room))[key]
      }

      this.guestRoomLocationMap = this.guestRoomSettingsMap['locations']
      this.guestRoomIconMap = this.guestRoomSettingsMap['rooms']
      this.guestRoomOrder = this.guestRoomSettingsMap['roomOrder']

      if (this.guestRoomLocationMap && this.guestRoomLocationMap.length === 0 || this.guestRoomLocationMap === undefined || this.guestRoomLocationMap === null) {
        this.hasLocation = false
      } else {
        this.mainList = []
        this.itemPerPage = 3
        this.hasLocation = true
        for (const key in this.guestRoomLocationMap) {
          this.mainList.push(this.guestRoomLocationMap[key].name)
        }
      }

      for (const key in this.unitStatMap) {
        if (this.guestRoomIconMap && this.guestRoomIconMap[key]) {
          this.guestRoomData[key] = {
            'icon': this.guestRoomIconMap[key].icon,
            'icon_map': 'group',
            'connected': this.unitStatMap[key].connected,
            'rent_stat': this.unitStatMap[key].rent_stat,
            'occ_stat': this.unitStatMap[key].occ_stat,
            'rem_rent_op': this.unitStatMap[key].rem_rent_op
          }
        } else {
          this.guestRoomData[key] = {
            'icon': 'Single.png',
            'icon_map': 'group',
            'connected': this.unitStatMap[key].connected,
            'rent_stat': this.unitStatMap[key].rent_stat,
            'occ_stat': this.unitStatMap[key].occ_stat,
            'rem_rent_op': this.unitStatMap[key].rem_rent_op
          }
        }
      }

      var roomAdded = []
      for (const index in this.guestRoomOrder) {
        if (this.guestRoomData[this.guestRoomOrder[index]]) {
          roomAdded.push(this.guestRoomOrder[index])
          this.reorderedMap[this.guestRoomOrder[index]] = this.guestRoomData[this.guestRoomOrder[index]]
        }
      }

      for (const key in this.unitStatMap) {
        if (!roomAdded.includes(key)) {
          this.reorderedMap[key] = this.guestRoomData[key]
        }
      }

      this.guestRoomData = {}
      this.guestRoomData = this.reorderedMap

      if (!this.hasLocation) {
        this.mainList = Object.keys(this.guestRoomData)
      }


      this.roomSettIsLoading = false

      this.$nextTick(function () {
        if (this.$refs.location1.scrollHeight > 485) {
          this.needsScroll = true;
        }
      })
    },

    "commModule.realTimeGuestRoom"(res) {
      for (const key in res) {
        if (this.guestRoomData[key]) {

          if (typeof res[key].connected !== 'undefined') {
            this.guestRoomData[key].connected = res[key].connected
          }

          if (typeof res[key].rent_stat !== 'undefined') {
            this.guestRoomData[key].rent_stat = res[key].rent_stat
          }

          if (typeof res[key].occ_stat !== 'undefined') {
            this.guestRoomData[key].occ_stat = res[key].occ_stat
          }
        }
      }
//      console.log(this.guestRoomData)
    }

  },
  methods: {
    getInit(room) {
      for (const key in JSON.parse(JSON.stringify(room))) {
        this.unitStatMap[key] = JSON.parse(JSON.stringify(room))[key]
      }
      this.unitStatIsLoading = false
      this.commModule.getGuests();
    },

    toBottom() {
//      console.log('scrollHeight: ' + this.$refs.location1.scrollHeight)
      const element = document.getElementById("location-container")

      var sum = element.scrollTop = element.scrollHeight

      if (sum > element.scrollTop) {
        element.scrollTop = element.scrollHeight
        sum = element.offsetHeight
        this.isTop = false;
        this.isBottom = true;
      }
      else if (element.scrollTop > 0 && element.scrollTop < element.scrollHeight) {
        this.isBottom = false
        this.isTop = false
      }
    },

    scrollDown() {
      const element = document.getElementById("location-container")

      var sum = element.scrollTop += 240

      if (sum > element.scrollTop) {
        element.scrollTop = element.scrollHeight
        sum = element.offsetHeight
        this.isTop = false;
        this.isBottom = true;
      }
      else if (element.scrollTop > 0 && element.scrollTop < element.scrollHeight) {
        this.isBottom = false
        this.isTop = false
      }
    },

    toTop() {
      const element = document.getElementById("location-container");

      element.scrollTop = 0

      if (element.scrollTop == 0) {
        this.isBottom = false
        this.isTop = true
      } else if (element.scrollTop > 0 && element.scrollTop < element.scrollHeight) {
        this.isBottom = false
        this.isTop = false
      }
      else {
        this.isBottom = true
      }
    },

    scrollUp() {
      const element = document.getElementById("location-container");

      element.scrollTop -= 240
      if (element.scrollTop == 0) {
        this.isBottom = false
        this.isTop = true
      } else if (element.scrollTop > 0 && element.scrollTop < element.scrollHeight) {
        this.isBottom = false
        this.isTop = false
      }
      else {
        this.isBottom = true
      }
    },

  },
  computed: {
  },
  components: {
    LoadingSpinner,
    TileGuestRoom,
    NavPagination,
  },
}
</script>