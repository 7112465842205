<template>
  <div class="frame date-time-holder vertical-center">
    <img v-if="!hideDatePicker" :src="ReiriIcons.chevron_left" @click="previousDate" class="previous-btn" role="button" />
    <VueDatePicker v-if="!hideDatePicker" class="vue-datepicker" v-model="this.$parent.startDtInput"
      :format="ConvertHelper.datePickerFormat(parent_date_var)" :enable-time-picker="false" :clearable="false" auto-apply
      inputmode='none' :transitions="false" />
    <!-- <input type="date" class="form-control" :class="dateClass" v-model="this.$parent.startDtInput" /> -->
    <SelectTime v-if="showSelectTime" name="time" :minuteInterval="60" />
    <SelectMonth v-if="showSelectMonth" />
    <img v-if="!hideDatePicker" :src="ReiriIcons.chevron_right" @click="nextDate" class="next-btn" role="button" />
  </div>
</template>

<style>
.vue-datepicker {
  width: 150px;
}

.vue-datepicker input {
  pointer-events: none;
}

.dp__input_wrap input {
  background-color: #e0e4eb;
}

.date-time-holder .previous-btn {
  margin-left: 0px;
  margin-right: auto;
}

.date-time-holder .next-btn {
  margin-right: 0px;
  margin-left: auto;
}

.date-time-holder .previous-btn,
.date-time-holder .next-btn {
  width: 30px;
}

.date-time-holder {
  width: inherit;
  display: flex;
  padding: 2px;
  font-size: 15px;
}

.date-time-holder input[type=date] {
  width: 190px;
}

.date-time-holder input[type=date].align-side {
  margin: 0 6px 0 auto;
}

.date-time-holder input[type=date].center-solo {
  margin: 0 auto;
}

.date-time-holder .select-time {
  margin-left: 6px;
}

.date-time-holder .select-date-range {
  margin-right: auto;
}

.date-time-holder .select-month {
  margin: 0 auto;
}
</style>


<script>
import SelectTime from './SelectTime.vue'
import SelectMonth from './SelectMonth.vue'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name: "BarDateTime",
  props: {
    hideDatePicker: Boolean,
    showSelectTime: Boolean,
    showSelectMonth: Boolean,
    defDate: Date,
  },
  data() {
    return {
      changeMonthSelIndex: 0,
    }
  },
  created() {
    this.$parent.startDtInput = this.ConvertHelper.dtObjToDtInput(this.defDate);
  },
  methods: {
    previousDate() {
      if (!this.hideDatePicker) {
        this.parent_date_var.setDate(this.parent_date_var.getDate() - 1);
        this.$parent.startDtInput = this.ConvertHelper.dtObjToDtInput(this.parent_date_var);
      } else this.changeMonthSelIndex++;
    },
    nextDate() {
      if (!this.hideDatePicker) {
        this.parent_date_var.setDate(this.parent_date_var.getDate() + 1);
        this.$parent.startDtInput = this.ConvertHelper.dtObjToDtInput(this.parent_date_var);
      } else this.changeMonthSelIndex--;
    },
    changeTime(name, newTime) {
      this.$parent.changeTime(name, newTime);
    },
    changeDateRange(newDateRange) {
      this.$parent.changeDateRange(newDateRange);
    },
    changeMonth(newMonth, monthIndex) {
      this.changeMonthSelIndex = monthIndex;
      this.$parent.changeMonth(newMonth);
    }
  },
  computed: {
    parent_date_var() {
      return this.ConvertHelper.dtInputToDtObj(this.$parent.startDtInput);
    },
    dateClass() {
      return this.showSelectTime ? "align-side" : "center-solo";
    }
  },
  components: {
    SelectTime,
    SelectMonth,
    VueDatePicker
  }
}

</script>