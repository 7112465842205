<template>
  <div>
    <label class="switch">
      <input type="checkbox" checked v-model="onSwitch">
      <span class="slider round"></span>
    </label>
  </div>
</template>

<style>
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 24px;
  z-index: 1;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  /* -webkit-transition: .1s; */
  /* transition: .1s; */
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  /* -webkit-transition: .1s; */
  /* transition: .1s; */
}

input:checked+.slider {
  background-color: #25bcf5;
}

input:focus+.slider {
  box-shadow: 0 0 1px #25bcf5;
}

input:checked+.slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>

<script>
export default {
  props: {
    name: String,
    default: Boolean
  },
  data() {
    return {
      onSwitch: false
    }
  },
  created() {
    this.onSwitch = this.default;
  },
  watch: {
    default() {
      this.onSwitch = this.default;
    },
    onSwitch() {
      this.$parent.changeSwitch(this.name, this.onSwitch);
    }
  }
}
</script>
