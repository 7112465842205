<template>
  <div class="select-time">
    <SelectOption v-if="intvl_exceed_thres" :selectList="timeList" :hideArrow="true" :defaultSelectIndex="selIndexTime" />
    <div v-else class="hour-min vertical-center">
      <SelectOption name="hour" :selectList="hourList" :hideArrow="true" :defaultSelectIndex="selIndexHour" /> :
      <SelectOption name="min" :selectList="minList" :hideArrow="true" :defaultSelectIndex="selIndexMin" />
    </div>
  </div>
</template>

<style>
.select-time {
  width: fit-content;
}

.select-time select {
  text-align: center;
  height: 38px !important;
}

.select-time .hour-min select {
  height: 28px !important;
}

.select-time .hour-min select {
  width: 45px;
}

.select-time .hour-min select:first-child {
  margin-right: 6px;
}

.select-time .hour-min select:last-child {
  margin-left: 6px;
}
</style>

<script>
import SelectOption from './SelectOption.vue'

export default {
  name: "SelectTime",
  props: {
    minuteInterval: Number,
    name: String,
    defaultSelectIndex: Number,
    includeLastHour: Boolean,
    selectValue: String
  },
  data() {
    return {
      selectValueCopy: this.selectValue,
      minThres: 10,
      timeList: [],
      hourList: [],
      minList: [],
      hour: '00',
      min: '00',
      selIndexTime: this.defaultSelectIndex,
      selIndexHour: 0,
      selIndexMin: 0
    }
  },
  created() {
    var tempTime = new Date(1999, 1, 1, 0, 0);
    var endTime = new Date(1999, 1, 2, 0, 0);
    this.timeList = [];
    endTime.setMinutes(endTime.getMinutes() - this.minuteInterval);
    this.pushTime(tempTime);
    while (tempTime < endTime) {
      var addedTime = tempTime.getMinutes() + this.minuteInterval;
      tempTime.setMinutes(addedTime);
      this.pushTime(tempTime);
    }
    if (this.includeLastHour) this.timeList.push('24:00');
    this.setTimeIndexFromProp();
  },
  methods: {
    pushTime(dateTimeObj) {
      if (this.minuteInterval > this.minThres) {
        var timeStr = this.ConvertHelper.dtObjToTimeStr(dateTimeObj);
        this.timeList.push(timeStr);
      } else {
        var hourStr = this.ConvertHelper.dtObjToHourStr(dateTimeObj);
        var minStr = this.ConvertHelper.dtObjToMinsStr(dateTimeObj);
        if (!this.hourList.includes(hourStr))
          this.hourList.push(hourStr);
        if (!this.minList.includes(minStr))
          this.minList.push(minStr);
      }
    },
    setTimeIndexFromProp() {
      if (this.selectValue) {
        if (this.intvl_exceed_thres)
          this.selIndexTime = this.timeList.indexOf(this.selectValue);
        else {
          var timeArr = this.selectValueCopy.split(':');
          this.selIndexHour = this.hourList.indexOf(timeArr[0]);
          this.selIndexMin = this.minList.indexOf(timeArr[1]);
        }
      }
    },
    changeOption(newTime, name) {
      if (!newTime) return;
      if (['hour', 'min'].includes(name)) {
        this[name] = newTime;
        newTime = `${this.hour}:${this.min}`
      }
      var timeName = this.name;
      if (!this.intvl_exceed_thres)
        timeName += `_${name}`;
      this.$parent.changeTime(timeName, newTime);
    }
  },
  watch: {
    selectValue() {
      this.setTimeIndexFromProp();
    },
  },
  computed: {
    intvl_exceed_thres() {
      return this.minuteInterval > this.minThres;
    }
  },
  components: {
    SelectOption
  }
}
</script>
