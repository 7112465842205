<template>
  <div class="box" :class="boxClass()">
    <div class="label"> {{ getLabel() }} </div>
    <ValueUnit :val="getValue()" :unit="getUnit()" />
  </div>
</template>

<script>
import ValueUnit from './ValueUnit.vue'

export default {
  name: "TileIaqValUnit",
  props: {
    commModule: Object,
    roomDetailProp: Object,
  },
  data() {
    return {
      roomDetail: this.roomDetailProp
    }
  },
  methods: {
    boxClass() {
      this.roomDetail.no_red = ['ac', 'temp', 'humi', 'dehumi'].includes(this.roomDetail.type);
      var indicator;
      if (this.roomDetail.av == 'NA') indicator = 'gray';
      else {
        if (this.roomDetail.no_red) {
          if (this.roomDetail.av >= this.roomDetail.threshold_yellow &&
            this.roomDetail.av <= this.roomDetail.threshold_red)
            indicator = 'green';
          else indicator = 'yellow';
        } else {
          if (this.roomDetail.av > this.roomDetail.threshold_red)
            indicator = 'red';
          else if (this.roomDetail.av > this.roomDetail.threshold_yellow)
            indicator = 'yellow';
          else indicator = 'green';
        }
      }
      this.roomDetail.indicator = indicator;
      return indicator;
    },
    getLabel() {
      var typeDisplay = this.stringTable.word(this.roomDetail.type == 'ac' ? 'temp' :
        this.roomDetail.type == 'dehumi' ? 'humi' : this.roomDetail.type);
      this.roomDetail.type_display = typeDisplay;
      return typeDisplay;
    },
    getValue() {
      var point = this.commModule.point_list[this.roomDetail.id];
      if (!point) return;
      var aVal;
      if (point.av != undefined) {
        aVal = point.av;
        if (this.roomDetail.type == 'tvoc')
          aVal = aVal.toFixed(2);
        if (['humi', 'pm25'].includes(this.roomDetail.type))
          aVal = aVal.toFixed(1);
        if (this.roomDetail.type == 'temp')
          aVal = this.tempConvert.tempConvert(aVal).toFixed(1)
      } else if (point.temp) {
        if (['ac', 'temp'].includes(this.roomDetail.type))
          aVal = point.temp;
        else aVal = point.temp;
        aVal = this.tempConvert.tempConvert(aVal).toFixed(1);
      } else aVal = 'NA';
      this.roomDetail.av = aVal;
      return aVal;
    },
    getUnit() {
      var unit;
      if (['ac', 'temp'].includes(this.roomDetail.type))
        unit = this.stringTable.word('DEG');
      else {
        var point = this.commModule.point_list[this.roomDetail.id];
        if (!point) return;
        unit = this.stringTable.word(point.unit);
      }
      this.roomDetail.unit = unit;
      return unit;
    },
  },
  computed: {
  },
  components: {
    ValueUnit
  },
}
</script>