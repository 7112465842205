<template>
  <div class="pop-up group-oper">
    <PopUpHead :details="details" :isIconActive="details.stat_on" :showIcon="true" />
    <LoadingSpinner v-if="isLoading" />
    <div class="content-holder">
      <div v-if="showGen" class="general-holder">
        <div class="title">{{ stringTable.word('general') }}</div>
        <BarTwoBtn label="stat" :buttons="['on', 'off']" :topBottom="isLongLanguage" :leftRight="!isLongLanguage" />
        <BarTwoBtn v-if="commModule.isAdmin" label="off_timer" :buttons="['on', 'off']" :topBottom="isLongLanguage"
          :leftRight="!isLongLanguage" />
      </div>
      <!-- {{ showDehumi }} -->
      <!-- {{ detailsCloned }} -->
      <div v-if="showAc || showDehumi" class="ac-holder">
        <div class="title">{{ stringTable.word('ac') }}</div>
        <DroppableAc v-if="!commModule.sp_proh && showAc && showSetpoint" :label="stringTable.word('sp')"
          :showSliderAndValue="true" :firstVal="tempConvert.spConvert(24)" :openSlider="true" type="sp"
          :range="[tempConvert.spConvert(16), tempConvert.spConvert(32)]" :interval="tempConvert.spGap()" />
        <BarIconBtn v-if="!commModule.mode_proh && showAc" label="mode" :btnIconObj="modeObj" />
        <DroppableAc v-if="showDehumi && !commModule.sp_proh" :label="stringTable.word('hum_sp')"
          :showSliderAndValue="true" :firstVal="50" :openSlider="true" :range="[30, 80]" :interval="0.1" :isAv="true"
          type="hum_sp" />
        <BarIconBtn v-if="showFanstep" label="fanstep" :btnIconObj="fSpeedObj" />
      </div>
      <div v-if="showHrv" class="ventilation-holder">
        <div>
          <div class="title">{{ stringTable.word('hrv') }}</div>
          <BarIconBtn v-if="!commModule.mode_proh" label="vmode" :btnIconObj="ReiriIcons.iconList.vmode" />
          <BarIconBtn label="vamount" :btnIconObj="ReiriIcons.iconList.vamount" />
        </div>
      </div>
      <div v-if="commModule.isAdmin" class="adv-stg-holder can-scroll" :class="{ 'scroll': showAc }">
        <div class="title">{{ stringTable.word('advanced') }}</div>
        <div class="duration-holder bar-spacing frame vertical-center">
          <div class="label">{{ stringTable.word('off_timer_duration') }}</div>
          <SelectTime name="timerDuration" :minuteInterval="5" selectValue="00:05" />
        </div>
        <div v-if="showAc">
          <div v-if="showSetpoint">
            <DroppableAc :label="stringTable.word('sb_csp')" :showSliderAndValue="true"
              :firstVal="tempConvert.spConvert(28)" :openSlider="true" type="sb_csp" topRightBtn="disable"
              :range="[tempConvert.spConvert(24), tempConvert.spConvert(35)]" :interval="1" />
            <DroppableAc :label="stringTable.word('sb_hsp')" :showSliderAndValue="true"
              :firstVal="tempConvert.spConvert(18)" :openSlider="true" type="sb_hsp" topRightBtn="disable"
              :range="[Math.round(tempConvert.spConvert(12)), tempConvert.spConvert(20)]" :interval="1" />
            <DroppableAc :label="`${stringTable.word('op_limit')}: ${stringTable.word('cool')}`"
              :showSliderAndValue="true" type="csp_limit" :firstVal="tempConvert.spConvert(16)"
              :secondVal="tempConvert.spConvert(32)" :openSlider="true" topRightBtn="disable"
              :range="[Math.round(tempConvert.spConvert(16)), tempConvert.spConvert(32)]"
              :interval="tempConvert.spGap()" />
            <DroppableAc :label="`${stringTable.word('op_limit')}: ${stringTable.word('heat')}`"
              :showSliderAndValue="true" type="hsp_limit" :firstVal="tempConvert.spConvert(16)"
              :secondVal="tempConvert.spConvert(32)" :openSlider="true" topRightBtn="disable"
              :range="[Math.round(tempConvert.spConvert(16)), tempConvert.spConvert(32)]"
              :interval="tempConvert.spGap()" />
          </div>
          <div v-if="showRcProh()">
            <BarTwoBtn label="rop_proh" :buttons="['enable', 'disable']" :topBottom="true" />
            <BarTwoBtn label="rsp_proh" :buttons="['enable', 'disable']" :topBottom="true" />
            <BarTwoBtn label="rmode_proh" :buttons="['enable', 'disable']" :topBottom="true" />
            <BarTwoBtn label="rfan_proh" :buttons="['enable', 'disable']" :topBottom="true" />
          </div>
        </div>
        <div v-else-if="showHrv && !showAc">
          <BarTwoBtn label="rop_proh" :buttons="['enable', 'disable']" :topBottom="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.pop-up.group-oper .loading-holder {
  margin-top: -80px !important;
}

.pop-up.group-oper .bar-two-btn .btn {
  margin-top: 5px;
  margin-bottom: 5px;
}

.pop-up.group-oper .bar-two-btn .btn-holder {
  text-align: end;
  /* width: 100; */
}

.pop-up.group-oper .label {
  margin-right: auto;
  width: fit-content;
  padding: 0 8px;
}

.pop-up.group-oper .btn {
  padding: 4px 10px;
  border-radius: 30px;
}

.pop-up.group-oper .ac-holder .btn,
.pop-up.group-oper .ventilation-holder .btn {
  padding: 0;
  border-radius: 8px;
}

.pop-up.group-oper .content-holder * {
  font-size: .98em;
}

.pop-up.group-oper .content-holder .title {
  font-size: 15px;
  text-align: center;
  padding: 4px 6px 6px 6px;
}

.pop-up.group-oper .content-holder {
  display: flex;
  justify-content: center;
  width: inherit;
  padding: 4px 0;
}

.pop-up.group-oper .content-holder>div {
  height: 530px;
  padding: 0 8px;
  width: 100%;
  max-width: 400px;
  border-left: 2px solid var(--disable);
}

.pop-up.group-oper .content-holder>div:first-child {
  border-left: none;
}

/* .pop-up.group-oper .adv-stg-holder {
  height: 530px;
} */

.pop-up.group-oper .select-time select {
  height: 30px !important;
}

.pop-up .duration-holder {
  padding: 6px;
}

.pop-up .duration-holder .select-time {
  margin-left: auto;
}

/* .pop-up.group-oper .adv-stg-holder .select-time {
} */
</style>

<script>
import PopUpHead from './PopUpHead.vue'
import BarTwoBtn from './BarTwoBtn.vue'
import DroppableAc from './DroppableAc.vue'
import BarIconBtn from './BarIconBtn.vue'
import SelectTime from './SelectTime.vue'
import LoadingSpinner from './LoadingSpinner.vue'

export default {
  name: "PopUpOperGrp",
  props: {
    commModule: Object,
    details: Object,
  },
  data() {
    return {
      isLoading: false,
      loadingTimer: null,
      timerDuration: '',
      modeObj: {},
      fSpeedObj: {},
      // mode_caps: [],
      // mode2_caps: [],
      showGen: false,
      showAc: false,
      showHrv: false,
      showDehumi: false,
      detailsCloned: {},
      boolToValueMap: { 'stat_on': { true: 'on', false: 'off' }, 'off_timer': { true: 'on', false: 'off' } },
      valueToBoolMap: { 'stat_on': { 'on': true, 'off': false }, 'off_timer': { 'on': true, 'off': false } },
      timer: null,
      fanstepKey: { '2': ['L', 'H', 'A'], '3': ['L', 'M', 'H', 'A'], '5': ['L', 'LM', 'M', 'MH', 'H', 'A'] },
      canStartSend: false,
      lang: '',
      showSetpoint: false,
      showFanstep: false
    }
  },
  created() {
    this.isLoading = true
    this.startLoadingTimer()
    this.lang = localStorage.getItem('Lang')
    this.details.mp.forEach(point => {
      if (this.commModule.point_list[point]) {
        if (this.commModule.point_list[point].comm_stat) {
          this.showGen = true;
        }
      }
    });
    this.showAc = this.details.usages.includes('ac');
    this.showHrv = this.details.usages.includes('hrv');
    this.showDehumi = this.details.usages.includes('dehumi');
    this.modeObj = { ...this.ReiriIcons.iconList.mode };
    delete this.modeObj.T;
    this.fSpeedObj = { ...this.ReiriIcons.iconList.fanstep3 };
    delete this.fSpeedObj.Q;

    this.detailsCloned = JSON.parse(JSON.stringify(this.details))
    setTimeout(() => {
      this.canStartSend = true;
    }, 500);

    this.detailsCloned.mp.forEach(point_id => {
      var point = this.commModule.point_list[point_id];
      if (point) {
        if (point.sp_cap) this.showSetpoint = true;
        if (point.fanstep_cap && point.fanstep_cap.S != 1) this.showFanstep = true;
      }
    });
  },
  watch: {
  },
  computed: {
    isLongLanguage() {
      return ['ES', 'BR', 'THAI', 'BAH'].includes(this.lang)
    }
  },
  methods: {
    showRcProh() {
      var toShow = false
      this.detailsCloned.mp.forEach(point_id => {
        var point = this.commModule.point_list[point_id];
        if (point && point.rc_proh_cap) {
          toShow = true;
        }
      })
      return toShow
    },
    startLoadingTimer() {
      if (this.loadingTimer)
        clearTimeout(this.loadingTimer);
      this.loadingTimer = setTimeout(() => {
        this.isLoading = false
      }, 1);
    },
    topRightBtnClicked(type) {
      if (!this.canStartSend) return;
      if (type == 'sb_csp' || type == 'sb_hsp') {
        this.detailsCloned.mp.forEach(pointKey => {
          if (this.commModule.point_list[pointKey].type == 'Ac') {
            const updateResult = {
              [pointKey]: {
                [type]: "--"
              }
            };
            this.commModule.editPoint(updateResult);
          }
        })
      } else if (type == 'csp_limit' || type == 'hsp_limit') {
        this.detailsCloned.mp.forEach(pointKey => {
          const updateResult = {
            [pointKey]: {
              [type == 'csp_limit' ? 'csp_limit_valid' : 'hsp_limit_valid']: false
            }
          };
          this.commModule.editPoint(updateResult);
        })
      }
    },
    startTimer() {
    },
    performFunction() {
    },
    changeTime(name, newTime) {
      if (!this.canStartSend) return;
      this.detailsCloned.mp.forEach(pointKey => {
        const updateResult = {
          [pointKey]: {
            ['off_duration']: this.timeStringToMinutes(newTime)
          }
        };
        this.commModule.editPoint(updateResult);
      })
    },
    timeStringToMinutes(timeString) {
      // Split the time string into hours and minutes
      const [hours, minutes] = timeString.split(':').map(Number);
      // Convert hours to minutes and add to the minutes
      return hours * 60 + minutes;
    },
    barTwoBtnClicked(value, type) {
      if (!this.canStartSend) return;
      if (type == "stat") {
        // var resultGrp = JSON.parse(JSON.stringify(this.detailsCloned))
        // resultGrp["stat_on"] = this.valueToBoolMap["stat_on"][value]
        // delete resultGrp["usages"]
        // delete resultGrp["grpKey"]
        // var result = [this.detailsCloned.grpKey, resultGrp]
        // this.commModule.editGroup(result)
        this.detailsCloned.mp.forEach(pointKey => {
          const updateResult = {
            [pointKey]: {
              [type]: value
            }
          };
          this.commModule.editPoint(updateResult);
        })
      } else if (type == 'off_timer') {
        this.detailsCloned.mp.forEach(pointKey => {
          const updateResult = {
            [pointKey]: {
              [type]: value
            }
          };
          this.commModule.editPoint(updateResult);
        })
      } else if (['rop_proh', 'rsp_proh', 'rmode_proh', 'rfan_proh'].includes(type)) {
        this.detailsCloned.mp.forEach(pointKey => {
          // if (Object.prototype.hasOwnProperty.call(this.commModule.point_list[pointKey], type)) {
          const updateResult = {
            [pointKey]: {
              [type]: value == "enable" ? true : false
            }
          };
          this.commModule.editPoint(updateResult);
          // }
        });
      }
    },
    barIconBtnClicked(value, type) {
      if (!this.canStartSend) return;
      if (type == 'mode') {
        this.detailsCloned.mp.forEach(pointKey => {
          // check mode cap, if true only update 
          if (this.commModule.point_list[pointKey]) {
            if (this.commModule.point_list[pointKey].type == 'Ac') {
              if (this.commModule.point_list[pointKey].mode_cap[value]) {
                const updateResult = {
                  [pointKey]: {
                    [type]: value
                  }
                };
                this.commModule.editPoint(updateResult);
              }
            }
          }
        })
      }
      else if (type == 'fanstep') {
        this.detailsCloned.mp.forEach(pointKey => {
          if (this.commModule.point_list[pointKey]) {
            //            console.log(this.commModule.point_list[pointKey].type)
            var updateResult = {}
            if (this.commModule.point_list[pointKey].type == 'Ac') {
              if (this.fanstepKey[this.commModule.point_list[pointKey].fanstep_cap['S']].includes(value)) {
                updateResult = {
                  [pointKey]: {
                    [type]: value
                  }
                };
              }
            } else {
              updateResult = {
                [pointKey]: {
                  [type]: value
                }
              };
            }
            //            console.log(updateResult)
            this.commModule.editPoint(updateResult);
          }
        })
      }
      else if (['vmode', 'vamount'].includes(type)) {
        this.detailsCloned.mp.forEach(pointKey => {
          if (this.commModule.point_list[pointKey]) {
            if (this.commModule.point_list[pointKey].type == 'Hrv') {
              //              console.log(value, type)
              const updateResult = {
                [pointKey]: {
                  [type]: value
                }
              };
              //              console.log(updateResult)
              this.commModule.editPoint(updateResult);
            }
          }
        })
      }
    },
    changeSliderValue(value, type) {
      if (!this.canStartSend) return;
      if (['sp', 'sb_csp', 'sb_hsp'].includes(type)) {
        this.detailsCloned.mp.forEach(pointKey => {
          var typeKey = type;
          var canUpdate = true;
          if (typeKey == 'sp') {
            var point = this.commModule.point_list[pointKey];
            if (point) {
              var mode = point.mode;
              if (mode == 'A')
                mode = point.actual_mode;
              if (mode == 'C') typeKey = 'csp';
              if (mode == 'H') typeKey = 'hsp';
            }
            canUpdate = typeKey != 'sp';
          }
          if (canUpdate) {
            const updateResult = {
              [pointKey]: {
                [typeKey]: type == 'sp' ? Math.round(this.tempConvert.spRevConvert(value) * 2) / 2 : this.tempConvert.spRevConvert(value)
              }
            };
            this.commModule.editPoint(updateResult);
          }
        })
      } else if (['csp_limit', 'hsp_limit'].includes(type)) {
        this.detailsCloned.mp.forEach(pointKey => {
          if (this.commModule.point_list[pointKey].type == 'Ac') {
            const updateResult = {
              [pointKey]: {
                [type]: this.tempConvert.spRevConvertDoubleItemArr(value),
                [type == 'csp_limit' ? 'csp_limit_valid' : 'hsp_limit_valid']: true
              }
            };
            this.commModule.editPoint(updateResult);
          }
        })
      } else if (['hum_sp'].includes(type)) {
        this.detailsCloned.mp.forEach(pointKey => {
          if (this.commModule.point_list[pointKey].type == 'Dehumi') {
            const updateResult = {
              [pointKey]: {
                [type]: value
              }
            };
            this.commModule.editPoint(updateResult);
          }
        })
      }
    },
  },
  components: {
    PopUpHead,
    BarTwoBtn,
    DroppableAc,
    BarIconBtn,
    SelectTime,
    LoadingSpinner,
  }
}
</script>