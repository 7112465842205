<template>
  <div class="pop-up hotel-room system-setting device-setting">
    <PopUpToast />
    <div class="content-holder">
      <div class="left-content nav-hori-holder">
        <PopUpHead :details="{ name: 'conf_rent_unit' }" :hideBorderBtm="true" :leftArr="true" :small="true"
          :hideCross="true" />
        <LoadingSpinner v-if="isLoadingLeft" :topSpacing="true" />
        <div v-else class="nav-list">
          <div class="frame">
            <NavHorizontal v-for="details, title  in screenTitleMap" :key="title" :name="title" :icon="details.icon"
              @click="showScreen(details, title)" :isIconActiveColor="true" :noOneLine="true" />
          </div>
        </div>
      </div>
      <div class="right-frame">
        <LoadingSpinner v-if="isLoadingRight" />
        <component v-else :is="currentScreen" :commModule="commModule" :details="details" :cache="cacheCloned" />
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import PopUpToast from './PopUpToast.vue'
import PopUpHead from './PopUpHead.vue'
import NavHorizontal from './NavHorizontal.vue'
import GuestRooms from './GuestRooms.vue'
import GuestLocations from './GuestLocations.vue'
import GuestSettings from './GuestSettings.vue'
import LoadingSpinner from './LoadingSpinner.vue'

export default {
  name: "PopUpGuestRoom",
  props: {
    commModule: Object,
    cache: Object,
  },
  data() {
    return {
      toast: {},
      isLoadingLeft: false,
      isLoadingRight: false,
      cacheCloned: this.cache,
      details: {},
      currentScreen: '',
      toChange: {},
      screenTitleMap: {
        guest_rooms: { screen: 'GuestRooms', icon: 'bed_room' },
        locations: { screen: 'GuestLocations', icon: 'floor' },
        set_ctrl_param: { screen: 'GuestSettings', icon: 'setting_2' },
      }
    }
  },
  created() {
    this.commModule.getGuests();
    if (this.cacheCloned) {
      this.details.name = this.cacheCloned.screen
      this.currentScreen = this.screenTitleMap[this.details.name].screen;
    } else {
      this.isLoadingLeft = true;
    }
  },
  watch: {
    'commModule.guest_room_settings'() {
      this.commModule.getGuestRooms();
    },
    'commModule.get_unit_stat'(availableRooms) {
      var availableRoomNames = Object.keys(availableRooms);
      var settings = this.commModule.guest_room_settings;
      if (!settings.roomOrder) settings.roomOrder = availableRoomNames
      availableRoomNames.forEach(roomName => {
        if (settings.roomOrder && !settings.roomOrder.includes(roomName))
          settings.roomOrder.push(roomName);
      });
      if (Object.keys(this.toChange).length > 0) {
        this.details = { name: this.toChange.title, settings };
        this.currentScreen = this.toChange.screen;
        this.toChange = {};
      }
      this.isLoadingRight = false;
      this.isLoadingLeft = false;
    },
  },
  methods: {
    showScreen(details, title) {
      this.isLoadingRight = true;
      this.commModule.getGuests();
      this.toChange = { title, screen: details.screen, }
      this.currentScreen = '';
      this.cacheCloned = null;
    },
    closeRightScreen(showCompleted) {
      this.currentScreen = '';
      if (showCompleted) {
        this.isLoadingRight = true;
        this.commModule.getGuests();
        this.toast = { text: 'succeed' }
      }
    }
  },
  computed: {
  },
  components: {
    PopUpToast,
    PopUpHead,
    NavHorizontal,
    GuestRooms,
    GuestLocations,
    GuestSettings,
    LoadingSpinner
  }
}
</script>
