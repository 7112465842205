<template>
  <PopUpToast />
  <div class="bar-reorderable vertical-center">
    <div class="content">
      <!-- {{ cache.page }} -->
      <div v-for="item, index in slicedList" :key="index" class="frame vertical-center"
        :class="{ 'invisible': !item, 'highlight': index == movedIndex }" @click="barClicked(items, item)"
        :role="!noClick ? 'button' : ''">
        <img class="icon-up frame circle" :class="{ 'hidden': index + index_spacing == 0 }" :src="ReiriIcons.chevron_up"
          @click.stop="changeOrder(index, true)" />
        <img class="icon-down frame circle" :class="{ 'hidden': index + index_spacing == items.length - 1 }"
          :src="ReiriIcons.chevron_down" @click.stop="changeOrder(index, false)" />
        <img v-if="item && item.iconDisplay" class="icon-main" :src="item.iconDisplay" />
        <div v-if="item" class="name">{{ item.name }}</div>
        <img v-if="!hideDelete" class="delete to-error" :src="ReiriIcons.delete" @click.stop="deleteItem(index)" />
      </div>
    </div>
    <div class="pagin-holder">
      <NavPagination direction="up" />
      <NavPagination direction="down" />
    </div>
  </div>
</template>

<style>
.bar-reorderable .content {
  flex: 1;
  margin-bottom: auto;
}

.bar-reorderable .content>.frame {
  padding: 3px 10px 3px 6px;
  margin: 6px 4px;
  border: 2px solid transparent;
}

.bar-reorderable .content>.frame.highlight {
  border-color: var(--active);
}

.bar-reorderable .icon-main {
  width: 30px;
}

.bar-reorderable .icon-up,
.bar-reorderable .icon-down {
  padding: 4px;
  margin: 0 3px;
}

.bar-reorderable .icon-down {
  margin-right: 14px;
}

.bar-reorderable .name {
  margin: 0 6px;
}

.bar-reorderable .delete {
  width: 30px;
  margin-left: auto;
}

.bar-reorderable .pagin-holder {
  margin: 0 3px;
}
</style>

<script>
import PopUpToast from './PopUpToast.vue'
import NavPagination from './NavPagination.vue'

export default {
  name: "BarReorderable",
  props: {
    cache: Object,
    nameLi: Array,
    iconRefer: Object,
    hideDelete: Boolean,
    hideIcon: Boolean,
    noClick: Boolean,
    removeHighlight: Boolean,
    pageTrack: Number,
    itemPerPage: Number,
  },
  data() {
    return {
      slicedList: [],
      toast: {},
      page: this.$parent.page,
      movedIndex: -1,
    }
  },
  created() {
  },
  watch: {
    removeHighlight() {
      this.movedIndex = -1;
    },
    page() {
      this.$parent.page = this.page;
    },
    pageTrack() {
      this.page = this.pageTrack;
    },
  },
  methods: {
    barClicked(items, item) {
      if (!this.noClick)
        this.$parent.barClicked(items, item);
    },
    changeOrder(index, isUp) {
      index = index + this.index_spacing;
      var list = this.$parent.reorder;
      var besideIndex = isUp ? index - 1 : index + 1;
      var targetContent = list[index];
      var besideContent = list[besideIndex];
      list[besideIndex] = targetContent;
      list[index] = besideContent;
      this.movedIndex = besideIndex - this.index_spacing;
      if (this.movedIndex == -1) {
        this.page--; this.movedIndex = this.itemPerPage - 1;
      } else if (this.movedIndex == this.itemPerPage) {
        this.page++; this.movedIndex = 0;
      } if ('reorderChanged' in this.$parent.$options.methods)
        this.$parent.reorderChanged();
    },
    deleteItem(index) {
      this.toast = {
        text: 'conf_del',
        pendingYesMethod: () => {
          var delIndex = index + this.index_spacing;
          var deletedItem = this.$parent.reorder[delIndex];
          this.$parent.reorder.splice(delIndex, 1);
          if ('reorderDeleted' in this.$parent.$options.methods)
            this.$parent.reorderDeleted(deletedItem);
          this.movedIndex = -1;
        }
      }
    },
    pageClicked() {
      this.movedIndex = -1;
    }
  },
  computed: {
    index_spacing() {
      return (this.page - 1) * this.itemPerPage;
    },
    mainList() {
      return this.items;
    },
    items() {
      var items = [];
      if (this.nameLi) {
        this.nameLi.forEach(item => {
          var icon, iconDisplay;
          if (!this.hideIcon) {
            icon = 'Single.png';
            if (this.cache && this.cache.content.name == item && this.cache.content.icon) {
              icon = this.cache.content.icon;
            } else if (this.iconRefer && Object.keys(this.iconRefer).length > 0 && this.iconRefer[item] && this.iconRefer[item].icon) {
              icon = this.iconRefer[item].icon;
            }
            iconDisplay = this.ReiriIcons.groupIconMap[icon];
          }
          items.push({ name: item, iconDisplay, icon });
        });
      }
      return items;
    },
  },
  components: {
    PopUpToast,
    NavPagination
  },
}
</script>
