<template>
  <div class="select-dropdown-holder"
    :class="{ 'plus-btn': plusIcon, 'chevon-right': showChevonRight, 'frame': !plusIcon, 'border-btm': borderBtm }">
    <!-- {{ isDropdownVisible }} -->
    <div class="vertical-center">
      <div v-if="title" class="title">{{ stringTable.word(title) }}</div>
      <div class="btn-group">
        <img v-if="showChevonRight" class="add-device-btn" :src="ReiriIcons.chevron_right" data-bs-display="static"
          :data-bs-auto-close="auto_close" />
        <img v-else-if="plusIcon" class="add-rotate add-device-btn" :class="{ 'hidden': plusIconHidden }"
          :src="ReiriIcons.cross" :data-bs-toggle="disableToggle ? '' : 'dropdown'" data-bs-display="static"
          data-bs-auto-close="outside" role="button" />
        <div v-else-if="hideCheckBox" class="point-select btn text-left" :data-bs-toggle="disableToggle ? '' : 'dropdown'"
          data-bs-display="static" :data-bs-auto-close="auto_close" role="button">
          <!-- {{ selectedPoint }} -->
          <div class="vertical-center">
            <img v-if="Object.keys(selectedPoint).length != 0 && !isCond" :src="IconsHelper.iconMain(selectedPoint)"
              class="point-icon" />
            <div v-if="isCond" :class="{ 'grey-text': selectedCond == '' }">
              {{ selectedCond == '' ? stringTable.word("no_condition") : stringTable.word(selectedCond) }}
            </div>
            <div v-else :class="{ 'grey-text': Object.keys(selectedPoint).length == 0 }">
              {{ Object.keys(selectedPoint).length == 0 ? (isCondDevice ? stringTable.word("sel_device") :
                (isRTCond ? stringTable.word("no_condition") : stringTable.word("select"))) :
                stringTable.word(selectedPoint.name) }}
            </div>
          </div>
          <div>
            <img :src="ReiriIcons.chevron_down" />
          </div>
        </div>
        <button v-else-if="!invisible" class="btn select-click-able" :class="{ 'normal-font': normalFontLabel }"
          :data-bs-toggle="disableToggle ? '' : 'dropdown'" data-bs-display="static" :data-bs-auto-close="auto_close"
          @click="clickedDropDown">
          {{ stringTable.word(mainLabel) }}
        </button>
        <ul ref="dropdownMenu" class="dropdown-menu" :class="{ 'dropdown-top': plusIcon, 'got-pagin': show_pagin }">
          <div class="vertical-top">
            <div v-if="hideCheckBox" class="bars-check-boxes hide-check-boxes">
              <div v-for="item in processed_sliced_list" :key="item" role="button">
                <div v-if="item" class="form-check vertical-center" @click="hideCheckBox ? pointClicked(item) : {}">
                  <label class="form-check-label vertical-center">
                    <div v-if="!hideIcon" class="icon-holder">
                      <img :src="IconsHelper.iconMain(item)" />
                    </div>
                    <div class="can-scroll one-line" :class="{ 'label-spacing': hideIcon }">
                      <div v-if="isExecCond">{{ stringTable.word(execConditionDescp(item)) }}</div>
                      <div v-else-if="showChevonRight || isCond || showStringTableValue">{{ stringTable.word(item) }}
                      </div>
                      <div v-else>{{ item.name }}</div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <BarCheckBoxes v-else :items="processed_sliced_list" :checkedString="checkedString" :hideIcon="hideIcon"
              :showStringTableValue="showStringTableValue" :isTarget="isTarget" :commModule="commModule" />

            <div class="pagin-holder">
              <div v-if="show_pagin">
                <NavPagination direction="up" :noNull="true" />
                <NavPagination direction="down" :noNull="true" />
              </div>
              <div v-else style="display: none">
                <NavPagination direction="up" :noNull="true" />
              </div>
            </div>
          </div>
        </ul>
      </div>
    </div>
    <div v-if="subtitle && subtitle.length > 0" class="subtitle">{{ subtitle }}</div>
  </div>
</template>

<style>
.grey-text {
  color: var(--hint);
}

.select-dropdown-holder {
  width: 100%;
  padding: 0;
}

.select-dropdown-holder .dropdown-top {
  margin-left: -310px !important;
}

.select-dropdown-holder.border-btm {
  border-bottom: 2px solid var(--disable);
}

.select-dropdown-holder .btn-group {
  width: 100%;
}

.select-dropdown-holder .add-device-btn {
  margin-left: auto !important;
}

.select-dropdown-holder .point-select.text-left {
  text-align: left;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.select-click-able,
.select-click-able:hover {
  font-size: 1.2em;
  color: var(--active);
}

.select-click-able.normal-font,
.select-click-able.normal-font:hover {
  font-size: 1em;
  color: black;
}

.select-dropdown-holder .dropdown-menu {
  padding: 6px;
  width: 420px;
  max-height: 370px;
}

.select-dropdown-holder .subtitle {
  color: var(--fontGray);
  padding: 0 16px 0 16px;
}

.select-dropdown-holder .pagin-holder {
  margin-left: auto;
}

.select-dropdown-holder.chevon-right {
  width: fit-content;
}

.select-dropdown-holder.plus-btn {
  padding: 0;
}

.select-dropdown-holder.plus-btn .title {
  width: 100%;
}

.select-dropdown-holder.plus-btn .btn-group {
  margin-left: auto;
  width: fit-content;
}

.select-dropdown-holder .can-scroll {
  max-width: 220px;
}

.bars-check-boxes.hide-check-boxes {
  padding-left: 6px;
}

.bars-check-boxes.hide-check-boxes .form-check {
  padding-left: 0;
}

.bars-check-boxes.hide-check-boxes .icon-holder {
  margin-left: 0;
}

.bars-check-boxes.hide-check-boxes .one-line {
  width: 100%;
  max-width: 300px;
}

.dropdown-menu .one-line {
  width: inherit;
}

.dropdown-menu.got-pagin .one-line {
  /* width: 225px; */
  width: auto;
}
</style>

<script>
import BarCheckBoxes from './BarCheckBoxes.vue'
import NavPagination from './NavPagination.vue'

export default {
  name: "SelectDropDown",
  props: {
    mainLabel: String,
    items: Array,
    plusIcon: Boolean,
    plusIconHidden: Boolean,
    maxSelection: Number,
    title: String,
    subtitle: String,
    normalFontLabel: Boolean,
    checkedString: String,
    isCloseOutside: Boolean,
    isCloseFalse: Boolean,
    hideIcon: Boolean,
    hideCheckBox: Boolean,
    currentPoint: Object,
    currentCond: String,
    showChevonRight: Boolean,
    isCond: Boolean,
    isRTCond: Boolean,
    isExecCond: Boolean,
    isCondDevice: Boolean,
    borderBtm: Boolean,
    showStringTableValue: Boolean,
    invisible: Boolean,
    isTarget: Boolean,
    disableToggle: Boolean,
    commModule: Object,
  },
  data() {
    return {
      page: 1,
      itemPerPage: 8,
      slicedList: [],
      selectedPoint: {},
      selectedCond: '',
      mainListCopy: [],
      isDropdownVisible: false,
    }
  },
  created() {

    this.mainListCopy = this.mainList;
    this.selectedPoint = this.currentPoint ? this.currentPoint : {}
    this.selectedCond = this.currentCond ? this.currentCond : ''
  },
  watch: {
    items() {
      if (JSON.stringify(this.mainListCopy) !=
        JSON.stringify(this.mainList)) {
        this.page = 1;
        this.mainListCopy = this.mainList;
        this.selectedPoint = {};
      }
    },
    currentCond() {
      this.selectedCond = this.currentCond ? this.currentCond : ''
    },
  },
  methods: {
    // parentMethodsGot(methodName) {
    //   var parentMethods = this.$parent.$options.methods;
    //   const parentMethodsKeys = Object.keys(parentMethods ? parentMethods : {});
    //   return parentMethodsKeys.includes(methodName);
    // },
    clickedDropDown() {
      if (this.ConvertHelper.parentMethodsGot(this, 'clickedDropDown'))
        this.$parent.clickedDropDown(
          this.$refs.dropdownMenu.classList.contains("show"));
    },
    closeDropDown() {
      this.$refs.dropdownMenu.classList.remove("show")
    },
    toggleDropdown() {
      // Toggle the visibility of the dropdown menu
      this.isDropdownVisible = !this.isDropdownVisible;
      if (this.isDropdownVisible)
        this.$refs.dropdownMenu.classList.add("show")
      else
        this.closeDropDown()
    },
    execConditionDescp(value) {
      if (value == "all") return "all_valid"
      else if (value == "one") return "one_valid"
      return "n_valid"
    },
    pointClicked(item) {
      if (this.hideCheckBox)
        this.closeDropDown()
      if (this.isCond) this.selectedCond = item
      else this.selectedPoint = item
      this.$parent.changeOption(item, this.isCond, this.isRTCond, this.isCondDevice)
    },
    clickedCheckBox(dev) {
      if (this.maxSelection)
        var checkedCount = this.items.filter(d => d[this.checkedString]).length;
      var isOverMax = checkedCount > this.maxSelection;
      if (isOverMax) dev[this.checkedString] = false;
      this.$parent.clickedCheckBox(dev, isOverMax, this.checkedString);
    },
  },
  computed: {
    show_pagin() {
      return this.items != null && (this.is_object ? Object.keys(this.items) : this.items).length > 8
    },
    is_object() {
      return typeof this.items === 'object' && !Array.isArray(this.items);
    },
    mainList() {
      if (this.is_object)
        return Object.keys(this.items);
      return this.items;
    },
    auto_close() {
      return this.isCloseFalse ? 'false' :
        !this.hideCheckBox || this.isCloseOutside ? 'outside' : 'true';
    },
    processed_sliced_list() {
      if (this.is_object) {
        var resLi = [];
        this.slicedList.forEach(key => {
          resLi.push(this.items[key]);
        });
        return resLi;
      } return this.slicedList;
    }
  },
  components: {
    BarCheckBoxes,
    NavPagination
  },

}
</script>

