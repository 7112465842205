<template>
  <div v-if="!details" class="tile frame hidden"></div>
  <div v-else class="tile tile-history frame vertical-center">
    <div class="whole-content">
      <div class="top-content vertical-center">
        <div class="device-name font-bold">
          <!-- {{ details }} -->
          <div v-if="details[1] == 'system'">{{ stringTable.word(details[2]) }} {{ stringTable.word(details[5]) ?
            ': ' + stringTable.word(details[5]) : '' }}
          </div>
          <div v-else-if="details[1] == 'func'"> {{ stringTable.word(details[2]) }}</div>
          <div v-else-if="['cos', 'op', 'error'].includes(details[1])">
            {{ commModule.point_list[details[3]] ? commModule.point_list[details[3]].name : details[3] }}
          </div>
          <div v-else>{{ stringTable.word(details[3]) }}</div>
        </div>
        <div class="time">
          <div>{{ this.ConvertHelper.dtServerToTimeString(details[0]) }}</div>
        </div>
      </div>
      <div class="btm-content vertical-center">
        <div class="description-tile-history can-scroll one-line vertical-center">
          <div v-if="details[1] == 'error'">{{ (Object.values(details[4].error)[0]) ? stringTable.word("error") :
            stringTable.word("clear") }}: {{ Object.keys(details[4].error)[0] }}</div>
          <div v-else-if="details[1] == 'cos'" class="description-deviceOutput">
            <span v-for="(value, key, index) in details[4]" :key="key">{{ stringTable.word(key) }}: {{
              key == "csp" || key == "hsp" ? stringTable.word(this.tempConvert.spConvert(value)).toFixed(1) :
              stringTable.word(value) }}{{ key == "csp" || key == "hsp" ? this.stringTable.word('DEG') : '' }}
              {{ Object.keys(details[4]).length - index > 1 ? ",&nbsp;" : "" }}
            </span>
          </div>
          <div v-else-if="details[1] == 'system'">
            <div v-if="['connect', 'disconnect'].includes(details[2])">
              {{ stringTable.word(details[4]['dev']) + ': ' + details[3] }}
            </div>
            <div v-else-if="['login_fail'].includes(details[2])">
              {{ stringTable.word(details[4]['result']) }}
            </div>
            <div v-else-if="['ssc_con'].includes(details[2])">
              {{ stringTable.word(details[4]) }}
            </div>
            <div v-else-if="['sys_start'].includes(details[2])">
              {{ stringTable.word(details[4]['model']) + ': ' + details[4]['ver'] }}
            </div>
            <div v-else-if="['message'].includes(details[2])">
              {{ stringTable.word(details[4]) }}
            </div>
            <div v-else-if="['update'].includes(details[2])">
              {{ stringTable.word(details[4]) }}
            </div>
          </div>
          <!-- <div v-else-if="details[1] == 'op'">
            {{ stringTable.word(Object.keys(details[4])) }}: {{ stringTable.word(Object.values(details[4])) }} - {{
              stringTable.word(details[5]) }}
          </div> -->
          <div v-else-if="details[1] == 'alert'">
            {{ stringTable.word("alert") }}
          </div>
          <div v-else-if="['op', 'func'].includes(details[1])">
            <span v-if="details[4] && Object.keys(details[4]).some(k =>
              ['sp', 'csp_limit', 'hsp_limit', 'csp_limit_valid', 'hsp_limit_valid'].includes(k))">
              {{ stringTable.word('attr_change') }}
            </span>
            <span v-else v-for="(value, key, index) in details[4]" :key="key">
              {{ stringTable.word(key) }}: {{ stringTable.word(value) }}{{ Object.keys(details[4]).length - index > 1
                ?
                ",&nbsp;" : "" }}
            </span>
            <span v-if="details[1] == 'op'"> - {{ stringTable.word(details[5]) }}</span>
          </div>
          <div v-else>
            {{ '' }}
          </div>
        </div>
        <div class="status">{{ stringTable.word(details[1]) }}</div>
      </div>
    </div>
  </div>
  <!-- <div class="left-content">
      <div class="upper-left-content">
      </div>
    <div class="right-content">
    </div> -->
</template>

<style>
.tile-history {
  height: 64px;
  padding: 0 14px !important;
}

.tile-history .whole-content {
  flex: 1;
}

.tile-history .top-content .time,
.tile-history .btm-content .status {
  margin-left: auto;
}

.tile-history .btm-content {
  height: 26px;
  width: inherit;
}

.tile-history .right-content {
  align-items: right;
  margin-left: auto;
}

.tile-history .device-name {
  margin: 2px 25px 0px 0px;
}

.tile-history .btm-content .status {
  background-color: #e0e4eb;
  font-weight: 700;
  font-size: 14px;
  width: max-content;
  padding: 3px 14px;
  border-radius: 20px;
}

.tile-history .description-tile-history {
  /* border: 1px solid black; */
  width: 0;
  flex: 1;
  color: gray;
  margin-right: 8px;
}
</style>

<script>
export default {
  name: "TileHistory",
  props: {
    commModule: Object,
    details: Object,
  },
  data() {
    return {
    }
  },
  computed: {
    deviceOutput() { // fan speed, setpoint
      return Object.keys(this.details[4])[0]
    }
  }
}
</script>