import wordList from './StringTable.json';

export class StringTable {
	constructor() {
		this.lang = 'EN';
		this.tempUnit = 'C';
		this.table = wordList;
	}

	setLang(lang) {
		if (lang != null) this.lang = lang;
	}

	setTempUnit(unit) {
		if (unit != null) this.tempUnit = unit;
	}

	word(key) {
		if (key == null) return '';
		if (key == 'DEG') return '°' + this.tempUnit;

		let word = this.table[this.lang][key];
		if (word == null) return key;
		return word;
	}
}