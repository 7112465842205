<template>
  <div class="pop-up holiday-form">
    <PopUpHead :details="details" />
    <div class="content-holder">
      <div class="month-nav vertical-center">
        <img :src="ReiriIcons.chevron_left" @click="prevMth()" role="button" />
        <div class="month-label">{{ currentMonth }}</div>
        <img :src="ReiriIcons.chevron_right" @click="nextMth()" role="button" />
      </div>
      <div class="cal-date-holder">
        <div class="day-of-week vertical-center justify-between">
          <div v-for="week in ConvertHelper.getWeeks()" :key="week" class="day-block">
            {{ week }}
          </div>
        </div>
        <div class="days-holder">
          <div v-for="week in weekInMonthList" :key="week" class="vertical-center justify-between">
            <div v-for="data in week" :key="data" class="day-block vertical-center" :class="data.class">
              <div v-if="data.day != Infinity" class="vertical-center justify-center" @click="clickDay(data)"
                role="button">
                {{ data.day }}
              </div>
            </div>
          </div>
        </div>
        <div class="legend-holder">
          <div v-for="lbl in [{ color: 'date', text: 'specified_by_date' },
          { color: 'dow', text: 'specified_by_day_of_week' }]" :key="lbl" class="vertical-center">
            <div class="color-label circle" :class="lbl.color"></div>
            <div class="text-label">{{ stringTable.word(lbl.text) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.pop-up.holiday-form .content-holder {
  width: 800px;
  margin: 0 auto;
  font-size: 1.2em;
}

.pop-up.holiday-form .month-nav {
  margin: 16px 0;
}

.pop-up.holiday-form .month-nav img {
  display: block;
  margin: 4px 14px;
}

.pop-up.holiday-form .month-label {
  margin-right: auto;
  font-size: 1.25em;
}

.pop-up.holiday-form .cal-date-holder {
  width: 700px;
  margin: 0 auto;
}

.pop-up.holiday-form .days-holder>div {
  margin: 8px 0;
}

.pop-up.holiday-form .content-holder .day-block {
  width: 50px;
  text-align: center;
}

.pop-up.holiday-form .days-holder .day-block {
  height: 50px;
  border-radius: 50%;
}

.pop-up.holiday-form .days-holder .day-block>div {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.pop-up.holiday-form .days-holder .day-block.date,
.pop-up.holiday-form .legend-holder .color-label.date {
  background-color: var(--specifyDate);
  color: white;
}

.pop-up.holiday-form .days-holder .day-block.dow,
.pop-up.holiday-form .legend-holder .color-label.dow {
  background-color: var(--specifyDow);
  color: white;
}

.pop-up.holiday-form .legend-holder {
  font-size: .9em;
  margin-top: 16px;
}

.pop-up.holiday-form .legend-holder .color-label {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
</style>

<script>
import PopUpHead from "./PopUpHead.vue"

export default {
  name: "PopUpCalendar",
  props: {
    commModule: Object,
    details: Object,
  },
  data() {
    return {
      currentMonth: '',
      currentMonthObj: new Date(),
      weekInMonthList: [],
      calendar: this.details.calendar
    }
  },
  created() {
    this.refreshWeekInMonthList();
  },
  methods: {
    getMthTh() {
      return this.currentMonthObj.getMonth() + 1;
    },
    getSpecifyByKey(key) {
      var isDow = key == 'dow';
      var res = this.calendar[key][this.getMthTh()]
      if (!res) this.calendar[key][this.getMthTh()] = isDow ? {} : [];
      return res;
    },
    getSpecify() {
      return {
        date: this.getSpecifyByKey('date'),
        dow: this.getSpecifyByKey('dow'),
      }
    },
    // delSpecifyByKey(key) {
    //   delete this.calendar[key][this.getMthTh()];
    // },
    refreshWeekInMonthList() {
      this.currentMonth = this.ConvertHelper.dtObjToMthYear(this.currentMonthObj);
      this.currentMonthObj.setDate(1);
      var lastDayOfMth = new Date(this.currentMonthObj.getFullYear(), this.currentMonthObj.getMonth() + 1, 0);
      var dayOfMonth = this.currentMonthObj.getDate();
      var dayOfMonthLast = lastDayOfMth.getDate();
      var dayDay = this.currentMonthObj.getDay();
      const firstDayDay = dayDay;

      this.weekInMonthList = [];
      while (dayOfMonth <= dayOfMonthLast) {
        var aWeek = new Array(dayDay).fill({});
        while (dayDay < 7) {
          if (dayOfMonth != Infinity) {
            var specify = this.getSpecify();
            var specifyType = '';
            var dowWeekTh;
            if (specify.date && specify.date.includes(dayOfMonth))
              specifyType = 'date';
            else if (specify.dow) {
              dowWeekTh = this.weekInMonthList.length + 1;
              if (dayDay < firstDayDay) dowWeekTh--;
              dowWeekTh = dowWeekTh == 5 ? 'last' : dowWeekTh;
              var dowWeeks = specify.dow[dowWeekTh];
              if (dowWeeks && dowWeeks.includes(dayDay))
                specifyType = 'dow';
            }
            aWeek.push({ day: dayOfMonth, class: specifyType, dayDay, dowWeekTh });
          }
          else aWeek.push({});
          if (dayOfMonth > dayOfMonthLast - 1)
            dayOfMonth = Infinity;
          else dayOfMonth++;
          dayDay++;
        } dayDay = 0;
        this.weekInMonthList.push(aWeek);
      }
    },
    prevMth() {
      this.currentMonthObj.setMonth(this.currentMonthObj.getMonth() - 1);
      this.refreshWeekInMonthList();
    },
    nextMth() {
      this.currentMonthObj.setMonth(this.currentMonthObj.getMonth() + 1);
      this.refreshWeekInMonthList();
    },
    clickDay(data) {
      var dataClassMap = { date: 'dow', dow: '', '': 'date' };
      data.class = dataClassMap[data.class];
      var specify = this.getSpecify();
      switch (data.class) {
        case 'date':
          specify.date.push(data.day);
          specify.date = specify.date.sort((a, b) => a - b);
          break;
        case 'dow':
          specify.date = this.ConvertHelper.removeArrayItems(specify.date, data.day);
          if (!specify.dow[data.dowWeekTh]) specify.dow[data.dowWeekTh] = [];
          specify.dow[data.dowWeekTh].push(data.dayDay);
          specify.dow[data.dowWeekTh] = specify.dow[data.dowWeekTh].sort((a, b) => a - b);
          break;
        default:
          specify.dow[data.dowWeekTh] = this.ConvertHelper.removeArrayItems(specify.dow[data.dowWeekTh], data.dayDay);
          break;
      }
    }
  },
  components: {
    PopUpHead,
  },
}

</script>